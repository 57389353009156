<ul class="pagination">
  <li class="page-item">
    <button
        (click)="getPage(1)"
        [disabled]="meta.current_page === 1"
        [ngClass]="{'page-link-disabled': meta.current_page === 1}"
        class="page-link">
      First
    </button>
  </li>
  <li class="page-item">
    <button
        (click)="getPage(meta.current_page - 1)"
        [disabled]="meta.current_page === 1"
        [ngClass]="{'page-link-disabled': meta.current_page === 1}"
        class="page-link">
      Prev
    </button>
  </li>
  <li class="page-item">
    <input (change)="goToInputPage()" type="number" class="form-control page-input" [(ngModel)]="meta.current_page">
  </li>
  <li class="page-item">
    <button
        (click)="getPage(meta.current_page + 1)"
        [disabled]="meta.current_page === meta.last_page"
        [ngClass]="{'page-link-disabled': meta.current_page === meta.last_page}"
        class="page-link">
      Next
    </button>
  </li>
  <li class="page-item">
    <button
        (click)="getPage(meta.last_page)"
        [disabled]="meta.current_page === meta.last_page"
        [ngClass]="{'page-link-disabled': meta.current_page === meta.last_page}"
        class="page-link">
      Last
    </button>
  </li>
  <li class="page-item ion-hide-sm-down">
    <span class="mx-2">Total pages: {{ meta.last_page }}</span>
  </li>
</ul>
