import { Component, OnDestroy, OnInit } from '@angular/core';
import { Inventory } from '../../models/inventory.model';
import { InventoriesService } from '../../services/inventories.service';
import { SnotifyService } from 'ng-snotify';
import { InventoriesResponse } from '../../responses/inventory.response';
import { Subscription } from 'rxjs';
import { BusService } from '../../../shared/services/bus.service';
import { InventoryTypeEnum } from '../../../shared/enums/inventory-type.enum';
import { Links, Meta } from '../../../shared/models/pagination.model';

@Component({
  selector: 'app-inventory-index',
  templateUrl: './inventory-index.component.html',
  styleUrls: ['./inventory-index.component.scss']
})
export class InventoryIndexComponent implements OnInit, OnDestroy {
  public inventories: Inventory[];
  public activeInventory: Inventory;
  public gettingAllInventories = false;
  public meta: Meta;
  public links: Links;
  public subscriptions: Subscription = new Subscription();
  public inventoryTypeEnum = InventoryTypeEnum;

  constructor(private inventoriesService: InventoriesService, private snotifyService: SnotifyService, private busService: BusService) {}

  ngOnInit() {
    this.getAllInventories();
    this.addSubscriptions();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  addSubscriptions() {
    this.subscriptions.add(
      this.busService.listen('loading').subscribe(event => {
        this.gettingAllInventories = event.data;
      })
    );

    this.subscriptions.add(
      this.busService.listen('pagination').subscribe(event => {
        this.updateInventoryList(event.data);
      })
    );
  }

  getAllInventories() {
    this.gettingAllInventories = true;

    this.inventoriesService.getAllInventories().subscribe(
      (response: InventoriesResponse) => {
        this.gettingAllInventories = false;
        this.updateInventoryList(response);
      },
      () => {
        this.gettingAllInventories = false;
        this.snotifyService.error('Unable to load inventory items. Please try again.', 'Error!');
      }
    );
  }

  onInventoryDelete(inventory: Inventory) {
    this.snotifyService.confirm(`Inventory item "${inventory.name}" is going to be deleted.`, 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.deleteInventory(inventory),
          bold: false
        },
        { text: 'No', action: () => {} }
      ]
    });
  }

  deleteInventory(inventory) {
    inventory.deleting = true;

    this.inventoriesService.deleteInventory(inventory).subscribe(
      () => {
        inventory.deleting = false;
        this.snotifyService.success(`Inventory item "${inventory.name}" has been successfully deleted.`, 'Success!');
        this.getAllInventories();
      },
      () => {
        inventory.deleting = false;
        this.snotifyService.error('Unable to delete inventory item. Please try again.', 'Error!');
      }
    );
  }

  updateInventoryList(response) {
    this.inventories = response.data;
    this.meta = response.meta;
    this.links = response.links;
  }
}
