<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Sites</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li class="breadcrumb-item active">
      <span>Sites</span>
    </li>
  </ol>

  <app-loading *ngIf="findingClientById"></app-loading>

  <div *ngIf="!findingClientById" class="animated fadeIn card card-accent-primary">
    <div class="card-header">
      <strong>Sites</strong>
      <div class="float-right">
        <button (click)="onSiteCreate()" class="btn btn-sm btn-primary">
          Add site
        </button>
      </div>
    </div>

    <div *ngIf="sites?.length === 0" class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      No sites found.
    </div>

    <div class="table-responsive">
      <table *ngIf="sites?.length > 0" class="table table-striped mb-0">
        <tr>
          <th>Name</th>
          <th class="text-right">Actions</th>
        </tr>

        <tr *ngFor="let site of sites">
          <td>{{ site.name }}</td>
          <td class="text-right d-md-none">
            <button
                (click)="activeSite = site"
                class="btn btn-secondary">
              <i class="fa fa-bars"></i>
            </button>
          </td>
          <td class="text-right d-none d-md-table-cell">
            <ng-container *ngTemplateOutlet="actions; context:{ $implicit: site }"></ng-container>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div *ngIf="activeSite" class="fixed-bottom" style="z-index: 9999;">
    <div class="list-group m-2 fadeIn">
      <ng-container *ngTemplateOutlet="actions; context:{ $implicit: activeSite }"></ng-container>
    </div>
  </div>

  <div *ngIf="activeSite" (click)="activeSite = null" class="modal-backdrop fade show"></div>

  <ng-template #actions let-site>
    <button
        (click)="onSiteUpdate(site)"
        [className]="activeSite ? 'list-group-item list-group-item-action' : 'btn btn-sm btn-info'">
      <i class="fa fa-edit mr-1"></i>
      Edit
    </button>
    <button
        (click)="onSiteDelete(site)"
        [disabled]="site.deleting"
        [className]="activeSite ? 'list-group-item list-group-item-action' : 'btn btn-sm btn-danger'">
      <span *ngIf="!site.deleting"><i class="fa fa-trash mr-1"></i> Delete</span>
      <span *ngIf="site.deleting"><i class="fa fa-spin fa-spinner"></i> Deleting</span>
    </button>
  </ng-template>
</ion-content>
