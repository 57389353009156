import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusService } from '../../services/bus.service';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
})
export class LoadingBarComponent implements OnInit {
  public syncingItems = 0;
  private subscriptions: Subscription = new Subscription();

  constructor(private busService: BusService) { }

  ngOnInit() {
    this.subscriptions.add(
      this.busService.listen('addSyncingItems').subscribe(event => {
        this.syncingItems += event.data;
      })
    );

    this.subscriptions.add(
      this.busService.listen('removeSyncedItems').subscribe(event => {
        this.syncingItems -= event.data;

        if (this.syncingItems === 0) {
          this.busService.dispatch({ type: 'refreshJobs', data: null });
        }
      })
    );
  }

}
