import { BaseModel } from '../classes/query-builder.class';

export class Client extends BaseModel {
  public table = 'clients';
  public schema = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    name: 'VARCHAR',
    address: 'VARCHAR',
    enabled_projects: 'INTEGER',
  };
}
