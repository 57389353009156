import { BaseModel } from '../classes/query-builder.class';

export class User extends BaseModel {
  public table = 'users';
  public schema = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    name: 'VARCHAR',
    email: 'VARCHAR',
    mobile: 'VARCHAR',
  };
}
