import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormTemplateResponse, FormTemplatesResponse } from '../responses/form-template.response';
import { environment } from '../../../../environments/environment';
import { QueryBuilder } from '../../shared/classes/query-builder.class';
import { FormTemplate } from '../../shared/entities/form_template.entity';
import { ApiService } from '../../shared/services/api.service';

@Injectable()
export class FormTemplatesService {
  private resource = 'form_templates';
  private queryBuilder: QueryBuilder;

  constructor(private http: HttpClient) {
    if (environment.cordova) {
      this.queryBuilder = new QueryBuilder(new FormTemplate());
    }
  }

  /**
   * Get all form templates.
   */
  getAllFormTemplates(): Observable<FormTemplatesResponse> {
    return !environment.cordova
      ? this.http.get<FormTemplatesResponse>(`${ApiService.ApiUrl}/${this.resource}`)
      : this.queryBuilder.get();
  }

  /**
   * Find form template by id.
   */
  findFormTemplateById(form_template_id: string): Observable<FormTemplateResponse> {
    const params = new HttpParams().set('include', 'form_template_sections');

    return this.http.get<FormTemplateResponse>(`${ApiService.ApiUrl}/${this.resource}/${form_template_id}`, { params });
  }
}
