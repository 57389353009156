import { BaseModel } from '../classes/query-builder.class';

export class Project extends BaseModel {
  public table = 'projects';
  public schema = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    client_id: 'VARCHAR',
    real_client_id: 'VARCHAR',
    number: 'VARCHAR',
    start_date: 'VARCHAR',
    title: 'VARCHAR',
    commissioner: 'VARCHAR',
    lock_commissioner: 'INTEGER',
    building_type: 'INTEGER',
    lock_building_type: 'INTEGER',
    scope: 'VARCHAR',
    lock_scope: 'INTEGER',
    purpose: 'VARCHAR',
    lock_purpose: 'INTEGER',
    areas_description: 'VARCHAR',
    lock_areas_description: 'INTEGER',
    building_usage: 'VARCHAR',
    lock_building_usage: 'INTEGER',
  };
}
