<div class="animated fadeIn card card-accent-primary">
  <div class="card-header">
    <strong>Stages</strong>
  </div>

  <div class="card-body text-center">
    <p>Select stage</p>

    <button
        (click)="hop('stage_1_button')"
        class="btn btn-block btn-secondary">
      Stage 1 - Preliminary Sites Check
    </button>

    <button
        (click)="hop('stage_2_button')"
        class="btn btn-block btn-secondary">
      Stage 2 - Thorough Visual Inspection
    </button>

    <button
        (click)="hop('stage_3_button')"
        class="btn btn-block btn-secondary">
      Stage 3 - Clearance Air Testing
    </button>

    <button
        (click)="hop('stage_4_button')"
        class="btn btn-block btn-secondary">
      Stage 4 - Final Assessment
    </button>
  </div>

  <div class="card-footer d-flex justify-content-end">
    <button
        (click)="hop('stages_done_button')"
        class="btn btn-sm btn-primary">
      Done
    </button>
  </div>
</div>