import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../modules/auth/classes/user.class';
import { AuthService } from '../../modules/auth/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AppVersion } from '@ionic-native/app-version/ngx';

@Component({
  selector: 'app-sidebar-nav',
  template: `
    <nav class="sidebar-nav">
      <ul class="nav">
        <li (click)="onNavbarItemClicked()" *ngIf="(user.is('admin') || user.is('client_admin') || user.is('apple')) && !environment.cordova" class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/dashboard']">
            <i class="icon-speedometer"></i>
            Dashboard
          </a>
        </li>
        <li (click)="onNavbarItemClicked()" *ngIf="user.is('technician') || environment.cordova" class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/home']">
            <i class="icon-speedometer"></i>
            Home
          </a>
        </li>
        <li *ngIf="user.is('admin') && !environment.cordova" class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
          <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
            <i class="icon-layers"></i>
            User management
          </a>
          <ul class="nav-dropdown-items">
            <li (click)="onNavbarItemClicked()" class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/users']">
                <i class="icon-layers"></i>
                Users
              </a>
            </li>
            <li (click)="onNavbarItemClicked()" class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/roles']">
                <i class="icon-badge"></i>
                Roles
              </a>
            </li>
          </ul>
        </li>
        <li (click)="onNavbarItemClicked()" *ngIf="user.is('admin') && !environment.cordova" class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/clients']">
            <i class="icon-layers"></i>
            Clients
          </a>
        </li>
        <li *ngIf="user.is('client_admin') && !environment.cordova" class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
          <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
            <i class="icon-layers"></i>
            User management
          </a>
          <ul class="nav-dropdown-items">
            <li (click)="onNavbarItemClicked()" class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/clients', user.profile.client_id, 'users']">
                <i class="icon-layers"></i>
                Users
              </a>
            </li>
          </ul>
        </li>
        <li (click)="onNavbarItemClicked()" *ngIf="(user.is('reviewer') || user.is('admin')) && !environment.cordova" class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/forms']">
            <i class="icon-list"></i>
            Forms
          </a>
        </li>
        <li *ngIf="(user.can('manage-inventory') || user.is('admin')) && !environment.cordova" class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
          <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
            <i class="icon-layers"></i>
            Inventory
          </a>
          <ul class="nav-dropdown-items">
            <li (click)="onNavbarItemClicked()" class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/inventory']">
                <i class="icon-layers"></i>
                Items
              </a>
            </li>
          </ul>
        </li>
        <li *ngIf="environment.cordova" (click)="onNavbarItemClicked()" class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/uploader']">
            <i class="icon-speedometer"></i>
            Uploader
          </a>
        </li>
        <li class="nav-item text-center text-muted" *ngIf="version">
            Version {{ version }}
        </li>
      </ul>
    </nav>
  `
})
export class AppSidebarNavComponent implements OnInit, OnDestroy {
  public onNavbarItemClicked = AppSidebarNavComponent.onNavbarItemClicked;
  public environment = environment;
  public version;

  public user: User;
  private subscription: any;

  public hasRole(item) {
    return !!item.role;
  }

  public isDivider(item) {
    return !!item.divider;
  }

  public isTitle(item) {
    return !!item.title;
  }

  public static onNavbarItemClicked() {
    document.querySelector('body').classList.toggle('sidebar-mobile-show');
  }

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private appVersion: AppVersion
  ) { }

  ngOnInit() {
    this.subscription = this.route.data.subscribe(({ user }) => {
      this.user = new User(user.data);
      this.authService.user = this.user;

      if (this.user.is('technician')) {
        return this.router.navigate(['/home']);
      }
    });

    this.appVersion.getVersionNumber().then(value => {
      this.version = value ? value : 'web';
    }).catch(err => {
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

export const APP_SIDEBAR_NAV = [
  AppSidebarNavComponent
];
