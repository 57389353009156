<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Users</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li class="breadcrumb-item">
      <a [routerLink]="['/clients/index']" routerDirection="backward">Clients</a>
    </li>

    <li *ngIf="client_id" class="breadcrumb-item active">
      <span>Users</span>
    </li>
  </ol>

  <app-loading *ngIf="gettingAllClientUsers"></app-loading>

  <div *ngIf="!gettingAllClientUsers" class="animated fadeIn card card-accent-primary">
    <div class="card-header">
      <strong>Users</strong>
      <div class="float-right">
        <a [routerLink]="['/clients', client.id, 'users', 'create']" class="btn btn-sm btn-primary">
          Add user
        </a>
      </div>
    </div>

    <div *ngIf="users?.length === 0" class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      No users found.
    </div>

    <div class="table-responsive">
      <table *ngIf="users?.length > 0" class="table table-striped mb-0">
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Roles</th>
          <th class="text-right">Actions</th>
        </tr>

        <tr *ngFor="let user of users">
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>
            <span *ngFor="let role of user.roles.data" class="badge badge-primary mx-1">{{ role.display_name }}</span>
          </td>
          <td class="text-right d-md-none">
            <button
                (click)="activeUser = user"
                class="btn btn-secondary">
              <i class="fa fa-bars"></i>
            </button>
          </td>
          <td class="text-right d-none d-md-table-cell">
            <ng-container *ngTemplateOutlet="actions; context:{ $implicit: user }"></ng-container>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div *ngIf="activeUser" class="fixed-bottom" style="z-index: 9999;">
    <div class="list-group m-2 fadeIn">
      <ng-container *ngTemplateOutlet="actions; context:{ $implicit: activeUser }"></ng-container>
    </div>
  </div>

  <div *ngIf="activeUser" (click)="activeUser = null" class="modal-backdrop fade show"></div>

  <ng-template #actions let-user>
    <button
        *ngIf="!user.deleted_at"
        [routerLink]="['/clients', client.id, 'users', 'update', user.id]"
        [className]="activeUser ? 'list-group-item list-group-item-action' : 'btn btn-sm btn-info'">
      <i class="fa fa-edit mr-1"></i>
      Edit
    </button>
    <button
        (click)="onUserDelete(user)"
        [disabled]="user.deleting || user.id === authenticatedUser.profile.id"
        [className]="activeUser ? 'list-group-item list-group-item-action' : 'btn btn-sm btn-danger'">
      <span *ngIf="!user.deleting"><i class="fa fa-trash mr-1"></i> Delete</span>
      <span *ngIf="user.deleting"><i class="fa fa-spin fa-spinner"></i> Deleting</span>
    </button>
  </ng-template>
</ion-content>
