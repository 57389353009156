import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class BusService {
  public bus: Subject<any> = new Subject<any>();

  constructor() {}

  /**
   * Dispatch an event.
   */
  dispatch(event: { type: string; data?: any }) {
    this.bus.next(event);
  }

  /**
   * Listen to an event.
   */
  listen(type: string): Observable<any> {
    return this.bus.pipe(filter(event => event.type === type));
  }

}
