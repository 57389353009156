import { Component, OnInit } from '@angular/core';
import { SurveyJob } from '../../../survey-jobs/models/survey-job.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from '../../../auth/services/auth.service';
import { SurveyTypeEnum } from '../../../shared/enums/survey-type.enum';
import { SurveysService } from '../../services/surveys.service';
import { SurveyResponse } from '../../responses/survey.response';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SurveyStatusEnum } from '../../../shared/enums/survey-status.enum';
import { Job } from '../../../jobs/models/job.model';
import {AppHelper} from "../../../shared/classes/app.helper";

@Component({
  selector: 'app-surveys-create',
  templateUrl: './surveys-create.component.html',
  styleUrls: ['./surveys-create.component.scss'],
})
export class SurveysCreateComponent implements OnInit {
  public job: Job;
  public formGroup: FormGroup;
  public postingSurvey = false;
  public surveyTypeEnum = SurveyTypeEnum;
  public type: SurveyTypeEnum;
  public onHide = new Subject();
  public optionValueField: string;
  public environment = environment;

  constructor(
    public bsModalRef: BsModalRef,
    private snotifyService: SnotifyService,
    private formBuilder: FormBuilder,
    private surveysService: SurveysService,
    public authService: AuthService,
  ) {
    this.optionValueField = environment.cordova ? 'hashed_id' : 'id';
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      survey_users: [[this.authService.user.profile.id], [Validators.required]],
      assignment_id: [this.job.id, [Validators.required]],
      status: [SurveyStatusEnum.Created],
      survey_type: [SurveyTypeEnum.Management],
    });

    if (this.type) {
      this.formGroup.get('survey_type').setValue(this.type);
    }
  }

  onSubmit() {
    this.postingSurvey = true;
    const payload = this.formGroup.getRawValue();

    if (environment.cordova) {
      delete payload.survey_users;
      payload.assignment_id = payload.assignment_id.toString();
    }

    this.surveysService.createSurvey(payload).subscribe(
      (response: SurveyResponse) => {
        this.postingSurvey = false;
        this.bsModalRef.hide();
        this.onHide.next(response.data);
        AppHelper.chime();
      },
      () => {
        this.postingSurvey = false;
        this.snotifyService.error('Unable to create Survey. Please try again.', 'Error!');
      },
    );
  }

}
