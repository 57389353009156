import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { User } from '../../../auth/models/user.model';
import { UsersService } from '../../../users/services/users.service';
import { SnotifyService } from 'ng-snotify';
import { UsersResponse } from '../../../auth/responses/user.response';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { BusService } from '../../../shared/services/bus.service';
import { Links, Meta } from '../../../shared/models/pagination.model';

@Component({
  selector: 'app-technicians-index',
  templateUrl: './technicians-index.component.html',
  styleUrls: ['./technicians-index.component.scss']
})
export class TechniciansIndexComponent implements OnInit, OnDestroy {
  public users: User[];
  public meta: Meta;
  public links: Links;
  public gettingAllTechnicianUsers = false;
  public onUserSelect: Subject<User>;
  public subscriptions: Subscription = new Subscription();

  constructor(
    public bsModalRef: BsModalRef,
    private usersService: UsersService,
    private snotifyService: SnotifyService,
    private busService: BusService
  ) {}

  ngOnInit() {
    this.onUserSelect = new Subject();

    this.getAllTechnicianUsers();
    this.addSubscriptions();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  addSubscriptions() {
    this.subscriptions.add(
      this.busService.listen('loading').subscribe(event => {
        this.gettingAllTechnicianUsers = event.data;
      })
    );

    this.subscriptions.add(
      this.busService.listen('pagination').subscribe(event => {
        this.updateUsersList(event.data);
      })
    );
  }

  getAllTechnicianUsers() {
    this.gettingAllTechnicianUsers = true;

    this.usersService.getAllTechnicianUsers().subscribe(
      (response: UsersResponse) => {
        this.gettingAllTechnicianUsers = false;
        this.updateUsersList(response);
      },
      () => {
        this.gettingAllTechnicianUsers = false;
        this.snotifyService.error('Unable to load users. Please try again.', 'Error!');
      }
    );
  }

  updateUsersList(response: UsersResponse) {
    this.users = response.data;
    this.meta = response.meta;
    this.links = response.links;
  }

  onUserAttach(user: User) {
    this.onUserSelect.next(user);
    this.bsModalRef.hide();
  }
}
