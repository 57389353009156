<div class="form-group">
  <label for="project">Project</label>

  <div class="input-group">
    <input
        (change)="findProject()"
        [(ngModel)]="search"
        name="project"
        id="project"
        placeholder="Search for project..."
        type="text"
        class="form-control">

    <div class="input-group-append">
      <button
          [disabled]="findingProject"
          (click)="findProject()"
          class="btn btn-primary">
        <i *ngIf="!findingProject" class="fa fa-search"></i>
        <i *ngIf="findingProject" class="fa fa-spin fa-spinner"></i>
      </button>
    </div>
  </div>

  <span *ngIf="hint" class="text-info">* {{ hint }}</span>

  <ul *ngIf="projects.length > 0" class="list-group mt-1">
    <li *ngFor="let project of projects"
        (click)="selectProject(project)"
        class="list-group-item pointer list-group-item-action">{{ project.title }}</li>
  </ul>
</div>
