import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ClientResponse, ClientsResponse } from '../responses/client.response';
import { Client } from '../models/client.model';
import { UserResponse } from '../../auth/responses/user.response';
import { ClientQueryBuilder } from '../../shared/classes/client.query-builder.class';
import { ApiService } from '../../shared/services/api.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ClientsService {
  private resource = 'clients';
  private queryBuilder: ClientQueryBuilder;

  constructor(private http: HttpClient) {
    this.queryBuilder = new ClientQueryBuilder();
  }

  /**
   * Get all clients.
   */
  getAllClients(limit: number = 15, searchTerm: string = null): Observable<ClientsResponse> {
    let params = new HttpParams();

    if (environment.cordova) {
      this.queryBuilder = new ClientQueryBuilder();
    }

    params = params.set('limit', limit.toFixed(0));

    if (searchTerm) {
      params = params.set('filter[name]', searchTerm);
    }

    return !environment.cordova
      ? this.http.get<ClientsResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params })
      : this.queryBuilder.get();
  }

  /**
   * Get all Clients filtered by Name.
   */
  getAllClientsByName(limit: number = 15, name: string = null): Observable<ClientsResponse> {
    let params = new HttpParams();

    if (environment.cordova) {
      this.queryBuilder = new ClientQueryBuilder();
    }

    params = params.set('limit', limit.toFixed(0));
    params = params.set('include', 'sites');

    return !environment.cordova
      ? this.http.get<ClientsResponse>(`${ApiService.ApiUrl}/${this.resource}/name/${name}`, { params })
      : this.queryBuilder.where('name', 'like', `${name}%`).resourceIncludes(this.resource, ['sites']).limit(10).get();
  }

  /**
   * Find client by id.
   */
  findClientById(client_id: string, includes: string[] = null): Observable<ClientResponse> {
    let params = new HttpParams();

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    return this.http.get<ClientResponse>(`${ApiService.ApiUrl}/${this.resource}/${client_id}`, { params });
  }

  /**
   * Get all client users.
   */
  getAllClientUsers(client_id: string): Observable<ClientResponse> {
    const params = new HttpParams().set('include', 'users,users.roles');

    return this.http.get<ClientResponse>(`${ApiService.ApiUrl}/${this.resource}/${client_id}`, { params });
  }

  /**
   * Create new user client.
   */
  createClient(client: Client): Observable<ClientResponse> {
    const payload = {
      name: client.name,
      address: client.address,
      status: client.status,
      enabled_projects: client.enabled_projects,
    };

    return this.http.post<ClientResponse>(`${ApiService.ApiUrl}/${this.resource}`, payload);
  }

  /**
   * Update user client.
   */
  updateClient(client: Client): Observable<ClientResponse> {
    const payload = {
      name: client.name,
      address: client.address,
      status: client.status,
      enabled_projects: client.enabled_projects,
    };

    return this.http.patch<ClientResponse>(`${ApiService.ApiUrl}/${this.resource}/${client.id}`, payload);
  }

  /**
   * Delete user client.
   */
  deleteClient(client: Client): Observable<any> {
    return this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${client.id}`);
  }

  /**
   * Create client user.
   */
  createClientUser(user, client_id: string): Observable<UserResponse> {
    const payload = {
      name: user.name,
      email: user.email,
      mobile: user.mobile,
      role: user.role,
      client_id,
      project_id: user.project_id
    };

    return this.http.post<UserResponse>(`${ApiService.ApiUrl}/users`, payload);
  }

}
