import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../../shared/services/api.service';
import { RevisionResponse, RevisionsResponse } from '../responses/revision.response';

@Injectable()
export class RevisionsService {
  private resource = 'revisions';

  constructor(private http: HttpClient) {
  }

  /**
   * Get all Revisions.
   */
  getAllRevisions(limit: number, formId: string, surveyId: string): Observable<RevisionsResponse> {
    let params = new HttpParams().set('limit', limit.toFixed(0));

    if (formId) {
      params = params.set('filter[form_id]', formId);
    }

    if (surveyId) {
      params = params.set('filter[survey_id]', surveyId);
    }

    return this.http.get<RevisionsResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params });
  }

  /**
   * Find revision by ID.
   */
  findRevisionById(revisionId: string): Observable<RevisionResponse> {
    return this.http.get<RevisionResponse>(`${ApiService.ApiUrl}/${this.resource}/${revisionId}`);
  }

  /**
   * Create a new revision.
   */
  createRevision(revision): Observable<RevisionResponse> {
    return this.http.post<RevisionResponse>(`${ApiService.ApiUrl}/${this.resource}`, revision);
  }

  /**
   * Update revision by id.
   */
  updateRevision(revision): Observable<RevisionResponse> {
    return this.http.patch<RevisionResponse>(`${ApiService.ApiUrl}/${this.resource}/${revision.id}`, revision);
  }

  /**
   * Delete revision by id.
   */
  deleteRevision(revision): Observable<any> {
    return this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${revision.id}`);
  }
}
