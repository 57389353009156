import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../auth/models/user.model';
import { Role } from '../../../roles/models/role.model';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '../../../users/services/users.service';
import { AuthorizationService } from '../../../roles/services/authorization.service';
import { SnotifyService } from 'ng-snotify';
import { UserResponse } from '../../../auth/responses/user.response';
import { forkJoin, Subscription } from 'rxjs';
import { Project } from '../../../projects/models/project.model';
import { AppHelper } from '../../../shared/classes/app.helper';

@Component({
  selector: 'app-users-update',
  templateUrl: './users-update.component.html',
  styleUrls: ['./users-update.component.scss']
})
export class UsersUpdateComponent implements OnInit, OnDestroy {
  public subscriptions = new Subscription();
  public userFormGroup: FormGroup;
  public user: User;
  public user_id: string;
  public client_id: string;
  public roles: Role[];
  public updatingUser = false;
  public loadingRequests = false;
  public addedProjects: Project[] = [];

  private static passwordMatchValidator(formGroup: FormGroup) {
    return formGroup.get('password').value ===
    formGroup.get('password_confirmation').value
      ? null
      : { 'password-mismatch': true };
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private authorizationService: AuthorizationService,
    private snotifyService: SnotifyService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.route.params.subscribe(params => {
        this.user_id = params['user_id'];
        this.client_id = params['client_id'];
        this.initialRequests();
      })
    );

    this.initUserForm();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initUserForm() {
    this.userFormGroup = this.formBuilder.group(
      {
        name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        role: ['', [Validators.required]],
        mobile: [null],
        enabled_projects_limitations: [null],
        password: [null],
        password_confirmation: [null]
      },
      {
        validator: UsersUpdateComponent.passwordMatchValidator
      });

    const passwordFormControl = this.userFormGroup.get('password');

    this.subscriptions.add(
      passwordFormControl.valueChanges.subscribe(
        (value) => {
          if (value.length > 0) {
            passwordFormControl.setValidators([Validators.minLength(8)]);
          } else {
            passwordFormControl.setValidators(null);
          }
        }
      )
    );
  }

  initialRequests() {
    const group = 'client';

    const includes = ['roles', 'auth_attempts', 'projects'];
    const findUserByIdRequest = this.usersService.findUserById(this.user_id, includes);
    const getAllRolesRequest = this.authorizationService.getAllRoles(group);

    this.loadingRequests = true;

    forkJoin([findUserByIdRequest, getAllRolesRequest]).subscribe(
      (response: any[]) => {
        this.loadingRequests = false;

        this.user = response[0].data;
        this.roles = response[1].data;

        if (this.user) {
          this.fillUserForm();
          this.addedProjects = this.user.projects.data;
        }
      },
      error => {
        this.loadingRequests = false;
        this.snotifyService.error(
          'Unable to load resources. Please try again.',
          'Error!'
        );
      }
    );
  }

  fillUserForm() {
    this.userFormGroup.controls['name'].setValue(this.user.name);
    this.userFormGroup.controls['email'].setValue(this.user.email);
    this.userFormGroup.controls['mobile'].setValue(this.user.mobile);
    this.userFormGroup.controls['enabled_projects_limitations'].setValue(this.user.enabled_projects_limitations);
    if (this.user.roles.data.length > 0) {
      this.userFormGroup.controls['role'].setValue(this.user.roles.data[0].id);
    }
  }

  onSubmit() {
    this.updatingUser = true;
    const payload: any = {
      id: this.user.id,
      name: this.userFormGroup.controls['name'].value,
      enabled_projects_limitations: this.userFormGroup.controls['enabled_projects_limitations'].value,
      email: this.userFormGroup.controls['email'].value,
      mobile: this.userFormGroup.controls['mobile'].value,
      roles: [this.userFormGroup.controls['role'].value]
    };

    if (this.userFormGroup.get('password').value) {
      payload.password = this.userFormGroup.get('password').value;
      payload.password_confirmation = this.userFormGroup.get('password_confirmation').value;
    }

    payload.project_ids = AppHelper.pluck(this.addedProjects, 'id');

    this.usersService.updateUser(payload).subscribe(
      (response: UserResponse) => {
        this.updatingUser = false;
        this.snotifyService.success(
          'User has been successfully updated.',
          'Success!'
        );
        this.user = response.data;
        AppHelper.chime();
      },
      error => {
        this.updatingUser = false;
        this.snotifyService.error(
          'Unable to update user. Please try again.',
          'Error!'
        );
      }
    );
  }

  toggleProject(project: Project) {
    const projectExists = this.addedProjects.find(entry => entry.id === project.id);

    if (projectExists) {
      return this.addedProjects = this.addedProjects.filter(entry => entry.id !== project.id);
    }

    this.addedProjects.push(project);
  }
}
