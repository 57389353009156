<label *ngIf="question.js_id !== 'sample_number'">
  {{ question.text }}
</label>

<div class="input-group">
  <select
      (change)="onSelectChange()"
      [ngClass]="{'is-invalid': question.validation === false && !question.skipped}"
      [(ngModel)]="question.answer.value"
      [id]="id"
      [name]="name"
      class="form-control">
    <ng-container *ngIf="question.js_class !== 'pump_number' && question.js_id !== 'sample_number'">
      <option
          *ngFor="let option of question.form_template_question_options.data"
          [value]="option.option_value">
        {{ option.option_text }}
      </option>
    </ng-container>
    <ng-container *ngIf="question.js_class !== 'pump_number' && question.js_id === 'sample_number'">
      <ng-container *ngFor="let option of question.form_template_question_options.data">
        <option
            *ngIf="option.option_value <= numberOfSamples"
            [value]="option.option_value">
          {{ option.option_text }}
        </option>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="question.js_class === 'pump_number'">
      <option value="n_a">
        N/A
      </option>
      <ng-container *ngFor="let inventory of inventories">
        <option
            *ngIf="parseInt(inventory.type) === inventoryTypeEnum['Pump']"
            [value]="inventory.serial_number">
          {{ inventory.name }}
        </option>
      </ng-container>
    </ng-container>
  </select>
  <app-input-value-skip-button [question]="question"></app-input-value-skip-button>
</div>

<app-input-comment [question]="question"></app-input-comment>
