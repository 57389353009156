<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Dashboard</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
  <div
      *ngIf="isEdge || isSafari"
      class="alert alert-warning mb-0">
    <i class="fa fa-warning"></i>
    This browser isn’t supported, offline mode is disabled.
  </div>
</ion-header>

<ion-content>
  <app-home-admin *ngIf="user.is('admin') && !environment.cordova"></app-home-admin>
  <app-home-technician *ngIf="user.is('technician') || user.is('surveyor') || environment.cordova"></app-home-technician>
  <app-home-reviewer *ngIf="user.is('reviewer')"></app-home-reviewer>

  <ng-container *ngIf="user.is('apple')">
    <!-- Breadcrumb -->
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
    </ol>
    <div class="container-fluid">
      <div class="alert alert-info">
        No Jobs found!
      </div>
    </div>
  </ng-container>
</ion-content>
