import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ClientsRoutingModule } from './clients-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientsIndexComponent } from './components/clients-index/clients-index.component';
import { ClientsCreateComponent } from './components/clients-create/clients-create.component';
import { ClientsUpdateComponent } from './components/clients-update/clients-update.component';
import { ClientsService } from './services/clients.service';
import { UsersIndexComponent } from './components/users-index/users-index.component';
import { UsersCreateComponent } from './components/users-create/users-create.component';
import { UsersUpdateComponent } from './components/users-update/users-update.component';
import { SitesIndexComponent } from './components/sites-index/sites-index.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    ClientsRoutingModule,
    IonicModule
  ],
  declarations: [
    ClientsIndexComponent,
    ClientsCreateComponent,
    ClientsUpdateComponent,
    UsersIndexComponent,
    UsersCreateComponent,
    UsersUpdateComponent,
    SitesIndexComponent
  ],
  providers: [
    ClientsService
  ]
})
export class ClientsModule { }
