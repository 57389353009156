import { User as UserModel } from '../models/user.model';
import { Role } from '../../roles/models/role.model';
import { Permission } from '../../roles/models/permission.model';

/**
 * User helper class.
 */
export class User {
  /**
   * User profile data.
   */
  public profile: UserModel;

  /**
   * User class constructor.
   */
  constructor(data) {
    this.profile = data;
  }

  /**
   * Checks if the user is admin.
   */
  isAdmin(): boolean {
    return this.profile.is_admin;
  }

  /**
   * Returns User Role.
   */
  role(): Role {
    return this.profile.roles ? this.profile.roles.data[0] : null;
  }

  /**
   * Returns User Role.
   */
  roles(): Role[] {
    return this.profile.roles.data;
  }

  /**
   * Returns User Permissions.
   */
  permissions(): Permission[] {
    return this.role().permissions ? this.role().permissions.data : null;
  }

  /**
   * Checks if user has a permission assigned to his/her role.
   */
  can(permissionName: string): boolean {
    const entry = this.permissions().find((permission: Permission) => {
      return permission.name === permissionName;
    });

    return !!entry;
  }

  /**
   * Checks if user has a role assigned to him/her.
   */
  is(roleName: string): boolean {
    return this.roles().some(role => role.name === roleName);
  }
}
