import { Component, Input, OnInit } from '@angular/core';
import { FormTemplateQuestion } from '../../models/form-template-question.model';
import { FormHelpers } from '../../classes/form-helpers.class';

@Component({
  selector: 'app-input-comment',
  templateUrl: './input-comment.component.html',
  styleUrls: ['./input-comment.component.scss']
})
export class InputCommentComponent implements OnInit {
  @Input('question') question: FormTemplateQuestion;
  public fieldValidation = FormHelpers.fieldValidation;
  public id: string;
  public name: string;

  constructor() { }

  ngOnInit() {
    this.id = FormHelpers.createQuestionFieldAttributeValue(this.question, 'comment', 'id');
    this.name = FormHelpers.createQuestionFieldAttributeValue(this.question, 'comment', 'name');
  }

}
