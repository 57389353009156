import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { UserResponse } from '../responses/user.response';
import { AuthService } from '../services/auth.service';
import { NetworkService } from '../../shared/services/network.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UserResolver implements Resolve<UserResponse> {
  constructor(private authService: AuthService, private networkService: NetworkService, private snotifyService: SnotifyService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserResponse> {
    if (this.networkService.connected && AuthService.getUserResponse()) {
      AuthService.removeUserResponse();
      this.authService.user = null;
    }

    if (!this.networkService.connected && AuthService.getUserResponse()) {
      return of(AuthService.getUserResponse());
    }

    return this.authService.getAuthenticatedUserProfile().pipe(
      catchError(error => {
        this.snotifyService.error('Unable to reach backend server. Please try to login again.', 'Error!');
        this.authService.logout();
        return EMPTY;
      })
    );
  }
}
