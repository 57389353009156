import { BaseModel } from '../classes/query-builder.class';

export class Inventory extends BaseModel {
  public table: string = 'inventories';
  public schema: Object = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    name: 'VARCHAR',
    type: 'VARCHAR',
    model: 'VARCHAR',
    serial_number: 'VARCHAR',
    inventory_code: 'VARCHAR'
  };
}
