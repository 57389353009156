import { QueryBuilder } from './query-builder.class';
import { Site } from '../entities/site.entity';
import { from, Observable } from 'rxjs';
import { JsonSerializer } from './serializer.class';
import { Site as SiteModel } from './../models/site.model';

/**
 * Site Entity Query Builder.
 */
export class SiteQueryBuilder extends QueryBuilder {
  constructor() {
    super(new Site());
  }

  getSitesByNameClientId(clientId, siteName): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const query = `SELECT * FROM sites WHERE client_id = "${clientId}" AND name LIKE "%${siteName}%"`;
        const response = await this._query(query);
        const data: SiteModel[] = this._filterQueryToArray(response);

        return resolve(JsonSerializer.collection(data));
      })
    );
  }
}
