import { Component, OnDestroy, OnInit } from '@angular/core';
import { Inventory } from '../../models/inventory.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { InventoriesService } from '../../services/inventories.service';
import { InventoryResponse } from '../../responses/inventory.response';
import { InventoryTypeEnum } from '../../../shared/enums/inventory-type.enum';

@Component({
  selector: 'app-inventory-update',
  templateUrl: './inventory-update.component.html',
  styleUrls: ['./inventory-update.component.scss']
})
export class InventoryUpdateComponent implements OnInit, OnDestroy {
  public inventoryFormGroup: FormGroup;
  private inventory: Inventory;
  public findingInventoryById = false;
  private paramsSubscription: any;
  private inventory_id: string;
  public updatingInventory = false;
  public inventoryTypeEnum = InventoryTypeEnum;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private snotifyService: SnotifyService,
    private inventoriesService: InventoriesService
  ) {}

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe(params => {
      this.inventory_id = params['inventory_id'];
      this.findInventoryById();
    });

    this.initInventoryForm();
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }

  initInventoryForm() {
    this.inventoryFormGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      type: [''],
      model: [''],
      serial_number: [''],
      inventory_code: ['']
    });
  }

  findInventoryById() {
    this.findingInventoryById = true;

    this.inventoriesService.findInventoryById(this.inventory_id).subscribe(
      (response: InventoryResponse) => {
        this.inventory = response.data;
        this.findingInventoryById = false;

        if (this.inventory) {
          this.fillInventoryForm();
        }
      },
      error => {
        this.findingInventoryById = false;
        this.snotifyService.error('Unable to load inventory item. Please try again.', 'Error!');
      }
    );
  }

  fillInventoryForm() {
    this.inventoryFormGroup.controls['name'].setValue(this.inventory.name);
    this.inventoryFormGroup.controls['type'].setValue(this.inventory.type);
    this.inventoryFormGroup.controls['model'].setValue(this.inventory.model);
    this.inventoryFormGroup.controls['serial_number'].setValue(this.inventory.serial_number);
    this.inventoryFormGroup.controls['inventory_code'].setValue(this.inventory.inventory_code);
  }

  onSubmit() {
    this.updatingInventory = true;
    const payload = {
      id: this.inventory.id,
      name: this.inventoryFormGroup.controls['name'].value,
      type: this.inventoryFormGroup.controls['type'].value,
      model: this.inventoryFormGroup.controls['model'].value,
      serial_number: this.inventoryFormGroup.controls['serial_number'].value,
      inventory_code: this.inventoryFormGroup.controls['inventory_code'].value
    };

    this.inventoriesService.updateInventory(payload).subscribe(
      (response: InventoryResponse) => {
        this.updatingInventory = false;
        this.snotifyService.success('Inventory item has been successfully updated.', 'Success!');
        this.inventory = response.data;
      },
      error => {
        this.updatingInventory = false;
        this.snotifyService.error('Unable to update inventory item. Please try again.', 'Error!');
      }
    );
  }
}
