import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../auth/services/auth.service';
import { User } from '../auth/classes/user.class';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  templateUrl: 'dashboard.component.html',
})
export class DashboardComponent implements OnInit, OnDestroy {
  public user: User;
  public environment = environment;
  public subscription: any;
  private userResponse = AuthService.getUserResponse();
  public isEdge = /Edge/.test(navigator.userAgent);
  public isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
  public app: string;
  public subscriptions = new Subscription();

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.subscriptions.add(
      this.route.queryParams.subscribe(queryParams => {
        if (queryParams.app) {
          this.app = queryParams.app;
        }
      })
    );
  }

  ngOnInit() {
    this.user = new User(this.userResponse.data);

    if (this.user.is('technician') && !this.user.is('surveyor')) {
      return this.app = 'air-testing';
    }

    if (!this.user.is('technician') && this.user.is('surveyor')) {
      return this.app = 'surveying';
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
