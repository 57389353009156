import { BaseModel } from '../classes/query-builder.class';

export class InventoryMessage extends BaseModel {
  public table: string = 'inventory_messages';
  public schema: Object = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    form_id: 'VARCHAR',
    message: 'VARCHAR'
  };
}
