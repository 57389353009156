import { QueryBuilder } from './query-builder.class';
import { from, Observable } from 'rxjs';
import { JsonSerializer } from './serializer.class';
import { Sample as SampleModel } from '../../forms/models/sample.model';
import { Sample } from '../entities/sample.entity';
import { SampleResponse, SamplesResponse } from '../../forms/responses/sample.response';
import { FormSectionQueryBuilder } from './form_section.query-builder.class';

/**
 * Sample Entity Query Builder.
 */
export class SampleQueryBuilder extends QueryBuilder {
  constructor() {
    super(new Sample());
  }

  /**
   * Create a new form and the first
   * form section for it.
   */
  create(items: object): Observable<any> {
    const itemsMark = [];
    const itemsKey = [];
    const itemsValue = [];

    for (const item in items) {
      if (items.hasOwnProperty(item)) {
        itemsMark.push('?');
        itemsKey.push(item);
        itemsValue.push(items[item]);
      }
    }

    return from(
      new Promise(async (resolve, reject) => {
        const query = `INSERT INTO ${this._table} (${itemsKey.join(', ')}) VALUES (${itemsMark.join(', ')})`;
        const response = await this._query(query, itemsValue);
        const sampleResponse: SampleResponse = JsonSerializer.item(Object.assign(items, { id: response.insertId }));

        const formSectionQueryBuilder = new FormSectionQueryBuilder();
        sampleResponse.data.form_sections = await formSectionQueryBuilder
          .where('sample_id', '=', sampleResponse.data.id)
          .get()
          .toPromise();

        return resolve(sampleResponse);
      })
    );
  }

  /**
   * Get all form sections.
   * Includes: FormTemplateSection, FormAnswers
   */
  get(items: string = '*'): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const query = `SELECT ${items} FROM ${
          this._table
        } ${this._buildWhereQuery()} ${this._buildOrderByQuery()} ${this._buildTakeQuery()}`;
        const response = await this._query(query);
        const data = this._filterQueryToArray(response);
        const samplesResponse: SamplesResponse = JsonSerializer.collection(data);

        await Promise.all(
          samplesResponse.data.map(async (sample: SampleModel) => {
            const formSectionQueryBuilder = new FormSectionQueryBuilder();
            sample.form_sections = await formSectionQueryBuilder
              .where('sample_id', '=', sample.id)
              .get()
              .toPromise();

            return sample;
          })
        );

        return resolve(samplesResponse);
      })
    );
  }
}
