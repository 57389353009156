import { BaseModel } from '../classes/query-builder.class';

export class Sample extends BaseModel {
  public table: string = 'samples';
  public schema: Object = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    form_id: 'VARCHAR'
  };
}
