import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Client } from '../../models/client.model';
import { SnotifyService } from 'ng-snotify';
import { ClientsService } from '../../services/clients.service';
import { ClientResponse } from '../../responses/client.response';
import { ClientStatusEnum } from '../../enums/status.enum';
import { Router } from '@angular/router';
import { AppHelper } from '../../../shared/classes/app.helper';

@Component({
  selector: 'app-clients-create',
  templateUrl: './clients-create.component.html',
  styleUrls: ['./clients-create.component.scss']
})
export class ClientsCreateComponent implements OnInit {
  public clientFormGroup: FormGroup;
  private client: Client;
  public postingClient = false;
  public statuses = ClientStatusEnum;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private snotifyService: SnotifyService,
    private clientsService: ClientsService
  ) {}

  ngOnInit() {
    this.initClientForm();
  }

  initClientForm() {
    this.clientFormGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      address: [''],
      status: ['1', [Validators.required]],
      enabled_projects: [true, [Validators.required]],
    });
  }

  onSubmit() {
    this.postingClient = true;
    const payload = {
      name: this.clientFormGroup.get('name').value,
      address: this.clientFormGroup.get('address').value,
      status: parseFloat(this.clientFormGroup.get('status').value),
      enabled_projects: this.clientFormGroup.get('enabled_projects').value,
    };

    this.clientsService.createClient(payload).subscribe(
      (response: ClientResponse) => {
        this.postingClient = false;
        this.snotifyService.success('A new client has been created.', 'Success!');
        this.client = response.data;
        AppHelper.chime();
        return this.router.navigate(['/clients/index']);
      },
      error => {
        this.postingClient = false;
        this.snotifyService.error(
          'Unable to create client. Please try again.',
          'Error!'
        );
      }
    );
  }

}
