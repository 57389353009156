import { QueryBuilder } from './query-builder.class';
import { Project } from '../entities/project.entity';
import { from, Observable } from 'rxjs';
import { Project as ProjectModel } from '../../projects/models/project.model';
import { Client } from '../../clients/models/client.model';
import { JsonSerializer } from './serializer.class';
import { TempAssignment } from "../../jobs/models/temp-assignment.model";

/**
 * Project Entity Query Builder.
 */
export class ProjectQueryBuilder extends QueryBuilder {
  constructor(migrate = false) {
    super(new Project(), migrate);
  }

  getWithIncludes(includes: string[], sorting: string = 'id'): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        let query = 'SELECT * FROM projects';

        const orderQuery = this.buildOderByQuery(sorting);
        query = `${query} ${orderQuery}`;
        const response = await this._query(query);
        let data: ProjectModel[] = this._filterQueryToArray(response);
        let clients: Client[] = [];
        let tempAssignments: TempAssignment[] = [];

        if (includes.includes('client')) {
          clients = await this.loadCollectionRelationships('clients', 'hashed_id', 'client_id', data);
        }

        if (includes.includes('temp_assignments')) {
          tempAssignments = await this.loadCollectionRelationships('temp_assignments', 'project_id', 'hashed_id', data);
        }

        data = data.map(entry => {
          if (includes.includes('client')) {
            const childClient = clients.find(client => client.hashed_id === entry.client_id);

            if (childClient) {
              entry.client = {
                data: childClient
              };
            }
          }

          if (includes.includes('temp_assignments')) {
            const childTempAssignments = tempAssignments.filter(tempAssignment => tempAssignment.project_id === entry.hashed_id);

            entry.temp_assignments = {
              data: childTempAssignments
            };
          }

          return entry;
        });

        return resolve(JsonSerializer.collection(data));
      })
    );
  }

  buildOderByQuery(sorting) {
    let direction: string;

    if (!sorting) {
      direction = 'ASC';
      sorting = 'id';
    } else if (sorting.charAt(0) === '-') {
      direction = 'ASC';
      sorting = sorting.replace('-', '');
    } else {
      direction = 'DESC';
    }

    return `ORDER BY ${sorting} ${direction}`;
  }
}
