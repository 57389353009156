import { BaseModel } from '../classes/query-builder.class';

export class Material extends BaseModel {
  public table = 'materials';
  public schema = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    component_template_id: 'VARCHAR',
    description: 'VARCHAR',
    code: 'VARCHAR',
  };
}
