import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { YesNoEnum } from '../../../shared/enums/yes-no.enum';
import { Area } from '../../models/area.model';
import { BsModalRef } from 'ngx-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { AreasService } from '../../services/areas.service';
import { ImageCompressService } from 'ng2-image-compress';
import { AreaAccessEnum } from '../../enums/area-access.enum';
import {AppHelper} from "../../../shared/classes/app.helper";

@Component({
  selector: 'app-areas-update',
  templateUrl: './areas-update.component.html',
  styleUrls: ['./areas-update.component.scss'],
})
export class AreasUpdateComponent implements OnInit {
  public formGroup: FormGroup;
  public postingArea = false;
  public area: Area;
  public onHide = new Subject();
  public yesNoEnum = YesNoEnum;
  public areaAccessButtons = [
    {
      value: AreaAccessEnum.No_Access,
      label: AreaAccessEnum[AreaAccessEnum.No_Access]
    },
    {
      value: AreaAccessEnum.Limited_Access,
      label: AreaAccessEnum[AreaAccessEnum.Limited_Access]
    },
    {
      value: AreaAccessEnum.Accessible,
      label: AreaAccessEnum[AreaAccessEnum.Accessible]
    },
  ];

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private snotifyService: SnotifyService,
    private areasService: AreasService,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      id: [this.area.id],
      building_id: [this.area.building_id],
      floor_id: [this.area.floor_id],
      description: [this.area.description],
      accessible: [this.area.accessible, [Validators.required]],
      deviation_to_scope: [this.area.deviation_to_scope ? this.area.deviation_to_scope : YesNoEnum.No, [Validators.required]],
      no_access_reason: [this.area.no_access_reason],
      image: [this.area.image],
    });
  }

  onSubmit() {
    this.postingArea = true;
    const payload = this.formGroup.getRawValue();

    this.areasService.updateArea(payload).subscribe(
      () => {
        this.bsModalRef.hide();
        this.onHide.next();
        this.postingArea = false;
        AppHelper.chime();
      },
      () => {
        this.postingArea = false;
        this.snotifyService.error('Unable to create Area. Please try again.', 'Error!');
      },
    );
  }

  onFileChange($event) {
    ImageCompressService.filesToCompressedImageSource($event.srcElement.files).then(observableImages => {
      observableImages.subscribe(
        image => {
          this.formGroup.get('image').setValue(image.compressedImage.imageDataUrl as string);
        },
        error => {
          this.snotifyService.error('Unable to load photo. Please try again.', 'Error!');
        },
        () => {
          // Done
        }
      );
    });
  }

}
