import { Component, OnInit } from '@angular/core';
import { InputTypeBaseFieldComponent } from '../input-type-base-field/input-type-base-field.component';
import { FormHelpers } from '../../classes/form-helpers.class';

@Component({
  selector: 'app-input-type-text',
  templateUrl: './input-type-text.component.html',
  styleUrls: ['./input-type-text.component.scss']
})
export class InputTypeTextComponent extends InputTypeBaseFieldComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    this.id = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'id');
    this.name = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'name');
  }
}
