import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Client } from '../../models/client.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from '../../services/clients.service';
import { SnotifyService } from 'ng-snotify';
import { ClientResponse } from '../../responses/client.response';
import { ClientStatusEnum } from '../../enums/status.enum';
import { AppHelper } from '../../../shared/classes/app.helper';

@Component({
  selector: 'app-clients-update',
  templateUrl: './clients-update.component.html',
  styleUrls: ['./clients-update.component.scss']
})
export class ClientsUpdateComponent implements OnInit, OnDestroy {
  public clientFormGroup: FormGroup;
  public client: Client;
  public client_id: string;
  public updatingClient = false;
  public findingClientById = false;
  private subscription: any;
  public statuses = ClientStatusEnum;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private clientsService: ClientsService,
    private snotifyService: SnotifyService
  ) {}

  ngOnInit() {
    this.subscription = this.route.params.subscribe(params => {
      this.client_id = params['client_id'];
      this.findClientById();
    });

    this.initClientForm();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initClientForm() {
    this.clientFormGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      address: [''],
      status: ['1', [Validators.required]],
      enabled_projects: [true, [Validators.required]],
    });
  }

  findClientById() {
    this.findingClientById = true;

    this.clientsService.findClientById(this.client_id).subscribe(
      (response: ClientResponse) => {
        this.findingClientById = false;
        this.client = response.data;
        this.fillClientForm();
      },
      error => {
        this.findingClientById = false;
        this.snotifyService.error(
          'Unable to load client. Please try again.',
          'Error!'
        );
      }
    );
  }

  fillClientForm() {
    this.clientFormGroup.get('name').setValue(this.client.name);
    this.clientFormGroup.get('address').setValue(this.client.address);
    this.clientFormGroup.get('status').setValue(this.client.status);
    this.clientFormGroup.get('enabled_projects').setValue(this.client.enabled_projects);
  }

  onSubmit() {
    this.updatingClient = true;
    const payload = {
      id: this.client.id,
      name: this.clientFormGroup.get('name').value,
      address: this.clientFormGroup.get('address').value,
      status: parseFloat(this.clientFormGroup.get('status').value),
      enabled_projects: this.clientFormGroup.get('enabled_projects').value,
    };

    this.clientsService.updateClient(payload).subscribe(
      (response: ClientResponse) => {
        this.updatingClient = false;
        this.snotifyService.success(
          'Client has been successfully updated.',
          'Success!'
        );
        this.client = response.data;
        AppHelper.chime();
        return this.router.navigate(['/clients/index']);
      },
      error => {
        this.updatingClient = false;
        this.snotifyService.error(
          'Unable to update client. Please try again.',
          'Error!'
        );
      }
    );
  }

}
