import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-json-values',
  templateUrl: './json-values.component.html',
  styleUrls: ['./json-values.component.scss']
})
export class JsonValuesComponent implements OnInit {
  @Input('values') values;
  public json: any;
  public keys: any[] = [];

  constructor() { }

  ngOnInit() {
    this.parseValues();
  }

  parseValues() {
    this.json = (JSON.parse(this.values)) ? JSON.parse(this.values) : {};

    Object.keys(this.json).forEach(key => {
      this.keys.push(key);
    });
  }

}
