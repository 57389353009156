<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Update Client</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li class="breadcrumb-item">
      <a [routerLink]="['/clients/index']" routerDirection="backward">Clients</a>
    </li>

    <li class="breadcrumb-item active">
      <span>Update</span>
    </li>
  </ol>

  <app-loading *ngIf="findingClientById"></app-loading>

  <div *ngIf="!findingClientById" class="card animated fadeIn">
    <div class="card-header">
      <strong>Update</strong> Client
    </div>
    <form (ngSubmit)="onSubmit()" action="" method="post">
      <div [formGroup]="clientFormGroup" class="card-body">
        <div class="form-group">
          <label for="name">
            Name
          </label>
          <input
              [formControl]="clientFormGroup.controls['name']"
              [ngClass]="{'is-invalid': !clientFormGroup.controls['name'].valid && clientFormGroup.controls['name'].touched}"
              class="form-control"
              id="name"
              name="name"
              placeholder="Enter client name"
              type="text">
          <div class="help-block text-danger"
               *ngIf="clientFormGroup.controls['name'].touched && clientFormGroup.controls['name'].hasError('required')">
            * This field is required.
          </div>
        </div>

        <div class="form-group">
          <label for="address">
            Address
          </label>
          <input
              [formControl]="clientFormGroup.controls['address']"
              class="form-control"
              id="address"
              name="address"
              placeholder="Enter client address"
              type="text">
        </div>

        <div class="form-group">
          <label for="status">
            Status
          </label>
          <select
              [formControl]="clientFormGroup.controls['status']"
              [ngClass]="{'is-invalid': !clientFormGroup.controls['status'].valid && clientFormGroup.controls['status'].touched}"
              name="status"
              id="status"
              class="form-control">
            <option value="{{ statuses['Active'] }}">Active</option>
            <option value="{{ statuses['Credit_Hold'] }}">Credit hold</option>
            <option value="{{ statuses['Suspended'] }}">Suspended</option>
          </select>
          <div class="help-block text-danger"
               *ngIf="clientFormGroup.controls['status'].touched && clientFormGroup.controls['status'].hasError('required')">
            * This field is required.
          </div>
          <div class="help-block text-info">
          <span *ngIf="clientFormGroup.controls['status'].value === '1'" class="animated fadeIn">
            <i class="fa fa-info mr-1"></i>
            Client users will have full access to the system and all its features.
          </span>
            <span *ngIf="clientFormGroup.controls['status'].value === '2'" class="animated fadeIn">
            <i class="fa fa-info mr-1"></i>
            Client users will have access to the system however all of their certificates are marked as DRAFT as a watermark across the back.
          </span>
            <span *ngIf="clientFormGroup.controls['status'].value === '3'" class="animated fadeIn">
            <i class="fa fa-info mr-1"></i>
            Client users will no longer have access to the system or the reports.
          </span>
          </div>
        </div>

        <div class="form-group">
          <label for="enabled_projects" class="d-block">
            Enabled projects
          </label>

          <label class="switch switch-text switch-info-outline-alt mb-0">
            <input class="switch-input" type="checkbox" id="enabled_projects" name="enabled_projects" formControlName="enabled_projects">
            <span class="switch-label"></span>
            <span class="switch-handle"></span>
          </label>
        </div>
      </div>

      <div class="card-footer">
        <button
            (click)="onSubmit()"
            [disabled]="!clientFormGroup.valid"
            class="btn btn-sm btn-primary"
            type="submit">
        <span *ngIf="!updatingClient">
          <i class="fa fa-save mr-1"></i>
          Update
        </span>
          <span *ngIf="updatingClient">
          <i class="fa fa-spin fa-spinner mr-1"></i>
          Updating
        </span>
        </button>
      </div>
    </form>
  </div>
</ion-content>
