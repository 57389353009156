/**
 * Json API Serializer
 */
export class JsonSerializer {
  /**
   * Return json item
   */
  public static item(item, includesResponses: any[] = null, resource: string = null) {
    if (includesResponses) {
      includesResponses.forEach(includeResponse => {
        const data = includeResponse.data.filter(entity => {
          return +entity[`${resource.slice(0, -1)}_id`] == +item['id'];
        });

        item[includeResponse.resource] = this.item(data);
      });
    }

    return {
      data: item,
      meta: {}
    };
  }

  /**
   * Return json item
   */
  public static count(item) {
    return item[0]['COUNT(*)'];
  }

  /**
   * Return json collection
   */
  public static collection(response) {
    return {
      data: response,
      meta: null,
      links: null
    };
  }
}
