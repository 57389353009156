<div class="modal-header">
  <h4 class="modal-title pull-left">
    Update document
  </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="documentFormGroup">
    <div class="form-group">
      <label for="name">
        Document name
      </label>
      <input
          [formControl]="documentFormGroup.controls['name']"
          type="text"
          class="form-control"
          id="name"
          name="name">
    </div>

    <div class="form-group">
      <label for="type">
        Type
      </label>
      <select
          [formControl]="documentFormGroup.controls['type']"
          name="type"
          id="type"
          class="form-control">
        <option value=""></option>
        <option *ngIf="this.documentFormGroup.get('documentable_type').value !== 'surveys'" [value]="document_types['Invoice']">Invoice</option>
        <option [value]="document_types['Report']">Report</option>
        <option [value]="document_types['Document']">Document</option>
      </select>
    </div>

    <div class="form-group">
      <label for="public" class="d-block">
        Public
      </label>

      <label class="switch switch-text switch-info-outline-alt mb-0">
        <input class="switch-input" type="checkbox" id="public" name="public" formControlName="public">
        <span class="switch-label"></span>
        <span class="switch-handle"></span>
      </label>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
  <button
      [disabled]="!documentFormGroup.valid"
      type="button"
      class="btn btn-primary"
      (click)="update()">
    <span *ngIf="updating"><i class="fa fa-spin fa-spinner mr-1"></i> Updating</span>
    <span *ngIf="!updating">Update</span>
  </button>
</div>
