import { QueryBuilder } from './query-builder.class';
import { FormTemplateSection } from '../entities/form_template_section.entity';
import { from, Observable } from 'rxjs';
import { FormTemplateSectionResponse } from '../../forms/responses/form-template-section.response';
import { JsonSerializer } from './serializer.class';
import { FormTemplateQuestionQueryBuilder } from './form_template_question.query-builder.class';

/**
 * Form Template Section Entity Query Builder.
 */
export class FormTemplateSectionQueryBuilder extends QueryBuilder {
  constructor() {
    super(new FormTemplateSection());
  }

  /**
   * Get the first resource element.
   * Includes: FormTemplateQuestions
   */
  first(items: string = '*'): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const query = `SELECT ${items} FROM ${this._table} ${this._buildWhereQuery()} ${this._buildOrderByQuery()} LIMIT 1`;
        const response = await this._query(query);
        const data = this._filterQueryToArray(response);
        const formTemplateSectionResponse: FormTemplateSectionResponse = JsonSerializer.item(data[0]);

        const formTemplateSectionQuestionsQueryBuilder = new FormTemplateQuestionQueryBuilder();
        formTemplateSectionResponse.data.form_template_questions = await formTemplateSectionQuestionsQueryBuilder
          .where('form_template_section_id', '=', formTemplateSectionResponse.data.hashed_id)
          .get()
          .toPromise();

        return resolve(formTemplateSectionResponse);
      })
    );
  }
}
