import { Form } from '../models/form.model';
import { FormTemplateSection } from '../models/form-template-section.model';
import { FormHelpers } from './form-helpers.class';

/**
 * Air Test Helper Class.
 */
export class AirTestSectionRules {
  /**
   * Air Test - A.1 - Details
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_a1(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_a3' };
  }

  /**
   * Air Test - A.2B - Samples Table
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_a2b(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_a3' };
  }

  /**
   * Air Test - A.3 - Temp & Pressure
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_a3(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_a5' };
  }

  /**
   * Air Test - A.4 - Sample Details
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_a4(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_a6' };
  }

  /**
   * Air Test - A.5 - Microscope Set Up
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_a5(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_a2b', action: 'load' };
  }

  /**
   * Air Test - A.6 - Sample Analysis Prep
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_a6(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_a7' };
  }

  /**
   * Air Test - A.7 - Analysis of samples
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_a7(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_a2b', action: 'load', task: 'clear_active_sample' };
  }

  /**
   * Air Test - New Sample (2)
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_new_sample(form: Form, section: FormTemplateSection) {
    const prepare_new_samples = FormHelpers.findSectionQuestionByJsId(section, 'prepare_new_samples');
    // Yes: 1, No: 2
    return prepare_new_samples.answer.value === 1 ? { nextSectionJsRule: 'air_test_a2b' } : { nextSectionJsRule: 'air_test_summary' };
  }

  /**
   * Air Test - Field Blank Count
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_field_blank_count(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_summary', action: 'load' };
  }

  /**
   * Air Test - Summary
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string, role: string}}
   */
  public static air_test_summary(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_desktop_review', role: 'reviewer' };
  }

  /**
   * Air Test - Section List
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string, role: string}}
   */
  public static air_test_section_list(form: Form, section: FormTemplateSection) {
    const next_section = FormHelpers.findSectionQuestionByJsId(section, 'next_section');

    switch (+next_section.answer.value) {
      case 1:
        return { nextSectionJsRule: 'air_test_a1' };
      case 2:
        return { nextSectionJsRule: 'air_test_a2' };
      case 3:
        return { nextSectionJsRule: 'air_test_a3' };
      case 4:
        return { nextSectionJsRule: 'air_test_a4' };
      case 5:
        return { nextSectionJsRule: 'air_test_a5' };
      case 6:
        return { nextSectionJsRule: 'air_test_a6' };
      case 7:
        return { nextSectionJsRule: 'air_test_a7' };
      case 8:
        return { nextSectionJsRule: 'air_test_new_sample' };
      case 9:
        return { nextSectionJsRule: 'air_test_summary' };
      default:
        return { nextSectionJsRule: 'air_test_a1' };
    }
  }

  /**
   * Air Test - Desktop Review (Office Only)
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_desktop_review(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'completed' };
  }

  /**
   * Air Test - Microscope Set Up Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_microscope_set_up_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_a5', action: 'load' };
  }

  /**
   * Air Test - Samples Done Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_samples_done_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_field_blank_count', action: 'load' };
  }

  /**
   * Air Test - Start Sample Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_sample_start_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_a4', action: 'load' };
  }

  /**
   * Air Test - Collect Sample Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_sample_collect_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_a4', action: 'load' };
  }

  /**
   * Air Test - Prepare Sample Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_sample_prepare_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_a6', action: 'load' };
  }

  /**
   * Air Test - Analyse Sample Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_sample_analyse_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_a7', action: 'load' };
  }

  /**
   * Air Test - Samples Table Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_samples_table_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_a2b', action: 'load' };
  }
}
