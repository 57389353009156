<div class="modal-header">
  <h4 class="modal-title pull-left">
    Technicians index
  </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="card animated fadeIn mb-0">
    <div class="card-header">
      <strong>Technicians</strong>
    </div>

    <div *ngIf="users?.length === 0 && !gettingAllTechnicianUsers" class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      No technicians found.
    </div>

    <div *ngIf="gettingAllTechnicianUsers" class="alert alert-info mb-0">
      <i class="fa fa-spin fa-spinner mr-1"></i>
      Loading technicians users. Please wait!
    </div>

    <div class="table-responsive">
      <table *ngIf="users?.length > 0" class="table table-striped mb-0">
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th class="text-right">Actions</th>
        </tr>

        <tr *ngFor="let user of users">
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td class="text-right">
            <button
                (click)="onUserAttach(user)"
                class="btn btn-primary btn-sm">
              Assign
            </button>
          </td>
        </tr>
      </table>
    </div>

    <div *ngIf="meta && !gettingAllTechnicianUsers && meta.last_page > 1" class="card-footer">
      <app-pagination
          [meta]="meta"
          [links]="links"
          (updated)="updateUsersList($event)"></app-pagination>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
    Close
  </button>
</div>
