<div class="card border-light mb-md-3 animated fadeIn" *ngFor="let comment of comments">
  <div class="card-body">
    <div class="media">
      <i class="fa fa-user-circle-o fa-5x text-muted"></i>
      <div class="media-body ml-md-3">
        <h5 class="mt-0">{{ comment?.user_name }}</h5>
        <p *ngIf="!comment.editing" class="mb-0" [innerHTML]="comment?.message | printText"></p>
        <textarea
            *ngIf="comment.editing"
            [(ngModel)]="comment.message"
            [disabled]="comment.updating"
            placeholder="Edit comment..."
            [id]="comment.id"
            [name]="comment.id"
            class="form-control"
            cols="30"
            rows="5"></textarea>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <small class="text-muted">Submitted at: {{ moment(comment?.created_at.date).format('HH:mm:ss - DD/MM/YYYY') }}</small>

    <div class="float-right">
      <button
          *ngIf="comment.user_id === user.profile.id"
          type="button"
          class="btn btn-danger btn-sm"
          (click)="onCommentDelete(comment)">
        <span *ngIf="!comment?.deleting"><i class="fa fa-trash mr-1"></i> Delete</span>
        <span *ngIf="comment?.deleting"><i class="fa fa-spin fa-spinner mr-1"></i> Deleting</span>
      </button>

      <button
          (click)="onCommentEdit(comment)"
          *ngIf="!comment.editing && comment.user_id === user.profile.id"
          class="btn btn-info btn-sm">
        <span><i class="fa fa-edit mr-1"></i> Edit</span>
      </button>

      <button
          (click)="onCommentEditCancel(comment)"
          *ngIf="comment.editing"
          class="btn btn-secondary btn-sm">
        <span><i class="fa fa-times mr-1"></i> Cancel</span>
      </button>

      <button
          (click)="onCommentUpdate(comment)"
          *ngIf="comment.editing && comment.user_id === user.profile.id"
          class="btn btn-primary btn-sm">
        <span *ngIf="!comment.updating"><i class="fa fa-send mr-1"></i> Update</span>
        <span *ngIf="comment.updating"><i class="fa fa-spin fa-spinner mr-1"></i> Updating</span>
      </button>
    </div>
  </div>
</div>

<form (onSubmit)="createComment()">
  <div [formGroup]="commentFormGroup" class="form-group">
    <label for="comment_message">
      New comment
    </label>
    <div class="input-group">
      <textarea
          [formControl]="commentFormGroup.controls['message']"
          placeholder="Type a comment..."
          id="comment_message"
          name="comment_message"
          class="form-control"
          cols="30"
          rows="5"></textarea>
      <span class="input-group-append">
        <button
            type="submit"
            (click)="createComment()"
            [disabled]="!commentFormGroup.controls['message'].valid"
            class="btn btn-primary">
          <i *ngIf="!postingMessage" class="fa fa-send"></i>
          <i *ngIf="postingMessage" class="fa fa-spin fa-spinner"></i>
        </button>
      </span>
    </div>
  </div>
</form>
