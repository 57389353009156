import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SurveyJob } from '../../models/survey-job.model';
import { User } from '../../../auth/models/user.model';
import { Client } from '../../../clients/models/client.model';
import { Project } from '../../../projects/models/project.model';
import { environment } from 'src/environments/environment';
import { UsersResponse } from '../../../auth/responses/user.response';
import { JobResponse } from '../../../jobs/responses/job.response';
import { ClientsResponse } from '../../../clients/responses/client.response';
import { ProjectsResponse } from '../../../projects/responses/project.response';
import { BsModalRef } from 'ngx-bootstrap';
import { SurveyJobsService } from '../../services/survey-jobs.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/services/auth.service';
import { UsersService } from '../../../users/services/users.service';
import { SnotifyService } from 'ng-snotify';
import { ClientsService } from '../../../clients/services/clients.service';
import { ProjectsService } from '../../../projects/services/projects.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-survey-jobs-update',
  templateUrl: './survey-jobs-update.component.html',
  styleUrls: ['./survey-jobs-update.component.scss'],
})
export class SurveyJobsUpdateComponent implements OnInit, OnDestroy {
  public jobFormGroup: FormGroup;
  private job: SurveyJob;
  public postingJob = false;
  public findingServiceM8JobByNumber = false;
  public findingJobByNumber = false;
  public gettingAllUsers = false;
  public users: User[];
  public clients: Client[];
  public projects: Project[];
  public clientsLoading: boolean;
  public projectsLoading: boolean;
  public address: string;
  public client: Client;
  public optionValueField: string;
  public environment = environment;
  public subscriptions = new Subscription();

  constructor(
    public bsModalRef: BsModalRef,
    private surveyJobsService: SurveyJobsService,
    private router: Router,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private usersService: UsersService,
    private snotifyService: SnotifyService,
    private clientsService: ClientsService,
    private projectsService: ProjectsService,
  ) {
    this.clientsLoading = true;
    this.projectsLoading = true;
    this.clients = [];
    this.projects = [];
    this.optionValueField = environment.cordova ? 'hashed_id' : 'id';
  }

  ngOnInit() {
    this.initJobForm();

    this.loadClients();
    this.loadProjects();

    if (this.authService.user.is('admin')) {
      this.getAllUsers();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initJobForm() {
    const userIds = [];
    this.job.users.data.forEach(user => userIds.push(user.id));

    this.jobFormGroup = this.formBuilder.group({
      id: [this.job.id, [Validators.required]],
      number: [this.job.number, [Validators.required]],
      user_id: [userIds, [Validators.required]],
      client_id: [{value: this.job.client_id, disabled: true}, [Validators.required]],
      project_id: [{value: this.job.project_id, disabled: true}, [Validators.required]],
      status: [this.job.status],
      address: [this.job.address, [Validators.required]],
      commissioner_name: [this.job.commissioner_name],
      scope: [this.job.scope],
      deviations_exclusions: [this.job.deviations_exclusions],
    });

    this.subscriptions.add(
      this.jobFormGroup.get('project_id').valueChanges.subscribe(value => {
        const project = this.projects.find(entity => entity.id === value);

        if (project && project.client_id) {
          this.jobFormGroup.get('client_id').setValue(project.client_id);
        }
      })
    );
  }

  getAllUsers() {
    this.gettingAllUsers = true;

    this.usersService.getAllSurveyorUsers(500).subscribe(
      (response: UsersResponse) => {
        this.gettingAllUsers = false;
        this.users = response.data;
        this.jobFormGroup.get('user_id').setValue(this.authService.user.profile.id);
      },
      () => {
        this.gettingAllUsers = false;
        this.snotifyService.error('Unable to load Users. Please try again!', 'Error!');
      }
    );
  }

  onSubmit() {
    this.postingJob = true;
    const payload = this.jobFormGroup.value;

    this.surveyJobsService.updateSurveyJob(payload).subscribe(
      (response: JobResponse) => {
        this.postingJob = false;
        this.snotifyService.success('A new job has been created.', 'Success!');
        this.job = response.data;
        this.bsModalRef.hide();
        return this.router.navigate([`/survey-jobs/view/${this.job.id}`]);
      },
      () => {
        this.postingJob = false;
        this.snotifyService.error('Unable to create job. Please try again.', 'Error!');
      }
    );
  }

  loadClients() {
    const limit = 0;

    this.clientsLoading = true;
    this.clientsService.getAllClients(limit).subscribe(
      (response: ClientsResponse) => {
        this.clients = response.data;
        this.clientsLoading = false;
        this.jobFormGroup.get('client_id').enable();

        if (this.client) {
          this.jobFormGroup.get('client_id').setValue(this.client.id);
        }
      },
      () => {
        this.snotifyService.error('Unable to load Clients!', 'Error!');
        this.clientsLoading = false;
      }
    );
  }

  loadProjects() {
    const limit = 0;

    this.projectsLoading = true;
    this.projectsService.getAllProjects(limit).subscribe(
      (response: ProjectsResponse) => {
        this.projects = response.data;
        this.projectsLoading = false;
        this.jobFormGroup.get('project_id').enable();
      },
      () => {
        this.snotifyService.error('Unable to load Projects!', 'Error!');
        this.projectsLoading = false;
      }
    );
  }

}
