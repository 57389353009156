export enum InventoryTypeEnum {
  Pump = 1,
  Cowl,
  Microscope,
  Laboratory,
  NPL_Test_Slide,
  Stage_Micrometer,
  Barometer_Thermometer,
  High_Flow_Flowmeter,
  Timepiece,
  Low_Flow_Flowmeter
}
