import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateObjectToString'
})
export class DateObjectToStringPipe implements PipeTransform {

  transform(input: any): any {
    if (input instanceof Object) {
      const date = input[Object.keys(input)[0]].split('.');
      return date[0];
    }

    return input;
  }

}
