import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { RolesRoutingModule } from './roles-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { RolesIndexComponent } from './components/roles-index/roles-index.component';
import { RolesCreateComponent } from './components/roles-create/roles-create.component';
import { RolesUpdateComponent } from './components/roles-update/roles-update.component';
import { AuthorizationService } from './services/authorization.service';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    RolesRoutingModule
  ],
  declarations: [
    RolesIndexComponent,
    RolesCreateComponent,
    RolesUpdateComponent
  ],
  providers: [
    AuthorizationService
  ]
})
export class RolesModule { }
