import { FormTemplateSection } from '../models/form-template-section.model';
import { CertificateOfVisualCleanlinessSectionRules } from './certificate-of-visual-cleanliness-section-rules.class';
import { AirTestSectionRules } from './air-test-section-rules.class';
import { AirTestDCUSectionRules } from './air-test-dcu-section-rules.class';
import { AirTestSoilsSectionRules } from './air-test-soils-section-rules.class';
import { AirTestPersonalSectionRules } from './air-test-personal-section-rules.class';
import { Form } from '../models/form.model';
import { CertificateOfReoccupationSectionRules } from './certificate-of-reoccupation-section-rules.class';

/**
 * Form section rules.
 */
export class FormSectionRules {
  /**
   * Certificate of Visual Cleanliness imports.
   */
  public static certificate_of_visual_cleanliness_inspection_details = CertificateOfVisualCleanlinessSectionRules.certificate_of_visual_cleanliness_inspection_details;
  public static certificate_of_visual_cleanliness_stage_1_of_3_preliminary_check = CertificateOfVisualCleanlinessSectionRules.certificate_of_visual_cleanliness_stage_1_of_3_preliminary_check;
  public static certificate_of_visual_cleanliness_stage_2_of_3_thorough_visual = CertificateOfVisualCleanlinessSectionRules.certificate_of_visual_cleanliness_stage_2_of_3_thorough_visual;
  public static certificate_of_visual_cleanliness_stage_3_of_3_final_assessment = CertificateOfVisualCleanlinessSectionRules.certificate_of_visual_cleanliness_stage_3_of_3_final_assessment;
  public static certificate_of_visual_cleanliness_summary = CertificateOfVisualCleanlinessSectionRules.certificate_of_visual_cleanliness_summary;

  /**
   * Air Test imports.
   */
  public static air_test_a1 = AirTestSectionRules.air_test_a1;
  public static air_test_a2b = AirTestSectionRules.air_test_a2b;
  public static air_test_a3 = AirTestSectionRules.air_test_a3;
  public static air_test_a4 = AirTestSectionRules.air_test_a4;
  public static air_test_a5 = AirTestSectionRules.air_test_a5;
  public static air_test_a6 = AirTestSectionRules.air_test_a6;
  public static air_test_a7 = AirTestSectionRules.air_test_a7;
  public static air_test_new_sample = AirTestSectionRules.air_test_new_sample;
  public static air_test_field_blank_count = AirTestSectionRules.air_test_field_blank_count;
  public static air_test_summary = AirTestSectionRules.air_test_summary;
  public static air_test_section_list = AirTestSectionRules.air_test_section_list;
  public static air_test_desktop_review = AirTestSectionRules.air_test_desktop_review;
  public static air_test_microscope_set_up_button = AirTestSectionRules.air_test_microscope_set_up_button;
  public static air_test_samples_done_button = AirTestSectionRules.air_test_samples_done_button;
  public static air_test_sample_start_button = AirTestSectionRules.air_test_sample_start_button;
  public static air_test_sample_collect_button = AirTestSectionRules.air_test_sample_collect_button;
  public static air_test_sample_prepare_button = AirTestSectionRules.air_test_sample_prepare_button;
  public static air_test_sample_analyse_button = AirTestSectionRules.air_test_sample_analyse_button;
  public static air_test_samples_table_button = AirTestSectionRules.air_test_samples_table_button;

  /**
   * Air Test DCU imports.
   */
  public static air_test_dcu_d1 = AirTestDCUSectionRules.air_test_dcu_d1;
  public static air_test_dcu_d2 = AirTestDCUSectionRules.air_test_dcu_d2;
  public static air_test_dcu_d3 = AirTestDCUSectionRules.air_test_dcu_d3;
  public static air_test_dcu_d3b = AirTestDCUSectionRules.air_test_dcu_d3b;
  public static air_test_dcu_d4 = AirTestDCUSectionRules.air_test_dcu_d4;
  public static air_test_dcu_d5 = AirTestDCUSectionRules.air_test_dcu_d5;
  public static air_test_dcu_d6 = AirTestDCUSectionRules.air_test_dcu_d6;
  public static air_test_dcu_d7 = AirTestDCUSectionRules.air_test_dcu_d7;
  public static air_test_dcu_d8 = AirTestDCUSectionRules.air_test_dcu_d8;
  public static air_test_dcu_new_sample = AirTestDCUSectionRules.air_test_dcu_new_sample;
  public static air_test_dcu_field_blank_count = AirTestDCUSectionRules.air_test_dcu_field_blank_count;
  public static air_test_dcu_summary = AirTestDCUSectionRules.air_test_dcu_summary;
  public static air_test_dcu_section_list = AirTestDCUSectionRules.air_test_dcu_section_list;
  public static air_test_dcu_desktop_review = AirTestDCUSectionRules.air_test_dcu_desktop_review;
  public static air_test_dcu_microscope_set_up_button = AirTestDCUSectionRules.air_test_dcu_microscope_set_up_button;
  public static air_test_dcu_samples_done_button = AirTestDCUSectionRules.air_test_dcu_samples_done_button;
  public static air_test_dcu_sample_start_button = AirTestDCUSectionRules.air_test_dcu_sample_start_button;
  public static air_test_dcu_sample_collect_button = AirTestDCUSectionRules.air_test_dcu_sample_collect_button;
  public static air_test_dcu_sample_prepare_button = AirTestDCUSectionRules.air_test_dcu_sample_prepare_button;
  public static air_test_dcu_sample_analyse_button = AirTestDCUSectionRules.air_test_dcu_sample_analyse_button;
  public static air_test_dcu_samples_table_button = AirTestDCUSectionRules.air_test_dcu_samples_table_button;

  /**
   * Air Test - Soils imports.
   */
  public static air_test_soils_inspection_details = AirTestSoilsSectionRules.air_test_soils_inspection_details;
  public static air_test_soils_samples_table = AirTestSoilsSectionRules.air_test_soils_samples_table;
  public static air_test_soils_temp_n_pressure = AirTestSoilsSectionRules.air_test_soils_temp_n_pressure;
  public static air_test_soils_sample_details = AirTestSoilsSectionRules.air_test_soils_sample_details;
  public static air_test_soils_microscope_set_up = AirTestSoilsSectionRules.air_test_soils_microscope_set_up;
  public static air_test_soils_sample_analysis_prep = AirTestSoilsSectionRules.air_test_soils_sample_analysis_prep;
  public static air_test_soils_analysis_of_samples = AirTestSoilsSectionRules.air_test_soils_analysis_of_samples;
  public static air_test_soils_new_sample = AirTestSoilsSectionRules.air_test_soils_new_sample;
  public static air_test_soils_field_blank_count = AirTestSoilsSectionRules.air_test_soils_field_blank_count;
  public static air_test_soils_summary = AirTestSoilsSectionRules.air_test_soils_summary;
  public static air_test_soils_section_list = AirTestSoilsSectionRules.air_test_soils_section_list;
  public static air_test_soils_desktop_review = AirTestSoilsSectionRules.air_test_soils_desktop_review;
  public static air_test_soils_microscope_set_up_button = AirTestSoilsSectionRules.air_test_soils_microscope_set_up_button;
  public static air_test_soils_samples_done_button = AirTestSoilsSectionRules.air_test_soils_samples_done_button;
  public static air_test_soils_sample_start_button = AirTestSoilsSectionRules.air_test_soils_sample_start_button;
  public static air_test_soils_sample_collect_button = AirTestSoilsSectionRules.air_test_soils_sample_collect_button;
  public static air_test_soils_sample_prepare_button = AirTestSoilsSectionRules.air_test_soils_sample_prepare_button;
  public static air_test_soils_sample_analyse_button = AirTestSoilsSectionRules.air_test_soils_sample_analyse_button;
  public static air_test_soils_samples_table_button = AirTestSoilsSectionRules.air_test_soils_samples_table_button;

  /**
   * Air Test - Personal imports.
   */
  public static air_test_personal_c1 = AirTestPersonalSectionRules.air_test_personal_c1;
  public static air_test_personal_c2b = AirTestPersonalSectionRules.air_test_personal_c2b;
  public static air_test_personal_c3 = AirTestPersonalSectionRules.air_test_personal_c3;
  public static air_test_personal_c4 = AirTestPersonalSectionRules.air_test_personal_c4;
  public static air_test_personal_c5 = AirTestPersonalSectionRules.air_test_personal_c5;
  public static air_test_personal_c6 = AirTestPersonalSectionRules.air_test_personal_c6;
  public static air_test_personal_c7 = AirTestPersonalSectionRules.air_test_personal_c7;
  public static air_test_personal_c8 = AirTestPersonalSectionRules.air_test_personal_c8;
  public static air_test_personal_field_blank_count = AirTestPersonalSectionRules.air_test_personal_field_blank_count;
  public static air_test_personal_summary = AirTestPersonalSectionRules.air_test_personal_summary;
  public static air_test_personal_section_list = AirTestPersonalSectionRules.air_test_personal_section_list;
  public static air_test_personal_desktop_review = AirTestPersonalSectionRules.air_test_personal_desktop_review;
  public static air_test_personal_microscope_set_up_button = AirTestPersonalSectionRules.air_test_personal_microscope_set_up_button;
  public static air_test_personal_samples_done_button = AirTestPersonalSectionRules.air_test_personal_samples_done_button;
  public static air_test_personal_sample_start_button = AirTestPersonalSectionRules.air_test_personal_sample_start_button;
  public static air_test_personal_sample_collect_button = AirTestPersonalSectionRules.air_test_personal_sample_collect_button;
  public static air_test_personal_sample_prepare_button = AirTestPersonalSectionRules.air_test_personal_sample_prepare_button;
  public static air_test_personal_sample_analyse_button = AirTestPersonalSectionRules.air_test_personal_sample_analyse_button;
  public static air_test_personal_samples_table_button = AirTestPersonalSectionRules.air_test_personal_samples_table_button;

  /**
   * Certificate of Reoccupation imports.
   */
  public static certificate_of_reoccupation_b1 = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b1;
  public static certificate_of_reoccupation_b2 = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b2;
  public static certificate_of_reoccupation_b3 = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b3;
  public static certificate_of_reoccupation_b4 = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b4;
  public static certificate_of_reoccupation_b5_1 = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b5_1;
  public static certificate_of_reoccupation_b5_2 = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b5_2;
  public static certificate_of_reoccupation_b5_2b = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b5_2b;
  public static certificate_of_reoccupation_b5_3 = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b5_3;
  public static certificate_of_reoccupation_b5_4 = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b5_4;
  public static certificate_of_reoccupation_b5_5 = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b5_5;
  public static certificate_of_reoccupation_b5_6 = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b5_6;
  public static certificate_of_reoccupation_b5_7 = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b5_7;
  public static certificate_of_reoccupation_b5_new_sample = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b5_new_sample;
  public static certificate_of_reoccupation_b5_field_blank_count = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b5_field_blank_count;
  public static certificate_of_reoccupation_b5_summary = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b5_summary;
  public static certificate_of_reoccupation_b5_desktop_review = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b5_desktop_review;
  public static certificate_of_reoccupation_b6 = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_b6;
  public static certificate_of_reoccupation_desktop_review = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_desktop_review;
  public static certificate_of_reoccupation_microscope_set_up_button = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_microscope_set_up_button;
  public static certificate_of_reoccupation_samples_done_button = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_samples_done_button;
  public static certificate_of_reoccupation_sample_start_button = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_sample_start_button;
  public static certificate_of_reoccupation_sample_collect_button = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_sample_collect_button;
  public static certificate_of_reoccupation_sample_prepare_button = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_sample_prepare_button;
  public static certificate_of_reoccupation_sample_analyse_button = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_sample_analyse_button;
  public static certificate_of_reoccupation_samples_table_button = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_samples_table_button;
  public static certificate_of_reoccupation_stages_done_button = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_stages_done_button;
  public static certificate_of_reoccupation_stage_1_button = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_stage_1_button;
  public static certificate_of_reoccupation_stage_2_button = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_stage_2_button;
  public static certificate_of_reoccupation_stage_3_button = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_stage_3_button;
  public static certificate_of_reoccupation_stage_4_button = CertificateOfReoccupationSectionRules.certificate_of_reoccupation_stage_4_button;
  public static certificate_of_reoccupation_stages_selection_button= CertificateOfReoccupationSectionRules.certificate_of_reoccupation_stages_selection_button;

  /**
   * Returns the next section based on rules specified for the current section.
   * @param form
   * @param section
   * @returns {any}
   */
  public static nextSection(form: Form, section: FormTemplateSection) {
    if (typeof this[section.js_rule] === 'function') {
      return this[section.js_rule](form, section);
    }
  }

  /**
   * Returns a specific section.
   * @param form
   * @param section
   * @param js_rule
   * @returns {any}
   */
  public static goToSection(form: Form, section: FormTemplateSection, js_rule: string) {
    if (typeof this[js_rule] === 'function') {
      return this[js_rule](form, section);
    }
  }
}
