<label *ngIf="question.js_id !== 'sample_number'">
  {{ question.text }}
</label>

<br>

<app-signature-pad
    [question]="question"
    (onSignatureDrawn)="drawComplete(question)"></app-signature-pad>
<app-input-value-skip-button [question]="question"></app-input-value-skip-button>

<div class="clearfix"></div>

<div *ngIf="question.validation === false && !question.skipped" class="invalid-feedback d-block">
  This field is required. Please either enter a signature or press <i>Skip</i> button and enter a comment.
</div>

<app-input-comment [question]="question"></app-input-comment>