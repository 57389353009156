import { Component, OnDestroy, OnInit } from '@angular/core';
import { Role } from '../../models/role.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Permission } from '../../models/permission.model';
import { ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { AuthorizationService } from '../../services/authorization.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-roles-update',
  templateUrl: './roles-update.component.html',
  styleUrls: ['./roles-update.component.scss']
})
export class RolesUpdateComponent implements OnInit, OnDestroy {
  public role: Role;
  public roleFormGroup: FormGroup;
  public role_id: string;
  public updatingRole = false;
  public gettingRole = false;
  public loadingRequests = false;
  public subscription: any;
  public syncingRolePermissions = false;
  public permissions: Permission[];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private snotifyService: SnotifyService
  ) {}

  ngOnInit() {
    this.subscription = this.route.params.subscribe(params => {
      this.role_id = params['role_id'];
      this.initialRequests();
    });

    this.initRoleForm();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initialRequests() {
    const includes = ['permissions'];
    const findRoleByIdRequest = this.authorizationService.findRoleById(this.role_id, includes);
    const getAllPermissionsRequest = this.authorizationService.getAllPermissions();

    this.loadingRequests = true;

    forkJoin([findRoleByIdRequest, getAllPermissionsRequest]).subscribe(
      (response: any) => {
        this.loadingRequests = false;
        this.role = response[0].data;

        if (this.role) {
          this.fillRoleForm();
        }

        this.permissions = response[1].data;

        if (this.permissions) {
          this.passRolePermissionsToPermissionElements();
        }
      },
      error => {
        this.loadingRequests = false;
        this.snotifyService.error(
          'Unable to load role resources. Please try again.',
          'Error!'
        );
      }
    );
  }

  initRoleForm() {
    this.roleFormGroup = this.formBuilder.group({
      display_name: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
  }

  fillRoleForm() {
    this.roleFormGroup.controls['display_name'].setValue(this.role.display_name);
    this.roleFormGroup.controls['description'].setValue(this.role.description);
  }

  onSubmit() {
    this.updatingRole = true;
    const payload = {
      id: this.role_id,
      name: this.roleFormGroup.controls['display_name'].value.toLowerCase().split(' ').join('_'),
      display_name: this.roleFormGroup.controls['display_name'].value,
      description: this.roleFormGroup.controls['description'].value
    };

    this.authorizationService.updateRole(payload).subscribe(
      response => {
        this.updatingRole = false;
        this.snotifyService.success(
          'The user role has been updated.',
          'Success!'
        );
        this.syncRolePermissions();
      },
      error => {
        this.updatingRole = false;
        this.snotifyService.error(
          'Unable to update user role. Please try again.',
          'Error!'
        );
      }
    );
  }

  syncRolePermissions() {
    const permissionsIds = this.getEnabledPermissionsIds();

    if (permissionsIds.length === 0) {
      return;
    }

    this.syncingRolePermissions = true;

    this.authorizationService.syncRolePermissions(this.role.id, permissionsIds).subscribe(
      response => {
        this.syncingRolePermissions = false;
        this.snotifyService.success(
          'User role permissions have been synced.',
          'Success!'
        );
      },
      error => {
        this.syncingRolePermissions = false;
        this.snotifyService.success('Unable to sync user roles.', 'Error!');
      }
    );
  }

  getEnabledPermissionsIds() {
    const ids = [];

    this.permissions.forEach(permission => {
      if (permission.selected) {
        ids.push(permission.id);
      }
    });

    return ids;
  }

  passRolePermissionsToPermissionElements() {
    this.role.permissions.data.forEach(rolePermission => {
      this.permissions = this.permissions.map(permission => {
        if (permission.id === rolePermission.id) {
          permission.selected = true;
        }

        return permission;
      });
    });
  }

}
