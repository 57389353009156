import { BaseModel } from '../classes/query-builder.class';

export class Floor extends BaseModel {
  public table = 'floors';
  public schema = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    building_id: 'VARCHAR',
    type: 'INTEGER',
    number: 'VARCHAR',
  };
}
