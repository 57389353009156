<form action="">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Update Revision
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div [formGroup]="revisionFormGroup" class="modal-body">
    <div class="form-group">
      <label for="number">
        Number
      </label>
      <input
          [formControl]="revisionFormGroup.controls['number']"
          [ngClass]="{'is-invalid': !revisionFormGroup.controls['number'].valid && revisionFormGroup.controls['number'].touched}"
          class="form-control"
          id="number"
          name="number"
          type="text">
      <div class="help-block text-danger"
           *ngIf="revisionFormGroup.controls['number'].touched && revisionFormGroup.controls['number'].hasError('required')">
        * This field is required.
      </div>
    </div>

    <div class="form-group">
      <label for="date">
        Date
      </label>
      <input
          [formControl]="revisionFormGroup.controls['date']"
          [ngClass]="{'is-invalid': !revisionFormGroup.controls['date'].valid && revisionFormGroup.controls['date'].touched}"
          class="form-control"
          id="date"
          name="date"
          [textMask]="{mask: dateMask}"
          type="text">
      <div class="help-block text-danger"
           *ngIf="revisionFormGroup.controls['date'].touched && revisionFormGroup.controls['date'].hasError('required')">
        * This field is required.
      </div>
    </div>

    <div class="form-group">
      <label for="publisher">
        Publisher
      </label>
      <input
          [formControl]="revisionFormGroup.controls['publisher']"
          [ngClass]="{'is-invalid': !revisionFormGroup.controls['publisher'].valid && revisionFormGroup.controls['publisher'].touched}"
          class="form-control"
          id="publisher"
          name="publisher"
          type="text">
      <div class="help-block text-danger"
           *ngIf="revisionFormGroup.controls['publisher'].touched && revisionFormGroup.controls['publisher'].hasError('required')">
        * This field is required.
      </div>
    </div>

    <div class="form-group">
      <label for="reason">
        Reason
      </label>
      <input
          [formControl]="revisionFormGroup.controls['reason']"
          [ngClass]="{'is-invalid': !revisionFormGroup.controls['reason'].valid && revisionFormGroup.controls['reason'].touched}"
          class="form-control"
          id="reason"
          name="reason"
          type="text">
      <div class="help-block text-danger"
           *ngIf="revisionFormGroup.controls['reason'].touched && revisionFormGroup.controls['reason'].hasError('required')">
        * This field is required.
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" [disabled]="!revisionFormGroup.valid || postingRevision" (click)="onSubmit()">
      <span *ngIf="!postingRevision">
        Update
      </span>
      <span *ngIf="postingRevision">
        <i class="fa fa-spin fa-spinner mr-1"></i>
        Updating
      </span>
    </button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
  </div>
</form>
