import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { FormResponse, FormsResponse } from '../responses/form.response';
import { Form, Form as FormModel } from '../models/form.model';
import { AuditsResponse } from '../../shared/responses/audit.response';
import { FormQueryBuilder } from '../../shared/classes/form.query-builder.class';
import { QueryBuilder } from '../../shared/classes/query-builder.class';
import { InventoryMessage } from '../../shared/entities/inventory_message.entity';
import { ApiService } from '../../shared/services/api.service';

@Injectable()
export class FormsService {
  private resource = 'forms';
  private queryBuilder: FormQueryBuilder;

  constructor(private http: HttpClient) {
    if (environment.cordova) {
      this.queryBuilder = new FormQueryBuilder();
    }
  }

  /**
   * Get all forms.
   */
  getAllForms(
    includes: string[] = null,
    filter: string = null,
    sorting: string,
    appends: string[] = null,
    search: string = null,
    page: number = null,
  ): Observable<FormsResponse> {
    let params = new HttpParams();

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    if (appends) {
      params = params.set('append', appends.join(','));
    }

    if (sorting) {
      params = params.set('sort', sorting);
    }

    if (filter) {
      params = params.set('filter[status]', filter);
    }

    if (search) {
      params = params.set('filter[search]', search);
    }

    if (page) {
      params = params.set('page', page.toString());
    }

    return this.http.get<FormsResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params });
  }

  /**
   * Get all CoR forms.
   */
  getAllCoRForms(assignmentId: string, formTemplateSlug: string): Observable<FormsResponse> {
    return this.http.get<FormsResponse>(`${ApiService.ApiUrl}/assignments/${assignmentId}/${this.resource}/${formTemplateSlug}`);
  }

  /**
   * Get all un-synced forms
   */
  getAllUnSyncedForms() {
    this.queryBuilder = new FormQueryBuilder();

    return this.queryBuilder
      .whereNull('synced_at')
      .whereNotNull('completed_at')
      .get();
  }

  /**
   * Find form by id.
   */
  findFormById(form_id: string | number, includes: string[] = null, forceAPI: boolean = false): Observable<FormResponse> {
    let params;

    if (environment.cordova && !forceAPI) {
      this.queryBuilder = new FormQueryBuilder();
    }

    if (includes) {
      params = new HttpParams().set('include', includes.join(','));
    }

    return !environment.cordova || forceAPI
      ? this.http.get<FormResponse>(`${ApiService.ApiUrl}/${this.resource}/${form_id}`, { params })
      : this.queryBuilder
          .whereInt('id', '=', +form_id)
          .resourceIncludes(this.resource, includes)
          .first();
  }

  /**
   * Create new form.
   */
  createForm(form): Observable<FormResponse> {
    const payload = {
      form_template_id: form.form_template_id,
      form_template_name: form.form_template_name,
      form_template_slug: form.form_template_slug,
      assignment_id: form.job_id,
      user_id: form.user_id
    };

    return !environment.cordova
      ? this.http.post<FormResponse>(`${ApiService.ApiUrl}/${this.resource}`, payload)
      : this.queryBuilder.create(payload);
  }

  /**
   * Update form.
   */
  updateForm(form: Form): Observable<FormResponse> {
    let payload = null;

    if (environment.cordova) {
      payload = {
        id: form.id,
        hashed_id: form.hashed_id,
        form_template_id: form.form_template_id,
        form_template_name: form.form_template_name,
        form_template_slug: form.form_template_slug,
        assignment_id: form.assignment_id,
        user_id: form.user_id,
        site_id: form.site_id.toString(),
        certificate_number: form.certificate_number,
        completed_at: form.completed_at,
        published_at: form.published_at,
        synced_at: form.synced_at,
      };
    }

    return !environment.cordova
      ? this.http.patch<FormResponse>(`${ApiService.ApiUrl}/${this.resource}/${form.id}`, form)
      : this.queryBuilder.update(payload);
  }

  /**
   * Publish form.
   */
  publishForm(form): Observable<FormResponse> {
    return this.http.patch<FormResponse>(`${ApiService.ApiUrl}/${this.resource}/${form.id}/publish`, form);
  }

  /**
   * Unpublish form.
   */
  unpublishForm(form): Observable<FormResponse> {
    return this.http.patch<FormResponse>(`${ApiService.ApiUrl}/${this.resource}/${form.id}/unpublish`, form);
  }

  /**
   * Complete form.
   */
  completeForm(form): Observable<FormResponse> {
    return !environment.cordova
      ? this.http.patch<FormResponse>(`${ApiService.ApiUrl}/${this.resource}/${form.id}/complete`, form)
      : this.queryBuilder.update(form);
  }

  /**
   * Delete form by id.
   */
  deleteForm(form: FormModel): Observable<any> {
    if (environment.cordova) {
      this.queryBuilder = new FormQueryBuilder();
    }

    return !environment.cordova
      ? this.http.delete<any>(`${ApiService.ApiUrl}/${this.resource}/${form.id}`)
      : this.queryBuilder.where('id', '=', form.id).delete(form);
  }

  /**
   * Get all form templates.
   */
  deleteFormPhoto(uri: string): Observable<any> {
    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/photo/delete`, { uri });
  }

  /**
   * Send
   */
  formInventoriesAdminMessage(form_id: string, message: string): Observable<any> {
    let inventoryMessagesQueryBuilder: QueryBuilder;

    if (environment.cordova) {
      inventoryMessagesQueryBuilder = new QueryBuilder(new InventoryMessage());
    }

    return !environment.cordova
      ? this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/${form_id}/inventories/message`, { message })
      : inventoryMessagesQueryBuilder.create({ form_id, message });
  }

  /**
   * Get all form and form answers audit logs.
   */
  getAllFormAudits(form_id: string): Observable<AuditsResponse> {
    return this.http.get<AuditsResponse>(`${ApiService.ApiUrl}/${this.resource}/${form_id}/audits`);
  }

  /**
   * Upload a photo against a form.
   */
  uploadFormPhoto(formData: FormData): Observable<any> {
    return this.http.post<any>(`${ApiService.ApiUrl}/forms/photo`, formData);
  }

  /**
   * Sync form.
   */
  syncForm(payload): Observable<any> {
    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/sync`, payload);
  }
}
