import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MaterialResponse } from '../responses/material.response';
import { Material } from '../models/material.model';
import { ApiService } from './api.service';

@Injectable()
export class MaterialsService {
  private resource = 'materials';

  constructor(private http: HttpClient) {
  }

  /**
   * Create new user material.
   */
  createMaterial(material: Material): Observable<MaterialResponse> {
    return this.http.post<MaterialResponse>(`${ApiService.ApiUrl}/${this.resource}`, material);
  }

  /**
   * Update user material.
   */
  updateMaterial(material: Material): Observable<MaterialResponse> {
    return this.http.patch<MaterialResponse>(`${ApiService.ApiUrl}/${this.resource}/${material.id}`, material);
  }

  /**
   * Delete user material.
   */
  deleteMaterial(material: Material): Observable<any> {
    return this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${material.id}`);
  }
}
