import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { InventoryIndexComponent } from './components/inventory-index/inventory-index.component';
import { InventoryCreateComponent } from './components/inventory-create/inventory-create.component';
import { InventoryUpdateComponent } from './components/inventory-update/inventory-update.component';
import { InventoryRoutingModule } from './inventory-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { InventoriesService } from './services/inventories.service';
import { InventoryViewComponent } from './components/inventory-view/inventory-view.component';
import { TechniciansIndexComponent } from './components/technicians-index/technicians-index.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, SharedModule, InventoryRoutingModule, IonicModule],
  declarations: [InventoryIndexComponent, InventoryCreateComponent, InventoryUpdateComponent, InventoryViewComponent, TechniciansIndexComponent],
  providers: [InventoriesService],
  entryComponents: [TechniciansIndexComponent]
})
export class InventoryModule {}
