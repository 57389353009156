import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Inventory } from '../../models/inventory.model';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { InventoriesService } from '../../services/inventories.service';
import { InventoryResponse } from '../../responses/inventory.response';
import { InventoryTypeEnum } from '../../../shared/enums/inventory-type.enum';

@Component({
  selector: 'app-inventory-create',
  templateUrl: './inventory-create.component.html',
  styleUrls: ['./inventory-create.component.scss']
})
export class InventoryCreateComponent implements OnInit {
  public inventoryFormGroup: FormGroup;
  private inventory: Inventory;
  public postingInventory = false;
  public inventoryTypeEnum = InventoryTypeEnum;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private snotifyService: SnotifyService,
    private inventoriesService: InventoriesService
  ) { }

  ngOnInit() {
    this.initInventoryForm();
  }

  initInventoryForm() {
    this.inventoryFormGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      type: [''],
      model: [''],
      serial_number: [''],
      inventory_code: [''],
    });
  }

  onSubmit() {
    this.postingInventory = true;
    const payload = this.inventoryFormGroup.value;

    this.inventoriesService.createInventory(payload).subscribe(
      (response: InventoryResponse) => {
        this.postingInventory = false;
        this.snotifyService.success('A new inventory item has been created.', 'Success!');
        this.inventory = response.data;
        return this.router.navigate(['/inventory/index']);
      },
      () => {
        this.postingInventory = false;
        this.snotifyService.error(
          'Unable to create inventory item. Please try again.',
          'Error!'
        );
      }
    );
  }

}
