<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Update Inventory</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li class="breadcrumb-item ">
      <a [routerLink]="['/inventory/index']" routerDirection="backward">Inventory</a>
    </li>

    <li class="breadcrumb-item active">
      <span>View</span>
    </li>
  </ol>

  <app-loading *ngIf="findingInventoryById"></app-loading>

  <div *ngIf="!findingInventoryById" class="row">
    <div class="col-md-3">
      <div class="card animated fadeIn">
        <div class="card-header">
          <strong>Item</strong>
          info
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item d-flex justify-content-between">
            <span>Name:</span>
            <span>{{ inventory?.name }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between">
            <span>Type:</span>
            <span>{{ inventory?.type ? inventoryTypeEnum[inventory.type].split('_').join(' ') : '' }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between">
            <span>Model:</span>
            <span>{{ inventory?.model }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between">
            <span>Serial number:</span>
            <span>{{ inventory?.serial_number }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between">
            <span>Inventory code:</span>
            <span>{{ inventory?.inventory_code }}</span>
          </li>
        </ul>
        <div class="card-footer">
          <div class="btn-block">
            <a
                [routerLink]="['/inventory/update', inventory.id]"
                class="btn btn-sm btn-info">
              <i class="fa fa-edit mr-1"></i>
              Edit
            </a>
            <button
                [disabled]="inventory.deleting"
                (click)="onInventoryDelete()"
                class="btn btn-sm btn-danger">
              <span *ngIf="!inventory.deleting"><i class="fa fa-trash mr-1"></i> Delete</span>
              <span *ngIf="inventory.deleting"><i class="fa fa-spin fa-spinner mr-1"></i> Deleting</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-9">
      <div class="card animated fadeIn">
        <div class="card-header">
          <strong>Assigned</strong>
          users
          <button
              [disabled]="!inventory?.is_available"
              (click)="onUserAssign()"
              class="btn btn-sm btn-primary pull-right">
            Assign
          </button>
        </div>

        <div *ngIf="users?.length === 0 && !gettingAllInventoryUsers" class="alert alert-info mb-0">
          <i class="fa fa-info mr-1"></i>
          No users found.
        </div>

        <div *ngIf="attachingInventoryUser" class="alert alert-info mb-0">
          <i class="fa fa-spin fa-spinner mr-1"></i>
          Attaching inventory item to user. Please wait!
        </div>

        <div *ngIf="gettingAllInventoryUsers" class="alert alert-info mb-0">
          <i class="fa fa-spin fa-spinner mr-1"></i>
          Loading inventory item assigned users. Please wait!
        </div>

        <div *ngIf="!attachingInventoryUser && !gettingAllInventoryUsers" class="table-responsive">
          <table *ngIf="users?.length > 0" class="table table-striped mb-0">
            <tr>
              <th>Name</th>
              <th>Used from</th>
              <th>Used to</th>
              <th class="text-right">Actions</th>
            </tr>

            <tr *ngFor="let user of users">
              <td>{{ user.name }}</td>
              <td>{{ user.pivot.used_from }}</td>
              <td>{{ user.pivot.used_to }}</td>
              <td class="text-right">
                <button
                    *ngIf="!user.pivot.used_to"
                    (click)="onUserDetach(user)"
                    [disabled]="user.detaching"
                    class="btn btn-warning btn-sm">
                  <span *ngIf="!user.detaching"><i class="fa fa-chain-broken mr-1"></i> Unassign</span>
                  <span *ngIf="user.detaching"><i class="fa fa-spin fa-spinner"></i> Unassigning</span>
                </button>
                <button
                    (click)="onUserDelete(user)"
                    [disabled]="user.deleting"
                    class="btn btn-danger btn-sm">
                  <span *ngIf="!user.deleting"><i class="fa fa-trash mr-1"></i> Delete</span>
                  <span *ngIf="user.deleting"><i class="fa fa-spin fa-spinner"></i> Deleting</span>
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div *ngIf="metaUsers && !gettingAllInventoryUsers && metaUsers.last_page > 1" class="card-footer">
          <app-pagination
              [meta]="metaUsers"
              [links]="linksUsers"
              (updated)="updateUsersList($event)"></app-pagination>
        </div>
      </div>

      <div class="card animated fadeIn">
        <div class="card-header">
          <strong>Assigned</strong>
          forms
        </div>

        <div *ngIf="forms?.length === 0 && !gettingAllInventoryForms" class="alert alert-info mb-0">
          <i class="fa fa-info mr-1"></i>
          No forms found.
        </div>

        <div *ngIf="gettingAllInventoryForms" class="alert alert-info mb-0">
          <i class="fa fa-spin fa-spinner mr-1"></i>
          Loading inventory item assigned users. Please wait!
        </div>

        <div class="table-responsive">
          <table *ngIf="forms?.length > 0" class="table table-striped mb-0">
            <tr>
              <th>Form template</th>
              <th>Job</th>
              <th>Completed at</th>
            </tr>

            <tr *ngFor="let form of forms">
              <td>{{ form.form_template_name }}</td>
              <td>{{ form.assignment_name }}</td>
              <td>{{ form.completed_at }}</td>
            </tr>
          </table>
        </div>

        <div *ngIf="metaForms && !gettingAllInventoryForms && metaForms.last_page > 1" class="card-footer">
          <app-pagination
              [meta]="metaForms"
              [links]="linksForms"
              (updated)="updateFormsList($event)"></app-pagination>
        </div>
      </div>
    </div>
  </div>
</ion-content>
