import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoginResponse } from '../responses/login.response';
import { RefreshTokenResponse } from '../responses/refreshToken.response';
import { UserResponse } from '../responses/user.response';
import { User } from '../classes/user.class';
import { NetworkService } from '../../shared/services/network.service';
import { ApiService } from '../../shared/services/api.service';
import { Observable, Observer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BusService } from '../../../modules/shared/services/bus.service';

@Injectable()
export class AuthService {
  public redirectUrl = 'dashboard';
  private token: string;
  private refreshToken: string;
  public user: User;

  /**
   * Persists token in the storage, based on the session retention state.
   */
  public static setToken(token, remember = false) {
    return localStorage.setItem('token', token);
  }

  /**
   * Persists refresh token in the storage, based on the session retention state.
   */
  public static setRefreshToken(refreshToken, remember = false) {
    return localStorage.setItem('refresh_token', refreshToken);
  }

  /**
   * Persists user data in the storage.
   */
  public static setUserResponse(userResponse: UserResponse) {
    return localStorage.setItem('user_response', JSON.stringify(userResponse));
  }

  /**
   * Remove user data from storage.
   */
  public static removeUserResponse() {
    return localStorage.removeItem('user_response');
  }

  /**
   * Retrieves token from the storage.
   */
  public static getToken() {
    return localStorage.getItem('token');
  }

  /**
   * Retrieves refresh token from the storage.
   */
  public static getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  /**
   * Persists user data in the storage.
   */
  public static getUserResponse() {
    return JSON.parse(localStorage.getItem('user_response'));
  }

  /**
   * Removes token from the storage.
   */
  public static removeToken() {
    localStorage.removeItem('token');
  }

  /**
   * Removes refresh token from the storage.
   */
  public static removeRefreshToken() {
    localStorage.removeItem('refresh_token');
  }

  /**
   * Removes token and refresh token from the storage.
   */
  public static removeAllTokens() {
    AuthService.removeToken();
    AuthService.removeRefreshToken();
  }

  /**
   * Auth service constructor.
   */
  constructor(
    private http: HttpClient,
    private navController: NavController,
    private networkService: NetworkService,
    private busService: BusService,
  ) {}

  /**
   * Get authenticated user profile.
   */
  getAuthenticatedUserProfile(): Observable<UserResponse> {
    const params = new HttpParams().set('include', 'roles,roles.permissions');

    return this.http.get<UserResponse>(`${ApiService.ApiUrl}/user/profile`, { params });
  }

  /**
   * Registers a new user.
   */
  register(credentials): Observable<any> {
    const payload = {
      name: credentials.name,
      company_name: credentials.company_name,
      email: credentials.email,
      password: credentials.password
    };

    return this.http.post(`${ApiService.ApiUrl}/register`, payload);
  }

  /**
   * Logs a user in.
   */
  login(credentials): Observable<any> {
    if (!this.networkService.connected && AuthService.getUserResponse()) {
      return new Observable(
        (observer: Observer<string>) => {
          observer.next(this.redirectUrl);
        }
      );
    }

    const payload = {
      username: credentials.email,
      password: credentials.password
    };

    return this.http.post(`${ApiService.BaseServerUrl}/oauth/token`, payload).pipe(
      tap(
        (response: LoginResponse) => {
          this.token = response.access_token;
          this.refreshToken = response.refresh_token;

          AuthService.setToken(this.token, credentials.remember);
          AuthService.setRefreshToken(this.refreshToken, credentials.remember);

          return this.redirectUrl;
        }
      )
    );
  }

  /**
   * Logs a user out.
   */
  logout() {
    this.token = null;
    this.user = null;

    AuthService.removeAllTokens();
    AuthService.removeUserResponse();
    ApiService.removeAppMode();
    this.busService.dispatch({ type: 'logged_out' });

    return this.navController.navigateRoot(['/auth/login']);
  }

  /**
   * Requests a password reset mail.
   */
  forgotPassword(credentials): Observable<any> {
    const payload = {
      email: credentials.email,
      resetUrl: 'password-reset'
    };

    return this.http.post(`${ApiService.ApiUrl}/password/forgot`, payload);
  }

  /**
   * Resets user password.
   */
  resetPassword(credentials): Observable<any> {
    return this.http.post(`${ApiService.ApiUrl}/password/reset`, credentials);
  }

  /**
   * Refreshes the access token using the stored refresh token.
   */
  public refreshAccessToken() {
    const refreshToken = AuthService.getRefreshToken();

    if (!refreshToken) {
      return;
    }

    const payload = {
      refreshToken
    };

    return this.http
      .post(`${ApiService.BaseServerUrl}/oauth/token`, payload)
      .pipe(
        tap(
          (response: RefreshTokenResponse) => {
            this.token = response.access_token;
            this.refreshToken = response.refresh_token;

            AuthService.setToken(this.token);
            AuthService.setRefreshToken(this.refreshToken);

            return response.access_token;
          }
        )
      );
  }
}
