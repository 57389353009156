import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Site } from '../../models/site.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SitesService } from '../../../sites/services/sites.service';
import { SnotifyService } from 'ng-snotify';
import { SiteResponse } from '../../responses/site.response';
import {AppHelper} from "../../classes/app.helper";

@Component({
  selector: 'app-sites-update-modal',
  templateUrl: './sites-update-modal.component.html',
  styleUrls: ['./sites-update-modal.component.scss'],
})
export class SitesUpdateModalComponent implements OnInit {
  public site: Site;
  public formGroup: FormGroup;
  public onHide: Subject<Site> = new Subject();
  public postingSite = false;

  constructor(
    public bsModalRef: BsModalRef,
    private sitesService: SitesService,
    private modalService: BsModalService,
    private snotifyService: SnotifyService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.initFormGroup();
  }

  initFormGroup() {
    this.formGroup = this.formBuilder.group({
      id: [this.site.id, [Validators.required]],
      client_id: [this.site.client_id, [Validators.required]],
      name: [this.site.name, [Validators.required]],
    });
  }

  onSubmit() {
    this.postingSite = true;
    const payload = this.formGroup.value;

    this.sitesService.updateSite(payload).subscribe(
      (site: SiteResponse) => {
        this.postingSite = false;
        this.snotifyService.success('Site has been updated.', 'Success!');
        this.bsModalRef.hide();
        this.onHide.next(site.data);
        AppHelper.chime();
      },
      () => {
        this.postingSite = false;
        this.snotifyService.error('Unable to update site. Please try again.', 'Error!');
      }
    );
  }

}
