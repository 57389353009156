import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Job } from '../../models/job.model';
import { JobsService } from '../../services/jobs.service';
import { JobResponse, JobsResponse } from '../../responses/job.response';
import { SnotifyService } from 'ng-snotify';
import * as moment from 'moment';
import { JobsCreateComponent } from '../jobs-create/jobs-create.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BusService } from '../../../shared/services/bus.service';
import { Subscription } from 'rxjs';
import { Links, Meta } from '../../../shared/models/pagination.model';
import { JobsUpdateComponent } from '../jobs-update/jobs-update.component';
import {AppHelper} from "../../../shared/classes/app.helper";

@Component({
  selector: 'app-jobs-index',
  templateUrl: './jobs-index.component.html',
  styleUrls: ['./jobs-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JobsIndexComponent implements OnInit, OnDestroy {
  public jobs: Job[];
  public activeJob: Job;
  public meta: Meta;
  public links: Links;
  public gettingAllJobs = false;
  public jobsSiteSortingDirection = 1;
  public jobsDateSortingDirection = -1;
  private jobsSortedBy: string;
  private bsModalRef: BsModalRef;
  public subscriptions: Subscription = new Subscription();
  public filter = 'open';
  public search: string;

  constructor(
    private modalService: BsModalService,
    private jobsService: JobsService,
    private snotifyService: SnotifyService,
    private busService: BusService
  ) {}

  ngOnInit() {
    this.getAllJobs();
    this.addSubscriptions();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  addSubscriptions() {
    this.subscriptions.add(
      this.busService.listen('loading').subscribe(event => {
        this.gettingAllJobs = event.data;
      })
    );

    this.subscriptions.add(
      this.busService.listen('pagination').subscribe(event => {
        this.updateJobsList(event.data);
      })
    );
  }

  resetSearch() {
    this.search = null;

    this.getAllJobs();
  }

  getAllJobs() {
    this.gettingAllJobs = true;
    const includes = ['client', 'project', 'users'];

    this.jobsService.getAllJobs(includes, this.filter, this.search).subscribe(
      (response: JobsResponse) => {
        this.gettingAllJobs = false;
        this.jobs = response.data;
        this.updateJobsList(response);
      },
      () => {
        this.gettingAllJobs = false;
        this.snotifyService.error('Unable to load jobs. Please try again!', 'Error!');
      }
    );
  }

  updateJobsList(response: JobsResponse) {
    this.jobs = response.data;
    this.meta = response.meta;
    this.links = response.links;
  }

  sortJobsBySite() {
    const argument = 'address';
    this.jobsSortedBy = argument;
    this.jobsSiteSortingDirection = -1 * this.jobsSiteSortingDirection;

    this.jobs = this.jobs.sort((a: any, b: any) => {
      if (a[argument] < b[argument]) {
        return -1 * this.jobsSiteSortingDirection;
      } else if (a[argument] > b[argument]) {
        return this.jobsSiteSortingDirection;
      } else {
        return 0;
      }
    });
  }

  sortJobsByDate() {
    const argument = 'created_at_formatted';
    this.jobsSortedBy = argument;
    this.jobsDateSortingDirection = -1 * this.jobsDateSortingDirection;

    this.jobs = this.jobs.sort((a: any, b: any) => {
      if (this.jobsDateSortingDirection === 1) {
        return moment.utc(b[argument], 'YYYY-MM-DD HH:mm:ss').diff(moment.utc(a[argument], 'YYYY-MM-DD HH:mm:ss'));
      }

      return moment.utc(a[argument], 'YYYY-MM-DD HH:mm:ss').diff(moment.utc(b[argument], 'YYYY-MM-DD HH:mm:ss'));
    });
  }

  onJobDelete(job: Job) {
    this.snotifyService.confirm(`Job "${job.name}" and all related forms are going to be erased.`, 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.deleteJob(job),
          bold: false
        },
        { text: 'No', action: () => {} }
      ]
    });
  }

  deleteJob(job: Job) {
    job.deleting = true;

    this.jobsService.deleteJob(job).subscribe(
      () => {
        job.deleting = false;
        AppHelper.chime();
        this.getAllJobs();
      },
      () => {
        job.deleting = false;
        this.snotifyService.error('Unable to delete job. Please try again!', 'Error!');
      }
    );
  }

  onNewJob() {
    this.bsModalRef = this.modalService.show(JobsCreateComponent);
  }

  onUpdateJob(job: Job) {
    const initialState = {
      job
    };

    this.bsModalRef = this.modalService.show(JobsUpdateComponent, { initialState });
  }

  toggleOpenStatus(job: Job) {
    if (job.open === true) {
      job.open = false;
    } else if (job.open === false) {
      job.open = true;
    } else if (+job.open === 1) {
      job.open = false;
    } else if (+job.open === 0) {
      job.open = true;
    }

    const userIds = [];
    job.users.data.forEach(user => userIds.push(user.id));
    job.user_id = userIds;

    this.updateJob(job);
  }

  updateJob(job: Job) {
    job.updating = true;

    this.jobsService.updateJob(job).subscribe(
      (response: JobResponse) => {
        job.updating = false;
        AppHelper.chime();
        this.snotifyService.success('Job has been updated.', 'Success!');
      },
      () => {
        job.updating = false;
        this.snotifyService.error('Unable to update job. Please try again.', 'Error!');
      }
    );
  }

  switchFilter(filter) {
    this.filter = filter;
    this.getAllJobs();
  }
}
