import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsFillComponent } from './components/forms-fill/forms-fill.component';
import { HFSFormsRoutingModule } from './hfs-forms-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxUploaderModule } from 'ngx-uploader';
import { ModalModule, TabsModule } from 'ngx-bootstrap';
import { FormsIndexComponent } from './components/forms-index/forms-index.component';
import { FormsViewComponent } from './components/forms-view/forms-view.component';
import { FormsUpdateComponent } from './components/forms-update/forms-update.component';
import { FormsAuditLogComponent } from './components/forms-audit-log/forms-audit-log.component';
import { InputTypeStringComponent } from './components/input-type-string/input-type-string.component';
import { InputCommentComponent } from './components/input-comment/input-comment.component';
import { InputValueSkipButtonComponent } from './components/input-value-skip-button/input-value-skip-button.component';
import { InputTypeTextComponent } from './components/input-type-text/input-type-text.component';
import { InputTypeBaseFieldComponent } from './components/input-type-base-field/input-type-base-field.component';
import { InputTypeNumberComponent } from './components/input-type-number/input-type-number.component';
import { InputTypeDateComponent } from './components/input-type-date/input-type-date.component';
import { InputTypeTimeComponent } from './components/input-type-time/input-type-time.component';
import { InputTypeRadioButtonComponent } from './components/input-type-radio-button/input-type-radio-button.component';
import { InputTypeGpsComponent } from './components/input-type-gps/input-type-gps.component';
import { InputTypeSignatureComponent } from './components/input-type-signature/input-type-signature.component';
import { InputTypePhotoComponent } from './components/input-type-photo/input-type-photo.component';
import { InputTypeDropDownComponent } from './components/input-type-drop-down/input-type-drop-down.component';
import { InputTypeCheckboxesComponent } from './components/input-type-checkboxes/input-type-checkboxes.component';
import { FormsSamplesComponent } from './components/forms-samples/forms-samples.component';
import { FormsStagesComponent } from './components/forms-stages/forms-stages.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TextMaskModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    HFSFormsRoutingModule,
    NgxUploaderModule,
    ModalModule,
    TabsModule
  ],
  declarations: [
    FormsFillComponent,
    FormsIndexComponent,
    FormsViewComponent,
    FormsUpdateComponent,
    FormsAuditLogComponent,
    InputTypeStringComponent,
    InputCommentComponent,
    InputValueSkipButtonComponent,
    InputTypeTextComponent,
    InputTypeBaseFieldComponent,
    InputTypeNumberComponent,
    InputTypeDateComponent,
    InputTypeTimeComponent,
    InputTypeRadioButtonComponent,
    InputTypeGpsComponent,
    InputTypeSignatureComponent,
    InputTypePhotoComponent,
    InputTypeDropDownComponent,
    InputTypeCheckboxesComponent,
    FormsSamplesComponent,
    FormsStagesComponent,
  ],
  entryComponents: [
  ]
})
export class HFSFormsModule {}
