import { BaseModel } from '../classes/query-builder.class';

export class Area extends BaseModel {
  public table = 'areas';
  public schema = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    building_id: 'VARCHAR',
    floor_id: 'VARCHAR',
    description: 'VARCHAR',
    accessible: 'INTEGER',
    deviation_to_scope: 'INTEGER',
    no_access_reason: 'VARCHAR',
    image: 'LONGTEXT',
    code: 'VARCHAR',
    number: 'VARCHAR',
  };
}
