<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Users</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li class="breadcrumb-item">
      Roles
    </li>
  </ol>

  <app-loading *ngIf="gettingAllRoles"></app-loading>

  <div *ngIf="!gettingAllRoles" class="card animated fadeIn">
    <div class="card-header">
      <strong>User</strong> Roles
      <a [routerLink]="['/roles/create']" class="btn btn-success pull-right btn-sm"><i class="icon-plus"></i> Add role</a>
    </div>

    <div *ngIf="roles.length === 0" class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      No user roles found.
    </div>

    <div class="table-responsive">
      <table *ngIf="roles.length > 0" class="table table-striped mb-0">
        <tr>
          <th>Name</th>
          <th class="d-none d-sm-none d-md-block">Description</th>
          <th class="d-none d-sm-none d-md-block">Permissions</th>
          <th class="text-right">Actions</th>
        </tr>

        <tr *ngFor="let role of roles">
          <td>{{ role.display_name }}</td>
          <td class="d-none d-sm-none d-md-block">{{ role.description }}</td>
          <td class="d-none d-sm-none d-md-block">
            <span *ngFor="let permission of role.permissions.data" class="badge badge-primary mx-1">{{ permission.description }}</span>
          </td>
          <td class="text-right d-md-none">
            <button
                (click)="activeRole = role"
                class="btn btn-secondary">
              <i class="fa fa-bars"></i>
            </button>
          </td>
          <td class="text-right d-none d-md-table-cell">
            <ng-container *ngTemplateOutlet="actions; context:{ $implicit: role }"></ng-container>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div *ngIf="activeRole" class="fixed-bottom" style="z-index: 9999;">
    <div class="list-group m-2 fadeIn">
      <ng-container *ngTemplateOutlet="actions; context:{ $implicit: activeRole }"></ng-container>
    </div>
  </div>

  <div *ngIf="activeRole" (click)="activeRole = null" class="modal-backdrop fade show"></div>

  <ng-template #actions let-role>
    <button
        (click)="activeRole = null"
        [disabled]="role.name === 'admin'"
        [routerLink]="['/roles/update', role.id]"
        [className]="activeRole ? 'list-group-item list-group-item-action' : 'btn btn-sm btn-info'">
      <i class="fa fa-edit mr-1"></i>
      Edit
    </button>
    <button
        (click)="activeRole = null; onRoleDelete(role)"
        [disabled]="role.deleting || role.name === 'admin'"
        [className]="activeRole ? 'list-group-item list-group-item-action' : 'btn btn-sm btn-danger'">
      <span *ngIf="!role.deleting"><i class="fa fa-trash mr-1"></i> Delete</span>
      <span *ngIf="role.deleting"><i class="fa fa-spin fa-spinner"></i> Deleting</span>
    </button>
  </ng-template>
</ion-content>
