import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'convertDateTimeDatesFormat'
})
export class ConvertDateTimeDatesFormatPipe implements PipeTransform {

  transform(value: any): any {
    if (!moment(value).isValid()) {
      return '';
    }

    return moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
  }

}
