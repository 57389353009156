import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../../auth/models/user.model';
import { Inventory } from '../../../inventory/models/inventory.model';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '../../services/users.service';
import { UserResponse } from '../../../auth/responses/user.response';
import { SnotifyService } from 'ng-snotify';
import { InventoriesService } from '../../../inventory/services/inventories.service';
import { InventoriesResponse } from '../../../inventory/responses/inventory.response';
import { InventoryListComponent } from '../inventory-list/inventory-list.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { InventoryTypeEnum } from '../../../shared/enums/inventory-type.enum';
import { Subscription } from 'rxjs';
import { BusService } from '../../../shared/services/bus.service';
import { Links, Meta } from '../../../shared/models/pagination.model';

@Component({
  selector: 'app-users-view',
  templateUrl: './users-view.component.html',
  styleUrls: ['./users-view.component.scss']
})
export class UsersViewComponent implements OnInit, OnDestroy {
  public user: User;
  public user_id: string;
  public findingUserById = false;
  public gettingAllUserInventories = false;
  public attachingInventoryUser = false;
  public inventories: Inventory[];
  private paramsSubscription: Subscription;
  public meta: Meta;
  public links: Links;
  private bsModalRef: BsModalRef;
  public inventoryTypeEnum = InventoryTypeEnum;
  public subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    private snotifyService: SnotifyService,
    private inventoriesService: InventoriesService,
    private modalService: BsModalService, private busService: BusService
  ) {}

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe(params => {
      this.user_id = params['user_id'];
      this.findUserById();
      this.getAllUserInventories();
    });
    this.addSubscriptions();
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  addSubscriptions() {
    this.subscriptions.add(
      this.busService.listen(`loading_${this.user_id}`).subscribe(event => {
        this.gettingAllUserInventories = event.data;
      })
    );

    this.subscriptions.add(
      this.busService.listen(`pagination_${this.user_id}`).subscribe(event => {
        this.updateInventoryList(event.data);
      })
    );
  }

  findUserById() {
    this.findingUserById = true;
    const includes = ['roles', 'auth_attempts'];

    this.usersService.findUserById(this.user_id, includes).subscribe(
      (response: UserResponse) => {
        this.findingUserById = false;
        this.user = response.data;
      },
      error => {
        this.findingUserById = false;
        this.snotifyService.error('Unable to load user. Please try again.', 'Error!');
      }
    );
  }

  getAllUserInventories() {
    this.gettingAllUserInventories = true;

    this.inventoriesService.getAllUserInventories(this.user_id).subscribe(
      (response: InventoriesResponse) => {
        this.gettingAllUserInventories = false;
        this.updateInventoryList(response);
      },
      error => {
        this.gettingAllUserInventories = false;
        this.snotifyService.error('Unable to load user inventory items. Please try again.', 'Error!');
      }
    );
  }

  updateInventoryList(response: InventoriesResponse) {
    this.inventories = response.data;
    this.meta = response.meta;
    this.links = response.links;
  }

  onInventoryAssign() {
    this.bsModalRef = this.modalService.show(InventoryListComponent, {'class': 'modal-lg'});
    this.bsModalRef.content.onInventorySelect.subscribe(inventory => this.attachInventoryUser(inventory));
  }

  attachInventoryUser(inventory: Inventory) {
    this.attachingInventoryUser = true;

    this.inventoriesService.attachInventoryUser(inventory.id, this.user_id).subscribe(
      response => {
        this.attachingInventoryUser = false;
        this.snotifyService.success('The inventory item has been successfully attached to current user.', 'Success!');
        this.getAllUserInventories();
      },
      error => {
        this.attachingInventoryUser = false;
        this.snotifyService.error('Unable to attach user to current inventory item.', 'Error!');
      }
    );
  }

  onInventoryDetach(inventory: Inventory) {
    this.snotifyService.confirm(`Inventory item "${inventory.name}" is going to be detached from current user.`, 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.detachInventoryUser(inventory),
          bold: false
        },
        { text: 'No', action: () => {} }
      ]
    });
  }

  detachInventoryUser(inventory: Inventory) {
    inventory.detaching = true;

    this.inventoriesService.detachInventoryUser(inventory.id, this.user_id, inventory.pivot.used_from).subscribe(
      response => {
        inventory.detaching = false;
        this.snotifyService.success('The inventory item has been successfully detached from current user.', 'Success!');
        this.getAllUserInventories();
      },
      error => {
        inventory.detaching = false;
        this.snotifyService.error('Unable to detach inventory item from current user.', 'Error!');
      }
    );
  }

  onInventoryDelete(inventory: Inventory) {
    this.snotifyService.confirm(`Inventory item "${inventory.name}" is going to be removed from current user.`, 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.deleteInventoryUser(inventory),
          bold: false
        },
        { text: 'No', action: () => {} }
      ]
    });
  }

  deleteInventoryUser(inventory: Inventory) {
    inventory.deleting = true;

    this.inventoriesService.deleteInventoryUser(inventory.id, this.user_id, inventory.pivot.used_from).subscribe(
      response => {
        inventory.deleting = false;
        this.snotifyService.success('The inventory item has been successfully deleted from current user.', 'Success!');
        this.getAllUserInventories();
      },
      error => {
        inventory.deleting = false;
        this.snotifyService.error('Unable to delete inventory item from current user.', 'Error!');
      }
    );
  }
}
