import { Injectable } from '@angular/core';
import { Document } from '../models/document.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { DocumentResponse } from '../responses/document.response';

@Injectable()
export class DocumentsService {
  private resource = 'documents';

  constructor(private http: HttpClient) {}

  /**
   * Delete document.
   */
  updateDocument(document: Document): Observable<DocumentResponse> {
    return this.http.put<DocumentResponse>(`${ApiService.ApiUrl}/${this.resource}/${document.id}`, document);
  }

  /**
   * Delete document.
   */
  deleteDocument(document: Document): Observable<any> {
    return this.http.delete<any>(`${ApiService.ApiUrl}/${this.resource}/${document.id}`);
  }

}
