import { APP_INITIALIZER, ErrorHandler, Injectable, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
// Import containers
import { FullLayoutComponent } from './containers';

import { ImageCompressService, ResizeOptions } from 'ng2-image-compress';
// Import components
import {
  APP_SIDEBAR_NAV,
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
} from './components';
// Import directives
import {
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES,
} from './directives';
// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import * as Sentry from '@sentry/browser';
// Import app components
import { ResetPasswordComponent } from './modules/auth/components/reset-password/reset-password.component';
// Import app modules
import { SharedModule } from './modules/shared/shared.module';
import { AuthModule } from './modules/auth/auth.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsersModule } from './modules/users/users.module';
import { RolesModule } from './modules/roles/roles.module';
import { ClientsModule } from './modules/clients/clients.module';
import { BsModalService, ModalModule } from 'ngx-bootstrap';
import { JobsCreateComponent } from './modules/jobs/components/jobs-create/jobs-create.component';
import { JobsUpdateComponent } from './modules/jobs/components/jobs-update/jobs-update.component';
import { JobsService } from './modules/jobs/services/jobs.service';
import { HFSFormsModule } from './modules/forms/hfs-forms.module';
import { InventoryModule } from './modules/inventory/inventory.module';
import { NetworkService } from './modules/shared/services/network.service';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { UploaderModule } from './modules/uploader/uploader.module';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Network } from '@ionic-native/network/ngx';
import { SurveyJobsCreateComponent } from './modules/survey-jobs/components/survey-jobs-create/survey-jobs-create.component';
import { SurveyJobsUpdateComponent } from './modules/survey-jobs/components/survey-jobs-update/survey-jobs-update.component';
import { SurveysCreateComponent } from './modules/surveys/components/surveys-create/surveys-create.component';
import { SurveysUpdateComponent } from './modules/surveys/components/surveys-update/surveys-update.component';
import { BuildingsCreateComponent } from './modules/buildings/components/buildings-create/buildings-create.component';
import { BuildingsUpdateComponent } from './modules/buildings/components/buildings-update/buildings-update.component';
import { FloorsCreateComponent } from './modules/floors/components/floors-create/floors-create.component';
import { FloorsUpdateComponent } from './modules/floors/components/floors-update/floors-update.component';
import { AreasCreateComponent } from './modules/areas/components/areas-create/areas-create.component';
import { AreasUpdateComponent } from './modules/areas/components/areas-update/areas-update.component';
import { AreasCopyComponent } from './modules/areas/components/areas-copy/areas-copy.component';
import { ComponentTemplatesCreateComponent } from './modules/component-templates/components/component-templates-create/component-templates-create.component';

const APP_CONTAINERS = [FullLayoutComponent];

const APP_COMPONENTS = [
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV,
];

const APP_DIRECTIVES = [AsideToggleDirective, NAV_DROPDOWN_DIRECTIVES, ReplaceDirective, SIDEBAR_TOGGLE_DIRECTIVES];

ToastDefaults.toast.position = 'rightTop';

const conditionalProviders = [];

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

/**
 * Sets network connection status.
 */
export function initializeProviderFactory(network: Network, injector: Injector) {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      const networkService = injector.get(NetworkService) as NetworkService;
      networkService.connected = !(network.type === 'none');

      resolve();
    });
  };
}

const APP_MODALS = [
  JobsCreateComponent,
  JobsUpdateComponent,
  SurveyJobsCreateComponent,
  SurveyJobsUpdateComponent,
  SurveysCreateComponent,
  SurveysUpdateComponent,
  BuildingsCreateComponent,
  BuildingsUpdateComponent,
  FloorsCreateComponent,
  FloorsUpdateComponent,
  AreasCreateComponent,
  AreasUpdateComponent,
  AreasCopyComponent,
  ComponentTemplatesCreateComponent,
];

@NgModule({
  declarations: [
    AppComponent,
    ResetPasswordComponent,
    ...APP_CONTAINERS,
    ...APP_COMPONENTS,
    ...APP_DIRECTIVES,
    ...APP_MODALS,
  ],
  entryComponents: [
    ...APP_MODALS,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule.forRoot(),
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    SnotifyModule,
    DashboardModule,
    UploaderModule,
    AuthModule,
    UsersModule,
    RolesModule,
    ClientsModule,
    HFSFormsModule,
    InventoryModule,
    Ng2ImgMaxModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Network,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ...conditionalProviders,
    ImageCompressService,
    ResizeOptions,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeProviderFactory,
      multi: true,
      deps: [Network, Injector],
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: 'SnotifyToastConfig',
      useValue: ToastDefaults,
    },
    SnotifyService,
    BsModalService,
    JobsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
