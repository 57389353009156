import { BaseModel } from '../classes/query-builder.class';

export class FormTemplate extends BaseModel {
  public table: string = 'form_templates';
  public schema: Object = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    name: 'VARCHAR',
    slug: 'VARCHAR'
  };
}
