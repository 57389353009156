import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { DataTypeEnum } from '../../../shared/enums/data-type.enum';
import { FormTemplateQuestion } from '../../models/form-template-question.model';
import { FormsService } from '../../services/forms.service';
import { FormTemplateSection } from '../../models/form-template-section.model';
import { FormAnswersService } from '../../services/form-answers.service';
import { FormResponse } from '../../responses/form.response';
import { FormHelpers } from '../../classes/form-helpers.class';
import { Form } from '../../models/form.model';
import * as moment from 'moment';
import { InventoriesService } from '../../../inventory/services/inventories.service';
import { InventoriesResponse } from '../../../inventory/responses/inventory.response';
import { Inventory } from '../../../inventory/models/inventory.model';
import { FormSection } from '../../models/form-section.model';
import { FormSectionRules } from '../../classes/form-section-rules.class';
import { FormSectionsService } from '../../services/form-sections.service';
import { isNullOrUndefined } from 'util';
import { AuthService } from '../../../auth/services/auth.service';
import { forkJoin, Subscription } from 'rxjs';
import { BusService } from '../../../shared/services/bus.service';
import { FormAnswersResponse } from '../../responses/form-answer.response';
import { Sample } from '../../models/sample.model';
import { FormSectionResponse } from '../../responses/form-section.response';
import { InventoryTypeEnum } from '../../../shared/enums/inventory-type.enum';
import { environment } from '../../../../../environments/environment';
import { SitesService } from '../../../sites/services/sites.service';
import { Site } from '../../../shared/models/site.model';
import { AppHelper } from '../../../shared/classes/app.helper';

@Component({
  selector: 'app-forms-fill',
  templateUrl: './forms-fill.component.html',
  styleUrls: ['./forms-fill.component.scss']
})
export class FormsFillComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  private form_id: string;
  public loadingResources = false;
  public markingFormAsCompleted = false;
  public findingFormTemplateById = false;
  public inventoryChecked = false;
  public inventoryDenied = false;
  public syncingFormInventories = false;
  public creatingForm = false;
  public creatingNextFormSection = false;
  public creatingMultipleFormAnswers = false;
  public form: Form;
  public form_section: FormSection;
  public form_template_section: FormTemplateSection;
  public data_types = DataTypeEnum;
  public inventories: Inventory[];
  public inventoryMessage: string;
  public submittingInventoryMessage = false;
  public fieldValidation = FormHelpers.fieldValidation;
  public scrollToTop = FormHelpers.scrollToTop;
  public sampleNumberIndex: number;
  public isNullOrUndefined = isNullOrUndefined;
  public sectionValidationEnabled = true;
  public isApplicable = true;
  public activeSample: Sample;
  public inventoryTypeEnum = InventoryTypeEnum;
  public parseInt = parseInt;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snotifyService: SnotifyService,
    private formsService: FormsService,
    public authService: AuthService,
    private formAnswersService: FormAnswersService,
    private formSectionsService: FormSectionsService,
    private inventoriesService: InventoriesService,
    private busService: BusService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.route.params.subscribe(params => {
        this.form_id = params['form_id'];
        this.loadResources();
      })
    );

    this.subscriptions.add(
      this.busService.listen('filter_occluded').subscribe(event => {
        this.sectionValidationEnabled = event.data;
      })
    );

    this.subscriptions.add(
      this.busService.listen('applicable').subscribe(event => {
        this.isApplicable = event.data;
        this.sectionValidationEnabled = event.data;
      })
    );

    this.subscriptions.add(
      this.busService.listen('loading').subscribe(event => {
        this.loadingResources = event.data;
      })
    );

    this.subscriptions.add(
      this.busService.listen('pagination').subscribe(event => {
        this.updateInventoriesList(event.data);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loadResources() {
    this.loadingResources = true;
    const findFormByIdRequest = this.formsService.findFormById(this.form_id, [
      'site',
      'assignment',
      'form_sections',
      'form_sections.form_template_section',
      'form_sections.form_template_section.form_template_questions',
      'form_sections.form_template_section.form_template_questions.form_template_question_options',
      'form_sections.form_answers',
      'samples.form_sections',
      'samples.form_sections.form_template_section',
      'samples.form_sections.form_template_section.form_template_questions',
      'samples.form_sections.form_template_section.form_template_questions.form_template_question_options',
      'samples.form_sections.form_answers'
    ]);
    const getAllAuthenticatedUserInventories = this.inventoriesService.getAllAuthenticatedUserInventories();

    forkJoin([findFormByIdRequest, getAllAuthenticatedUserInventories]).subscribe(
      responses => {
        if (responses[0].data.form_template_slug === 'certificate_of_visual_cleanliness') {
          this.inventoryChecked = true;
        }

        this.loadingResources = false;
        this.initFormResponse(responses[0]);

        const userInventoriesResponse: InventoriesResponse = responses[1];
        this.updateInventoriesList(userInventoriesResponse);
      },
      () => {
        this.loadingResources = false;
        this.snotifyService.error('Unable to load resources. Please try again.', 'Error!');
      }
    );
  }

  createMultipleFormAnswers(redirect = false, form_template_section: FormTemplateSection) {
    this.scrollToTop();
    this.form_section.saving = !redirect;
    this.form_section.submitting = redirect;
    this.creatingMultipleFormAnswers = true;
    const answers = this.createMultipleAnswersPayload(form_template_section);

    this.formAnswersService.createMultipleFormAnswers(answers).subscribe(
      (response: FormAnswersResponse) => {
        this.form.form_sections.data = this.form.form_sections.data.map((form_section: FormSection) => {
          if (form_section.form_template_section_id === form_template_section.id) {
            form_section.form_answers.data = response.data;
            form_section.saving = false;
            form_section.submitting = false;
          }

          return form_section;
        });

        this.creatingMultipleFormAnswers = false;

        AppHelper.chime();
        if (redirect) {
          return this.goToJobsView();
        }
      },
      () => {
        this.creatingMultipleFormAnswers = false;
        this.form_section.saving = false;
        this.form_section.submitting = false;
        this.snotifyService.error('Unable to store form answers. Please try again.', 'Error!');
      }
    );
  }

  createForm() {
    this.scrollToTop();
    this.creatingForm = true;
    const form = {
      form_template_id: this.form.form_template_id,
      job_id: this.form.assignment_id,
      form_template_name: this.form.form_template_name,
      form_template_slug: this.form.form_template_slug,
      user_id: this.form.user_id
    };

    this.formsService.createForm(form).subscribe(
      (response: FormResponse) => {
        const form = response.data;
        this.creatingForm = false;
        AppHelper.chime();
        return this.router.navigate(['/forms/fill', form.id]);
      },
      () => {
        this.creatingForm = false;
        this.snotifyService.error('Unable to create form. Please try again.', 'Error!');
      }
    );
  }

  markFormAsCompleted() {
    this.markingFormAsCompleted = true;
    const payload = {
      id: this.form_id,
      completed_at: moment().format('YYYY/MM/DD')
    };

    this.formsService.completeForm(payload).subscribe(
      () => {
        this.markingFormAsCompleted = false;
        this.snotifyService.success('The form has been successfully completed.', 'Success!');
      },
      () => {
        this.markingFormAsCompleted = false;
        this.snotifyService.error('Unable to complete form. Please try again.', 'Error!');
      }
    );
  }

  createNextFormSection(nextSectionJsRule: string, hop = null, sample: Sample = null) {
    this.creatingNextFormSection = true;
    const payload = {
      form_id: this.form.id,
      position: this.form_section.position + 1,
      js_rule: nextSectionJsRule,
      sample_id: sample ? sample.id : null
    };
    const includes = [
      'form_template_section',
      'form_template_section.form_template_questions',
      'form_template_section.form_template_questions.form_template_question_options',
      'form_answers'
    ];

    this.formSectionsService.createNextFormSection(payload, includes).subscribe(
      (response: FormSectionResponse) => {
        this.creatingNextFormSection = false;
        const formSection = response.data;

        if (formSection.sample_id) {
          this.form.samples.data = this.form.samples.data.map((sample: Sample) => {
            if (sample.id.toString() === formSection.sample_id.toString()) {
              sample.form_sections.data.push(formSection);
            }

            return sample;
          });
        } else {
          this.form.form_sections.data.push(formSection);
        }

        this.initFormResponse({ data: this.form });

        if (hop) {
          return this.goToNextSection();
        }
      },
      () => {
        this.creatingNextFormSection = false;
        this.snotifyService.error(`Unable to create next section.`, 'Error!');
      }
    );
  }

  initFormResponse(response: FormResponse) {
    this.form = response.data;
    let sample;

    if (this.activeSample) {
      sample = this.form.samples.data.find(item => item.id === this.activeSample.id);
    }

    if (this.form.form_template_slug === 'certificate_of_reoccupation') {
      this.form.form_sections.data = this.form.form_sections.data.filter(formSection => {
        const titles = [
          'B.5 - Stage 3 - Clearance Air Testing - Desk top Review (Office Only)',
          'Desktop Review (Office Only)',
        ];

        return !titles.includes(formSection.form_template_section.data.title);
      });
    }

    // Set the last form section as the active section
    this.form_section = sample
      ? sample.form_sections.data[sample.form_sections.data.length - 1]
      : this.form.form_sections.data[this.form.form_sections.data.length - 1];
    this.initFormSection();
  }

  onSectionSave() {
    const redirect = true;
    this.createMultipleFormAnswers(redirect, JSON.parse(JSON.stringify(this.form_template_section)));
  }

  initFormSection() {
    this.isApplicable = true;
    this.sectionValidationEnabled = true;
    this.form_template_section = this.form_section.form_template_section.data;
    this.form_section.form_template_section.data.form_template_questions.data = FormHelpers.passFormAnswersToQuestions(this.form_section);
    this.sampleNumberIndex = FormHelpers.findSectionQuestionIndexByJsId(this.form_template_section, 'sample_number');
  }

  onSetActiveSection(index: number = null) {
    if (this.creatingMultipleFormAnswers) {
      return;
    }

    this.activeSample = null;
    this.setActiveSection(index);
  }

  setActiveSection(index: number = null) {
    index = !isNullOrUndefined(index) ? index : this.form.form_sections.data.length - 1;
    this.form_section = this.form.form_sections.data[index];
    this.form_section.saving = false;
    this.form_section.submitting = false;
    this.initFormSection();
  }

  setSampleActiveSection(index: number = null) {
    index = !isNullOrUndefined(index) ? index : this.activeSample.form_sections.data.length - 1;
    this.form_section = this.activeSample.form_sections.data[index];
    this.form_section.saving = false;
    this.form_section.submitting = false;
    this.initFormSection();
  }

  onNextSection() {
    this.form_template_section.validation = this.validateSection();

    if (!this.form_template_section.validation) {
      return;
    }

    const form_template_section = JSON.parse(JSON.stringify(this.form_template_section));
    this.createMultipleFormAnswers(false, form_template_section);

    const rule: { nextSectionJsRule: string; role: string; action: string; task: string } = FormSectionRules.nextSection(
      this.form,
      this.form_template_section
    );

    if (rule.action && rule.action === 'load') {
      const index = this.form.form_sections.data.findIndex(
        (form_section: FormSection) => form_section.form_template_section.data.js_rule === rule.nextSectionJsRule
      );

      if (index >= 0) {
        return this.setActiveSection(index);
      }
    }

    if (rule.action && rule.action === 'createOrLoad') {
      const index = this.form.form_sections.data.findIndex(
        (form_section: FormSection) => form_section.form_template_section.data.js_rule === rule.nextSectionJsRule
      );

      if (index >= 0) {
        return this.setActiveSection(index);
      } else {
        const hop = null;
        return this.createNextFormSection(rule.nextSectionJsRule, hop);
      }
    }

    if (this.activeSample) {
      const index = this.activeSample.form_sections.data.findIndex(
        (form_section: FormSection) => form_section.form_template_section.data.js_rule === rule.nextSectionJsRule
      );

      if (index >= 0) {
        return this.setSampleActiveSection(index);
      }
    }

    if (rule.task && rule.task === 'clear_active_sample') {
      this.activeSample = null;
    }

    if (rule.nextSectionJsRule === 'failed') {
      return this.failedForm();
    }

    if (this.activeSample) {
      return this.createNextFormSection(rule.nextSectionJsRule, null, this.activeSample);
    }

    const last_form_section = this.form.form_sections.data[this.form.form_sections.data.length - 1];
    const nextSectionExists = this.form.form_sections.data.find(formSection => formSection.form_template_section.data.js_rule === rule.nextSectionJsRule);

    if (!nextSectionExists) {
      return this.goToNextSection();
    }

    if (last_form_section.id === this.form_section.id) {
      return this.goToNextSection();
    }

    this.form_section.saving = false;
    this.form_section.submitting = false;

    const index = this.form.form_sections.data.length - 1;
    this.form_section = this.form.form_sections.data[index];
    this.initFormSection();
  }

  goToNextSection() {
    this.isApplicable = true;
    this.sectionValidationEnabled = true;

    const rule: { nextSectionJsRule: string; role: string; action: string } = FormSectionRules.nextSection(
      this.form,
      this.form_template_section
    );

    if (rule.action && rule.action === 'sample_completed') {
      this.activeSample = null;
    }

    if (!isNullOrUndefined(rule.role) && rule.role === 'reviewer') {
      const hop = true;
      return this.createNextFormSection(rule.nextSectionJsRule, hop);
    }

    if (rule.nextSectionJsRule === 'completed') {
      return this.completedForm();
    }

    if (rule.nextSectionJsRule === 'failed') {
      return this.failedForm();
    }

    this.createNextFormSection(rule.nextSectionJsRule);
  }

  jumpToSection(data: { js_rule: string; sample?: Sample }) {
    this.activeSample = data.sample ? data.sample : null;

    this.isApplicable = true;
    this.sectionValidationEnabled = true;

    const rule: { nextSectionJsRule: string; role: string; action: string } = FormSectionRules.goToSection(
      this.form,
      this.form_template_section,
      data.js_rule
    );

    if (rule.nextSectionJsRule === 'completed') {
      return this.completedForm();
    }

    if (data.sample) {
      const index = data.sample.form_sections.data
        ? data.sample.form_sections.data.findIndex(
            (form_section: FormSection) => form_section.form_template_section.data.js_rule === rule.nextSectionJsRule
          )
        : null;

      if (index >= 0) {
        return this.setSampleActiveSection(index);
      }
    }

    if (rule.action && rule.action === 'load') {
      const index = this.form.form_sections.data.findIndex(
        (form_section: FormSection) => form_section.form_template_section.data.js_rule === rule.nextSectionJsRule
      );

      if (index >= 0) {
        return this.setActiveSection(index);
      }
    }

    if (rule.action && rule.action === 'createOrLoad') {
      const index = this.form.form_sections.data.findIndex(
        (form_section: FormSection) => form_section.form_template_section.data.js_rule === rule.nextSectionJsRule
      );

      if (index >= 0) {
        return this.setActiveSection(index);
      } else {
        const hop = null;
        return this.createNextFormSection(rule.nextSectionJsRule, hop);
      }
    }

    if (!isNullOrUndefined(rule.role) && rule.role === 'reviewer') {
      const hop = true;
      return this.createNextFormSection(rule.nextSectionJsRule, hop);
    }

    if (rule.action && rule.action === 'sample_completed') {
      this.activeSample = null;
    }

    const hop = null;
    this.createNextFormSection(rule.nextSectionJsRule, hop, data.sample);
  }

  validateSection() {
    let validation = true;

    if (this.sectionValidationEnabled === false) {
      return validation;
    }

    this.form_template_section.form_template_questions.data.map(question => {
      this.fieldValidation(question);

      if (question.validation === false) {
        validation = false;
        FormHelpers.scrollToQuestion(question);
      }

      return question;
    });

    if (!validation) {
      this.snotifyService.warning('One or more fields failed validation. Please fill the red marked fields.', 'Warning!');
    }

    return validation;
  }

  goToJobsView() {
    const navigate = environment.cordova
      ? ['/uploader/air-testing']
      : ['/jobs/view', this.form.assignment_id];

    return this.router.navigate(navigate);
  }

  createMultipleAnswersPayload(form_template_section: FormTemplateSection) {
    const answers = [];

    form_template_section.form_template_questions.data.forEach((question: FormTemplateQuestion) => {
      let questionAnswerValue;

      if (Array.isArray(question.answer.value)) {
        questionAnswerValue = question.answer.value.join('|');
      } else {
        questionAnswerValue = !(question.answer.value === null || question.answer.value === undefined)
          ? question.answer.value.toString()
          : null;
      }

      const answer = {
        id: question.answer.id,
        form_id: this.form_id.toString(),
        form_section_id: this.form_section.id.toString(),
        form_template_question_id: question.id,
        value: questionAnswerValue,
        comment: question.answer.comment ? question.answer.comment.toString() : null,
        input_type: question.input_type.toString()
      };

      if (environment.cordova) {
        answer.form_template_question_id = question.hashed_id;
      }

      answers.push(answer);
    });

    return answers;
  }

  failedForm() {
    this.snotifyService.warning(
      `Section "${this.form_template_section.title}" has been marked as failed.`,
      'Warning!'
    );
    this.markFormAsCompleted();
    return this.router.navigate(['/jobs/view', this.form.assignment_id]);
  }

  completedForm() {
    this.markFormAsCompleted();
    return this.goToJobsView();
  }

  onInventoryMessageSubmit() {
    this.submittingInventoryMessage = true;

    this.formsService.formInventoriesAdminMessage(this.form_id, this.inventoryMessage).subscribe(
      () => {
        this.submittingInventoryMessage = false;
        this.snotifyService.success('Your message has been successfully sent to the system administrator.', 'Success!');
        this.inventoryChecked = true;
      },
      () => {
        this.submittingInventoryMessage = false;
        this.snotifyService.error('Unable to send message to system administrator. Please try again.', 'Error!');
      }
    );
  }

  updateInventoriesList(response: InventoriesResponse) {
    this.inventories = response.data;
  }

  onInventoryDeny() {
    this.inventoryDenied = true;
  }

  onInventoryConfirm() {
    this.inventoryChecked = true;
  }

  setSelectedSite(site: Site) {
    this.form.site_id = site.id;
    this.updateForm();
  }

  updateForm() {
    this.formsService.updateForm(this.form).subscribe(
      () => {},
      () => {
        this.snotifyService.error('Unable to update form data. Please try again.', 'Error!');
      },
    );
  }
}
