import { ZAMPA_VERSION } from '../app/config/version';

const server_url = 'https://api-staging.hfs.hcx.co';
const testing_server_url = 'https://api-staging.hfs.hcx.co';
const api_version = 'v1';

export const environment = {
  version: ZAMPA_VERSION,
  production: true,
  cordova: false,
  portal_url: `https://portal.staging.zampa.io/`,
  base_server_url: `${server_url}`,
  base_testing_server_url: `${testing_server_url}`,
  api_url: `${server_url}/${api_version}`,
  api_testing_url: `${testing_server_url}/${api_version}`,
};
