import { Form } from '../models/form.model';
import { FormTemplateSection } from '../models/form-template-section.model';
import { FormHelpers } from './form-helpers.class';

/**
 * Air Test - Personal Helper Class.
 */
export class AirTestPersonalSectionRules {
  /**
   * Air Test - Personal - C.1 - Inspection Details
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_personal_c1(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_c3' };
  }

  /**
   * Air Test - Personal - C.2B - Samples Table
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_personal_c2b(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_c3' };
  }

  /**
   * Air Test - Personal - C.3 - Temp & Pressure
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_personal_c3(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_c5' };
  }

  /**
   * Air Test - Personal - C.4 - Sample Details
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_personal_c4(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_c6' };
  }

  /**
   * Air Test - Personal - C.5 - Microscope Set Up
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_personal_c5(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_c2b', action: 'load' };
  }

  /**
   * Air Test - Personal - C.6 - Sample Analysis Prep
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_personal_c6(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_c7' };
  }

  /**
   * Air Test - Personal - C.7 - Analysis of samples
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_personal_c7(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_c2b', action: 'load', task: 'clear_active_sample' };
  }

  /**
   * Air Test - Personal - C.8 - Interpretation of results
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_personal_c8(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_summary' };
  }

  /**
   * Air Test - Personal - Field Blank Count
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string, role: string}}
   */
  public static air_test_personal_field_blank_count(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_c8' };
  }

  /**
   * Air Test - Personal - Summary
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string, role: string}}
   */
  public static air_test_personal_summary(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_desktop_review', role: 'reviewer' };
  }

  /**
   * Air Test - Personal - Section List
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string, role: string}}
   */
  public static air_test_personal_section_list(form: Form, section: FormTemplateSection) {
    const next_section = FormHelpers.findSectionQuestionByJsId(section, 'next_section');

    switch (+next_section.answer.value) {
      case 1:
        return { nextSectionJsRule: 'air_test_personal_c1' };
      case 2:
        return { nextSectionJsRule: 'air_test_personal_c2' };
      case 3:
        return { nextSectionJsRule: 'air_test_personal_c3' };
      case 4:
        return { nextSectionJsRule: 'air_test_personal_c4' };
      case 5:
        return { nextSectionJsRule: 'air_test_personal_c5' };
      case 6:
        return { nextSectionJsRule: 'air_test_personal_c6' };
      case 7:
        return { nextSectionJsRule: 'air_test_personal_c7' };
      case 8:
        return { nextSectionJsRule: 'air_test_personal_c8' };
      case 9:
        return { nextSectionJsRule: 'air_test_personal_summary' };
      default:
        return { nextSectionJsRule: 'air_test_personal_c1' };
    }
  }

  /**
   * Air Test - Personal - Desktop Review (Office Only)
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_personal_desktop_review(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'completed' };
  }

  /**
   * Air Test - Personal - Microscope Set Up Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_personal_microscope_set_up_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_c5', action: 'load' };
  }

  /**
   * Air Test - Personal - Samples Done Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_personal_samples_done_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_field_blank_count', action: 'load' };
  }

  /**
   * Air Test - Personal - Start Sample Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_personal_sample_start_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_c4', action: 'load' };
  }

  /**
   * Air Test - Personal - Collect Sample Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_personal_sample_collect_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_c4', action: 'load' };
  }

  /**
   * Air Test - Personal - Prepare Sample Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_personal_sample_prepare_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_c6', action: 'load' };
  }

  /**
   * Air Test - Personal - Analyse Sample Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_personal_sample_analyse_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_c7', action: 'load' };
  }

  /**
   * Air Test - Personal - Samples Table Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_personal_samples_table_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_personal_c2b', action: 'load' };
  }
}
