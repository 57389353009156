import { BaseModel } from '../classes/query-builder.class';

export class ComponentTemplate extends BaseModel {
  public table = 'component_templates';
  public schema = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    name: 'VARCHAR',
  };
}
