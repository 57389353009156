import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-reviewer',
  templateUrl: './home-reviewer.component.html',
  styleUrls: ['./home-reviewer.component.scss']
})
export class HomeReviewerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
