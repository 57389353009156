<div class="modal-header">
  <h4 class="modal-title pull-left">
    Survey Settings
  </h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div [formGroup]="formGroup" class="modal-body">
  <div class="form-group">
    <label for="custom_number" class="d-block">
      Use custom report number
    </label>

    <label class="switch switch-text switch-info-outline-alt mb-0">
      <input class="switch-input" type="checkbox" id="custom_number" name="custom_number" formControlName="custom_number">
      <span class="switch-label"></span>
      <span class="switch-handle"></span>
    </label>
  </div>

  <div
      *ngIf="this.formGroup.get('custom_number').value"
      class="form-group">
    <label for="number">
      Number
    </label>
    <input
        formControlName="number"
        [ngClass]="{'is-invalid': !formGroup.controls['number'].valid && formGroup.controls['number'].touched}"
        class="form-control"
        id="number"
        name="number"
        type="text">
    <div class="help-block text-danger"
         *ngIf="formGroup.controls['number'].touched && formGroup.controls['number'].hasError('required')">
      * This field is required.
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="submit" class="btn btn-primary" [disabled]="!formGroup.valid || updating" (click)="update()">
    <span *ngIf="!updating">
      Update
    </span>
    <span *ngIf="updating">
      <i class="fa fa-spin fa-spinner mr-1"></i>
      Updating
    </span>
  </button>
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
</div>
