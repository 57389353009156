<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Audit Log</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li class="breadcrumb-item">
      <a [routerLink]="['/forms/index']" routerDirection="backward">Forms</a>
    </li>

    <li class="breadcrumb-item active">
      <span>Logs</span>
    </li>
  </ol>

  <app-loading *ngIf="gettingAllFormAudits"></app-loading>

  <div *ngIf="!gettingAllFormAudits" class="card animated fadeIn">
    <div class="card-header">
      <strong>Audit</strong> Log
    </div>

    <div *ngIf="audits.length === 0" class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      No audit log entries found.
    </div>

    <div class="table-responsive">
      <table *ngIf="audits.length > 0" class="table table-striped mb-0">
        <tr>
          <th>User</th>
          <th>Event</th>
          <th>Old values</th>
          <th>New values</th>
          <th>Logged at</th>
        </tr>
        <tr *ngFor="let audit of audits">
          <td>{{ audit.user_name }}</td>
          <td>{{ audit.event | firstLetterUppercase }}</td>
          <td><app-json-values [values]="audit.old_values"></app-json-values></td>
          <td><app-json-values [values]="audit.new_values"></app-json-values></td>
          <td>{{ audit.created_at }}</td>
        </tr>
      </table>
    </div>

    <div *ngIf="meta && !gettingAllFormAudits && meta.last_page > 1" class="card-footer">
      <app-pagination
          [meta]="meta"
          [links]="links"
          (updated)="updateAuditsList($event)"></app-pagination>
    </div>
  </div>
</ion-content>
