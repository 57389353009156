<div class="animated fadeIn card card-accent-primary">
  <div class="card-header">
    <strong>Samples</strong>

    <div class="float-right">
      <button
          (click)="onAddSample()"
          [disabled]="creatingSample"
          class="btn btn-sm btn-primary">
        <i *ngIf="creatingSample" class="fa fa-spin fa-spinner mr-1"></i>
        <span *ngIf="!creatingSample">Add Sample</span>
        <span *ngIf="creatingSample">Adding Sample</span>
      </button>
    </div>
  </div>

  <div *ngIf="form.samples.data.length === 0" class="alert alert-info mb-0">
    <i class="fa fa-info mr-1"></i>
    No Samples found.
  </div>

  <div class="table-responsive">
    <table *ngIf="form.samples.data.length > 0" class="table table-striped mb-0">
      <thead>
        <tr>
          <th>#</th>
          <th>Stage</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let sample of form.samples.data; let i = index;">
          <td>{{ i + 1 }}</td>
          <td>
            <span *ngIf="!sample.form_sections.data || sample.form_sections.data.length === 0" class="badge badge-secondary">Initial</span>
            <span *ngIf="sample.form_sections.data && sample.form_sections.data.length === 1" class="badge badge-info">Running</span>
            <span *ngIf="sample.form_sections.data && sample.form_sections.data.length === 2" class="badge badge-info">Collected</span>
            <span *ngIf="sample.form_sections.data && sample.form_sections.data.length === 3" class="badge badge-success">Analysed</span>
          </td>
          <td>
            <button
                (click)="onSampleDelete(sample)"
                class="btn btn-sm btn-danger">
              <i *ngIf="sample.deleting" class="fa fa-spin fa-spinner mr-1"></i>
              <span *ngIf="!sample.deleting">Delete</span>
              <span *ngIf="sample.deleting">Deleting</span>
            </button>

            <button
                *ngIf="!sample.form_sections.data || sample.form_sections.data.length === 0"
                (click)="hop('sample_start_button', sample)"
                class="btn btn-sm btn-primary">
              Start
            </button>

            <button
                *ngIf="sample.form_sections.data && sample.form_sections.data.length >= 1"
                (click)="hop('sample_collect_button', sample)"
                class="btn btn-sm btn-primary">
              Collect
            </button>

            <button
                *ngIf="sample.form_sections.data && sample.form_sections.data.length >= 2"
                (click)="hop('sample_prepare_button', sample)"
                class="btn btn-sm btn-primary">
              Prepare
            </button>

            <button
                *ngIf="sample.form_sections.data && sample.form_sections.data.length === 3"
                (click)="hop('sample_analyse_button', sample)"
                class="btn btn-sm btn-primary">
              Analyse
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="card-footer d-flex justify-content-between">
    <button
        (click)="hop('microscope_set_up_button')"
        class="btn btn-sm btn-success">
      Microscope setup
    </button>

    <button
        [disabled]="!allSamplesDone() || form.samples.data.length === 0"
        (click)="hop('samples_done_button')"
        class="btn btn-sm btn-primary">
      Done
    </button>
  </div>
</div>
