<div class="modal-header">
  <h4 class="modal-title pull-left">
    Add document
  </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="documentFormGroup">
    <div class="form-group">
      <label for="name">
        Document name
      </label>
      <input
          [formControl]="documentFormGroup.controls['name']"
          type="text"
          class="form-control"
          id="name"
          name="name">
    </div>

    <div class="form-group">
      <label for="type">
        Type
      </label>
      <select
          [formControl]="documentFormGroup.controls['type']"
          name="type"
          id="type"
          class="form-control">
        <option value=""></option>
        <option *ngIf="this.documentFormGroup.get('documentable_type').value !== 'surveys'" [value]="document_types['Invoice']">Invoice</option>
        <option [value]="document_types['Report']">Report</option>
        <option [value]="document_types['Document']">Document</option>
      </select>
    </div>

    <div class="form-group">
      <label for="public" class="d-block">
        Public
      </label>

      <label class="switch switch-text switch-info-outline-alt mb-0">
        <input class="switch-input" type="checkbox" id="public" name="public" formControlName="public">
        <span class="switch-label"></span>
        <span class="switch-handle"></span>
      </label>
    </div>


    <div class="form-group">
      <label for="file">
        Document
      </label>
      <!--Used a hidden field for form validation reasons-->
      <input
          [formControl]="documentFormGroup.controls['file']"
          type="hidden">
      <input
          (change)="onFileChange($event)"
          id="file"
          name="file"
          class="form-control"
          type="file"
          ngFileSelect
          [options]="options"
          (uploadOutput)="onUploadOutput($event)"
          [uploadInput]="uploadInput"
          accept="image/jpeg, image/jpg, image/png, application/pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt">
      <div class="help-dock text-muted">
        Allowed file types: <code>jpeg</code>, <code>jpg</code>, <code>png</code>, <code>pdf</code>, <code>doc</code>, <code>docx</code>, <code>xls</code>, <code>xlsx</code>, <code>ppt</code>, <code>pptx</code>, <code>txt</code>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
  <button
      [disabled]="!documentFormGroup.valid"
      type="button"
      class="btn btn-primary"
      (click)="startUpload()">
    <span *ngIf="creatingDocument"><i class="fa fa-spin fa-spinner mr-1"></i> Uploading</span>
    <span *ngIf="!creatingDocument">Upload</span>
  </button>
</div>
