import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UploaderComponent } from './components/uploader/uploader.component';
import { SurveyingUploaderComponent } from './components/surveying-uploader/surveying-uploader.component';

const routes: Routes = [
  {
    path: 'air-testing',
    component: UploaderComponent
  },
  {
    path: 'surveying',
    component: SurveyingUploaderComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UploaderRoutingModule {}
