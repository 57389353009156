import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './users.component';
import { UsersIndexComponent } from './components/users-index/users-index.component';
import { UsersCreateComponent } from './components/users-create/users-create.component';
import { UsersUpdateComponent } from './components/users-update/users-update.component';
import { UsersRoutingModule } from './users-routing.module';
import { UsersService } from './services/users.service';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsersViewComponent } from './components/users-view/users-view.component';
import { InventoryListComponent } from './components/inventory-list/inventory-list.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    UsersRoutingModule
  ],
  declarations: [
    UsersComponent,
    UsersIndexComponent,
    UsersCreateComponent,
    UsersUpdateComponent,
    UsersViewComponent,
    InventoryListComponent
  ],
  providers: [
    UsersService
  ],
  entryComponents: [
    InventoryListComponent
  ]
})
export class UsersModule { }
