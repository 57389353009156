import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InputTypeBaseFieldComponent } from '../input-type-base-field/input-type-base-field.component';
import { FormHelpers } from '../../classes/form-helpers.class';
import { Form } from '../../models/form.model';
import { SampleCalculator } from '../../classes/sample-calculator.class';
import { Sample } from '../../models/sample.model';
import { BusService } from '../../../shared/services/bus.service';
import { FormConfig } from '../../classes/form-config.class';
import { Subscription } from 'rxjs';
import { FormCalculator } from '../../classes/form-calculator.class';
import { FormAnswer } from '../../models/form-answer.model';

@Component({
  selector: 'app-input-type-number',
  templateUrl: './input-type-number.component.html',
  styleUrls: ['./input-type-number.component.scss']
})
export class InputTypeNumberComponent extends InputTypeBaseFieldComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  form: Form;
  @Input()
  sample: Sample;
  public disabled = false;
  public subscriptions: Subscription = new Subscription();
  public message: string;

  constructor(private busService: BusService) {
    super();
  }

  ngOnInit() {
    this.id = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'id');
    this.name = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'name');

    if (this.question.js_id === 'samples_and_results') {
      this.disabled = true;
      this.question.answer.value = this.form.samples.data.length;
    }

    if (this.question.js_id === 'fields_counted') {
      this.question.answer.value = 200;
    }

    const sectionsNumbers = FormConfig.certificates[this.form.form_template_slug]['sections'];
    const samplesNumbers = FormConfig.certificates[this.form.form_template_slug]['samples'];

    if (this.question.number === sectionsNumbers['field_blank_count']) {
      let requireCounting = false;

      this.form.samples.data.forEach((sample: Sample) => {
        const answer: FormAnswer = FormHelpers.findSampleFormAnswerByQuestionNumber(sample, samplesNumbers['field_blank_require_counting']);

        if (answer && answer.value === 'Yes') {
          requireCounting = true;
        }
      });

      if (requireCounting) {
        this.disabled = false;
        this.question.validation_rules_f = null;
        this.message = null;
      } else {
        this.disabled = true;
        this.question.validation_rules_f = 'optional';
        this.message = 'Field Count Not Required';
      }
    }
  }

  ngAfterViewInit() {
    if (FormConfig.calculatedFlowRate.includes(this.question.number)) {
      this.subscriptions.add(this.busService.listen(this.question.number).subscribe(event => this.calculateActualFlowRate()));

      setTimeout(() => {
        this.calculateActualFlowRate();
      }, 2);
    }

    if (FormConfig.calculatedResult.includes(this.question.number)) {
      this.subscriptions.add(this.busService.listen(this.question.number).subscribe(event => this.calculateResult()));

      setTimeout(() => {
        this.calculateResult();
      }, 2);
    }

    if (FormConfig.calculatedLitreage.includes(this.question.number)) {
      this.subscriptions.add(this.busService.listen(this.question.number).subscribe(event => this.calculateLitreage()));

      setTimeout(() => {
        this.calculateLitreage();
      }, 2);
    }

    if (FormConfig.calculatedTimeWeightedAVG.includes(this.question.number)) {
      this.subscriptions.add(this.busService.listen(this.question.number).subscribe(event => this.calculateTimeWeightedAVG()));

      setTimeout(() => {
        this.calculateTimeWeightedAVG();
      }, 2);
    }

    if (FormConfig.calculatedPooledAVG.includes(this.question.number)) {
      this.subscriptions.add(this.busService.listen(this.question.number).subscribe(event => this.calculatePooledAVG()));

      setTimeout(() => {
        this.calculatePooledAVG();
      }, 2);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  calculateActualFlowRate() {
    this.disabled = true;
    const sampleCalculator = new SampleCalculator(this.form, this.sample);
    this.question.answer.value = sampleCalculator.actualFlowRate();
  }

  calculateResult() {
    this.disabled = true;
    const sampleCalculator = new SampleCalculator(this.form, this.sample);
    this.question.answer.value = sampleCalculator.sampleCalculatedResult().toFixed(3);
  }

  calculateLitreage() {
    this.disabled = true;
    const sampleCalculator = new SampleCalculator(this.form, this.sample);
    this.question.answer.value = sampleCalculator.litreage();
  }

  calculateTimeWeightedAVG() {
    this.disabled = true;
    const formCalculator = new FormCalculator(this.form);
    this.question.answer.value = formCalculator.timeWeightedAVG();
  }

  calculatePooledAVG() {
    this.disabled = true;
    const formCalculator = new FormCalculator(this.form);
    this.question.answer.value = formCalculator.poolAVG();
  }

  valueChange() {
    this.fieldValidation(this.question);

    if (FormConfig.calculatable.includes(this.question.number)) {
      const relations: string[] = FormConfig.calculatableRelations[this.question.number];
      relations.forEach(relationNumber => this.busService.dispatch({ type: relationNumber }));
    }
  }
}
