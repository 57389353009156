<label *ngIf="question.js_id !== 'sample_number'">
  {{ question.text }}
</label>

<div class="input-group">
  <ion-input
      (change)="valueChange()"
      [ngClass]="{'is-invalid': question.validation === false && !question.skipped}"
      [(ngModel)]="question.answer.value"
      [id]="id"
      [name]="name"
      [disabled]="disabled"
      class="form-control"
      type="number">
  </ion-input>
  <span class="input-group-append">
    <span class="input-group-text">123</span>
    <app-input-value-skip-button [question]="question"></app-input-value-skip-button>
  </span>
  <div *ngIf="question.validation === false && !question.skipped" class="invalid-feedback">
    This field is required. Please either fill a value or press <i>Skip</i> button and enter a comment.
  </div>
</div>

<div *ngIf="disabled && message" class="alert alert-info mt-2 rounded">
  <i class="fa fa-info mr-1"></i>
  {{ message }}
</div>

<app-input-comment [question]="question"></app-input-comment>
