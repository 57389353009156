<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Update Inventory</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li class="breadcrumb-item ">
      <a [routerLink]="['/inventory/index']" routerDirection="backward">Inventory</a>
    </li>

    <li class="breadcrumb-item active">
      <span>Edit</span>
    </li>
  </ol>
  <app-loading *ngIf="findingInventoryById"></app-loading>

  <div *ngIf="!findingInventoryById" class="card animated fadeIn">
    <div class="card-header">
      <strong>Update</strong> Inventory
    </div>
    <form action="" method="post">
      <div [formGroup]="inventoryFormGroup" class="card-body">
        <div class="form-group">
          <label for="name">
            Name
          </label>
          <input
              [formControl]="inventoryFormGroup.controls['name']"
              [ngClass]="{'is-invalid': !inventoryFormGroup.controls['name'].valid && inventoryFormGroup.controls['name'].touched}"
              class="form-control"
              id="name"
              name="name"
              type="text">
          <div class="help-block text-danger"
               *ngIf="inventoryFormGroup.controls['name'].touched && inventoryFormGroup.controls['name'].hasError('required')">
            * This field is required.
          </div>
        </div>

        <div class="form-group">
          <label for="type">
            Type
          </label>
          <select
              [formControl]="inventoryFormGroup.controls['type']"
              [ngClass]="{'is-invalid': !inventoryFormGroup.controls['type'].valid && inventoryFormGroup.controls['type'].touched}"
              class="form-control"
              id="type"
              name="type">
            <option [value]="inventoryTypeEnum['Pump']">Pump</option>
            <option [value]="inventoryTypeEnum['Cowl']">Cowl</option>
            <option [value]="inventoryTypeEnum['Microscope']">Microscope</option>
            <option [value]="inventoryTypeEnum['Laboratory']">Laboratory</option>
            <option [value]="inventoryTypeEnum['NPL_Test_Slide']">NPL Test Slide</option>
            <option [value]="inventoryTypeEnum['Stage_Micrometer']">Stage Micrometer</option>
            <option [value]="inventoryTypeEnum['Barometer_Thermometer']">Barometer / Thermometer</option>
            <option [value]="inventoryTypeEnum['High_Flow_Flowmeter']">High Flow Flowmeter</option>
            <option [value]="inventoryTypeEnum['Low_Flow_Flowmeter']">Low Flow Flowmeter</option>
            <option [value]="inventoryTypeEnum['Timepiece']">Timepiece</option>
          </select>
        </div>

        <div class="form-group">
          <label for="model">
            Model
          </label>
          <input
              [formControl]="inventoryFormGroup.controls['model']"
              [ngClass]="{'is-invalid': !inventoryFormGroup.controls['model'].valid && inventoryFormGroup.controls['model'].touched}"
              class="form-control"
              id="model"
              name="model"
              type="text">
        </div>

        <div class="form-group">
          <label for="serial_number">
            Serial number
          </label>
          <input
              [formControl]="inventoryFormGroup.controls['serial_number']"
              [ngClass]="{'is-invalid': !inventoryFormGroup.controls['serial_number'].valid && inventoryFormGroup.controls['serial_number'].touched}"
              class="form-control"
              id="serial_number"
              name="serial_number"
              type="text">
        </div>

        <div class="form-group">
          <label for="inventory_code">
            Inventory code
          </label>
          <input
              [formControl]="inventoryFormGroup.controls['inventory_code']"
              [ngClass]="{'is-invalid': !inventoryFormGroup.controls['inventory_code'].valid && inventoryFormGroup.controls['inventory_code'].touched}"
              class="form-control"
              id="inventory_code"
              name="inventory_code"
              type="text">
        </div>
      </div>

      <div class="card-footer">
        <button
            (click)="onSubmit()"
            [disabled]="!inventoryFormGroup.valid"
            class="btn btn-sm btn-primary"
            type="submit">
        <span *ngIf="!updatingInventory">
          <i class="fa fa-save mr-1"></i>
          Update
        </span>
          <span *ngIf="updatingInventory">
          <i class="fa fa-spin fa-spinner mr-1"></i>
          Updating
        </span>
        </button>
      </div>
    </form>
  </div>
</ion-content>
