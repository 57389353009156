import { Component, OnInit } from '@angular/core';
import { InputTypeBaseFieldComponent } from '../input-type-base-field/input-type-base-field.component';
import { FormHelpers } from '../../classes/form-helpers.class';
import { FormTemplateQuestion } from '../../models/form-template-question.model';
import { FormTemplateQuestionOption } from '../../models/form-template-question-option.model';
import { BusService } from '../../../shared/services/bus.service';
import { FormConfig } from '../../classes/form-config.class';

@Component({
  selector: 'app-input-type-radio-button',
  templateUrl: './input-type-radio-button.component.html',
  styleUrls: ['./input-type-radio-button.component.scss']
})
export class InputTypeRadioButtonComponent extends InputTypeBaseFieldComponent implements OnInit {
  public disabled = false;

  constructor(private busService: BusService) {
    super();
  }

  ngOnInit() {
    this.id = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'id');
    this.name = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'name');

    this.filterOccluded(this.question);
    this.isApplicable(this.question);
    this.isCoRFullyControlled();
  }

  onButtonClick(question: FormTemplateQuestion, option: FormTemplateQuestionOption) {
    question.answer.value = option.option_value;

    this.filterOccluded(question);
    this.isApplicable(question);

    this.valueChange();
  }

  filterOccluded(question) {
    if (this.question.js_id === 'filter_occluded') {
      const sectionValidationEnabled = +question.answer.value !== 1;

      this.busService.dispatch({type: 'filter_occluded', data: sectionValidationEnabled});
    }
  }

  isApplicable(question) {
    if (question.js_id === 'applicable') {
      const isApplicable = +question.answer.value === 1 || question.answer.value === null;

      this.busService.dispatch({type: 'applicable', data: isApplicable});
    }
  }

  valueChange() {
    this.fieldValidation(this.question);

    if (FormConfig.calculatable.includes(this.question.number)) {
      const relations: string[] = FormConfig.calculatableRelations[this.question.number];
      relations.forEach(relationNumber => this.busService.dispatch({type: relationNumber}));
    }

    if (FormConfig.hideable.includes(this.question.number)) {
      const relations: string[] = FormConfig.hideableRelations[this.question.number];
      relations.forEach(relationNumber => this.busService.dispatch({type: relationNumber, data: this.question.answer.value !== 3}));
    }
  }

  isCoRFullyControlled() {
    if (this.question.number === 'B.1.12') {
      this.disabled = true;
      this.question.answer.value = 1;
    }
  }
}
