import { QueryBuilder } from './query-builder.class';
import { FormTemplateQuestion } from '../entities/form_template_question.entity';
import { from, Observable } from 'rxjs';
import { FormTemplateQuestionsResponse } from '../../forms/responses/form-template-question.response';
import { JsonSerializer } from './serializer.class';
import { FormTemplateQuestion as FormTemplateQuestionModel } from '../../forms/models/form-template-question.model';
import { FormTemplateQuestionOptionQueryBuilder } from './form_template_question_option.query-builder.class';

/**
 * Form Template Question Entity Query Builder.
 */
export class FormTemplateQuestionQueryBuilder extends QueryBuilder {
  constructor() {
    super(new FormTemplateQuestion());
  }

  /**
   * Get all form template questions.
   * Includes: FormTemplateQuestionOptions
   */
  get(items: string = '*'): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const query = `SELECT ${items} FROM ${
          this._table
        } ${this._buildWhereQuery()} ${this._buildOrderByQuery()} ${this._buildTakeQuery()}`;
        const response = await this._query(query);
        const data = this._filterQueryToArray(response);
        const formTemplateQuestionsResponse: FormTemplateQuestionsResponse = JsonSerializer.collection(data);

        await Promise.all(
          formTemplateQuestionsResponse.data.map(async (formTemplateQuestion: FormTemplateQuestionModel) => {
            const formTemplateQuestionOptionQueryBuilder = new FormTemplateQuestionOptionQueryBuilder();
            formTemplateQuestion.form_template_question_options = await formTemplateQuestionOptionQueryBuilder
              .where('form_template_question_id', '=', formTemplateQuestion.hashed_id)
              .get()
              .toPromise();
            return formTemplateQuestion;
          })
        );

        return resolve(formTemplateQuestionsResponse);
      })
    );
  }
}
