import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class ScoresService {
  private resource = 'scores';

  constructor(private http: HttpClient) {
  }

  /**
   * Create new sample score.
   */
  createScore(payload): Observable<any> {
    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}`, payload);
  }

  /**
   * Create new sample score.
   */
  updateScore(payload): Observable<any> {
    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/${payload.id}`, payload);
  }
}
