import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SiteResponse, SitesResponse } from '../../shared/responses/site.response';
import { environment } from '../../../../environments/environment';
import { Site } from '../../shared/models/site.model';
import { SiteQueryBuilder } from '../../shared/classes/site.query-builder.class';
import { ApiService } from '../../shared/services/api.service';

@Injectable()
export class SitesService {
  private resource = 'sites';
  private queryBuilder: SiteQueryBuilder;

  constructor(private http: HttpClient) {
    this.queryBuilder = new SiteQueryBuilder();
  }

  /**
   * Create new user site.
   * @param site
   * @returns {Observable<Object>}
   */
  createSite(site: Site): Observable<SiteResponse> {
    if (environment.cordova) {
      // site.client_id = site.client_hashed_id;
      // delete site.client_hashed_id;
    }

    return !environment.cordova
      ? this.http.post<SiteResponse>(`${ApiService.ApiUrl}/${this.resource}`, site)
      : this.queryBuilder.create(site);
  }

  /**
   * Get Sites by Client.
   * @returns {Observable<UsersResponse>}
   */
  getAllClientSites(clientId: number): Observable<SitesResponse> {
    const params = new HttpParams()
      .set('filter[client_id]', clientId.toString())
      .set('limit', '500');

    if (environment.cordova) {
      this.queryBuilder = new SiteQueryBuilder();
    }

    return !environment.cordova
      ? this.http.get<SitesResponse>(`${ApiService.ApiUrl}/sites`, { params })
      : this.queryBuilder.where('client_id', '=', clientId).get();
  }

  /**
   * Delete site by id.
   */
  deleteSite(site): Observable<any> {
    return this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${site.id}`);
  }

  updateSite(site: Site) {
    return this.http.put<SiteResponse>(`${ApiService.ApiUrl}/${this.resource}/${site.id}`, site);
  }

  findSiteByName(clientId, siteName) {
    const params = new HttpParams()
      .set('filter[client_id]', clientId.toString())
      .set('filter[name]', siteName)
      .set('limit', '50');

    if (environment.cordova) {
      this.queryBuilder = new SiteQueryBuilder();
    }

    return !environment.cordova
      ? this.http.get<SitesResponse>(`${ApiService.ApiUrl}/sites`, { params })
      : this.queryBuilder.getSitesByNameClientId(clientId, siteName);
  }

  /**
   * Sync site.
   */
  syncSite(payload): Observable<any> {
    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/sync`, payload);
  }
}
