import { BaseModel } from '../classes/query-builder.class';

export class FormSection extends BaseModel {
  public table: string = 'form_sections';
  public schema: Object = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    form_id: 'VARCHAR',
    form_hashed_id: 'VARCHAR',
    sample_id: 'VARCHAR',
    sample_hashed_id: 'VARCHAR',
    form_template_section_id: 'VARCHAR',
    position: 'INTEGER'
  };
}
