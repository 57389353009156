<div *ngIf="question.skipped" class="input-group mt-2">
  <textarea
      (change)="fieldValidation(question)"
      [ngClass]="{'is-invalid': question.validation === false && question.skipped}"
      placeholder="Specify the reason the above information could not be provided..."
      [(ngModel)]="question.answer.comment"
      [id]="id"
      [name]="name"
      class="form-control"
      cols="30"
      rows="5"></textarea>
  <span class="input-group-append">
    <span class="input-group-text">abc</span>
  </span>
  <div *ngIf="question.validation === false && question.skipped" class="invalid-feedback">
    This field is required. Please enter a comment explaining the reason it is being skipped.
  </div>
</div>