import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  credentials: FormGroup;
  submitted = false;
  submitting = false;

  constructor(
    private formBuilder: FormBuilder,
    private snotifyService: SnotifyService,
    private authService: AuthService
  ) {
    this.initCredentialsForm();
  }

  ngOnInit() {}

  initCredentialsForm() {
    this.credentials = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    this.submitting = true;
    const payload = {
      email: this.credentials.controls['email'].value
    };

    this.authService.forgotPassword(payload).subscribe(
      response => {
        this.submitting = false;
        this.submitted = true;
      },
      error => {
        this.submitting = false;
        this.snotifyService.error(
          'Unable to request password reset. Please try again.',
          'Error!'
        );
      }
    );
  }
}
