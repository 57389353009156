import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public credentials: FormGroup;
  public reset = false;
  public email: string;
  public token: string;
  public isClient: string;
  public subscription: any;
  public resetting = false;
  public portalUrl: string;

  private static passwordMatchValidator(formGroup: FormGroup) {
    return formGroup.get('password').value ===
    formGroup.get('password_confirmation').value
      ? null
      : { 'password-mismatch': true };
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private snotifyService: SnotifyService,
    private authService: AuthService
  ) {
    this.initCredentialsForm();
  }

  ngOnInit() {
    this.subscription = this.route.queryParams.subscribe(params => {
      this.email = params['email'];
      this.token = params['token'];
      this.isClient = params['isClient'];
    });

    this.portalUrl = environment.portal_url;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initCredentialsForm() {
    this.credentials = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(8)]],
        password_confirmation: ['', [Validators.required, Validators.minLength(8)]]
      },
      {
        validator: ResetPasswordComponent.passwordMatchValidator
      }
    );
  }

  onReset() {
    const payload = {
      password: this.credentials.controls['password'].value,
      password_confirmation: this.credentials.controls['password_confirmation'].value,
      email: this.email,
      token: this.token
    };

    this.resetting = true;

    this.authService.resetPassword(payload).subscribe(
      response => {
        this.reset = true;
        this.resetting = false;
      },
      error => {
        this.resetting = false;
        this.snotifyService.error(
          'Unable to reset password. Please try again.',
          'Error!'
        );
      }
    );
  }
}
