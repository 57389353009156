import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Job } from '../../models/job.model';
import { JobsService } from '../../services/jobs.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { JobResponse } from '../../responses/job.response';
import { AuthService } from '../../../auth/services/auth.service';
import { UsersService } from '../../../users/services/users.service';
import { UsersResponse } from '../../../auth/responses/user.response';
import { User } from '../../../auth/models/user.model';
import { ClientsResponse } from '../../../clients/responses/client.response';
import { ClientsService } from '../../../clients/services/clients.service';
import { Client } from '../../../clients/models/client.model';
import { environment } from '../../../../../environments/environment';
import { Servicem8Service } from '../../../shared/services/servicem8.service';
import { ClientQueryBuilder } from '../../../shared/classes/client.query-builder.class';
import { NetworkService } from '../../../shared/services/network.service';
import { QueryBuilder } from '../../../shared/classes/query-builder.class';
import { Assignment } from '../../../shared/entities/assignment.entity';
import { Project } from '../../../projects/models/project.model';
import { ProjectsService } from '../../../projects/services/projects.service';
import { ProjectsResponse } from '../../../projects/responses/project.response';
import { Subscription } from 'rxjs';
import { AppHelper } from '../../../shared/classes/app.helper';

@Component({
  selector: 'app-jobs-create',
  templateUrl: './jobs-create.component.html',
  styleUrls: ['./jobs-create.component.scss']
})
export class JobsCreateComponent implements OnInit, OnDestroy {
  public jobFormGroup: FormGroup;
  private job: Job;
  public postingJob = false;
  public findingServiceM8JobByNumber = false;
  public findingJobByNumber = false;
  public gettingAllUsers = false;
  public users: User[];
  public clients;
  public clientsLoading: boolean;
  public projectsLoading: boolean;
  public sitesLoading: boolean;
  public address: string;
  public client: Client;
  public optionValueField: string;
  public environment = environment;
  public project: Project;
  public projects: Project[];
  public subscriptions = new Subscription();
  public numToThreeDigits = AppHelper.numToThreeDigits;

  constructor(
    public bsModalRef: BsModalRef,
    private jobsService: JobsService,
    private router: Router,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private usersService: UsersService,
    private snotifyService: SnotifyService,
    private projectsService: ProjectsService,
    private clientsService: ClientsService,
    private servicem8Service: Servicem8Service,
    private networkService: NetworkService,
  ) {
    this.clientsLoading = true;
    this.projectsLoading = true;
    this.sitesLoading = false;
    this.clients = [];
    this.projects = [];
    this.optionValueField = environment.cordova ? 'hashed_id' : 'id';
  }

  ngOnInit() {
    this.initJobForm();

    this.loadClients();
    this.loadProjects();

    if (this.authService.user.is('admin')) {
      this.getAllUsers();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initJobForm() {
    this.jobFormGroup = this.formBuilder.group({
      number: ['', [Validators.required]],
      client_job_number: [null],
      project_id: [{value: null, disabled: true}],
      user_id: [this.authService.user.profile.id, [Validators.required]],
      client_id: [{value: null, disabled: true}, [Validators.required]],
      site_name: [null],
      open: [1],
      address: [{value: null, disabled: true}]
    });

    this.subscriptions.add(
      this.jobFormGroup.get('project_id').valueChanges.subscribe(projectId => {
        const field = environment.cordova
          ? 'hashed_id'
          : 'id';
        const project = this.projects.find(entry => entry[field] === projectId);

        if (!project) {
          this.jobFormGroup.get('client_id').enable();
          return;
        }

        this.jobFormGroup.get('client_id').disable();
        this.jobFormGroup.get('client_id').setValue(project.client_id);
      })
    );

    this.subscriptions.add(
        this.jobFormGroup.get('number').valueChanges.subscribe(jobNumber => {
          if (jobNumber) {
            let projectId = null;
            const field = environment.cordova
              ? 'hashed_id'
              : 'id';

            this.projects.forEach(project => {
              project.temp_assignments.data.forEach(tempAssignment => {
                if (tempAssignment.number === jobNumber) {
                  projectId = project[field];
                }
              });
            });

            this.jobFormGroup.get('project_id').setValue(projectId);
          }
        })
    );
  }

  getAllUsers() {
    this.gettingAllUsers = true;

    this.usersService.getAllTechnicianSurveyorUsers(500).subscribe(
      (response: UsersResponse) => {
        this.gettingAllUsers = false;
        this.users = response.data;
        this.jobFormGroup.get('user_id').setValue(this.authService.user.profile.id);
      },
      () => {
        this.gettingAllUsers = false;
        this.snotifyService.error('Unable to load Users. Please try again!', 'Error!');
      }
    );
  }

  loadProjects() {
    const limit = 0;
    const includes = ['client', 'temp_assignments'];

    this.projectsLoading = true;
    this.projectsService.getAllProjects(limit, includes).subscribe(
      (response: ProjectsResponse) => {
        this.projects = response.data;
        this.projectsLoading = false;
        this.jobFormGroup.get('project_id').enable();
      },
      () => {
        this.snotifyService.error('Unable to load Projects!', 'Error!');
        this.projectsLoading = false;
      }
    );
  }

  onSubmit() {
    this.postingJob = true;
    const payload = this.jobFormGroup.getRawValue();

    this.jobsService.createJob(payload).subscribe(
      (response: JobResponse) => {
        this.postingJob = false;
        this.snotifyService.success('A new job has been created.', 'Success!');
        this.job = response.data;
        this.bsModalRef.hide();
        AppHelper.chime();
        return this.router.navigate([`/jobs/view/${this.job.id}`]);
      },
      () => {
        this.postingJob = false;
        this.snotifyService.error('Unable to create job. Please try again.', 'Error!');
      }
    );
  }

  loadClients() {
    const limit = 0;

    this.clientsLoading = true;
    this.clientsService.getAllClients(limit).subscribe(
      (response: ClientsResponse) => {
        this.clients = response.data;
        this.clientsLoading = false;
        this.jobFormGroup.get('client_id').enable();

        if (this.client) {
          this.jobFormGroup.get('client_id').setValue(this.client.id);
        }
      },
      () => {
        this.snotifyService.error('Unable to load Clients!', 'Error!');
        this.clientsLoading = false;
      }
    );
  }

  findServiceM8JobByNumber() {
    this.findingServiceM8JobByNumber = true;
    const payload = this.jobFormGroup.get('number').value;

    this.servicem8Service.findServiceM8JobByNumber(payload).subscribe(
      (response: {client: Client, job_address: string}) => {
        this.findingServiceM8JobByNumber = false;
        this.client = response.client;

        if (response.job_address) {
          this.jobFormGroup.get('address').setValue(response.job_address);
        }

        if (environment.cordova) {
          const clientQueryBuilder = new ClientQueryBuilder();

          clientQueryBuilder.where('hashed_id', '=', this.client.id).first().subscribe(localClient => {
            if (!localClient) {
              clientQueryBuilder.create({
                hashed_id: this.client.id,
                name: this.client.name,
                address: this.client.address,
              }).subscribe(() => this.loadClients());
            } else {
              this.loadClients();
            }
          });
        } else {
          this.loadClients();
        }
      },
      () => {
        this.findingServiceM8JobByNumber = false;
        this.snotifyService.error('Unable to load ServiceM8 job. Please try again.', 'Error!');
      }
    );
  }

  findJobByNumber() {
    if (!this.networkService.connected) {
      return;
    }

    this.findingJobByNumber = true;
    const payload = this.jobFormGroup.get('number').value;

    this.jobsService.findJobByNumber(payload).subscribe(
      (response: JobResponse) => {
        this.findingJobByNumber = false;
        this.job = response.data;

        if (!this.job) {
          return;
        }

        if (environment.cordova) {
          const assignmentQueryBuilder = new QueryBuilder(new Assignment());

          assignmentQueryBuilder.where('hashed_id', '=', this.job.id).first().subscribe(localAssignment => {
            if (localAssignment) {
              this.bsModalRef.hide();
              return this.router.navigate([`/jobs/view/${localAssignment.data.id}`]);
            }
          });
        } else {
          this.bsModalRef.hide();
          return this.router.navigate([`/jobs/view/${this.job.id}`]);
        }
      },
      () => {
        this.findingJobByNumber = false;
      }
    );
  }
}
