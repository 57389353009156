import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from '../../auth/services/auth.service';

@Injectable()
export class RoleGuard implements CanActivate {
  private routeRoles: string[];

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.routeRoles = route.data.roles;

    return this.checkRoles();
  }

  checkRoles(): boolean {
    if (!this.authService.user) {
      this.router.navigate(['/dashboard']);

      return false;
    }

    return this.routeRoles.some(routeRole => this.authService.user.is(routeRole));
  }
}
