import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommentResponse } from '../responses/comment.response';
import { Comment } from '../models/comment.model';
import { ApiService } from './api.service';

@Injectable()
export class CommentsService {
  public parentResource: {
    id: string;
    name: string;
  };
  private resource = 'comments';

  constructor(private http: HttpClient) {}

  /**
   * Create new parent resource comment.
   * @param message
   * @returns {Observable<Object>}
   */
  createComment(message): Observable<CommentResponse> {
    return this.http.post<CommentResponse>(
      `${ApiService.ApiUrl}/${this.parentResource.name}/${this.parentResource.id}/${this.resource}`,
      { message }
    );
  }

  /**
   * Update new parent resource comment.
   * @param comment
   * @returns {Observable<Object>}
   */
  updateComment(comment: Comment): Observable<CommentResponse> {
    const payload = {
      message: comment.message
    };

    return this.http.patch<CommentResponse>(`${ApiService.ApiUrl}/${this.resource}/${comment.id}`, payload);
  }

  /**
   * Delete comment.
   * @param comment
   * @returns {Observable<Object>}
   */
  deleteComment(comment: Comment): Observable<any> {
    return this.http.delete<any>(`${ApiService.ApiUrl}/${this.resource}/${comment.id}`);
  }
}
