import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { BuildingsService } from '../../services/buildings.service';
import { Subject } from 'rxjs';
import { ComponentTemplate } from '../../../component-templates/models/component-template.model';
import { ComponentTemplatesService } from '../../../component-templates/services/component-templates.service';
import { ComponentTemplatesResponse } from '../../../component-templates/responses/component-template.response';
import { environment } from '../../../../../environments/environment';
import {AppHelper} from "../../../shared/classes/app.helper";

@Component({
  selector: 'app-buildings-create',
  templateUrl: './buildings-create.component.html',
  styleUrls: ['./buildings-create.component.scss'],
})
export class BuildingsCreateComponent implements OnInit {
  public formGroup: FormGroup;
  public postingBuilding = false;
  public surveyId: string;
  public onHide = new Subject();
  public loadingComponentTemplates = false;
  public componentTemplates: ComponentTemplate[];
  public optionValueField: string;

  constructor(
    public bsModalRef: BsModalRef,
    private componentTemplatesService: ComponentTemplatesService,
    private formBuilder: FormBuilder,
    private snotifyService: SnotifyService,
    private buildingsService: BuildingsService,
  ) {
    this.optionValueField = environment.cordova ? 'hashed_id' : 'id';
  }

  ngOnInit() {
    this.getAllComponentTemplates();
    this.initForm();
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      survey_id: [this.surveyId],
      name: [null, [Validators.required]],
      default_components: [[]],
    });
  }

  onSubmit() {
    this.postingBuilding = true;
    const payload = this.formGroup.getRawValue();
    payload.default_components = payload.default_components.join(',');

    this.buildingsService.createBuilding(payload).subscribe(
      () => {
        this.bsModalRef.hide();
        this.onHide.next();
        this.postingBuilding = false;
        AppHelper.chime();
      },
      () => {
        this.postingBuilding = false;
        this.snotifyService.error('Unable to create Building. Please try again.', 'Error!');
      },
    );
  }

  getAllComponentTemplates() {
    this.loadingComponentTemplates = true;
    const limit = 0;

    this.componentTemplatesService.getAllComponentTemplates(limit).subscribe(
      (response: ComponentTemplatesResponse) => {
        this.loadingComponentTemplates = false;
        this.componentTemplates = response.data;
      },
      () => {
        this.loadingComponentTemplates = false;
        this.snotifyService.error('Unable to load component templates. Please try again.', 'Error!');
      },
    );
  }

  setMultipleRadioValue(field: string, id: string) {
    const formControl = this.formGroup.get(field);

    if (!formControl.value) {
      formControl.setValue([]);
    }

    const initialValue = formControl.value;
    let value;

    if (initialValue.includes(id)) {
      value = initialValue.filter(entry => entry !== id);
    } else {
      initialValue.push(id);
      value = initialValue;
    }

    formControl.setValue(value);
  }
}
