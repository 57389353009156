<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Users</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li class="breadcrumb-item">
      <a [routerLink]="['/roles/index']" routerDirection="backward">Roles</a>
    </li>

    <li class="breadcrumb-item active">
      <span>Update</span>
    </li>
  </ol>
  <app-loading *ngIf="gettingRole"></app-loading>

  <div *ngIf="!gettingRole" class="card animated fadeIn">
    <div class="card-header">
      <strong>Update</strong> User Role
    </div>
    <div class="card-body">
      <form [formGroup]="roleFormGroup" action="" method="post">
        <div class="form-group">
          <label for="display_name">
            Name
          </label>
          <input
              [formControl]="roleFormGroup.controls['display_name']"
              [ngClass]="{'is-invalid': !roleFormGroup.controls['display_name'].valid && roleFormGroup.controls['display_name'].touched}"
              class="form-control"
              id="display_name"
              name="display_name"
              placeholder="Enter Role Name"
              type="text">
          <div class="help-block text-danger"
               *ngIf="roleFormGroup.controls['display_name'].touched && roleFormGroup.controls['display_name'].hasError('required')">
            * This field is required.
          </div>
        </div>
        <div class="form-group">
          <label for="description">
            Description
          </label>
          <input
              [formControl]="roleFormGroup.controls['description']"
              [ngClass]="{'is-invalid': !roleFormGroup.controls['description'].valid && roleFormGroup.controls['description'].touched}"
              class="form-control"
              id="description"
              name="description"
              placeholder="Enter Role Description"
              type="text">
          <div class="help-block text-danger"
               *ngIf="roleFormGroup.controls['description'].touched && roleFormGroup.controls['description'].hasError('required')">
            * This field is required.
          </div>
        </div>
      </form>

      <h6>Role Permissions</h6>
      <hr>
      <app-loading *ngIf="gettingRolePermissions"></app-loading>
      <div *ngIf="!gettingRolePermissions" class="row">
        <div *ngFor="let permission of permissions; let i = index" class="col-md-6">
          <label class="switch switch-text switch-info-outline-alt">
            <input class="switch-input" type="checkbox" name="{{ permission.id }}" [(ngModel)]="permission.selected">
            <span class="switch-label" data-off="Off" data-on="On"></span>
            <span class="switch-handle"></span>
          </label>
          <span class="ml-1">{{ permission.description }}</span>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
          (click)="onSubmit()"
          [disabled]="!roleFormGroup.valid"
          class="btn btn-sm btn-primary"
          type="button">
      <span *ngIf="!updatingRole && !syncingRolePermissions">
        <i class="fa fa-save mr-1"></i>
        Update
      </span>
        <span *ngIf="updatingRole || syncingRolePermissions">
        <i class="fa fa-spin fa-spinner mr-1"></i>
        Updating
      </span>
      </button>
    </div>
  </div>
</ion-content>
