<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Form View</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li *ngIf="!cordova" class="breadcrumb-item">
      <a [routerLink]="['/forms/index']" routerDirection="backward">Forms</a>
    </li>

    <li *ngIf="cordova" class="breadcrumb-item active">
      <span>Forms</span>
    </li>

    <li class="breadcrumb-item active">
      <span>View</span>
    </li>
  </ol>

  <app-loading *ngIf="findingFormById"></app-loading>

  <div *ngIf="!findingFormById" class="row">
    <div class="col-md-3">
      <div class="card animated fadeIn">
        <div class="card-header">
          <strong>Form</strong> info
        </div>

        <ul class="list-group list-group-flush">
          <li class="list-group-item d-flex justify-content-between">
            <span>Template name:</span>
            <span>{{ form?.form_template_name }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between">
            <span>Job:</span>
            <span>{{ form?.assignment.data.number }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between">
            <span>Site:</span>
            <span>{{ form?.site?.data?.name }}</span>
          </li>
          <li *ngIf="form?.completed_at" class="list-group-item d-flex justify-content-between">
            <span>Completed at:</span>
            <span>{{ form?.completed_at }}</span>
          </li>
        </ul>

        <div *ngIf="form" class="card-footer">
          <a
              *ngIf="!cordova"
              [routerLink]="['/forms/update', form?.id]" class="btn btn-sm btn-info float-right">
            <i class="fa fa-edit mr-1"></i>
            Edit
          </a>

          <a
              *ngIf="!cordova"
              [routerLink]="['/forms/fill', form?.id]" class="btn btn-sm btn-info float-right mr-1">
            <i class="fa fa-edit mr-1"></i>
            Fill
          </a>

          <button
              [disabled]="form.completed_at"
              *ngIf="cordova"
              (click)="startForm(form)"
              class="btn btn-sm btn-primary float-right">
            <i class="fa fa-rocket mr-1"></i>
            Start
          </button>

        </div>
      </div>
    </div>

    <div class="col-md-9">
      <tabset>
        <tab heading="Form data">
          <ng-container *ngFor="let formSection of form?.form_sections.data">
            <ng-container *ngIf="formSection.form_template_section.data.js_id !== 'samples_table'">
              <ng-container *ngTemplateOutlet="sectionTemplate; context:{ $implicit: formSection }"></ng-container>
            </ng-container>

            <ng-container *ngIf="formSection.form_template_section.data.js_id === 'samples_table'">
              <ng-container *ngFor="let sample of form.samples.data; let i = index;">
                <ng-container *ngFor="let sampleSection of sample.form_sections.data">
                  <ng-container *ngTemplateOutlet="sectionTemplate; context:{ $implicit: sampleSection, sample: sample, index: i }"></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </tab>
        <tab *ngIf="!cordova" heading="Revisions">
          <app-revisions-index [form]="form"></app-revisions-index>
        </tab>
        <tab *ngIf="!cordova" heading="Comments">
          <app-comments-create [comments]="form.comments.data" [parentResource]="{id: form_id, name: 'forms'}"></app-comments-create>
        </tab>
      </tabset>
    </div>
  </div>

  <ng-template #sectionTemplate let-section let-sample="sample" let-index="index">
    <div class="card fadeIn animated">
      <div (click)="onSectionHeaderClick(section)" class="card-header pointer">
        {{ section.form_template_section.data.title }}
        <span *ngIf="sample"> - Sample Number: {{ index + 1 }}</span>
      </div>

      <span [id]="section.id"></span>

      <div *ngIf="activeSectionId === section.id" class="card-body">
        <div *ngFor="let question of section.form_template_section.data.form_template_questions.data">
          <h6>
            {{ question.text }}
          </h6>

          <div *ngIf="question.answer; else unanswered_question">
            <div *ngIf="questionHasImage(question); else question_values" class="row">
              <div
                  *ngFor="let file of question.answer.value"
                  class="col-md-4">
                <div class="card">
                  <a [href]="file" target="_blank">
                    <img
                        class="card-img-top"
                        [src]="file"
                        alt="Form photo">
                  </a>
                </div>
              </div>
            </div>

            <ng-template #question_values>
              <div class="alert alert-secondary" *ngIf="question.answer.value">
                <p *ngIf="question.input_type === data_types['TEXT']; else normal_string;" class="mb-0" [innerHTML]="question.answer.value | printText"></p>
                <ng-template #normal_string>{{ question.answer.value }}</ng-template>
              </div>
            </ng-template>

            <div class="alert alert-info" *ngIf="question.answer.comment">
              <strong>Comment:</strong>
              <p class="mb-0" [innerHTML]="question.answer.comment | printText"></p>
            </div>
          </div>

          <ng-template #unanswered_question>
            <div class="alert alert-warning">
              This question has not been answered.
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-template>
</ion-content>
