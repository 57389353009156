import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DashboardComponent } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { HomeTechnicianComponent } from './components/home-technician/home-technician.component';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { HomeAdminComponent } from './components/home-admin/home-admin.component';
import { HomeReviewerComponent } from './components/home-reviewer/home-reviewer.component';
import { HomeClientsComponent } from './components/home-clients/home-clients.component';
import { HomeSurveyingComponent } from './components/home-surveying/home-surveying.component';
import { HomeAirTestingComponent } from './components/home-air-testing/home-air-testing.component';
import { HomeSurveyingAdminComponent } from './components/home-surveying-admin/home-surveying-admin.component';

@NgModule({
  imports: [CommonModule, DashboardRoutingModule, IonicModule, SharedModule],
  declarations: [
    DashboardComponent,
    HomeTechnicianComponent,
    HomeAdminComponent,
    HomeReviewerComponent,
    HomeClientsComponent,
    HomeSurveyingComponent,
    HomeAirTestingComponent,
    HomeSurveyingAdminComponent,
  ],
})
export class DashboardModule {
}
