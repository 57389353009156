import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from '../../shared/services/api.service';
import { Observable } from 'rxjs';
import { ProjectResponse, ProjectsResponse } from '../responses/project.response';
import { Project } from '../models/project.model';
import { environment } from '../../../../environments/environment';
import { ProjectQueryBuilder } from '../../shared/classes/project.query-builder.class';
import { ProjectStatusEnum } from '../../shared/enums/project-status.enum';

@Injectable()
export class ProjectsService {
  private resource = 'projects';
  private queryBuilder: ProjectQueryBuilder;

  constructor(private http: HttpClient) {
    if (environment.cordova) {
      this.queryBuilder = new ProjectQueryBuilder();
    }
  }

  /**
   * Get all projects.
   */
  getAllProjects(
    limit: number = 15,
    includes: string[] = null,
    sorting: string = null,
    appends: string[] = null,
    search: string = null,
    status: ProjectStatusEnum = null,
    clientId: string = null
  ): Observable<ProjectsResponse> {
    let params = new HttpParams();

    params = params.set('limit', limit.toFixed(0));

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    if (appends) {
      params = params.set('append', appends.join(','));
    }

    if (sorting) {
      params = params.set('sort', sorting);
    }

    if (search) {
      params = params.set('filter[search]', search);
    }

    if (status) {
      params = params.set('filter[status]', status.toString());
    }

    if (clientId) {
      params = params.set('filter[client_id]', clientId);
    }

    if (environment.cordova) {
      this.queryBuilder = new ProjectQueryBuilder();
    }

    return !environment.cordova
      ? this.http.get<ProjectsResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params })
      : this.queryBuilder.getWithIncludes(includes);
  }

  /**
   * Find project by id.
   */
  findProjectById(projectId: string, includes: string[] = null): Observable<ProjectResponse> {
    let params = new HttpParams();

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    return this.http.get<ProjectResponse>(`${ApiService.ApiUrl}/${this.resource}/${projectId}`, { params });
  }

  /**
   * Create new user project.
   */
  createProject(project: Project): Observable<ProjectResponse> {
    return this.http.post<ProjectResponse>(`${ApiService.ApiUrl}/${this.resource}`, project);
  }

  /**
   * Update user project.
   */
  updateProject(project: Project): Observable<ProjectResponse> {
    return this.http.patch<ProjectResponse>(`${ApiService.ApiUrl}/${this.resource}/${project.id}`, project);
  }

  /**
   * Delete user project.
   */
  deleteProject(project: Project): Observable<any> {
    return this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${project.id}`);
  }

  getClientProjectNumber(clientId: string): Observable<any> {
    return this.http.get(`${ApiService.ApiUrl}/${this.resource}/clients/${clientId}/number`);
  }
}
