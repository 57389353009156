<label *ngIf="question.js_id !== 'sample_number'" class="d-block">
  {{ question.text }}
</label>

<div class="btn-group btn-group-lg flex-wrap" role="group">
  <button
    [class.btn-disable]="disabled"
    [class.active]="question.answer.value == option.option_value"
    [class.btn-secondary]="question.answer.value == option.option_value"
    type="button"
    class="btn btn-outline-secondary ws-normal"
    *ngFor="let option of question.form_template_question_options.data; let first = first"
    (click)="onButtonClick(question, option)"
    [id]="id + '_' + option.id">
    {{ option.option_text }}
  </button>
  <app-input-value-skip-button *ngIf="question.form_template_question_options.data.length === 1" [question]="question"></app-input-value-skip-button>
</div>

<div *ngIf="question.validation === false && !question.skipped" class="text-danger">
  This field is required. Please either fill a value or press <i>Skip</i> button and enter a comment.
</div>

<app-input-comment [question]="question"></app-input-comment>
