<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Uploader</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/home']" routerDirection="root">Home</a>
    </li>
  </ol>

  <div class="animated fadeIn card card-accent-primary">
    <div class="card-header">
      <strong>Uploads</strong> list
    </div>

    <div *ngIf="forms?.length === 0" class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      No uploads pending.
    </div>

    <div *ngIf="forms?.length > 0" class="table-responsive">
      <table class="table table-striped mb-0">
        <ng-container *ngFor="let form of forms">
          <tr>
            <td>{{ form.form_template_name }}</td>
            <td>Completed at: {{ form.completed_at }}</td>
            <td class="text-right" *ngIf="form.synced_at && form.id !== syncingFormId"><span class="badge badge-success">Form Uploaded</span></td>
            <td class="text-right" *ngIf="!form.synced_at && form.id !== syncingFormId"><span class="badge badge-secondary">Form Pending Upload</span></td>
            <td class="text-right" *ngIf="form.id === syncingFormId"><span class="badge badge-primary"><i class="fa fa-spin fa-spinner mr-1"></i> Form Uploading</span></td>
            <td *ngIf="form.id === syncingFormId" class="text-right">Synced Packets: <span class="badge badge-info">{{ counter }}/{{ max }}</span></td>
            <td *ngIf="form.id !== syncingFormId" class="text-right">
              <button
                  *ngIf="!form.synced_at"
                  [disabled]="syncingFormId && form.id !== syncingFormId"
                  (click)="syncForm(form)"
                  class="btn btn-sm btn-primary">
                <i class="fa fa-rocket mr-1"></i>
                Sync
              </button>
            </td>
          </tr>
          <tr>
              <td colspan="5" style="padding: 0">
                <div
                    *ngIf="!form.synced_at"
                    class="progress" style="height: 3px;">
                  <div class="progress-bar" role="progressbar" [style.width]="calculateProgressBarWidth(form) +'%'"></div>
                </div>

                <div
                    *ngIf="form.synced_at"
                    class="progress" style="height: 3px;">
                  <div class="progress-bar progress-bar-success" role="progressbar" [style.width]="100 +'%'"></div>
                </div>
              </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
</ion-content>
