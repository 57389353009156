import { FormTemplateSection } from '../models/form-template-section.model';
import { FormHelpers } from './form-helpers.class';
import { Form } from '../models/form.model';

/**
 * Air Test DCU Helper Class.
 */
export class AirTestDCUSectionRules {
  /**
   * Air Test DCU - D.1 Inspection Details
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_d1(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_dcu_d2' };
  }

  /**
   * Air Test DCU - D.2 Visual
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_d2(form: Form, section: FormTemplateSection) {
    const passFailQuestion = FormHelpers.findSectionQuestionByJsId(section, 'stage_success');
    const section_success = passFailQuestion ? passFailQuestion.answer.value === 1 : true;

    return section_success ? { nextSectionJsRule: 'air_test_dcu_d3' } : { nextSectionJsRule: 'failed' };
  }

  /**
   * Air Test DCU - D.3 Number of Samples
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_d3(form: Form, section: FormTemplateSection) {
    return{ nextSectionJsRule: 'air_test_dcu_d4' };
  }

  /**
   * Air Test DCU - D.3B Samples table
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_d3b(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_dcu_d4' };
  }

  /**
   * Air Test DCU - D.4 Temp & Pressure
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_d4(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_dcu_d6' };
  }

  /**
   * Air Test DCU - D.5 Sample Details
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_d5(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_dcu_d7' };
  }

  /**
   * Air Test DCU - D.6 Microscope Setup
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_d6(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_dcu_d3b', action: 'load' };
  }

  /**
   * Air Test DCU - D.7 Sample Analysis Prep
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_d7(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_dcu_d8' };
  }

  /**
   * Air Test DCU - D.8 Analysis of samples
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_d8(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_dcu_d3b', action: 'load', task: 'clear_active_sample' };
  }

  /**
   * Air Test DCU - New Sample (2)
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_new_sample(form: Form, section: FormTemplateSection) {
    const prepare_new_samples = FormHelpers.findSectionQuestionByJsId(section, 'prepare_new_samples');
    // Yes: 1, No: 2
    return prepare_new_samples.answer.value === 1
      ? { nextSectionJsRule: 'air_test_dcu_d3b' }
      : { nextSectionJsRule: 'air_test_dcu_summary' };
  }

  /**
   * Air Test DCU - Field Blank Count
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string, role: string}}
   */
  public static air_test_dcu_field_blank_count(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_dcu_summary' };
  }

  /**
   * Air Test DCU - Summary
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string, role: string}}
   */
  public static air_test_dcu_summary(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_dcu_desktop_review', role: 'reviewer' };
  }

  /**
   * Air Test DCU - Section List
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string, role: string}}
   */
  public static air_test_dcu_section_list(form: Form, section: FormTemplateSection) {
    const next_section = FormHelpers.findSectionQuestionByJsId(section, 'next_section');

    switch (+next_section.answer.value) {
      case 1:
        return { nextSectionJsRule: 'air_test_dcu_d1' };
      case 2:
        return { nextSectionJsRule: 'air_test_dcu_d2' };
      case 3:
        return { nextSectionJsRule: 'air_test_dcu_d3' };
      case 4:
        return { nextSectionJsRule: 'air_test_dcu_d4' };
      case 5:
        return { nextSectionJsRule: 'air_test_dcu_d5' };
      case 6:
        return { nextSectionJsRule: 'air_test_dcu_d6' };
      case 7:
        return { nextSectionJsRule: 'air_test_dcu_d7' };
      case 8:
        return { nextSectionJsRule: 'air_test_dcu_d8' };
      case 9:
        return { nextSectionJsRule: 'air_test_dcu_new_sample' };
      case 10:
        return { nextSectionJsRule: 'air_test_dcu_summary' };
      default:
        return { nextSectionJsRule: 'air_test_dcu_d1' };
    }
  }

  /**
   * Air Test DCU - Desktop Review (Office Only)
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_desktop_review(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'completed' };
  }

  /**
   * Air Test DCU - Microscope Set Up Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_microscope_set_up_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_dcu_d6', action: 'load' };
  }

  /**
   * Air Test DCU - Samples Done Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_samples_done_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_dcu_field_blank_count', action: 'load' };
  }

  /**
   * Air Test DCU - Start Sample Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_sample_start_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_dcu_d5', action: 'load' };
  }

  /**
   * Air Test DCU - Collect Sample Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_sample_collect_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_dcu_d5', action: 'load' };
  }

  /**
   * Air Test DCU - Prepare Sample Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_sample_prepare_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_dcu_d7', action: 'load' };
  }

  /**
   * Air Test DCU - Analyse Sample Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_sample_analyse_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_dcu_d8', action: 'load' };
  }

  /**
   * Air Test DCU - Samples Table Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_dcu_samples_table_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_dcu_d3b', action: 'load' };
  }
}
