import { Form } from '../models/form.model';
import { FormTemplateSection } from '../models/form-template-section.model';
import { FormHelpers } from './form-helpers.class';

/**
 * Certificate of Visual Cleanliness Section Rules
 * Helper Class.
 */
export class CertificateOfVisualCleanlinessSectionRules {
  /**
   * Certificate of visual cleanliness - Inspection details.
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static certificate_of_visual_cleanliness_inspection_details(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_visual_cleanliness_stage_1_of_3_preliminary_check' };
  }

  /**
   * Certificate of visual cleanliness - Stage 1 of 3 Preliminary Check.
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static certificate_of_visual_cleanliness_stage_1_of_3_preliminary_check(form: Form, section: FormTemplateSection) {
    const passFailQuestion = FormHelpers.findSectionQuestionByJsId(section, 'stage_success');
    const section_success = passFailQuestion ? passFailQuestion.answer.value === 1 : true;

    return section_success
      ? { nextSectionJsRule: 'certificate_of_visual_cleanliness_stage_2_of_3_thorough_visual' }
      : { nextSectionJsRule: 'failed' };
  }

  /**
   * Certificate of visual cleanliness - Stage 2 of 3 Thorough visual.
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static certificate_of_visual_cleanliness_stage_2_of_3_thorough_visual(form: Form, section: FormTemplateSection) {
    const passFailQuestion = FormHelpers.findSectionQuestionByJsId(section, 'stage_success');
    const section_success = passFailQuestion ? passFailQuestion.answer.value === 1 : true;

    return section_success
      ? { nextSectionJsRule: 'certificate_of_visual_cleanliness_stage_3_of_3_final_assessment' }
      : { nextSectionJsRule: 'failed' };
  }

  /**
   * Certificate of visual cleanliness - Stage 3 of 3 Final assessment.
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static certificate_of_visual_cleanliness_stage_3_of_3_final_assessment(form: Form, section: FormTemplateSection) {
    const passFailQuestion = FormHelpers.findSectionQuestionByJsId(section, 'stage_success');
    const section_success = passFailQuestion ? passFailQuestion.answer.value === 1 : true;

    return section_success ? { nextSectionJsRule: 'certificate_of_visual_cleanliness_summary' } : { nextSectionJsRule: 'failed' };
  }

  /**
   * Certificate of visual cleanliness - Summary.
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static certificate_of_visual_cleanliness_summary(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'completed' };
  }
}
