import { Injectable } from '@angular/core';
import { QueryBuilder } from '../classes/query-builder.class';
import { FormTemplate } from '../entities/form_template.entity';
import { HttpClient } from '@angular/common/http';
import { FormTemplateSection } from '../entities/form_template_section.entity';
import { FormTemplateQuestion } from '../entities/form_template_question.entity';
import { FormTemplateQuestionOption } from '../entities/form_template_question_option.entity';
import { Inventory } from '../entities/inventory.entity';
import { FormSection } from '../entities/form_section.entity';
import { Form as FormModel } from '../../forms/models/form.model';
import { FormQueryBuilder } from '../classes/form.query-builder.class';
import * as moment from 'moment';
import { NetworkService } from './network.service';
import { SnotifyService } from 'ng-snotify';
import { Form } from '../entities/form.entity';
import { Client } from '../entities/client.entity';
import { Site } from '../entities/site.entity';
import { Assignment } from '../entities/assignment.entity';
import { Sample } from '../entities/sample.entity';
import { BusService } from './bus.service';
import { FormSectionQueryBuilder } from '../classes/form_section.query-builder.class';
import { FormAnswerQueryBuilder } from '../classes/form_answer.query-builder.class';
import { ApiService } from './api.service';
import { User } from '../entities/user.entity';
import { AssignmentQueryBuilder } from '../classes/assignment.query-builder.class';
import { SampleQueryBuilder } from '../classes/sample.query-builder.class';
import { Project } from '../entities/project.entity';
import { SurveyJob } from '../entities/survey_job.entity';
import { Survey } from '../entities/survey.entity';
import { environment } from '../../../../environments/environment';
import { Building } from '../entities/building.entity';
import { ComponentTemplate } from '../entities/component_template.entity';
import { Floor } from '../entities/floor.entity';
import { Area } from '../entities/area.entity';
import { Component } from '../entities/component.entity';
import { Material } from '../entities/material.entity';
import { SurveySample } from '../entities/survey_sample.entity';
import { TempAssignment } from '../entities/temp_assignment.entity';
import { SiteQueryBuilder } from '../classes/site.query-builder.class';

@Injectable()
export class SyncService {
  public resources: any[];
  private form_templates_QueryBuilder: QueryBuilder;
  private form_template_sections_QueryBuilder: QueryBuilder;
  private form_template_questions_QueryBuilder: QueryBuilder;
  private form_template_question_options_QueryBuilder: QueryBuilder;
  private forms_QueryBuilder: QueryBuilder;
  private form_sections_QueryBuilder: QueryBuilder;
  private samples_QueryBuilder: QueryBuilder;
  private inventories_QueryBuilder: QueryBuilder;
  private clients_QueryBuilder: QueryBuilder;
  private sites_QueryBuilder: QueryBuilder;
  private assignments_QueryBuilder: QueryBuilder;
  private survey_jobs_QueryBuilder: QueryBuilder;
  private users_QueryBuilder: QueryBuilder;
  private projects_QueryBuilder: QueryBuilder;
  private surveys_QueryBuilder: QueryBuilder;
  private buildings_QueryBuilder: QueryBuilder;
  private component_templates_QueryBuilder: QueryBuilder;
  private components_QueryBuilder: QueryBuilder;
  private floors_QueryBuilder: QueryBuilder;
  private areas_QueryBuilder: QueryBuilder;
  private materials_QueryBuilder: QueryBuilder;
  private survey_samples_QueryBuilder: QueryBuilder;
  private temp_assignments_QueryBuilder: QueryBuilder;

  /**
   * Remove attributes that do not exist on sqlite entity.
   */
  public static trimForeignAttributes(builder, entity) {
    for (const attribute in entity) {
      if (!entity.hasOwnProperty(attribute)) {
        return;
      }

      if (!builder.baseModel.schema.hasOwnProperty(attribute)) {
        delete entity[attribute];
      }
    }

    return entity;
  }

  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private snotifyService: SnotifyService,
    private busService: BusService
  ) {
    if (environment.cordova) {
      this.initQueryBuilders();
    }

    this.resources = [
      {
        name: 'sites',
        display_name: 'Sites',
        url: 'sites',
        excludes: [],
        appends: [],
        flush: false,
        syncing: false
      },
      {
        name: 'form_templates',
        display_name: 'Form Templates',
        url: 'form_templates',
        excludes: [],
        appends: [],
        flush: false,
        syncing: false
      },
      {
        name: 'form_template_sections',
        display_name: 'Form Template Sections',
        url: 'form_template_sections',
        excludes: ['form_template_questions'],
        appends: [],
        flush: false,
        syncing: false
      },
      {
        name: 'form_template_questions',
        display_name: 'Form Template Questions',
        url: 'form_template_questions',
        excludes: ['form_template_question_options'],
        appends: [],
        flush: false,
        syncing: false
      },
      {
        name: 'form_template_question_options',
        display_name: 'Form Template Question Options',
        url: 'form_template_question_options',
        appends: [],
        excludes: [],
        flush: false,
        syncing: false
      },
      {
        name: 'inventories',
        display_name: 'Inventory Items',
        url: 'user/inventories',
        appends: [],
        excludes: [],
        flush: true,
        syncing: false
      },
      {
        name: 'clients',
        display_name: 'Clients',
        url: 'clients',
        excludes: [],
        appends: [],
        flush: false,
        syncing: false
      },
      {
        name: 'assignments',
        display_name: 'Assignments',
        url: 'assignments/user/authenticated',
        excludes: [],
        appends: [],
        flush: false,
        syncing: false
      },
      {
        name: 'users',
        display_name: 'Users',
        url: 'users',
        excludes: [],
        appends: [],
        flush: false,
        syncing: false
      },
      {
        name: 'projects',
        display_name: 'projects',
        url: 'projects',
        excludes: [],
        appends: [],
        flush: true,
        syncing: false
      },
      {
        name: 'component_templates',
        display_name: 'Component Templates',
        url: 'component_templates',
        excludes: [],
        appends: [],
        flush: true,
        syncing: false
      },
      {
        name: 'materials',
        display_name: 'Materials',
        url: 'materials',
        excludes: [],
        appends: [],
        flush: true,
        syncing: false
      },
      {
        name: 'temp_assignments',
        display_name: 'Temp Assignments',
        url: 'temp_assignments',
        excludes: [],
        appends: [],
        flush: true,
        syncing: false
      },
      {
        name: 'surveys',
        display_name: 'Surveys',
        url: 'surveys/user/authenticated',
        excludes: [],
        appends: ['report_number', 'site_photo'],
        flush: false,
        syncing: false
      }
    ];
  }

  initQueryBuilders() {
    const migrate = true;

    this.form_templates_QueryBuilder = new QueryBuilder(new FormTemplate(), migrate);
    this.form_template_sections_QueryBuilder = new QueryBuilder(new FormTemplateSection(), migrate);
    this.form_template_questions_QueryBuilder = new QueryBuilder(new FormTemplateQuestion(), migrate);
    this.form_template_question_options_QueryBuilder = new QueryBuilder(new FormTemplateQuestionOption(), migrate);
    this.forms_QueryBuilder = new QueryBuilder(new Form(), migrate);
    this.form_sections_QueryBuilder = new QueryBuilder(new FormSection(), migrate);
    this.samples_QueryBuilder = new QueryBuilder(new Sample(), migrate);
    this.inventories_QueryBuilder = new QueryBuilder(new Inventory(), migrate);
    this.clients_QueryBuilder = new QueryBuilder(new Client(), migrate);
    this.sites_QueryBuilder = new QueryBuilder(new Site(), migrate);
    this.assignments_QueryBuilder = new QueryBuilder(new Assignment(), migrate);
    this.survey_jobs_QueryBuilder = new QueryBuilder(new SurveyJob(), migrate);
    this.users_QueryBuilder = new QueryBuilder(new User(), migrate);
    this.projects_QueryBuilder = new QueryBuilder(new Project(), migrate);
    this.surveys_QueryBuilder = new QueryBuilder(new Survey(), migrate);
    this.buildings_QueryBuilder = new QueryBuilder(new Building(), migrate);
    this.component_templates_QueryBuilder = new QueryBuilder(new ComponentTemplate(), migrate);
    this.floors_QueryBuilder = new QueryBuilder(new Floor(), migrate);
    this.areas_QueryBuilder = new QueryBuilder(new Area(), migrate);
    this.components_QueryBuilder = new QueryBuilder(new Component(), migrate);
    this.materials_QueryBuilder = new QueryBuilder(new Material(), migrate);
    this.survey_samples_QueryBuilder = new QueryBuilder(new SurveySample(), migrate);
    this.temp_assignments_QueryBuilder = new QueryBuilder(new TempAssignment(), migrate);
  }

  /**
   * Sync API models.
   */
  syncAPI() {
    if (!this.networkService.connected) {
      return;
    }

    this.resources.forEach(resource => this.check(resource));
  }

  /**
   * Truncate resource and fetch new data.
   */
  forceSyncAPI() {
    this.initQueryBuilders();

    this.resources.forEach(async resource => {
      resource.flush = true;
      await this.fetch(resource);
    });
  }

  /**
   * Check if resource model is already synced.
   */
  async check(resource) {
    const builder: QueryBuilder = this[`${resource.name}_QueryBuilder`];

    await builder.get().subscribe(
      async response => {
        if (resource.flush || response.data.length === 0) {
          await this.fetch(resource);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  /**
   * Fetch API resource, excluding relationships.
   */
  async fetch(resource) {
    await this.http.get(`${ApiService.ApiUrl}/${resource.url}?limit=0&exclude=${resource.excludes.join(',')}&append=${resource.appends.join(',')}`).subscribe(
      (response: { data: any; meta: any }) => {
        this.sync(resource, response.data);
      },
      () => {
        this.snotifyService.error('Unable to load resources', 'Error!');
      }
    );
  }

  /**
   * Sync resource data to sqlite table.
   * @param resource
   * @param data
   */
  sync(resource, data: any[]) {
    const noFlushResources = [
      'assignments',
      'surveys',
      'sites',
    ];

    const builder: QueryBuilder = this[`${resource.name}_QueryBuilder`];

    if (resource.flush && !noFlushResources.includes(resource.name)) {
      this.busService.dispatch({ type: 'addSyncingItems', data: data.length });

      return builder.truncate().subscribe(
        () => {
          this.createEntities(builder, data);
        },
        () => {
          this.snotifyService.error('Unable to sync resources', 'Error!');
        }
      );
    }

    if (noFlushResources.includes(resource.name)) {
      if (resource.name === 'surveys') {
        return this.createNewSurveysEntities(builder, data);
      } else if (resource.name === 'sites') {
        return this.createNewSiteEntities(builder, data);
      } else {
        return this.createNewEntities(builder, data, resource.name);
      }
    }

    this.createEntities(builder, data);
  }

  /**
   * Create entities.
   * @param builder
   * @param data
   */
  createEntities(builder: QueryBuilder, data: any[]) {
    const entities = [];

    data.forEach(entity => {
      entity.hashed_id = entity.id;
      delete entity.object;
      delete entity.id;

      entity = SyncService.trimForeignAttributes(builder, entity);

      entities.push(entity);
    });

    builder.createMultipleRows(entities).subscribe(() => this.busService.dispatch({ type: 'removeSyncedItems', data: entities.length }));
  }

  /**
   * Create entities.
   * @param builder
   * @param data
   */
  async createNewSurveysEntities(builder: QueryBuilder, data: any[]) {
    const entities = [];
    const assignments = await builder.loadCollectionRelationships('assignments', 'hashed_id', 'assignment_id', data);
    const sites = await builder.loadCollectionRelationships('sites', 'hashed_id', 'site_id', data);
    const surveys = await builder.loadTable('surveys');

    data = data.filter(entry => !surveys.find(survey => survey.hashed_id === entry.id));

    data.forEach(entity => {
      entity.hashed_id = entity.id;
      delete entity.object;
      delete entity.id;

      const site = sites
        .find(siteEntry => siteEntry.hashed_id === entity.site_id);

      if (site) {
        entity.site_id = site.id.toString();
      } else {
        entity.site_id = null;
      }

      const assignment = assignments
        .find(assignmentEntry => assignmentEntry.hashed_id === entity.assignment_id);

      if (assignment) {
        entity.assignment_id = assignment.id.toString();
      }

      entity = SyncService.trimForeignAttributes(builder, entity);

      entities.push(entity);
    });

    builder.createMultipleRows(entities).subscribe(() => this.busService.dispatch({ type: 'removeSyncedItems', data: entities.length }));
  }

  /**
   * Create entities.
   * @param builder
   * @param data
   */
  async createNewSiteEntities(builder: QueryBuilder, data: any[]) {
    const entities = [];
    const sites = await builder.loadTable('sites');

    data = data.filter(entry => !sites.find(site => site.hashed_id === entry.id));

    data.forEach(entity => {
      entity.hashed_id = entity.id;
      delete entity.object;
      delete entity.id;

      entity = SyncService.trimForeignAttributes(builder, entity);

      entities.push(entity);
    });

    builder.createMultipleRows(entities).subscribe(() => this.busService.dispatch({ type: 'removeSyncedItems', data: entities.length }));
  }

  /**
   * Create entities.
   */
  async createNewEntities(builder: QueryBuilder, data: any[], resourceName: string) {
    await data.forEach(async entity => {
      const item = Object.assign({}, entity);
      item.hashed_id = item.id;
      delete item.object;
      delete item.id;

      builder.resetWhere();

      await builder.where('hashed_id', '=', item.hashed_id).first().subscribe(
        response => {
          if (!response) {
            const payload = SyncService.trimForeignAttributes(builder, item);
            this.busService.dispatch({ type: 'addSyncingItems', data: 1 });

            builder.create(payload).subscribe(event => {
              this.busService.dispatch({ type: 'removeSyncedItems', data: 1 });
            });
          }

          if (response && resourceName === 'assignments') {
            const payload = SyncService.trimForeignAttributes(builder, item);
            payload.id = response.data.id;
            this.busService.dispatch({ type: 'addSyncingItems', data: 1 });

            builder.update(payload).subscribe(event => {
              this.busService.dispatch({ type: 'removeSyncedItems', data: 1 });
            });
          }
        }
      );
    });
  }

  /**
   * Mark form as synced.
   * @param form
   * @param response
   * @param formResponse
   */
  async markFormAsSynced(form: FormModel, response: any, formResponse) {
    const forms_QueryBuilder = new FormQueryBuilder();

    form.synced_at = moment().format('YYYY/MM/DD HH:mm:ss');
    delete form.syncing;

    const assignment_QueryBuilder = new AssignmentQueryBuilder();
    assignment_QueryBuilder.update({
      id: +form.assignment_id,
      hashed_id: response.data.assignment_id,
    });

    const site_QueryBuilder = new SiteQueryBuilder();
    site_QueryBuilder.update({
      id: +form.site_id,
      hashed_id: response.data.site_id,
    });

    await forms_QueryBuilder.update(form).subscribe(
      () => {
        this.removeFormFormSectionsData(form);
        this.removeFormSamplesData(form);
        this.removeFormFormAnswersData(form);
      },
      () => {
        //
      }
    );
  }

  /**
   * Mark form as not synced.
   */
  async markFormAsNotSynced(form: FormModel) {
    const forms_QueryBuilder = new FormQueryBuilder();

    form.synced_at = null;
    form.completed_at = null;
    delete form.downloading;

    await forms_QueryBuilder.update(form).subscribe(
      () => {
        //
      },
      () => {
        //
      }
    );
  }

  removeFormFormSectionsData(form: FormModel) {
    const form_sections_QueryBuilder = new FormSectionQueryBuilder();

    form_sections_QueryBuilder.where('form_id', '=', form.id).remove().subscribe(
      () => {
        //
      },
      () => {
        //
      }
    );
  }

  removeFormSamplesData(form: FormModel) {
    const samples_QueryBuilder = new SampleQueryBuilder();

    samples_QueryBuilder.where('form_id', '=', form.id).remove().subscribe(
      () => {
        //
      },
      () => {
        //
      }
    );
  }

  removeFormFormAnswersData(form: FormModel) {
    const form_answers_QueryBuilder = new FormAnswerQueryBuilder();

    form_answers_QueryBuilder.where('form_id', '=', form.id).remove().subscribe(
      () => {
        //
      },
      () => {
        //
      }
    );
  }

  chunk(array: any[], size: number): any[] {
    const chunked_arr = [];

    let index = 0;

    while (index < array.length) {
      chunked_arr.push(array.slice(index, size + index));
      index += size;
    }

    return chunked_arr;
  }
}
