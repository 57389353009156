import { QueryBuilder } from './query-builder.class';
import { SurveyJob } from '../entities/survey_job.entity';
import { from, Observable } from 'rxjs';
import { SurveyJob as SurveyJobModel } from '../../survey-jobs/models/survey-job.model';
import { Project } from '../../projects/models/project.model';
import { Client } from '../../clients/models/client.model';
import { JsonSerializer } from './serializer.class';
import { Survey } from '../../surveys/models/survey.model';

/**
 * Survey Job Entity Query Builder.
 */
export class SurveyJobQueryBuilder extends QueryBuilder {
  constructor(migrate = false) {
    super(new SurveyJob(), migrate);
  }

  findWithIncludes(id: number): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const data: SurveyJobModel = await this.find(this._table, id);
        const client: Client = await this.find('clients', data.client_id, 'hashed_id');
        const project: Project = await this.find('projects', data.project_id, 'hashed_id');
        const surveys: Survey[] = await this.loadItemRelationship('surveys', 'survey_job_id', data.id);

        if (client) {
          data.client = {
            data: client,
          };
        }

        if (project) {
          data.project = {
            data: project,
          };
        }

        if (surveys) {
          data.surveys = {
            data: surveys,
          };
        }

        resolve(JsonSerializer.item(data));
      })
    );
  }

  getWithProjectsClients(sorting: string = 'id'): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const orderQuery = this.buildOderByQuery(sorting);
        const query = `SELECT * FROM survey_jobs ${orderQuery}`;
        const response = await this._query(query);
        let data: SurveyJobModel[] = this._filterQueryToArray(response);

        const projects: Project[] = await this.loadCollectionRelationships('projects', 'hashed_id', 'project_id', data);
        const clients: Client[] = await this.loadCollectionRelationships('clients', 'hashed_id', 'client_id', data);

        data = data.map(entry => {
          const childProject = projects.find(project => project.hashed_id === entry.project_id);
          const childClient = clients.find(client => client.hashed_id === entry.client_id);

          if (childProject) {
            entry.project = {
              data: childProject
            };
          }

          if (childClient) {
            entry.client = {
              data: childClient
            };
          }

          return entry;
        });

        return resolve(JsonSerializer.collection(data));
      })
    );
  }

  buildOderByQuery(sorting) {
    let direction: string;

    if (!sorting) {
      direction = 'ASC';
      sorting = 'id';
    } else if (sorting.charAt(0) === '-') {
      direction = 'ASC';
      sorting = sorting.replace('-', '');
    } else {
      direction = 'DESC';
    }

    return `ORDER BY ${sorting} ${direction}`;
  }
}
