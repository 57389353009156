import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from '../../shared/services/api.service';
import { Observable } from 'rxjs';
import { Parameter } from '../../shared/models/parameter.model';
import { ComponentTemplateResponse, ComponentTemplatesResponse } from '../responses/component-template.response';
import { ComponentTemplate } from '../models/component-template.model';
import { ComponentTemplateQueryBuilder } from '../../shared/classes/component_template.query-builder.class';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ComponentTemplatesService {
  private resource = 'component_templates';
  private queryBuilder: ComponentTemplateQueryBuilder;

  constructor(private http: HttpClient) {
    if (environment.cordova) {
      this.queryBuilder = new ComponentTemplateQueryBuilder();
    }
  }

  /**
   * Get all Component Templates.
   */
  getAllComponentTemplates(
    limit: number = 15,
    includes: string[] = null,
    sorting: string = null,
    appends: string[] = null,
    parameters: Parameter[] = [],
  ): Observable<ComponentTemplatesResponse> {
    let params = new HttpParams();

    params = params.set('limit', limit.toFixed(0));

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    if (appends) {
      params = params.set('append', appends.join(','));
    }

    if (sorting) {
      params = params.set('sort', sorting);
    }

    parameters.forEach(parameter => {
      if (parameter.value) {
        params = params.set(`filter[${parameter.field}]`, `${parameter.value}`);
      }
    });

    return !environment.cordova
      ? this.http.get<ComponentTemplatesResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params })
      : this.queryBuilder.getWithIncludes(sorting, includes);
  }

  /**
   * Find Component Template by id.
   */
  findComponentTemplateById(componentTemplateId: string, includes: string[] = null): Observable<ComponentTemplateResponse> {
    let params = new HttpParams();

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    return this.http.get<ComponentTemplateResponse>(`${ApiService.ApiUrl}/${this.resource}/${componentTemplateId}`, { params });
  }

  /**
   * Create new Component Template.
   */
  createComponentTemplate(componentTemplate: ComponentTemplate): Observable<ComponentTemplateResponse> {
    return this.http.post<ComponentTemplateResponse>(`${ApiService.ApiUrl}/${this.resource}`, componentTemplate);
  }

  /**
   * Update Component Template.
   */
  updateComponentTemplate(componentTemplate: ComponentTemplate): Observable<ComponentTemplateResponse> {
    return this.http.patch<ComponentTemplateResponse>(`${ApiService.ApiUrl}/${this.resource}/${componentTemplate.id}`, componentTemplate);
  }

  /**
   * Delete Component Template.
   */
  deleteComponentTemplate(componentTemplate: ComponentTemplate): Observable<any> {
    return this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${componentTemplate.id}`);
  }
}
