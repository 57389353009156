<form action="">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Update Site
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div [formGroup]="formGroup" class="modal-body">
    <div class="form-group">
      <label for="name">
        Name
      </label>
      <input
          [formControl]="formGroup.controls['name']"
          [ngClass]="{'is-invalid': !formGroup.controls['name'].valid && formGroup.controls['name'].touched}"
          class="form-control"
          id="name"
          name="name"
          type="text">
      <div class="help-block text-danger"
           *ngIf="formGroup.controls['name'].touched && formGroup.controls['name'].hasError('required')">
        * This field is required.
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" [disabled]="!formGroup.valid || postingSite" (click)="onSubmit()">
      <span *ngIf="!postingSite">
        Update
      </span>
      <span *ngIf="postingSite">
        <i class="fa fa-spin fa-spinner mr-1"></i>
        Updating
      </span>
    </button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
  </div>
</form>
