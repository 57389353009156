import { FormAnswer } from '../entities/form_answer.entity';
import { QueryBuilder } from './query-builder.class';

/**
 * Form Answer Entity Query Builder.
 */
export class FormAnswerQueryBuilder extends QueryBuilder {
  constructor(migrate = false) {
    super(new FormAnswer(), migrate);
  }
}
