import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'printText' })
export class PrintTextPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    return value.replace(/(\\r\\n)|([\r\n])/gim, '<br>');
  }
}
