import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RolesIndexComponent } from './components/roles-index/roles-index.component';
import { RolesCreateComponent } from './components/roles-create/roles-create.component';
import { RolesUpdateComponent } from './components/roles-update/roles-update.component';
import { RoleGuard } from '../shared/guards/role.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'index'
  },
  {
    path: 'index',
    component: RolesIndexComponent,
    canActivate: [RoleGuard],
    data: {
      roles: ['admin']
    }
  },
  {
    path: 'create',
    component: RolesCreateComponent,
    canActivate: [RoleGuard],
    data: {
      roles: ['admin']
    }
  },
  {
    path: 'update/:role_id',
    component: RolesUpdateComponent,
    canActivate: [RoleGuard],
    data: {
      roles: ['admin']
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RolesRoutingModule {}
