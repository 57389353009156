import { Form } from '../models/form.model';
import { FormTemplateSection } from '../models/form-template-section.model';
import { FormHelpers } from './form-helpers.class';

/**
 * Certificate of Reoccupation Helper Class.
 */
export class CertificateOfReoccupationSectionRules {
  /**
   * B.1 - Inspection Details
   */
  public static certificate_of_reoccupation_b1(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b2', action: 'createOrLoad' };
  }

  /**
   * B.2 - Stages to Complete
   */
  public static certificate_of_reoccupation_b2(form: Form, section: FormTemplateSection) {
    return {};
  }

  /**
   * B.3 - Stage 1 of 4 Preliminary Sites Check
   */
  public static certificate_of_reoccupation_b3(form: Form, section: FormTemplateSection) {
    const stage_success = FormHelpers.findSectionQuestionByJsId(section, 'stage_success');

    return stage_success.answer.value === 2
      ? { nextSectionJsRule: 'failed' }
      : { nextSectionJsRule: 'certificate_of_reoccupation_b2', action: 'createOrLoad' };
  }

  /**
   * B.4 - Stage 2 of 4 - Thorough Visual Inspection
   */
  public static certificate_of_reoccupation_b4(form: Form, section: FormTemplateSection) {
    const stage_success = FormHelpers.findSectionQuestionByJsId(section, 'stage_success');

    return stage_success.answer.value === 2
      ? { nextSectionJsRule: 'failed' }
      : { nextSectionJsRule: 'certificate_of_reoccupation_b2', action: 'createOrLoad' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - B.5.1 - Pre Sampling Conditions
   */
  public static certificate_of_reoccupation_b5_1(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b5_2' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - B.5.2 - Number of samples
   */
  public static certificate_of_reoccupation_b5_2(form: Form, section: FormTemplateSection) {
    const air_testing_to_be_conducted = FormHelpers.findSectionQuestionByJsId(section, 'air_testing_to_be_conducted');
    // "Yes": 1, "No": 2
    if (+air_testing_to_be_conducted.answer.value === 1) {
      return { nextSectionJsRule: 'certificate_of_reoccupation_b5_3' };
    }

    return { nextSectionJsRule: 'certificate_of_reoccupation_b2', action: 'createOrLoad' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - B.5.2B - Samples Table
   */
  public static certificate_of_reoccupation_b5_2b(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b5_3' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - B.5.3 - Temp & Pressure
   */
  public static certificate_of_reoccupation_b5_3(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b5_5' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - B.5.4 - Sample Details
   */
  public static certificate_of_reoccupation_b5_4(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b5_6' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - B.5.5 - Microscope Set Up
   */
  public static certificate_of_reoccupation_b5_5(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b5_2b', action: 'load' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - B.5.6 - Sample Analysis Prep
   */
  public static certificate_of_reoccupation_b5_6(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b5_7' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - B.5.7 - Analysis of samples
   */
  public static certificate_of_reoccupation_b5_7(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b5_2b', action: 'load', task: 'clear_active_sample' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - New Sample (2)
   */
  public static certificate_of_reoccupation_b5_new_sample(form: Form, section: FormTemplateSection) {
    const prepare_new_samples = FormHelpers.findSectionQuestionByJsId(section, 'prepare_new_samples');
    // Yes: 1, No: 2
    return prepare_new_samples.answer.value === 1
      ? { nextSectionJsRule: 'certificate_of_reoccupation_b5_2b' }
      : { nextSectionJsRule: 'certificate_of_reoccupation_b5_summary' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - Field Blank Count
   */
  public static certificate_of_reoccupation_b5_field_blank_count(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b5_summary' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - Summary
   */
  public static certificate_of_reoccupation_b5_summary(form: Form, section: FormTemplateSection) {
    const stage_success = FormHelpers.findSectionQuestionByJsId(section, 'stage_success');

    return stage_success.answer.value === 2
      ? { nextSectionJsRule: 'failed' }
      : { nextSectionJsRule: 'certificate_of_reoccupation_b2', action: 'createOrLoad' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - Desktop Review (Office Only)
   */
  public static certificate_of_reoccupation_b5_desktop_review(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b2', action: 'createOrLoad' };
  }

  /**
   * B.6 - Stage 4 of 4 FINAL ASSESSMENT
   */
  public static certificate_of_reoccupation_b6(form: Form, section: FormTemplateSection) {
    const stage_success = FormHelpers.findSectionQuestionByJsId(section, 'stage_success');

    return stage_success.answer.value === 2
      ? { nextSectionJsRule: 'failed' }
      : { nextSectionJsRule: 'certificate_of_reoccupation_b2', action: 'createOrLoad' };
  }

  /**
   * Desktop Review (Office Only)
   */
  public static certificate_of_reoccupation_desktop_review(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'completed' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - Microscope Set Up Button
   */
  public static certificate_of_reoccupation_microscope_set_up_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b5_5', action: 'load' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - Samples Done Button
   */
  public static certificate_of_reoccupation_samples_done_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b5_field_blank_count', action: 'load' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - Start Sample Button
   */
  public static certificate_of_reoccupation_sample_start_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b5_4', action: 'load' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - Collect Sample Button
   */
  public static certificate_of_reoccupation_sample_collect_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b5_4', action: 'load' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - Prepare Sample Button
   */
  public static certificate_of_reoccupation_sample_prepare_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b5_6', action: 'load' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - Analyse Sample Button
   */
  public static certificate_of_reoccupation_sample_analyse_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b5_7', action: 'load' };
  }

  /**
   * B.5 - Stage 3 - Clearance Air Testing - Samples Table Button
   */
  public static certificate_of_reoccupation_samples_table_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b5_2b', action: 'load' };
  }

  /**
   * Completed all stages
   */
  public static certificate_of_reoccupation_stages_done_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'completed' };
  }

  /**
   * Stage 1 button
   */
  public static certificate_of_reoccupation_stage_1_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b3', action: 'createOrLoad' };
  }

  /**
   * Stage 2 button
   */
  public static certificate_of_reoccupation_stage_2_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b4', action: 'createOrLoad' };
  }

  /**
   * Stage 3 button
   */
  public static certificate_of_reoccupation_stage_3_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b5_1', action: 'createOrLoad' };
  }

  /**
   * Stage 4 button
   */
  public static certificate_of_reoccupation_stage_4_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b6', action: 'createOrLoad' };
  }

  /**
   * Stages selection button
   */
  public static certificate_of_reoccupation_stages_selection_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'certificate_of_reoccupation_b2', action: 'load' };
  }
}
