import { Injectable } from '@angular/core';

@Injectable()
export class NetworkService {
  public connected = true;

  constructor() {}

  /**
   * Sets connection status
   *
   * @param value
   */
  public set connection(value: boolean) {
    this.connected = value;
  }
}
