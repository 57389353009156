import { QueryBuilder } from './query-builder.class';
import { ComponentTemplate } from '../entities/component_template.entity';
import { from, Observable } from 'rxjs';
import { JsonSerializer } from './serializer.class';
import { ComponentTemplate as ComponentTemplateModel } from '../../component-templates/models/component-template.model';
import { Material } from '../models/material.model';

/**
 * Site Entity Query Builder.
 */
export class ComponentTemplateQueryBuilder extends QueryBuilder {
  constructor() {
    super(new ComponentTemplate());
  }

  getWithIncludes(sorting: string = 'id', includes: string[] = []): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const orderQuery = `ORDER BY ${sorting} ASC`;
        const query = `SELECT * FROM ${this._table} ${orderQuery}`;
        const response = await this._query(query);
        let data: ComponentTemplateModel[] = this._filterQueryToArray(response);

        if (data && includes && includes.includes('materials')) {
          const materials: Material[] = await this.loadCollectionRelationships('materials', 'component_template_id', 'hashed_id', data);

          data = data.map(entry => {
            const childMaterials: Material[] = materials.filter(material => material.component_template_id === entry.hashed_id);

            if (childMaterials) {
              entry.materials = {
                data: childMaterials
              };
            }

            return entry;
          });
        }

        return resolve(JsonSerializer.collection(data));
      })
    );
  }
}
