export enum DataTypeEnum {
  STRING = 1,
  TEXT,
  NUMBER,
  DATE,
  TIME,
  RADIO_BUTTON,
  GPS,
  SIGNATURE,
  PHOTO,
  DRAWING,
  DROP_DOWN,
  SUMMARY,
  CHECKBOXES
}
