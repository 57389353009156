import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../shared/services/api.service';
import { NavController } from "@ionic/angular";
import { BusService } from '../../../../modules/shared/services/bus.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  credentials: FormGroup;
  rememberCredentials = false;
  loggingIn = false;
  isMobile = false;
  mode = 'default';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private snotifyService: SnotifyService,
    public navController: NavController,
    public busService: BusService
  ) {
    this.isMobile = environment.cordova;
    if (this.isMobile) {
      this.rememberCredentials = true;
    }

    this.initCredentialsForm();
  }

  ngOnInit() {
    this.mode = ApiService.getAppMode();
  }

  initCredentialsForm() {
    this.credentials = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  onLogin() {
    this.loggingIn = true;
    const payload = {
      email: this.credentials.controls['email'].value,
      password: this.credentials.controls['password'].value,
      remember: this.rememberCredentials
    };

    this.authService.login(payload).subscribe(
      response => {

        this.authService.getAuthenticatedUserProfile().subscribe(
          user => {
            this.loggingIn = false;

            AuthService.setUserResponse(user);

            this.busService.dispatch({ type: 'logged_in' });

            if (response && response !== '/auth/login') {
              this.navController.navigateRoot([response]);
            }

            this.navController.navigateRoot(['/dashboard']);
          },
          error => {
            this.loggingIn = false;
          }
         );

      },
      error => {
        this.loggingIn = false;

        if (error && error.error && error.error.message) {
          this.snotifyService.error(error.error.message, 'Error!');
        } else {
          this.snotifyService.error('Unable to Login.', 'Error!');
        }
      }
    );
  }

  toggleApi() {
    this.mode = ApiService.getAppMode() === 'default' ? 'testing' : 'default';
    ApiService.setAppMode(this.mode);
  }
}
