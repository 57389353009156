import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../auth/models/user.model';
import { Role } from '../../../roles/models/role.model';
import { SnotifyService } from 'ng-snotify';
import { UserResponse, UsersResponse } from '../../../auth/responses/user.response';
import { UsersService } from '../../services/users.service';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../../roles/services/authorization.service';
import { RolesResponse } from '../../../roles/responses/role.response';
import { AppHelper } from '../../../shared/classes/app.helper';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-users-create',
  templateUrl: './users-create.component.html',
  styleUrls: ['./users-create.component.scss']
})
export class UsersCreateComponent implements OnInit, OnDestroy {
  public subscriptions = new Subscription();
  public userFormGroup: FormGroup;
  private user: User;
  public roles: Role[];
  public postingUser = false;
  public gettingRoles = false;
  public syncingUserRoles = false;
  public codeUsers: User[] = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private snotifyService: SnotifyService,
    private usersService: UsersService,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
    this.initUserForm();
    this.getAllRoles();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initUserForm() {
    this.userFormGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      code: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      job_title: [''],
      roles: [[], [Validators.required]]
    });

    this.subscriptions.add(
      this.userFormGroup.get('code').valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(value => {
        this.codeUsers = [];

        if (value) {
          this.validateUserCode();
        }
      })
    );
  }

  onSubmit() {
    this.postingUser = true;
    const payload = this.userFormGroup.value;

    this.usersService.createUser(payload).subscribe(
      (response: UserResponse) => {
        this.postingUser = false;
        this.snotifyService.success('A new user has been created.', 'Success!');
        this.user = response.data;
        AppHelper.chime();
        return this.router.navigate(['/users/index']);
      },
      () => {
        this.postingUser = false;
        this.snotifyService.error('Unable to create user. Please try again.', 'Error!');
      }
    );
  }

  validateUserCode() {
    const code = this.userFormGroup.get('code').value;

    this.usersService.getUsersByCode(code).subscribe(
      (response: UsersResponse) => {
        this.codeUsers = response.data;
      },
      () => {

      }
    );
  }

  getAllRoles() {
    this.gettingRoles = true;

    this.authorizationService.getAllRoles().subscribe(
      (response: RolesResponse) => {
        this.gettingRoles = false;
        this.roles = response.data;
      },
      () => {
        this.gettingRoles = false;
        this.snotifyService.error('Unable to load user roles. Please try again.', 'Error!');
      }
    );
  }
}
