import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormResponse } from '../responses/form.response';
import { FormSectionQueryBuilder } from '../../shared/classes/form_section.query-builder.class';
import { ApiService } from '../../shared/services/api.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class FormSectionsService {
  private resource = 'form_sections';
  private queryBuilder: FormSectionQueryBuilder;

  constructor(private http: HttpClient) {
    this.queryBuilder = new FormSectionQueryBuilder();
  }

  /**
   * Create new form section by form template section js_rule field.
   * @param formSection
   * @param includes
   * @returns {Observable<Object>}
   */
  createNextFormSection(formSection, includes: string[] = null): Observable<FormResponse> {
    let params = new HttpParams();

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    return !environment.cordova
      ? this.http.post<FormResponse>(`${ApiService.ApiUrl}/${this.resource}/next`, formSection, { params })
      : this.queryBuilder.createNextFormSection(formSection);
  }

  /**
   * Sync form section.
   * @param payload
   * @returns {Observable<Object>}
   */
  syncFormSection(payload): Observable<any> {
    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/sync`, payload);
  }
}
