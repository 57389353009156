import { FormTemplateSection } from '../models/form-template-section.model';
import { FormHelpers } from './form-helpers.class';
import { Form } from '../models/form.model';

/**
 * Air Test - Soils Helper Class.
 */
export class AirTestSoilsSectionRules {
  /**
   * Air Test - Soils - Inspection Details
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_inspection_details(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_soils_temp_n_pressure' };
  }

  /**
   * Air Test - Soils - Samples Table
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_samples_table(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_soils_temp_n_pressure' };
  }

  /**
   * Air Test - Soils - Temp & Pressure
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_temp_n_pressure(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_soils_microscope_set_up' };
  }

  /**
   * Air Test - Soils - Sample Details
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_sample_details(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_soils_sample_analysis_prep' };
  }

  /**
   * Air Test - Soils - Microscope Set Up
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_microscope_set_up(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_soils_samples_table', action: 'load' };
  }

  /**
   * Air Test - Soils - Sample Analysis Prep
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_sample_analysis_prep(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_soils_analysis_of_samples' };
  }

  /**
   * Air Test - Soils - Analysis of samples
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_analysis_of_samples(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_soils_samples_table', action: 'load', task: 'clear_active_sample' };
  }

  /**
   * Air Test - Soils - New Sample (2)
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_new_sample(form: Form, section: FormTemplateSection) {
    const prepare_new_samples = FormHelpers.findSectionQuestionByJsId(section, 'prepare_new_samples');
    // Yes: 1, No: 2
    return prepare_new_samples.answer.value === 1
      ? { nextSectionJsRule: 'air_test_soils_samples_table' }
      : { nextSectionJsRule: 'air_test_soils_summary' };
  }

  /**
   * Air Test - Soils - Field Blank Count
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_field_blank_count(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_soils_summary' };
  }

  /**
   * Air Test - Soils - Summary
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string, role: string}}
   */
  public static air_test_soils_summary(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_soils_desktop_review', role: 'reviewer' };
  }

  /**
   * Air Test - Soils - Section List
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string, role: string}}
   */
  public static air_test_soils_section_list(form: Form, section: FormTemplateSection) {
    const next_section = FormHelpers.findSectionQuestionByJsId(section, 'next_section');

    switch (+next_section.answer.value) {
      case 1:
        return { nextSectionJsRule: 'air_test_soils_inspection_details' };
      case 2:
        return { nextSectionJsRule: 'air_test_soils_number_of_samples' };
      case 3:
        return { nextSectionJsRule: 'air_test_soils_temp_n_pressure' };
      case 4:
        return { nextSectionJsRule: 'air_test_soils_sample_details' };
      case 5:
        return { nextSectionJsRule: 'air_test_soils_microscope_set_up' };
      case 6:
        return { nextSectionJsRule: 'air_test_soils_sample_analysis_prep' };
      case 7:
        return { nextSectionJsRule: 'air_test_soils_analysis_of_samples' };
      case 8:
        return { nextSectionJsRule: 'air_test_soils_new_sample' };
      case 9:
        return { nextSectionJsRule: 'air_test_soils_summary' };
      default:
        return { nextSectionJsRule: 'air_test_soils_inspection_details' };
    }
  }

  /**
   * Air Test - Soils - Desktop Review (Office Only)
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_desktop_review(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'completed' };
  }

  /**
   * Air Test - Soils - Microscope Set Up Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_microscope_set_up_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_soils_microscope_set_up', action: 'load' };
  }

  /**
   * Air Test - Soils - Samples Done Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_samples_done_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_soils_field_blank_count', action: 'load' };
  }

  /**
   * Air Test - Soils - Start Sample Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_sample_start_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_soils_sample_details', action: 'load' };
  }

  /**
   * Air Test - Soils - Collect Sample Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_sample_collect_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_soils_sample_details', action: 'load' };
  }

  /**
   * Air Test - Soils - Prepare Sample Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_sample_prepare_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_soils_sample_analysis_prep', action: 'load' };
  }

  /**
   * Air Test - Soils - Analyse Sample Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_sample_analyse_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_soils_analysis_of_samples', action: 'load' };
  }

  /**
   * Air Test - Soils - Samples Table Button
   * @param form
   * @param section
   * @returns {{nextSectionJsRule: string}}
   */
  public static air_test_soils_samples_table_button(form: Form, section: FormTemplateSection) {
    return { nextSectionJsRule: 'air_test_soils_samples_table', action: 'load' };
  }
}
