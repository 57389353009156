import { BaseModel } from '../classes/query-builder.class';

export class TempAssignment extends BaseModel {
  public table: string = 'temp_assignments';
  public schema: Object = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    project_id: 'INTEGER',
    number: 'VARCHAR'
  };
}
