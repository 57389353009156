import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ServiceM8CompanyResponse } from '../responses/servicem8company.response';
import { AuthService } from '../../auth/services/auth.service';
import { ServiceM8JobActivitiesResponse } from '../responses/servicem8jobActivity.response';
import { Client } from '../../clients/models/client.model';

@Injectable()
export class Servicem8Service {
  private resource = 'servicem8';

  constructor(private http: HttpClient, private authService: AuthService) {}

  /**
   * Find ServiceM8 job by number.
   */
  findServiceM8JobByNumber(num: string): Observable<{client: Client, job_address: string}> {
    return this.http.get<{client: Client, job_address: string}>(`${environment.api_url}/${this.resource}/jobs/${num}`);
  }

  /**
   * Find ServiceM8 staff by email.
   */
  findServiceM8StaffByEmail(email: string): Observable<ServiceM8CompanyResponse> {
    return this.http.get<ServiceM8CompanyResponse>(`${environment.api_url}/users/${this.resource}/staff/${email}`);
  }

  /**
   * Find ServiceM8 company/user by name.
   */
  findServiceM8CompanyByName(name: string): Observable<ServiceM8CompanyResponse> {
    return this.http.get<ServiceM8CompanyResponse>(`${environment.api_url}/users/${this.resource}/company/${name}`);
  }

  /**
   * Get all ServiceM8 jobs for a specific company_uuid.
   */
  getAllServiceM8JobActivities(): Observable<ServiceM8JobActivitiesResponse> {
    const uuid = this.authService.user.profile.servicem8_staff_uuid;

    return this.http.get<ServiceM8JobActivitiesResponse>(`${environment.api_url}/jobActivities/${this.resource}/${uuid}`);
  }
}
