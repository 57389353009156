import {Component, HostListener, OnDestroy} from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Network } from '@ionic-native/network/ngx';
import { NetworkService } from './modules/shared/services/network.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { environment } from '../environments/environment';
import { AuthService } from './modules/auth/services/auth.service';
import { User } from './modules/auth/classes/user.class';
import { ActivatedRoute } from '@angular/router';
import { BusService } from './modules/shared/services/bus.service';
import { Subscription } from 'rxjs';

import * as Sentry from '@sentry/browser';
import { AppHelper } from './modules/shared/classes/app.helper';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnDestroy {
  private onConnectSubscription: any;
  private onDisconnectSubscription: any;
  public version;
  private userResponse = AuthService.getUserResponse();
  public user: User;
  public subscription: any;
  public environment;
  public subscriptions: Subscription = new Subscription();

  @HostListener('window:click', ['$event.target'])
  onClick(targetElement: string) {
    // AppHelper.plink();
  }

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private network: Network,
    private networkService: NetworkService,
    private appVersion: AppVersion,
    private authService: AuthService,
    private route: ActivatedRoute,
    private busService: BusService
  ) {
    this.environment = environment;
    this.version = environment.version;

    this.initializeApp();
  }

  initSentry() {
    if (environment.production) {
      Sentry.init({
        dsn: 'https://e6ed91e1c95e4f528c3e89ca97283b83@sentry.io/1250684',
        release: this.version,
      });
    }
  }

  ngOnDestroy() {
    this.onConnectSubscription.unsubscribe();
    this.onDisconnectSubscription.unsubscribe();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.onDisconnectSubscription = this.network.onDisconnect().subscribe(() => {
        this.networkService.connected = false;
      });

      this.onConnectSubscription = this.network.onConnect().subscribe(() => {
        this.networkService.connected = true;
      });

      this.setUserResponse();
      this.subscriptions.add(
        this.busService.listen('logged_in').subscribe(event => {
          this.setUserResponse();
        })
      );

      this.subscriptions.add(
        this.busService.listen('logged_out').subscribe(event => {
          this.user = null;
        })
      );

      this.initSentry();
    });
  }

  setUserResponse() {
    this.userResponse = AuthService.getUserResponse();
    if (this.userResponse) {
      this.user = new User(this.userResponse.data);
      this.authService.user = this.user;
    }
  }
}
