/**
 * Forms module config file.
 */
export class FormConfig {
  /**
   * These values need to
   * trigger on the fly calculations.
   */
  public static calculatable = [
    /**
     * Air Test
     */
    'A.6.2', // Finish Time
    'A.6.3', // Finish Flow Rate
    'A.7.3', // Filter Occluded
    'A.7.4', // Fibers Counted
    'A.7.5', // Fields

    /**
     * Certificate of Reoccupation
     */
    'B.5.6.2', // Finish Time
    'B.5.6.3', // Finish Flow Rate
    'B.5.7.3', // Filter Occluded
    'B.5.7.4', // Fibers Counted
    'B.5.7.5', // Fields

    /**
     * Air Test - Personal
     */
    'C.6.2', // Finish Time
    'C.6.3', // Finish Flow Rate
    'C.7.3', // Filter Occluded
    'C.7.4', // Fibers Counted
    'C.7.5', // Fields

    /**
     * Air Test - DCU
     */
    'D.7.2', // Finish Time
    'D.7.3', // Finish Flow Rate
    'D.8.3', // Filter Occluded
    'D.8.4', // Fibers Counted
    'D.8.5', // Fields
  ];

  /**
   * Which fields should be triggered.
   */
  public static calculatableRelations = {
    /**
     * Air Test
     */
    'A.6.3': [ // Finish Flow Rate
      'A.6.4' // Actual Flow Rate
    ],
    'A.7.3': [ // Occluded
      'A.7.6' // Result
    ],
    'A.7.4': [ // Fibers Counted
      'A.7.6' // Result
    ],
    'A.7.5': [ // Fields
      'A.7.6' // Result
    ],

    /**
     * Certificate of Reoccupation
     */
    'B.5.6.3': [ // Finish Flow Rate
      'B.5.6.4' // Actual Flow Rate
    ],
    'B.5.7.3': [ // Occluded
      'B.5.7.6' // Result
    ],
    'B.5.7.4': [ // Fibers Counted
      'B.5.7.6' // Result
    ],
    'B.5.7.5': [ // Fields
      'B.5.7.6' // Result
    ],

    /**
     * Air Test - Personal
     */
    'C.6.2': [ // Finish Time
      'C.6.4', // Litreage
    ],
    'C.6.3': [ // Finish Flow Rate
      'C.6.4', // Litreage
    ],
    'C.7.3': [ // Occluded
      'C.7.6' // Result
    ],
    'C.7.4': [ // Fibers Counted
      'C.7.6' // Result
    ],
    'C.7.5': [ // Fields
      'C.7.6' // Result
    ],

    /**
     * Air Test - DCU
     */
    'D.7.3': [ // Finish Flow Rate
      'D.7.4' // Actual Flow Rate
    ],
    'D.8.3': [ // Occluded
      'D.8.6' // Result
    ],
    'D.8.4': [ // Fibers Counted
      'D.8.6' // Result
    ],
    'D.8.5': [ // Fields
      'D.8.6' // Result
    ],
  };

  /**
   * The actual flow rate values of these numbers should be calculated.
   */
  public static calculatedFlowRate = [
    'A.6.4',
    'B.5.6.4',
    'D.7.4',
  ];

  /**
   * The result values of these numbers should be calculated.
   */
  public static calculatedResult = [
    'A.7.6',
    'B.5.7.6',
    'C.7.6',
    'D.8.6',
  ];

  /**
   * The litreage values of these numbers should be calculated.
   */
  public static calculatedLitreage = [
    'C.6.4',
  ];

  /**
   * The time weighted average of results of these numbers should be calculated.
   */
  public static calculatedTimeWeightedAVG = [
    'C.8.2',
  ];

  /**
   * The pooled average of results of these numbers should be calculated.
   */
  public static calculatedPooledAVG = [
    'C.8.3',
  ];

  /**
   * These question numbers trigger hide relations.
   * @type {[string,string,string]}
   */
  public static hideable = [
    'B.6.1',
    'B.6.4',
    'B.6.6',
  ];

  /**
   * Related question that is hidden when parent change is triggered.
   */
  public static hideableRelations = {
    'B.6.1': [
      'B.6.2',
    ],
    'B.6.4': [
      'B.6.5',
    ],
    'B.6.6': [
      'B.6.7',
    ],
  };

  /**
   * These questions should listen to events to be hidden/shown.
   * @type {[string,string,string]}
   */
  public static hidden = [
    'B.6.2',
    'B.6.5',
    'B.6.7',
  ];

  /**
   * Related hidden questions that should listen to events to be hidden/shown.
   * @type {[string,string,string]}
   */
  public static hiddenRelation = {
    'B.6.2': 'B.6.1',
    'B.6.5': 'B.6.4',
    'B.6.7': 'B.6.6',
  };

  /**
   * Certificates correlations.
   */
  public static certificates = {
    air_test: {
      samples: {
        start_time: 'A.4.9',
        finish_time: 'A.6.2',
        start_flow_rate: 'A.4.10',
        finish_flow_rate: 'A.6.3',
        occluded: 'A.7.3',
        fields: 'A.7.5',
        fibers_counted: 'A.7.4',
        field_blank_require_counting: 'A.7.7',
      },
      sections: {
        graticule_diameter: 'A.5.4',
        exposed_filter: 'A.5.5',
        field_blank_count: 'A.7.8',
      },
    },

    air_test_personal: {
      samples: {
        start_time: 'C.4.15',
        finish_time: 'C.6.2',
        start_flow_rate: 'C.4.16',
        finish_flow_rate: 'C.6.3',
        occluded: 'C.7.3',
        fields: 'C.7.5',
        fibers_counted: 'C.7.4',
        field_blank_require_counting: 'C.7.7',
      },
      sections: {
        graticule_diameter: 'C.5.4',
        exposed_filter: 'C.5.5',
        field_blank_count: 'C.7.8',
      },
    },

    air_test_dcu: {
      samples: {
        start_time: 'D.5.9',
        finish_time: 'D.7.2',
        start_flow_rate: 'D.5.10',
        finish_flow_rate: 'D.7.3',
        occluded: 'D.8.3',
        fields: 'D.8.5',
        fibers_counted: 'D.8.4',
        field_blank_require_counting: 'D.8.7',
      },
      sections: {
        graticule_diameter: 'D.6.4',
        exposed_filter: 'D.6.5',
        field_blank_count: 'D.8.8',
      },
    },

    certificate_of_reoccupation: {
      samples: {
        start_time: 'B.5.4.7',
        finish_time: 'B.5.6.2',
        start_flow_rate: 'B.5.4.8',
        finish_flow_rate: 'B.5.6.3',
        occluded: 'B.5.7.3',
        fields: 'B.5.7.5',
        fibers_counted: 'B.5.7.4',
        field_blank_require_counting: 'B.5.7.7',
      },
      sections: {
        graticule_diameter: 'B.5.5.4',
        exposed_filter: 'B.5.5.5',
        field_blank_count: 'B.5.7.8',
      },
    },
  };
}
