import { Component, OnDestroy, OnInit } from '@angular/core';
import { Form } from '../../models/form.model';
import { FormTemplate } from '../../models/form-template.model';
import { DataTypeEnum } from '../../../shared/enums/data-type.enum';
import { ActivatedRoute } from '@angular/router';
import { FormsService } from '../../services/forms.service';
import { SnotifyService } from 'ng-snotify';
import { FormHelpers } from '../../classes/form-helpers.class';
import { FormTemplateSection } from '../../models/form-template-section.model';
import { FormTemplateQuestion } from '../../models/form-template-question.model';
import { FormResponse } from '../../responses/form.response';
import { FormAnswersResponse } from '../../responses/form-answer.response';
import { FormAnswersService } from '../../services/form-answers.service';
import { FormSection } from '../../models/form-section.model';
import { UsersService } from '../../../users/services/users.service';
import { UsersResponse } from '../../../auth/responses/user.response';
import { User } from '../../../auth/models/user.model';
import { forkJoin, Subscription } from 'rxjs';
import { Sample } from '../../models/sample.model';
import { Inventory } from '../../../inventory/models/inventory.model';
import { Job } from '../../../jobs/models/job.model';
import { JobsUpdateComponent } from '../../../jobs/components/jobs-update/jobs-update.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import {AppHelper} from "../../../shared/classes/app.helper";
import { Site } from "../../../shared/models/site.model";

@Component({
  selector: 'app-forms-update',
  templateUrl: './forms-update.component.html',
  styleUrls: ['./forms-update.component.scss']
})
export class FormsUpdateComponent implements OnInit, OnDestroy {
  public form: Form;
  public inventories: Inventory[];
  public users: User[];
  private form_id: string;
  private paramsSubscription: Subscription;
  public loadingResources = false;
  public data_types = DataTypeEnum;
  public activeSectionId: string;
  public fieldValidation = FormHelpers.fieldValidation;
  public initQuestionsWithOptions = FormHelpers.initQuestionsWithOptions;
  private bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private formsService: FormsService,
    private usersService: UsersService,
    private snotifyService: SnotifyService,
    private formAnswersService: FormAnswersService
  ) {}

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe(params => {
      this.form_id = params['form_id'];
      this.loadResources();
    });
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }

  loadResources() {
    this.loadingResources = true;

    const includes = [
      'site',
      'assignment',
      'assignment.users',
      'assignment.client',
      'user',
      'form_sections',
      'form_sections.form_template_section',
      'form_sections.form_template_section.form_template_questions',
      'form_sections.form_template_section.form_template_questions.form_template_question_options',
      'form_sections.form_answers',
      'samples.form_sections',
      'samples.form_sections.form_template_section',
      'samples.form_sections.form_template_section.form_template_questions',
      'samples.form_sections.form_template_section.form_template_questions.form_template_question_options',
      'samples.form_sections.form_answers',
      'inventories',
    ];
    const findFormByIdRequest = this.formsService.findFormById(this.form_id, includes);
    const limit = 0;
    const getAllUsers = this.usersService.getAllUsers(limit);

    forkJoin([findFormByIdRequest, getAllUsers]).subscribe(
      responses => {
        const findFormByIdResponse: FormResponse = responses[0];
        this.form = findFormByIdResponse.data;
        this.inventories = this.form.inventories.data;
        this.initFormSections();
        this.activeSectionId = this.form.form_sections.data[0].id;
        this.passFormAnswersToQuestions();
        this.passFormSamplesAnswersToQuestions();

        const getAllUsersResponse: UsersResponse = responses[1];
        this.users = getAllUsersResponse.data;

        this.loadingResources = false;
      },
      () => {
        this.loadingResources = false;
        this.snotifyService.error('Unable to load resources. Please try again.', 'Error!');
      }
    );
  }

  updateFormUser() {
    this.form.updating = true;

    this.formsService.updateForm(this.form).subscribe(
      () => {
        this.form.updating = false;
      },
      () => {
        this.form.updating = false;
      }
    );
  }

  initFormSections() {
    this.form.form_sections.data = this.form.form_sections.data.map((section: FormSection) => {
      section.form_template_section.data = this.initQuestionsWithOptions(section.form_template_section.data);

      return section;
    });
  }

  passFormAnswersToQuestions() {
    this.form.form_sections.data = this.form.form_sections.data.map((section: FormSection) => {
      section.form_template_section.data.form_template_questions.data = section.form_template_section.data.form_template_questions.data.map(
        (question: FormTemplateQuestion) => {
          return this.passAnswerToQuestion(section, question);
        }
      );

      return section;
    });
  }

  passFormSamplesAnswersToQuestions() {
    this.form.samples.data = this.form.samples.data.map((sample: Sample) => {
      sample.form_sections.data = sample.form_sections.data.map((section: FormSection) => {
        section.form_template_section.data.form_template_questions.data = section.form_template_section.data.form_template_questions.data.map(
          (question: FormTemplateQuestion) => {
            return this.passAnswerToQuestion(section, question);
          }
        );

        return section;
      });

      return sample;
    });
  }

  passAnswerToQuestion(section: FormSection, question: FormTemplateQuestion) {
    const answer = section.form_answers.data.find(element => element.form_template_question_id === question.id);

    if (answer) {
      question.answer = answer;
    }

    if (answer && [DataTypeEnum['NUMBER'], DataTypeEnum['RADIO_BUTTON']].includes(question.input_type)) {
      question.answer.value = +answer.value;
    }

    if (answer && [DataTypeEnum['DROP_DOWN']].includes(question.input_type)) {
      question.answer.value = answer.value;
    }

    if (answer && answer.value && [DataTypeEnum['DRAWING'], DataTypeEnum['PHOTO']].includes(question.input_type)) {
      question.answer.value = answer.value.split('|');
    }

    if (answer && answer.value && [DataTypeEnum['SIGNATURE']].includes(question.input_type)) {
      question.answer.value = answer.value;
    }

    if (answer && answer.comment) {
      question.skipped = true;
    }

    if (!answer && question.form_template_question_options.data.length > 0) {
      question.answer.value = question.form_template_question_options.data[0].option_value;
    }

    if (question.js_id === 'sample_number') {
      section.form_template_section.data.title += ` - Sample Number: ${question.answer.value}`;
    }

    return question;
  }

  onSectionHeaderClick(section) {
    this.activeSectionId = this.activeSectionId === section.id ? null : section.id;

    setTimeout(() => {
      document.getElementById(`${section.id}`).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    }, 100);
  }

  onSectionSave(section: FormTemplateSection) {
    section.saving = true;
    const answers = this.createMultipleAnswersPayload(section);

    this.formAnswersService.createMultipleFormAnswers(answers).subscribe(
      (response: FormAnswersResponse) => {
        section.saving = false;
        this.snotifyService.success('The form section has been successfully updated.', 'Success!');
        AppHelper.chime();
      },
      () => {
        section.saving = false;
        section.submitting = false;
        this.snotifyService.error('Unable to store form answers. Please try again.', 'Error!');
      }
    );
  }

  createMultipleAnswersPayload(section: FormSection) {
    const answers = [];

    section.form_template_section.data.form_template_questions.data.forEach((question: FormTemplateQuestion) => {
      // If a signature is not updated don't submit it!
      if (question.input_type === DataTypeEnum['SIGNATURE'] && question.answer.signature) {
        return;
      }

      let questionAnswerValue;

      if (Array.isArray(question.answer.value)) {
        questionAnswerValue = question.answer.value.join('|');
      } else {
        questionAnswerValue = !(question.answer.value === null || question.answer.value === undefined) ? question.answer.value.toString() : null;
      }

      answers.push({
        id: question.answer.id,
        form_id: this.form_id,
        form_section_id: section.id,
        form_template_question_id: question.id,
        value: questionAnswerValue,
        comment: question.answer.comment,
        input_type: question.input_type
      });
    });

    return answers;
  }

  onUpdateJob(job: Job) {
    const initialState = {
      job,
      redirect: false
    };

    this.bsModalRef = this.modalService.show(JobsUpdateComponent, { initialState });
    this.bsModalRef.content.onModalHide.subscribe(() => {
      this.loadResources();
    });
  }

  setSelectedSite(site: Site) {
    this.form.site.data = site;
    this.form.site_id = site.id;
    this.updateFormUser();
  }
}
