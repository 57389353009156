<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Create User</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li class="breadcrumb-item">
      <a [routerLink]="['/users/index']" routerDirection="backward">Users</a>
    </li>

    <li class="breadcrumb-item active">
      <span>Create</span>
    </li>
  </ol>
  <div class="card animated fadeIn">
    <div class="card-header">
      <strong>Create</strong> User
    </div>
    <form action="" method="post">
      <div [formGroup]="userFormGroup" class="card-body">
        <div class="form-group">
          <label for="name">
            Full Name
          </label>
          <input
              [formControl]="userFormGroup.controls['name']"
              [ngClass]="{'is-invalid': !userFormGroup.controls['name'].valid && userFormGroup.controls['name'].touched}"
              class="form-control"
              id="name"
              name="name"
              placeholder="Enter Fullname"
              type="text">
          <div class="help-block text-danger"
               *ngIf="userFormGroup.controls['name'].touched && userFormGroup.controls['name'].hasError('required')">
            * This field is required.
          </div>
        </div>

        <div class="form-group">
          <label for="email">
            Email
          </label>
          <input
              [formControl]="userFormGroup.controls['email']"
              [ngClass]="{'is-invalid': !userFormGroup.controls['email'].valid && userFormGroup.controls['email'].touched}"
              class="form-control"
              id="email"
              name="email"
              placeholder="Enter Email Address"
              type="email">
          <div class="help-block text-danger"
               *ngIf="userFormGroup.controls['email'].touched && userFormGroup.controls['email'].hasError('required')">
            * This field is required.
          </div>
          <div class="help-block text-danger"
               *ngIf="userFormGroup.controls['email'].touched && userFormGroup.controls['email'].hasError('email')">
            * Please use a valid email address.
          </div>
        </div>

        <div class="form-group">
          <label for="code">
            Code
          </label>
          <input
              [formControl]="userFormGroup.controls['code']"
              [ngClass]="{'is-invalid': !userFormGroup.controls['code'].valid && userFormGroup.controls['code'].touched}"
              class="form-control"
              id="code"
              name="code"
              placeholder="Enter User Code"
              type="text">
          <div class="help-block text-danger"
               *ngIf="userFormGroup.controls['code'].touched && userFormGroup.controls['code'].hasError('required')">
            * This field is required.
          </div>
          <div class="help-block text-warning"
               *ngIf="codeUsers.length > 0">
            * This code has been assigned to another user.
          </div>
        </div>

        <div class="form-group">
          <label for="job_title">
            Job Title
          </label>
          <input
              [formControl]="userFormGroup.controls['job_title']"
              [ngClass]="{'is-invalid': !userFormGroup.controls['job_title'].valid && userFormGroup.controls['job_title'].touched}"
              class="form-control"
              id="job_title"
              name="job_title"
              placeholder="Enter Job Title"
              type="text">
        </div>

        <div class="form-group">
          <label>
            Role
          </label>
          <ngx-select
              class="form-control"
              [ngClass]="{'is-invalid': !userFormGroup.controls['roles'].valid && userFormGroup.controls['roles'].touched}"
              [items]="roles"
              [formControl]="userFormGroup.controls['roles']"
              [optionValueField]="'id'"
              [multiple]="true"
              optionTextField="display_name"></ngx-select>
          <div class="help-block text-danger"
               *ngIf="userFormGroup.controls['roles'].touched && userFormGroup.controls['roles'].hasError('required')">
            * This field is required.
          </div>
        </div>
      </div>

      <div class="card-footer">
        <button
            (click)="onSubmit()"
            [disabled]="!userFormGroup.valid"
            class="btn btn-sm btn-primary"
            type="submit">
          <span *ngIf="!postingUser">
            <i class="fa fa-save mr-1"></i>
            Create
          </span>
          <span *ngIf="postingUser">
            <i class="fa fa-spin fa-spinner mr-1"></i>
            Creating
          </span>
        </button>
      </div>
    </form>
  </div>
</ion-content>
