<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Uploader</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" [queryParams]="{ app: 'surveying' }">Dashboard</a>
    </li>
  </ol>

  <div class="animated fadeIn card card-accent-primary">
    <div class="card-header">
      <strong>Uploads</strong> list
    </div>

    <div class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      Please check your 4G signal strength before syncing.
    </div>

    <div *ngIf="surveys?.length === 0" class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      No uploads pending.
    </div>

    <div *ngIf="surveys?.length > 0" class="table-responsive">
      <table class="table table-striped mb-0">
        <ng-container *ngFor="let survey of surveys">
          <tr>
            <td>{{ surveyTypeEnum[survey.survey_type] | replaceUnderscoreWithSpace }}</td>
            <td>Completed at: {{ survey.completed_at }}</td>
            <td class="text-right" *ngIf="survey.synced_at && survey.id !== syncingSurveyId"><span class="badge badge-success">Survey Uploaded</span></td>
            <td class="text-right" *ngIf="!survey.synced_at && survey.id !== syncingSurveyId"><span class="badge badge-secondary">Survey Pending Upload</span></td>
            <td class="text-right" *ngIf="survey.id === syncingSurveyId"><span class="badge badge-primary"><i class="fa fa-spin fa-spinner mr-1"></i> Survey Uploading</span></td>
            <td class="text-right">
              <button
                  *ngIf="!survey.synced_at"
                  [disabled]="syncingSurveyId && survey.id !== syncingSurveyId"
                  (click)="sync(survey)"
                  class="btn btn-sm btn-primary">
                <i class="fa fa-rocket mr-1"></i>
                Sync
              </button>
              <button
                  *ngIf="!survey.synced_at"
                  [disabled]="syncingSurveyId && survey.id !== syncingSurveyId"
                  (click)="markAsSynced(survey)"
                  class="btn btn-sm btn-secondary">
                <i class="fa fa-check mr-1"></i>
                Mark as synced
              </button>
            </td>
          </tr>
          <tr *ngIf="activeAssignment && survey.id === syncingSurveyId">
            <td colspan="7">
              <h6 class="font-weight-bold">Syncing details</h6>
              <ul class="mb-0">
                <li *ngIf="activeAssignment"><span class="font-weight-bold">Job number:</span> {{ activeAssignment.number }}</li>
                <li *ngIf="activeSurvey"><span class="font-weight-bold">Survey:</span> {{ surveyTypeEnum[activeSurvey.survey_type] | replaceUnderscoreWithSpace }}</li>
                <li *ngIf="activeBuilding"><span class="font-weight-bold">Building:</span> {{ activeBuilding.name }}</li>
                <li *ngIf="activeFloor"><span class="font-weight-bold">Floor:</span> {{ getFloorName(activeFloor) }}</li>
                <li *ngIf="activeArea"><span class="font-weight-bold">Area:</span> {{ activeArea.description }}</li>
                <li *ngIf="activeComponent"><span class="font-weight-bold">Component:</span> {{ activeComponent.text }}</li>
                <li *ngIf="activeSurveySample"><span class="font-weight-bold">Survey sample:</span> {{ activeSurveySample.number }}</li>
              </ul>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
</ion-content>
