import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { FormTemplateQuestion } from '../../../forms/models/form-template-question.model';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements OnInit, AfterViewInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  @ViewChild('signatureContainer') public signatureContainer: ElementRef;
  @Output() onSignatureDrawn = new EventEmitter<FormTemplateQuestion>();
  @Input() question: FormTemplateQuestion;
  public signaturePadOptions: Object = {
    canvasWidth: 400,
    canvasHeight: 200
  };

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.size();

    if (this.question.answer.value) {
      this.signaturePad.fromDataURL(this.question.answer.value);
    }
  }

  drawComplete() {
    this.question.answer.value = this.signaturePad.toDataURL();
    this.onSignatureDrawn.emit(this.question);
  }

  clearSignature() {
    this.question.answer.value = null;
    this.signaturePad.clear();
  }

  public size() {
    this.signaturePad.set('canvasWidth', this.signatureContainer.nativeElement.clientWidth);
    this.signaturePad.set('canvasHeight', this.signatureContainer.nativeElement.clientHeight);

    if (this.question.answer.value) {
      this.signaturePad.fromDataURL(this.question.answer.value);
    }
  }
}
