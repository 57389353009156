import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserResponse } from '../responses/user.response';
import { User } from '../classes/user.class';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    const token = AuthService.getToken();
    const refresh_token = AuthService.getRefreshToken();
    const userResponse: UserResponse = AuthService.getUserResponse();

    if (userResponse) {
      this.authService.user = new User(userResponse.data);
    }

    if (token && refresh_token || userResponse) {
      return true;
    }

    // Store the attempted URL for redirecting
    this.authService.redirectUrl = url;

    // Log the user out - Remove token
    AuthService.removeAllTokens();

    this.router.navigate(['/auth/login']);

    return false;
  }
}
