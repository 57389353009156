import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../modules/auth/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { User } from '../../modules/auth/classes/user.class';
import { SyncService } from '../../modules/shared/services/sync.service';
import { environment } from '../../../environments/environment';
import { BusService } from '../../modules/shared/services/bus.service';
import { Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { UserMenuPopoverComponent } from '../../modules/shared/components/user-menu-popover/user-menu-popover.component';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html'
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  public user;
  private subscriptions: Subscription = new Subscription();
  public environment = environment;
  public popover: any;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private syncService: SyncService,
    private busService: BusService,
    public popoverController: PopoverController
  ) {}

  ngOnInit() {
    this.user = new User(this.authService.user);

    this.subscriptions.add(
      this.busService.listen('dismissUserMenu').subscribe(event => {
        if (this.popover) {
          this.popover.dismiss();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async presentPopover(ev: any) {
    this.popover = await this.popoverController.create({
      component: UserMenuPopoverComponent,
      event: ev,
      translucent: true
    });

    return this.popover.present();
  }
}
