<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>zampa</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="col-md-12">
    <div class="mb-0">
      <div class="p-4">
        <form *ngIf="!submitted" (ngSubmit)="onSubmit()">
          <div class="card-body">
            <h1>Forgot password?</h1>
            <p class="text-muted">Submit your email address to reset your password</p>
            <div [formGroup]="credentials">
              <div class="form-group mb-3">
                <input
                    [formControl]="credentials.controls['email']"
                    [ngClass]="{'is-invalid': !credentials.controls['email'].valid && credentials.controls['email'].touched}"
                    type="text"
                    class="form-control"
                    placeholder="Email address">
                <div class="help-block text-danger"
                     *ngIf="credentials.controls['email'].touched && credentials.controls['email'].hasError('required')">
                  * This field is required.
                </div>
                <div class="help-block text-danger"
                     *ngIf="credentials.controls['email'].touched && credentials.controls['email'].hasError('email')">
                  * Please use a valid email address.
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button
                    [disabled]="!credentials.valid || submitting"
                    type="submit"
                    class="btn btn-primary px-4">
                  <span *ngIf="!submitting">Submit</span>
                  <span *ngIf="submitting">
                    <i class="fa fa-spin fa-spinner mr-1"></i>
                    Submitting
                  </span>
                </button>

                <p class="text-muted pull-right"><a [routerLink]="['/auth/login']" [routerDirection]="'backward'">Back to Login</a></p>
              </div>
            </div>
          </div>
        </form>
        <div *ngIf="submitted" class="card-body p-4">
          <h2>Thank you!</h2>
          <p class="text-muted mb-0">You will receive a reset password email shortly.</p>
        </div>
      </div>
    </div>
  </div>
</ion-content>
