import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserResponse, UsersResponse } from '../../auth/responses/user.response';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { RoleResponse, RolesResponse } from '../responses/role.response';
import { Role } from '../models/role.model';
import { PermissionsResponse } from '../responses/permission.response';
import { AuthService } from '../../auth/services/auth.service';
import { ApiService } from '../../shared/services/api.service';

@Injectable()
export class AuthorizationService {
  private resource = 'roles';

  constructor(private http: HttpClient) { }

  /**
   * Get all roles.
   * @returns {Observable<UsersResponse>}
   */
  getAllRoles(group: string = null): Observable<RolesResponse> {
    let params;

    if (group) {
      params = new HttpParams().set('filter[group]', `${group}`);
    }

    return this.http.get<RolesResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params });
  }

  /**
   * Find role by id.
   * @returns {Observable<UsersResponse>}
   */
  findRoleById(role_id: string, includes: string[] = null): Observable<RoleResponse> {
    let params = new HttpParams();

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    return this.http.get<RoleResponse>(`${ApiService.ApiUrl}/${this.resource}/${role_id}`, { params });
  }

  /**
   * Create new user role.
   * @param role
   * @returns {Observable<Object>}
   */
  createRole(role: Role): Observable<RoleResponse> {
    const payload = {
      name: role.name,
      display_name: role.display_name,
      description: role.description,
      guard_name: 'web'
    };

    return this.http.post<RoleResponse>(`${ApiService.ApiUrl}/${this.resource}`, payload);
  }

  /**
   * Update user role.
   * @param role
   * @returns {Observable<Object>}
   */
  updateRole(role: Role): Observable<RoleResponse> {
    const payload = {
      name: role.name,
      display_name: role.display_name,
      description: role.description,
      guard_name: 'web'
    };

    return this.http.patch<RoleResponse>(`${ApiService.ApiUrl}/${this.resource}/${role.id}`, payload);
  }

  /**
   * Sync user roles.
   * @param user_id
   * @param roles_ids
   * @returns {Observable<Object>}
   */
  syncUserRoles(user_id: string, roles_ids: string[]): Observable<UserResponse> {
    const payload = {
      user_id,
      roles_ids
    };

    return this.http.post<UserResponse>(`${ApiService.ApiUrl}/${this.resource}/sync`, payload);
  }

  /**
   * Delete user role.
   * @param role
   * @returns {Observable<Object>}
   */
  deleteRole(role: Role): Observable<any> {
    return this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${role.id}`);
  }

  /**
   * Get all role permissions.
   * @returns {Observable<Object>}
   */
  getAllPermissions(group: string = 'admin'): Observable<PermissionsResponse> {
    const params = new HttpParams().set('filter[group]', `${group}`);

    return this.http.get<PermissionsResponse>(`${ApiService.ApiUrl}/permissions`, { params });
  }

  /**
   * Sync role permissions.
   * @param role_id
   * @param permissions_ids
   * @returns {Observable<Object>}
   */
  syncRolePermissions(role_id: string, permissions_ids: string[]): Observable<any> {
    const payload = {
      role_id,
      permissions_ids
    };

    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/permissions/sync`, payload);
  }

}
