import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceUnderscoreWithSpace'
})
export class ReplaceUnderscoreWithSpacePipe implements PipeTransform {

  transform(value: any): any {
    if (!value) {
      return '';
    }

    return value.split('_').join(' ');
  }

}
