<ion-app>
  <ng-snotify></ng-snotify>
  <app-loading-bar></app-loading-bar>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" class="ion-menu">
      <ion-content color="dark">
        <ion-toolbar><ion-title>zampa</ion-title></ion-toolbar>

        <ion-list *ngIf="!user">
          <ion-menu-toggle auto-hide="false">
            <ion-item
              [routerDirection]="'root'"
              [routerLink]="['/auth/login']"
              color="dark"
            >
              <ion-icon slot="start" name="people"></ion-icon>
              <ion-label>
                Login
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <ion-list *ngIf="user">
          <ion-menu-toggle auto-hide="false">
            <ion-item
                [routerDirection]="'root'"
                [routerLink]="['/projects']"
                color="dark"
                *ngIf="(user.is('surveyor') || user.is('admin')) && !environment.cordova"
            >
              <ion-icon slot="start" name="folder"></ion-icon>
              <ion-label>
                Projects
              </ion-label>
            </ion-item>

            <ion-item
                [routerDirection]="'root'"
                [routerLink]="['/dashboard']"
                color="dark"
                *ngIf="(user.is('admin') || user.is('client_admin') || user.is('apple')) && !environment.cordova"
            >
              <ion-icon slot="start" name="speedometer"></ion-icon>
              <ion-label>
                Jobs
              </ion-label>
            </ion-item>

            <ion-item
                [routerDirection]="'root'"
                [routerLink]="['/dashboard']"
                [queryParams]="{ app: 'air-testing' }"
                color="dark"
                *ngIf="(user.is('technician') || user.is('surveyor') || user.is('admin')) && environment.cordova"
            >
              <ion-icon slot="start" name="speedometer"></ion-icon>
              <ion-label>
                Jobs
              </ion-label>
            </ion-item>

            <ion-item
                *ngIf="user.is('admin') || user.is('reviewer') || user.is('technician') || user.is('client_admin') || user.is('apple')"
                color="primary">
              <ion-label>Air Testing</ion-label>
            </ion-item>

            <ion-item
                [routerDirection]="'root'"
                [routerLink]="['/uploader/air-testing']"
                color="dark"
                *ngIf="(user.is('technician') || user.is('admin')) && environment.cordova"
            >
              <ion-icon slot="start" name="cloud-upload"></ion-icon>
              <ion-label>
                Uploader
              </ion-label>
            </ion-item>

            <ion-item
                [routerDirection]="'root'"
                [routerLink]="['/forms']"
                color="dark"
                *ngIf="(user.is('reviewer') || user.is('admin')) && !environment.cordova"
            >
              <ion-icon slot="start" name="clipboard"></ion-icon>
              <ion-label>
                Quality Review
              </ion-label>
            </ion-item>

            <ion-item
                *ngIf="user.is('surveyor') || user.is('admin') || user.is('reviewer')"
                color="primary">
              <ion-label>Surveying</ion-label>
            </ion-item>

            <ion-item
                [routerLink]="['/uploader/surveying']"
                color="dark"
                *ngIf="(user.is('surveyor') || user.is('admin')) && environment.cordova"
            >
              <ion-icon slot="start" name="cloud-upload"></ion-icon>
              <ion-label>
                Uploader
              </ion-label>
            </ion-item>

            <ion-item
                [routerDirection]="'root'"
                [routerLink]="['/surveys']"
                color="dark"
                *ngIf="(user.is('reviewer') || user.is('admin')) && !environment.cordova"
            >
              <ion-icon slot="start" name="clipboard"></ion-icon>
              <ion-label>
                Quality Review
              </ion-label>
            </ion-item>

            <ion-item *ngIf="!environment.cordova" color="primary">
              <ion-label>System</ion-label>
            </ion-item>

            <ion-item
                [routerDirection]="'root'"
                [routerLink]="['/inventory']"
                color="dark"
                *ngIf="(user.can('manage-inventory') || user.is('admin')) && !environment.cordova"
            >
              <ion-icon slot="start" name="pricetags"></ion-icon>
              <ion-label>
                Inventory
              </ion-label>
            </ion-item>

            <ion-item
                [routerDirection]="'root'"
                [routerLink]="['/component-templates']"
                color="dark"
                *ngIf="user.is('admin') && !environment.cordova"
            >
              <ion-icon slot="start" name="pricetags"></ion-icon>
              <ion-label>
                Survey Components
              </ion-label>
            </ion-item>

            <ion-item
                color="dark"
                *ngIf="user.is('admin') && !environment.cordova"
                [routerDirection]="'root'"
                [routerLink]="['/roles']"
            >
              <ion-icon slot="start" name="people"></ion-icon>
              <ion-label>
                Roles
              </ion-label>
            </ion-item>

            <ion-item
              color="dark"
              *ngIf="user.is('admin') && !environment.cordova"
              [routerDirection]="'root'"
              [routerLink]="['/users']"
            >
              <ion-icon slot="start" name="people"></ion-icon>
              <ion-label>
                Users
              </ion-label>
            </ion-item>

            <ion-item
              [routerDirection]="'root'"
              [routerLink]="['/clients']"
              color="dark"
              *ngIf="user.is('admin') && !environment.cordova"
            >
              <ion-icon slot="start" name="contacts"></ion-icon>
              <ion-label>
                Clients
              </ion-label>
            </ion-item>

            <ion-item
              [routerDirection]="'root'"
              [routerLink]="['/clients', user.profile.client_id, 'users']"
              color="dark"
              *ngIf="user.is('client_admin') && !environment.cordova"
            >
              <ion-icon slot="start" name="people"></ion-icon>
              <ion-label>
                User Management
              </ion-label>
            </ion-item>

            <ion-item
              color="dark"
              *ngIf="version"
            >
              <ion-label class="text-muted ion-text-center">
                Version: {{ version }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
