import { QueryBuilder } from './query-builder.class';
import { Survey as SurveyModel  } from '../../surveys/models/survey.model';
import { Survey } from '../entities/survey.entity';
import { from, Observable } from 'rxjs';
import { JsonSerializer } from './serializer.class';
import { Building } from '../../buildings/models/building.model';
import { AppHelper } from './app.helper';
import { Floor } from '../../floors/models/floor.model';
import { Area } from '../../areas/models/area.model';
import { ComponentModel } from '../../components/models/component.model';
import { Job } from '../../jobs/models/job.model';
import { Site } from '../models/site.model';
import { SurveySample } from '../../survey-samples/models/survey-sample.model';
import { ComponentTemplate } from '../../component-templates/models/component-template.model';
import { Project } from '../../projects/models/project.model';

/**
 * Survey Job Entity Query Builder.
 */
export class SurveyQueryBuilder extends QueryBuilder {
  constructor(migrate = false) {
    super(new Survey(), migrate);
  }

  findWithIncludes(id: number, includes: string[] = []): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const data: SurveyModel = await this.find(this._table, id);

        if (data && includes && includes.includes('buildings')) {
          let buildings: Building[] = await this.loadItemRelationship('buildings', 'survey_id', data.id);

          if (buildings) {
            const buildingsIncludes = AppHelper.getSubIncludes(includes, 'buildings.');

            if (buildingsIncludes.includes('floors')) {
              const floors: Floor[] = await this.loadCollectionRelationships('floors', 'building_id', 'id', buildings);
              const areas: Area[] = await this.loadCollectionRelationships('areas', 'floor_id', 'id', floors);
              const components: ComponentModel[] = await this.loadCollectionRelationships('components', 'area_id', 'id', areas);
              const surveySamples: SurveySample[] = await this.loadItemRelationship('survey_samples', 'survey_id', data.id);
              const componentTemplates: ComponentTemplate[] = await this
                .loadCollectionRelationships('component_templates', 'hashed_id', 'component_template_id', components);

              buildings = buildings.map(entry => {
                let childFloors: Floor[] = floors.filter(floor => +entry.id === +floor.building_id);

                if (childFloors) {
                  const floorsIncludes = AppHelper.getSubIncludes(buildingsIncludes, 'floors.');

                  if (floorsIncludes.includes('areas')) {
                    childFloors = childFloors.map(floorEntry => {
                      let childAreas = areas.filter(area => +area.floor_id === +floorEntry.id);

                      if (childAreas) {
                        const areasIncludes = AppHelper.getSubIncludes(floorsIncludes, 'areas.');

                        if (areasIncludes.includes('components')) {
                          childAreas = childAreas.map(areaEntry => {
                            let childComponents = components.filter(component => +component.area_id === +areaEntry.id);

                            if (childComponents) {
                              const componentsIncludes = AppHelper.getSubIncludes(areasIncludes, 'components.');

                              if (componentsIncludes.includes('survey_samples')) {
                                childComponents = childComponents.map(componentEntry => {
                                  const childSurveySamples = surveySamples
                                    .filter(surveySample => +surveySample.component_id === +componentEntry.id)
                                    .map(surveySample => {
                                      if (surveySample.asbestos_type) {
                                        // @ts-ignore
                                        surveySample.asbestos_type = surveySample.asbestos_type.split(',').map(e => +e);
                                      } else {
                                        surveySample.asbestos_type = [];
                                      }

                                      if (surveySample.product_type) {
                                        // @ts-ignore
                                        surveySample.product_type = surveySample.product_type.split(',').map(e => +e);
                                      } else {
                                        surveySample.product_type = [];
                                      }

                                      return surveySample;
                                    });

                                  if (childSurveySamples) {
                                    componentEntry.survey_samples = {
                                      data: childSurveySamples,
                                    };
                                  }

                                  return componentEntry;
                                });
                              }

                              if (componentsIncludes.includes('component_template')) {
                                childComponents = childComponents.map(componentEntry => {
                                  const childComponentTemplate = componentTemplates
                                    .find(componentTemplate => componentTemplate.hashed_id === componentEntry.component_template_id);

                                  componentEntry.component_template = {
                                    data: childComponentTemplate,
                                  };

                                  return componentEntry;
                                });
                              }

                              areaEntry.components = {
                                data: childComponents,
                              };
                            }

                            return areaEntry;
                          });
                        }

                        floorEntry.areas = {
                          data: childAreas,
                        };
                      }

                      return floorEntry;
                    });
                  }

                  entry.floors = {
                    data: childFloors,
                  };
                }

                return entry;
              });
            }

            data.buildings = {
              data: buildings,
            };
          }
        }

        if (data && includes && includes.includes('assignment')) {
          const assignment: Job = await this.find('assignments', 'id', data.assignment_id);

          if (assignment) {
            const assignmentIncludes = AppHelper.getSubIncludes(includes, 'assignment.');

            if (assignmentIncludes.includes('project')) {
              const project: Project = await this.find('projects', assignment.project_id, 'hashed_id');

              if (project) {
                assignment.project = {
                  data: project,
                };
              }
            }
          }

          data.assignment = {
            data: assignment
          };
        }

        if (data && includes && includes.includes('site')) {
          const site: Site = await this.find('sites', 'id', data.site_id);

          data.site = {
            data: site
          };
        }

        resolve(JsonSerializer.item(data));
      })
    );
  }

  delete(survey: SurveyModel) {
    const query = `DELETE FROM ${this._table} ${this._buildWhereQuery()}`;

    return from(
      new Promise((resolve, reject) => {
        this._query(query, []).then(
          (response: any) => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      })
    );
  }
}
