import { QueryBuilder } from './query-builder.class';
import { Component } from '../entities/component.entity';
import { from, Observable } from 'rxjs';
import { ComponentModel } from '../../components/models/component.model';
import { JsonSerializer } from './serializer.class';
import { ComponentTemplate } from '../../component-templates/models/component-template.model';
import { SurveySample } from '../../survey-samples/models/survey-sample.model';
import { Building as BuildingModel } from '../../buildings/models/building.model';

/**
 * Component Entity Query Builder.
 */
export class ComponentQueryBuilder extends QueryBuilder {
  constructor() {
    super(new Component());
  }

   findWithIncludes(id: number, includes: string[] = []): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const data: ComponentModel = await this.find(this._table, id);

        if (data && includes.includes('survey_samples')) {
          const surveySamples: SurveySample[] = await this.loadItemRelationship('survey_samples', 'component_id', data.id);

          if (surveySamples) {
            data.survey_samples = {
              data: surveySamples,
            };
          }
        }

        if (includes.includes('component_template')) {
          const componentTemplate: ComponentTemplate = await this.find('component_templates', data.component_template_id, 'hashed_id');

          if (componentTemplate) {
            data.component_template = {
              data: componentTemplate,
            };
          }
        }

        resolve(JsonSerializer.item(data));
      })
    );
  }

  delete(component: ComponentModel) {
    const query = `DELETE FROM ${this._table} ${this._buildWhereQuery()}`;
    const surveySamplesQuery = `DELETE FROM survey_samples WHERE component_id = '${component.id}'`;
    this._query(surveySamplesQuery);

    return from(
      new Promise((resolve, reject) => {
        this._query(query, []).then(
          (response: any) => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      })
    );
  }
}
