import { QueryBuilder } from './query-builder.class';
import { from, Observable } from 'rxjs';
import { JsonSerializer } from './serializer.class';
import { Form } from '../entities/form.entity';
import { FormTemplateSection } from '../entities/form_template_section.entity';
import { FormSection } from '../entities/form_section.entity';
import { FormResponse } from '../../forms/responses/form.response';
import { FormTemplateSectionResponse } from '../../forms/responses/form-template-section.response';
import { FormSectionQueryBuilder } from './form_section.query-builder.class';
import { AssignmentQueryBuilder } from './assignment.query-builder.class';
import { InventoryMessageQueryBuilder } from './inventory_message.query-builder.class';
import { FormTemplateQuestionQueryBuilder } from './form_template_question.query-builder.class';
import { FormTemplateQuestion } from '../../forms/models/form-template-question.model';
import { FormTemplateQuestionsResponse } from '../../forms/responses/form-template-question.response';
import { FormAnswerQueryBuilder } from './form_answer.query-builder.class';
import { FormSectionResponse } from '../../forms/responses/form-section.response';
import { SampleQueryBuilder } from './sample.query-builder.class';
import { SiteQueryBuilder } from "./site.query-builder.class";

/**
 * Form Entity Query Builder.
 */
export class FormQueryBuilder extends QueryBuilder {
  constructor() {
    super(new Form());
  }

  /**
   * Create a new form and the first
   * form section for it.
   */
  create(items: object): Observable<any> {
    const itemsMark = [];
    const itemsKey = [];
    const itemsValue = [];

    for (const item in items) {
      if (items.hasOwnProperty(item)) {
        itemsMark.push('?');
        itemsKey.push(item);
        itemsValue.push(items[item]);
      }
    }

    return from(
      new Promise(async (resolve, reject) => {
        const query = `INSERT INTO ${this._table} (${itemsKey.join(', ')}) VALUES (${itemsMark.join(', ')})`;
        const response = await this._query(query, itemsValue);
        const formResponse: FormResponse = JsonSerializer.item(Object.assign(items, { id: response.insertId }));

        // Get the first form template section of form template of the form
        const formTemplateSectionQueryBuilder = new QueryBuilder(new FormTemplateSection());
        const formTemplateSectionResponse: FormTemplateSectionResponse = await formTemplateSectionQueryBuilder
          .where('form_template_id', '=', formResponse.data.form_template_id)
          .first()
          .toPromise();

        // Create the first form section of the newly created form
        const formSectionsQueryBuilder = new QueryBuilder(new FormSection());
        const formSectionResponse: FormSectionResponse = await formSectionsQueryBuilder
          .create({
            form_id: formResponse.data.id.toString(),
            form_template_section_id: formTemplateSectionResponse.data.hashed_id,
            position: 1
          })
          .toPromise();

        const formTemplateQuestionsQueryBuilder = new FormTemplateQuestionQueryBuilder();
        const formTemplateQuestionsResponse: FormTemplateQuestionsResponse = await formTemplateQuestionsQueryBuilder
          .where('form_template_section_id', '=', formTemplateSectionResponse.data.hashed_id)
          .get()
          .toPromise();
        formTemplateQuestionsResponse.data.forEach(async (formTemplateQuestion: FormTemplateQuestion) => {
          const formAnswerQueryBuilder = new FormAnswerQueryBuilder();
          const formAnswer = await formAnswerQueryBuilder.create({
            form_id: formResponse.data.id.toString(),
            form_section_id: formSectionResponse.data.id.toString(),
            form_template_question_id: formTemplateQuestion.hashed_id
          });
        });

        return resolve(formResponse);
      })
    );
  }

  /**
   * Get the first resource element.
   * Includes: Assignment, FormSections
   */
  first(items: string = '*'): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const query = `SELECT ${items} FROM ${this._table} ${this._buildWhereQuery()} ${this._buildOrderByQuery()} LIMIT 1`;
        const response = await this._query(query);
        const data = this._filterQueryToArray(response);
        const formResponse: FormResponse = JsonSerializer.item(data[0]);

        const assignmentQueryBuilder = new AssignmentQueryBuilder();
        formResponse.data.assignment = await assignmentQueryBuilder
          .where('id', '=', formResponse.data.assignment_id)
          .first()
          .toPromise();

        const siteQueryBuilder = new SiteQueryBuilder();
        formResponse.data.site = await siteQueryBuilder
          .where('id', '=', formResponse.data.site_id)
          .first()
          .toPromise();

        const inventoryMessagesQueryBuilder = new InventoryMessageQueryBuilder();
        formResponse.data.inventory_messages = await inventoryMessagesQueryBuilder
          .where('form_id', '=', formResponse.data.id)
          .get()
          .toPromise();

        const formSectionQueryBuilder = new FormSectionQueryBuilder();
        formResponse.data.form_sections = await formSectionQueryBuilder
          .where('form_id', '=', formResponse.data.id)
          .whereNull('sample_id')
          .get()
          .toPromise();

        const sampleQueryBuilder = new SampleQueryBuilder();
        formResponse.data.samples = await sampleQueryBuilder
          .where('form_id', '=', formResponse.data.id)
          .get()
          .toPromise();

        return resolve(formResponse);
      })
    );
  }

  /**
   * Remove entity.
   */
  delete(form): Observable<any> {
    const form_sections_QueryBuilder = new FormSectionQueryBuilder();
    form_sections_QueryBuilder.where('form_id', '=', form.id).remove();

    const form_answers_QueryBuilder = new FormAnswerQueryBuilder();
    form_answers_QueryBuilder.where('form_id', '=', form.id).remove();

    const query = `DELETE FROM ${this._table} ${this._buildWhereQuery()}`;

    return from(
      new Promise((resolve, reject) => {
        this._query(query, []).then(
          (response: any) => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      })
    );
  }
}
