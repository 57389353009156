import { BaseModel } from '../classes/query-builder.class';

export class FormTemplateSection extends BaseModel {
  public table: string = 'form_template_sections';
  public schema: Object = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    form_template_id: 'VARCHAR',
    title: 'VARCHAR',
    description: 'TEXT',
    position: 'INTEGER',
    js_rule: 'VARCHAR',
    js_id: 'VARCHAR'
  };
}
