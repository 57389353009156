import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ApiService {

  /**
   * Persists app mode in the storage.
   *
   * @param mode
   */
  public static setAppMode(mode: string) {
    return localStorage.setItem('app_mode', mode);
  }

  /**
   * Persists app mode in the storage.
   *
   * @returns {UserResponse}
   */
  public static getAppMode() {
    const mode = localStorage.getItem('app_mode');

    return mode ? mode : 'default';
  }

  /**
   * Remove app mode data from storage.
   */
  public static removeAppMode() {
    return localStorage.removeItem('app_mode');
  }

  /**
   * Get the Api Url.
   * @constructor
   */
  public static get ApiUrl() {
    return ApiService.getAppMode() === 'testing' ? environment.api_testing_url : environment.api_url;
  }

  /**
   * Get the Api Url.
   * @constructor
   */
  public static get BaseServerUrl() {
    return ApiService.getAppMode() === 'testing' ? environment.base_testing_server_url : environment.base_server_url;
  }

  constructor() {}
}
