<label *ngIf="question.js_id !== 'sample_number'" style="display: block;">
  {{ question.text }}
</label>

<input type="hidden" [id]="id" [name]="name" class="form-control" [(ngModel)]="question.answer.value">

<div class="btn-group btn-group-lg flex-wrap" role="group">
  <button
      type="button"
      class="btn btn-outline-secondary ws-normal"
      *ngFor="let option of question.form_template_question_options.data; let first = first"
      (click)="onCheckboxChange(option)"
      [ngClass]="{'active btn-secondary': checkboxState(option)}"
      [id]="id + '_' + option.id">
    {{ option.option_text }}
  </button>
  <app-input-value-skip-button *ngIf="question.form_template_question_options.data.length === 1" [question]="question"></app-input-value-skip-button>
</div>

<div class="text-muted">
  <i class="fa fa-info mr-1"></i>
  More than one options can be selected.
</div>

<div *ngIf="question.validation === false && !question.skipped" class="text-danger">
  This field is required. Please either fill a value or press <i>Skip</i> button and enter a comment.
</div>

<app-input-comment [question]="question"></app-input-comment>

