import { Component, Input, OnInit } from '@angular/core';
import { FormTemplateQuestion } from '../../models/form-template-question.model';
import { FormHelpers } from '../../classes/form-helpers.class';

@Component({
  selector: 'app-input-value-skip-button',
  templateUrl: './input-value-skip-button.component.html',
  styleUrls: ['./input-value-skip-button.component.scss']
})
export class InputValueSkipButtonComponent implements OnInit {
  @Input('question') question: FormTemplateQuestion;
  public toggleSkipButton = FormHelpers.toggleSkipButton;

  constructor() { }

  ngOnInit() {
  }

}
