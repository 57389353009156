import { QueryBuilder } from './query-builder.class';
import { User } from '../entities/user.entity';

/**
 * User Entity Query Builder.
 */
export class UserQueryBuilder extends QueryBuilder {
  constructor() {
    super(new User());
  }
}
