import { Component, OnDestroy, OnInit } from '@angular/core';
import { Job } from '../../models/job.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Client } from '../../../clients/models/client.model';
import { BsModalRef } from 'ngx-bootstrap';
import { JobsService } from '../../services/jobs.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/services/auth.service';
import { UsersService } from '../../../users/services/users.service';
import { SnotifyService } from 'ng-snotify';
import { JobResponse } from '../../responses/job.response';
import { UsersResponse } from '../../../auth/responses/user.response';
import { User } from '../../../auth/models/user.model';
import { environment } from 'src/environments/environment';
import { Subject, Subscription } from 'rxjs';
import { ProjectsService } from '../../../projects/services/projects.service';
import { ProjectsResponse } from '../../../projects/responses/project.response';
import { Project } from '../../../projects/models/project.model';
import {AppHelper} from "../../../shared/classes/app.helper";

@Component({
  selector: 'app-jobs-update',
  templateUrl: './jobs-update.component.html',
  styleUrls: ['./jobs-update.component.scss'],
})
export class JobsUpdateComponent implements OnInit, OnDestroy {
  public jobFormGroup: FormGroup;
  public job: Job;
  public postingJob = false;
  public clients;
  public address: string;
  public client: Client;
  public gettingAllUsers = false;
  public users: User[];
  public optionValueField: string;
  public environment = environment;
  public redirect = true;
  public onModalHide: Subject<boolean> = new Subject();
  public projectsLoading: boolean;
  public projects: Project[];
  public subscriptions = new Subscription();
  public numToThreeDigits = AppHelper.numToThreeDigits;

  constructor(
    public bsModalRef: BsModalRef,
    private jobsService: JobsService,
    private router: Router,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private projectsService: ProjectsService,
    private usersService: UsersService,
    private snotifyService: SnotifyService
  ) {
    this.projectsLoading = true;
    this.optionValueField = environment.cordova ? 'hashed_id' : 'id';
  }

  ngOnInit() {
    this.initJobForm();
    this.loadProjects();

    if (this.authService.user.is('admin')) {
      this.getAllUsers();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initJobForm() {
    const userIds = [];
    this.job.users.data.forEach(user => userIds.push(user.id));

    this.jobFormGroup = this.formBuilder.group({
      user_id: [userIds, [Validators.required]],
      project_id: [{value: this.job.project_id, disabled: true}],
      client_id: [{value: this.job.client_id, disabled: true}, [Validators.required]],
      number: [this.job.number, [Validators.required]],
      client_job_number: [this.job.client_job_number],
      address: [this.job.address]
    });

    this.subscriptions.add(
      this.jobFormGroup.get('project_id').valueChanges.subscribe(projectId => {
        const project = this.projects.find(entry => entry.id === projectId);

        if (!project) {
          return;
        }

        this.jobFormGroup.get('client_id').setValue(project.client_id);
      })
    );
  }

  loadProjects() {
    const limit = 0;
    const includes = ['client'];

    this.projectsLoading = true;
    this.projectsService.getAllProjects(limit, includes).subscribe(
      (response: ProjectsResponse) => {
        this.projects = response.data;
        this.projectsLoading = false;
        this.jobFormGroup.get('project_id').enable();
      },
      () => {
        this.snotifyService.error('Unable to load Projects!', 'Error!');
        this.projectsLoading = false;
      }
    );
  }

  onSubmit() {
    this.postingJob = true;
    const payload = {
      id: this.job.id,
      ...this.jobFormGroup.getRawValue(),
      start_date: this.job.start_date
    };

    this.jobsService.updateJob(payload).subscribe(
      (response: JobResponse) => {
        this.postingJob = false;
        this.snotifyService.success('Job has been updated.', 'Success!');
        this.job = response.data;
        this.bsModalRef.hide();
        this.onModalHide.next();
        AppHelper.chime();

        if (this.redirect) {
          return this.router.navigate([`/jobs/view/${this.job.id}`]);
        }
      },
      () => {
        this.postingJob = false;
        this.snotifyService.error('Unable to update job. Please try again.', 'Error!');
      }
    );
  }

  getAllUsers() {
    this.gettingAllUsers = true;

    this.usersService.getAllTechnicianUsers(500).subscribe(
      (response: UsersResponse) => {
        this.gettingAllUsers = false;
        this.users = response.data;
      },
      () => {
        this.gettingAllUsers = false;
        this.snotifyService.error('Unable to load Users. Please try again!', 'Error!');
      }
    );
  }

}
