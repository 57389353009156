import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JobResponse, JobsResponse } from '../responses/job.response';
import * as moment from 'moment';
import { AssignmentQueryBuilder } from '../../shared/classes/assignment.query-builder.class';
import { Job } from '../models/job.model';
import { ApiService } from '../../shared/services/api.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class JobsService {
  private resource = 'assignments';
  private queryBuilder: AssignmentQueryBuilder;

  constructor(private http: HttpClient) {
    if (environment.cordova) {
      this.queryBuilder = new AssignmentQueryBuilder();
    }
  }

  /**
   * Get all Jobs.
   */
  getAllJobs(includes: string[] = null, filter: string = null, search: string = null, clientId: number = null): Observable<JobsResponse> {
    let params = new HttpParams().set('sort', '-created_at');

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    if (filter) {
      params = params.set('filter[status]', filter);
    }

    if (search) {
      params = params.set('filter[search]', search);
    }

    if (clientId) {
      params = params.set('filter[clientId]', clientId.toString());
    }

    if (environment.cordova) {
      this.queryBuilder = new AssignmentQueryBuilder();
    }

    return !environment.cordova
      ? this.http.get<JobResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params })
      : this.queryBuilder.resourceIncludes(this.resource, includes).get();
  }

  /**
   * Find job by id.
   */
  findJobById(id: string, includes: string[] = null, excludes: string[] = null, appends: string[] = null): Observable<JobResponse> {
    let params = new HttpParams();

    if (environment.cordova) {
      this.queryBuilder = new AssignmentQueryBuilder();
    }

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    if (excludes) {
      params = params.set('exclude', excludes.join(','));
    }

    if (appends) {
      params = params.set('append', appends.join(','));
    }

    return !environment.cordova
      ? this.http.get<JobResponse>(`${ApiService.ApiUrl}/${this.resource}/${id}`, { params })
      : this.queryBuilder.findWithIncludes(+id, includes);
  }

  /**
   * Find job by number.
   */
  findJobByNumber(num: string, includes: string[] = null, excludes: string[] = null): Observable<JobResponse> {
    let params = new HttpParams();

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    if (excludes) {
      params = params.set('exclude', excludes.join(','));
    }

    return this.http.get<JobResponse>(`${ApiService.ApiUrl}/${this.resource}/number/${num}`, { params });
  }

  /**
   * Create new job.
   */
  createJob(job): Observable<JobResponse> {
    const payload = {
      project_id: job.project_id ? job.project_id : null,
      user_id: job.user_id ? job.user_id : null,
      site_id: job.site_id ? job.site_id : null,
      client_id: job.client_id ? job.client_id : null,
      name: job.name,
      address: job.address,
      site_name: job.site_name,
      number: job.number,
      client_job_number: job.client_job_number,
      open: job.open ? job.open : null,
      start_date: moment().format('YYYY-MM-DD HH:mm:ss')
    };

    return !environment.cordova
      ? this.http.post<JobResponse>(`${ApiService.ApiUrl}/${this.resource}`, payload)
      : this.queryBuilder.create(payload);
  }

  /**
   * Update job.
   */
  updateJob(job): Observable<JobResponse> {
    const payload = {
      id: job.id,
      project_id: job.project_id ? job.project_id : null,
      name: job.name,
      address: job.address,
      number: job.number,
      client_job_number: job.client_job_number,
      open: job.open,
      start_date: job.start_date,
      user_id: job.user_id ? job.user_id : null,
    };

    return !environment.cordova
      ? this.http.patch<JobResponse>(`${ApiService.ApiUrl}/${this.resource}/${job.id}`, payload)
      : this.queryBuilder.update(payload);
  }

  /**
   * Get all authenticated user Jobs.
   */
  getAllAuthenticatedUserJobs(includes: string[] = null): Observable<JobsResponse> {
    let params = new HttpParams();

    if (environment.cordova) {
      this.queryBuilder = new AssignmentQueryBuilder();
    }

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    return !environment.cordova
      ? this.http.get<JobsResponse>(`${ApiService.ApiUrl}/${this.resource}/user/authenticated`, { params })
      : this.queryBuilder.getWithIncludes(includes);
  }

  /**
   * Delete Job by id.
   * @param job
   * @returns {Observable<Object>}
   */
  deleteJob(job: Job): Observable<any> {
    return this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${job.id}`);
  }

  /**
   * Sync job.
   */
  syncJob(payload): Observable<any> {
    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/sync`, payload);
  }
}
