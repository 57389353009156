<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Form Update</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li class="breadcrumb-item">
      <a [routerLink]="['/forms/index']" routerDirection="backward">Forms</a>
    </li>

    <li class="breadcrumb-item active">
      <span>Update</span>
    </li>
  </ol>

  <app-loading *ngIf="loadingResources"></app-loading>

  <div *ngIf="!loadingResources" class="row">
    <div class="col-md-3">
      <div class="card animated fadeIn">
        <div class="card-header">
          <strong>Form</strong> info
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item d-flex justify-content-between">
            <span>Template name:</span>
            <span>{{ form?.form_template_name }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between">
            <span>Job number:</span>
            <span>{{ form?.assignment_name }}</span>
          </li>
          <li class="list-group-item">
            <app-sites-search
                (siteSelected)="setSelectedSite($event)"
                [siteName]="form.site?.data?.name"
                [clientId]="form.assignment.data.client_id"></app-sites-search>
          </li>
          <li class="list-group-item">
            <label for="user">
              Technician
            </label>
            <select
                [(ngModel)]="form.user_id"
                (change)="updateFormUser()"
                id="user"
                name="user"
                title="Technician"
                class="form-control">
              <option *ngFor="let user of users" [value]="user.id">{{ user.name }}</option>
            </select>
          </li>
          <li *ngIf="form?.completed_at" class="list-group-item d-flex justify-content-between">
            <span>Completed at:</span>
            <span>{{ form?.completed_at }}</span>
          </li>
        </ul>
        <div *ngIf="form" class="card-footer border-top-0">
          <a [routerLink]="['/forms/view', form?.id]" class="btn btn-sm btn-primary float-right">
            <i class="fa fa-eye mr-1"></i>
            View
          </a>

          <button
              (click)="onUpdateJob(form.assignment.data)"
              class="btn btn-sm btn-info float-right mx-2">
            <i class="fa fa-edit mr-1"></i>
            Edit Job
          </button>
        </div>
      </div>
    </div>

    <div class="col-md-9">
      <ng-container *ngFor="let formSection of form?.form_sections.data">
        <ng-container *ngIf="formSection.form_template_section.data.js_id !== 'samples_table'">
          <ng-container *ngTemplateOutlet="sectionTemplate; context:{ $implicit: formSection, index: i }"></ng-container>
        </ng-container>

        <ng-container *ngIf="formSection.form_template_section.data.js_id === 'samples_table'">
          <ng-container *ngFor="let sample of form.samples.data; let i = index;">
            <ng-container *ngFor="let sampleSection of sample.form_sections.data">
              <ng-container *ngTemplateOutlet="sectionTemplate; context:{ $implicit: sampleSection, sample: sample, index: i }"></ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <ng-template #sectionTemplate let-section let-sample="sample" let-index="index">
    <div class="card fadeIn animated">
      <div (click)="onSectionHeaderClick(section)" class="card-header pointer">
        {{ section.form_template_section.data.title }}
        <span *ngIf="sample"> - Sample Number: {{ index + 1 }}</span>
      </div>

      <span [id]="section.id"></span>

      <div *ngIf="activeSectionId === section.id" class="card-body">
        <div *ngFor="let question of section.form_template_section.data.form_template_questions.data" class="form-group">
          <div *ngIf="question.input_type === data_types['STRING']">
            <app-input-type-string [question]="question"></app-input-type-string>
          </div>

          <div *ngIf="question.input_type === data_types['TEXT']">
            <app-input-type-text [question]="question"></app-input-type-text>
          </div>

          <div *ngIf="question.input_type === data_types['NUMBER']">
            <app-input-type-number [question]="question" [form]="form" [sample]="sample"></app-input-type-number>
          </div>

          <div *ngIf="question.input_type === data_types['DATE']">
            <app-input-type-date [question]="question"></app-input-type-date>
          </div>

          <div *ngIf="question.input_type === data_types['TIME']">
            <app-input-type-time [question]="question"></app-input-type-time>
          </div>

          <div *ngIf="question.input_type === data_types['RADIO_BUTTON']">
            <app-input-type-radio-button [question]="question"></app-input-type-radio-button>
          </div>

          <div *ngIf="question.input_type === data_types['GPS']">
            <app-input-type-gps [question]="question"></app-input-type-gps>
          </div>

          <div *ngIf="question.input_type === data_types['SIGNATURE']">
            <app-input-type-signature [question]="question"></app-input-type-signature>
          </div>

          <div *ngIf="question.input_type === data_types['PHOTO'] || question.input_type === data_types['DRAWING']">
            <app-input-type-photo [question]="question" [form]="form"></app-input-type-photo>
          </div>

          <div *ngIf="question.input_type === data_types['DROP_DOWN']">
            <app-input-type-drop-down [question]="question" [inventories]="inventories" [form]="form" [sample]="sample"></app-input-type-drop-down>
          </div>

          <div *ngIf="question.input_type === data_types['CHECKBOXES']">
            <app-input-type-checkboxes [question]="question"></app-input-type-checkboxes>
          </div>
        </div>
      </div>
      <div *ngIf="activeSectionId === section.id" class="card-footer">
        <button (click)="onSectionSave(section)" class="btn btn-sm btn-primary float-right">
          <span *ngIf="!section.saving">Save</span>
          <span *ngIf="section.saving"><i class="fa fa-spin fa-spinner mr-1"></i> Saving</span>
        </button>
      </div>
    </div>
  </ng-template>
</ion-content>
