import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from '../../auth/services/auth.service';

@Injectable()
export class PermissionGuard implements CanActivate {
  private routePermissions: string[];

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.routePermissions = route.data['permissions'];

    return this.checkPermissions();
  }

  checkPermissions(): boolean {
    if (!this.authService.user) {
      this.router.navigate(['/dashboard']);

      return false;
    }

    const userPermissions = this.authService.user.permissions();

    return userPermissions.some(userPermission => this.routePermissions.includes(userPermission.name));
  }
}
