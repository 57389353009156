<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Inventory</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li class="breadcrumb-item active">
      <span>Inventory</span>
    </li>
  </ol>

  <app-loading *ngIf="gettingAllInventories"></app-loading>

  <div *ngIf="!gettingAllInventories" class="animated fadeIn card card-accent-primary">
    <div class="card-header">
      <strong>Inventory</strong>
      <div class="float-right">
        <a [routerLink]="['/inventory/create']" class="btn btn-sm btn-primary">
          Add item
        </a>
      </div>
    </div>

    <div *ngIf="inventories?.length === 0" class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      No inventory items found.
    </div>

    <div class="table-responsive">
      <table *ngIf="inventories?.length > 0" class="table table-striped mb-0">
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Model</th>
          <th>Serial number</th>
          <th>Inventory code</th>
          <th class="text-right">Actions</th>
        </tr>

        <tr *ngFor="let inventory of inventories">
          <td>{{ inventory.name }}</td>
          <td>{{ inventory.type ? inventoryTypeEnum[inventory.type].split('_').join(' ') : '' }}</td>
          <td>{{ inventory.model }}</td>
          <td>{{ inventory.serial_number }}</td>
          <td>{{ inventory.inventory_code }}</td>
          <td class="text-right d-md-none">
            <button
                (click)="activeInventory = inventory"
                class="btn btn-secondary">
              <i class="fa fa-bars"></i>
            </button>
          </td>
          <td class="text-right d-none d-md-table-cell">
            <ng-container *ngTemplateOutlet="actions; context:{ $implicit: inventory }"></ng-container>
          </td>
        </tr>
      </table>
    </div>

    <div *ngIf="meta && !gettingAllInventories && meta.last_page > 1" class="card-footer">
      <app-pagination
          [meta]="meta"
          [links]="links"
          (updated)="updateInventoryList($event)"></app-pagination>
    </div>
  </div>

  <div *ngIf="activeInventory" class="fixed-bottom" style="z-index: 9999;">
    <div class="list-group m-2 fadeIn">
      <ng-container *ngTemplateOutlet="actions; context:{ $implicit: activeInventory }"></ng-container>
    </div>
  </div>

  <div *ngIf="activeInventory" (click)="activeInventory = null" class="modal-backdrop fade show"></div>

  <ng-template #actions let-inventory>
    <a
        (click)="activeInventory = null"
        [routerLink]="['/inventory/view', inventory.id]"
        [className]="activeInventory ? 'list-group-item list-group-item-action' : 'btn btn-sm btn-primary'">
      <i class="fa fa-eye mr-1"></i>
      View
    </a>
    <a
        (click)="activeInventory = null"
        [routerLink]="['/inventory/update', inventory.id]"
        [className]="activeInventory ? 'list-group-item list-group-item-action' : 'btn btn-sm btn-info'">
      <i class="fa fa-edit mr-1"></i>
      Edit
    </a>
    <button
        (click)="onInventoryDelete(inventory)"
        [className]="activeInventory ? 'list-group-item list-group-item-action' : 'btn btn-sm btn-danger'"
        [disabled]="inventory.deleting">
      <span *ngIf="!inventory.deleting"><i class="fa fa-trash mr-1"></i> Delete</span>
      <span *ngIf="inventory.deleting"><i class="fa fa-spin fa-spinner"></i> Deleting</span>
    </button>
  </ng-template>
</ion-content>
