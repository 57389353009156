<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Create Client User</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li class="breadcrumb-item">
      <a [routerLink]="['/clients/index']" routerDirection="backward">Clients</a>
    </li>

    <li *ngIf="client_id" class="breadcrumb-item">
      <a [routerLink]="['/clients',client_id ,'users']" routerDirection="backward">Users</a>
    </li>

    <li *ngIf="client_id" class="breadcrumb-item">
      <span>Create</span>
    </li>
  </ol>

  <div class="card animated fadeIn">
    <div class="card-header">
      <strong>Create</strong> Client User
    </div>
    <form action="" method="post">
      <div [formGroup]="userFormGroup" class="card-body">
        <div class="alert alert-info">
          Please save the user before you can limit its access to projects
        </div>
        <div class="form-group">
          <label for="name">
            Full Name
          </label>
          <input
              [formControl]="userFormGroup.controls['name']"
              [ngClass]="{'is-invalid': !userFormGroup.controls['name'].valid && userFormGroup.controls['name'].touched}"
              class="form-control"
              id="name"
              name="name"
              placeholder="Enter Fullname"
              type="text">
          <div class="help-block text-danger"
               *ngIf="userFormGroup.controls['name'].touched && userFormGroup.controls['name'].hasError('required')">
            * This field is required.
          </div>
        </div>

        <div class="form-group">
          <label for="email">
            Email
          </label>
          <input
              [formControl]="userFormGroup.controls['email']"
              [ngClass]="{'is-invalid': !userFormGroup.controls['email'].valid && userFormGroup.controls['email'].touched}"
              class="form-control"
              id="email"
              name="email"
              placeholder="Enter Email Address"
              type="email">
          <div class="help-block text-danger"
               *ngIf="userFormGroup.controls['email'].touched && userFormGroup.controls['email'].hasError('required')">
            * This field is required.
          </div>
          <div class="help-block text-danger"
               *ngIf="userFormGroup.controls['email'].touched && userFormGroup.controls['email'].hasError('email')">
            * Please use a valid email address.
          </div>
        </div>

        <div class="form-group">
          <label for="mobile">
            Mobile
          </label>
          <input
              [formControl]="userFormGroup.controls['mobile']"
              class="form-control"
              id="mobile"
              name="mobile"
              type="text">
        </div>

        <div class="form-group">
          <label for="role">
            Role
          </label>
          <select
              [formControl]="userFormGroup.controls['role']"
              [ngClass]="{'is-invalid': !userFormGroup.controls['role'].valid && userFormGroup.controls['role'].touched}"
              name="role"
              id="role"
              class="form-control">
            <option *ngFor="let role of roles;" value="{{ role.id }}">{{ role.display_name }}</option>
          </select>
          <div class="help-block text-danger"
               *ngIf="userFormGroup.controls['role'].touched && userFormGroup.controls['role'].hasError('required')">
            * This field is required.
          </div>
        </div>
      </div>

      <div class="card-footer">
        <button
            (click)="onSubmit()"
            [disabled]="!userFormGroup.valid"
            class="btn btn-sm btn-primary"
            type="submit">
        <span *ngIf="!postingUser">
          <i class="fa fa-save mr-1"></i>
          Create
        </span>
          <span *ngIf="postingUser">
          <i class="fa fa-spin fa-spinner mr-1"></i>
          Creating
        </span>
        </button>
      </div>
    </form>
  </div>
</ion-content>
