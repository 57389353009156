<form action="">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Update Floor
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div [formGroup]="formGroup" class="modal-body">
    <div class="form-group">
      <label>
        Floor Type
      </label>

      <div class="btn-group btn-group-lg flex-wrap" role="group">
        <button
            *ngFor="let button of buttons"
            (click)="formGroup.get('type').setValue(button.value)"
            [ngClass]="{'active btn-secondary': +formGroup.get('type').value === button.value}"
            type="button"
            class="btn btn-outline-secondary ws-normal">
          {{ button.name | replaceUnderscoreWithSpace }}
        </button>
      </div>
    </div>

    <div
        *ngIf="+formGroup.get('type').value === floorTypeEnum.Number"
        class="form-group">
      <label for="number">
        Number
      </label>

      <input
          [formControl]="formGroup.controls['number']"
          [ngClass]="{'is-invalid': !formGroup.controls['number'].valid && formGroup.controls['number'].touched}"
          class="form-control"
          id="number"
          name="number"
          placeholder="Enter Floor number"
          type="text">
    </div>
  </div>

  <div class="modal-footer">
    <button
        type="submit"
        class="btn btn-primary"
        [disabled]="!formGroup.valid || postingFloor"
        (click)="onSubmit()">
      <span *ngIf="!postingFloor">
        Update
      </span>
      <span *ngIf="postingFloor">
        <i class="fa fa-spin fa-spinner mr-1"></i>
        Updating
      </span>
    </button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
  </div>
</form>
