import { QueryBuilder } from './query-builder.class';
import { from, Observable } from 'rxjs';
import { JsonSerializer } from './serializer.class';
import { JobResponse, JobsResponse } from '../../jobs/responses/job.response';
import { Assignment } from '../entities/assignment.entity';
import { Job } from '../../jobs/models/job.model';
import { FormQueryBuilder } from './form.query-builder.class';
import { UserQueryBuilder } from './user.query-builder.class';
import { Project } from '../../projects/models/project.model';
import { Client } from '../../clients/models/client.model';
import { Survey } from '../../surveys/models/survey.model';
import { Form } from '../../forms/models/form.model';
import { AppHelper } from './app.helper';
import { Site } from '../models/site.model';

/**
 * Assignment Query Builder.
 */
export class AssignmentQueryBuilder extends QueryBuilder {
  constructor() {
    super(new Assignment());
  }

  /**
   * Get all assignments.
   * Includes: Sites
   */
  get(items: string = '*'): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const query = `SELECT ${items} FROM ${
          this._table
        } ${this._buildWhereQuery()} ${this._buildOrderByQuery()} ${this._buildTakeQuery()}`;
        const response = await this._query(query);
        const data = this._filterQueryToArray(response);
        const assignmentsResponse: JobsResponse = JsonSerializer.collection(data);

        await Promise.all(
          assignmentsResponse.data.map(async (assignment: Job) => {
            const userQueryBuilder = new UserQueryBuilder();
            assignment.users = await userQueryBuilder
              .where('id', '=', assignment.user_id)
              .orWhere('hashed_id', '=', assignment.user_id)
              .get()
              .toPromise();

            return assignment;
          })
        );

        return resolve(assignmentsResponse);
      })
    );
  }

  /**
   * Get the first resource element.
   * Includes: Site
   */
  first(items: string = '*'): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const query = `SELECT ${items} FROM ${this._table} ${this._buildWhereQuery()} ${this._buildOrderByQuery()} LIMIT 1`;
        const response = await this._query(query);
        const data = this._filterQueryToArray(response);
        const assignmentResponse: JobResponse = JsonSerializer.item(data[0]);
        const formQueryBuilder = new FormQueryBuilder();
        assignmentResponse.data.forms = await formQueryBuilder
          .where('assignment_id', '=', assignmentResponse.data.id)
          .get()
          .toPromise();

        return resolve(assignmentResponse);
      })
    );
  }

  findWithIncludes(id: number, includes: string[]): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const data: Job = await this.find(this._table, id);

        if (includes.includes('client')) {
          const client: Client = await this.find('clients', data.client_id, 'hashed_id');

          if (client) {
            data.client = {
              data: client,
            };
          }
        }

        if (includes.includes('project')) {
          const project: Project = await this.find('projects', data.project_id, 'hashed_id');

          if (project) {
            data.project = {
              data: project,
            };
          }
        }

        if (includes.includes('surveys')) {
          let surveys: Survey[] = await this.loadItemRelationship('surveys', 'assignment_id', data.id);

          if (surveys) {
            const surveysIncludes = AppHelper.getSubIncludes(includes, 'surveys.');

            if (surveysIncludes.includes('site')) {
              const sites: Site[] = await this.loadCollectionRelationships('sites', 'id', 'site_id', surveys);
              surveys = surveys.map(surveyEntry => {
                const site = sites
                  .find(siteEntry => +siteEntry.id === +surveyEntry.site_id);

                surveyEntry.site = {
                  data: site,
                };

                return surveyEntry;
              });
            }

            data.surveys = {
              data: surveys,
            };
          }
        }

        if (includes.includes('forms')) {
          const forms: Form[] = await this.loadItemRelationship('forms', 'assignment_id', data.id);

          if (forms) {
            data.forms = {
              data: forms,
            };
          }
        }

        resolve(JsonSerializer.item(data));
      })
    );
  }

  getWithIncludes(includes: string[], open: number = null, sorting: string = 'id'): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        let query = 'SELECT * FROM assignments';

        if (open !== null) {
          query = `${query} WHERE open = ${open}`;
        }

        const orderQuery = this.buildOderByQuery(sorting);
        query = `${query} ${orderQuery}`;
        const response = await this._query(query);
        let data: Job[] = this._filterQueryToArray(response);
        let projects: Project[] = [];
        let clients: Client[] = [];

        if (includes.includes('project')) {
          projects = await this.loadCollectionRelationships('projects', 'hashed_id', 'project_id', data);
        }

        if (includes.includes('client')) {
          clients = await this.loadCollectionRelationships('clients', 'hashed_id', 'client_id', data);
        }

        data = data.map(entry => {
          if (includes.includes('project')) {
            const childProject = projects.find(project => project.hashed_id === entry.project_id);

            if (childProject) {
              entry.project = {
                data: childProject
              };
            }
          }

          if (includes.includes('client')) {
            const childClient = clients.find(client => client.hashed_id === entry.client_id);

            if (childClient) {
              entry.client = {
                data: childClient
              };
            }
          }

          return entry;
        });

        return resolve(JsonSerializer.collection(data));
      })
    );
  }

  buildOderByQuery(sorting) {
    let direction: string;

    if (!sorting) {
      direction = 'ASC';
      sorting = 'id';
    } else if (sorting.charAt(0) === '-') {
      direction = 'ASC';
      sorting = sorting.replace('-', '');
    } else {
      direction = 'DESC';
    }

    return `ORDER BY ${sorting} ${direction}`;
  }
}
