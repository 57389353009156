import { BaseModel } from '../classes/query-builder.class';

export class FormTemplateQuestion extends BaseModel {
  public table: string = 'form_template_questions';
  public schema: Object = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    form_template_section_id: 'VARCHAR',
    number: 'VARCHAR',
    text: 'TEXT',
    input_type: 'INTEGER',
    position: 'INTEGER',
    js_id: 'VARCHAR',
    js_class: 'VARCHAR',
    pdf_cell: 'VARCHAR',
    validation_rules_b: 'VARCHAR',
    validation_rules_f: 'VARCHAR'
  };
}
