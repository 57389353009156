import { Component, OnDestroy, OnInit } from '@angular/core';
import { Survey } from '../../../surveys/models/survey.model';
import { Subscription } from 'rxjs';
import { SurveysService } from '../../../surveys/services/surveys.service';
import { SurveysResponse } from '../../../surveys/responses/survey.response';
import { SurveyTypeEnum } from '../../../shared/enums/survey-type.enum';
import { SurveyJob } from '../../../survey-jobs/models/survey-job.model';
import { BuildingQueryBuilder } from '../../../shared/classes/building.query-builder.class';
import { Building } from '../../../buildings/models/building.model';
import { BuildingsService } from '../../../buildings/services/buildings.service';
import { SurveyQueryBuilder } from '../../../shared/classes/survey.query-builder.class';
import { Floor } from '../../../floors/models/floor.model';
import { FloorsService } from '../../../floors/services/floors.service';
import { FloorQueryBuilder } from '../../../shared/classes/floor.query-builder.class';
import { Area } from '../../../areas/models/area.model';
import { AreasService } from '../../../areas/services/areas.service';
import { ComponentsService } from '../../../components/services/components.service';
import { SurveySamplesService } from '../../../survey-samples/services/survey-samples.service';
import { AreaQueryBuilder } from '../../../shared/classes/area.query-builder.class';
import { ComponentModel } from '../../../components/models/component.model';
import { ComponentQueryBuilder } from '../../../shared/classes/component.query-builder.class';
import { SurveySample } from '../../../survey-samples/models/survey-sample.model';
import { SurveySampleQueryBuilder } from '../../../shared/classes/survey_sample.query-builder.class';
import { AppHelper } from '../../../shared/classes/app.helper';
import { CascadeService } from '../../../shared/services/cascade.service';
import * as moment from 'moment';
import { JobsService } from '../../../jobs/services/jobs.service';
import { AssignmentQueryBuilder } from '../../../shared/classes/assignment.query-builder.class';
import { Job } from '../../../jobs/models/job.model';
import { SiteQueryBuilder } from '../../../shared/classes/site.query-builder.class';
import { Site } from '../../../shared/models/site.model';
import { SitesService } from '../../../sites/services/sites.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-surveying-uploader',
  templateUrl: './surveying-uploader.component.html',
  styleUrls: ['./surveying-uploader.component.scss'],
})
export class SurveyingUploaderComponent implements OnInit, OnDestroy {
  public surveys: Survey[] = [];
  public subscriptions = new Subscription();
  public surveyTypeEnum = SurveyTypeEnum;
  public gettingAllSurveys = false;
  public syncingSurveyId: string;
  public counter = 0;
  public max = 0;
  public activeAssignment: SurveyJob;
  public activeAssignmentHashedId: string;
  public activeSurvey: Survey;
  public activeSurveyHashedId: string;
  public activeBuilding: Building;
  public activeBuildingHashedId: string;
  public activeFloor: Floor;
  public activeArea: Area;
  public activeComponent: ComponentModel;
  public activeSurveySample: SurveySample;
  public getFloorName = AppHelper.getFloorName;

  constructor(
    private cascadeService: CascadeService,
    private surveysService: SurveysService,
    private jobsService: JobsService,
    private buildingsService: BuildingsService,
    private floorsService: FloorsService,
    private areasService: AreasService,
    private componentsService: ComponentsService,
    private sitesService: SitesService,
    private surveySamplesService: SurveySamplesService,
    private snotifyService: SnotifyService,
  ) {
    this.getAllUnSyncedSurveys();
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getAllUnSyncedSurveys() {
    this.gettingAllSurveys = true;

    this.subscriptions.add(
      this.surveysService.getAllUnSyncedForms().subscribe(
        (response: SurveysResponse) => {
          this.surveys = response.data;
          this.gettingAllSurveys = false;
        }
      )
    );
  }

  async sync(survey: Survey) {
    this.syncingSurveyId = survey.id;

    await this.syncSurveyJob(survey);

    const surveyQueryBuilder = new SurveyQueryBuilder();
    surveyQueryBuilder.update({
      id: survey.id,
      synced_at: moment().format('YYYY/MM/DD'),
    });

    this.surveys = this.surveys.filter(el => survey.id !== el.id);
    this.activeAssignment = null;

    this.syncingSurveyId = null;
    this.activeAssignmentHashedId = null;
    this.activeSurveyHashedId = null;
    this.activeBuildingHashedId = null;
  }

  onMarkAsSynced(survey: Survey) {
    this.snotifyService.confirm('The selected survey will be marked as synced.', 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.markAsSynced(survey),
          bold: false
        },
        { text: 'No', action: () => {} }
      ]
    });
  }

  async markAsSynced(survey: Survey) {
    const surveyQueryBuilder = new SurveyQueryBuilder();

    surveyQueryBuilder.update({
      id: survey.id,
      synced_at: moment().format('YYYY/MM/DD'),
    });

    this.surveys = this.surveys.filter(el => survey.id !== el.id);
  }

  async syncSurveyJob(survey: Survey) {
    const assignmentQueryBuilder = new AssignmentQueryBuilder();
    const assignment: Job = await assignmentQueryBuilder.find('assignments', survey.assignment_id);
    this.activeAssignment = assignment;

    const syncResponse = await this.jobsService.syncJob(assignment).toPromise();
    this.activeAssignmentHashedId = syncResponse.assignment_id;
    assignmentQueryBuilder.update({
      id: assignment.id,
      hashed_id: syncResponse.assignment_id,
    });

    survey.assignment_id = syncResponse.assignment_id;

    await this.syncSite(survey);
  }

  async syncSite(survey: Survey) {
    const siteQueryBuilder = new SiteQueryBuilder();
    const site: Site = await siteQueryBuilder.find('sites', 'id', survey.site_id);

    if (site) {
      const syncResponse = await this.sitesService.syncSite(site).toPromise();
      siteQueryBuilder.update({
        id: site.id,
        hashed_id: syncResponse.site_id,
      });

      survey.site_id = syncResponse.site_id;
    }

    await this.syncSurvey(survey);
  }

  async syncSurvey(survey: Survey) {
    this.activeSurvey = survey;
    const syncResponse = await this.surveysService.syncSurvey(survey).toPromise();
    const surveyQueryBuilder = new SurveyQueryBuilder();
    this.activeSurveyHashedId = syncResponse.survey_id;
    survey.hashed_id = syncResponse.survey_id;
    surveyQueryBuilder.update({
      id: survey.id,
      hashed_id: survey.hashed_id,
    });

    await this.syncBuildings(survey);
    this.activeSurvey = null;
    await this.cascadeService.survey(survey);
  }

  async syncBuildings(survey: Survey) {
    const surveyQueryBuilder = new SurveyQueryBuilder();
    const buildings: Building[] = await surveyQueryBuilder.loadItemRelationship('buildings', 'survey_id', survey.id);

    for (const building of buildings) {
      const payload: any = Object.assign({}, building);
      payload.survey_id = survey.hashed_id;
      await this.syncBuilding(payload);
    }
  }

  async syncBuilding(building: Building) {
    this.activeBuilding = building;
    const syncResponse = await this.buildingsService.syncBuilding(building).toPromise();

    building.hashed_id = syncResponse.building_id;
    this.activeBuildingHashedId = syncResponse.building_id;
    const buildingQueryBuilder = new BuildingQueryBuilder();
    buildingQueryBuilder.update({
      id: building.id,
      hashed_id: building.hashed_id,
    });
    await this.syncFloors(building);
    this.activeBuilding = null;
    await this.buildingsService.deleteBuilding(building).toPromise();
  }

  async syncFloors(building: Building) {
    const buildingQueryBuilder = new BuildingQueryBuilder();
    const floors: Floor[] = await buildingQueryBuilder.loadItemRelationship('floors', 'building_id', building.id);

    for (const floor of floors) {
      const payload: any = Object.assign({}, floor);
      payload.building_id = building.hashed_id;
      await this.syncFloor(payload);
    }
  }

  async syncFloor(floor: Floor) {
    this.activeFloor = floor;
    const syncResponse = await this.floorsService.syncFloor(floor).toPromise();

    floor.hashed_id = syncResponse.floor_id;
    const floorQueryBuilder = new FloorQueryBuilder();
    floorQueryBuilder.update({
      id: floor.id,
      hashed_id: floor.hashed_id,
    });
    await this.syncAreas(floor);
    this.activeFloor = null;
    await this.floorsService.deleteFloor(floor).toPromise();
  }

  async syncAreas(floor: Floor) {
    const floorQueryBuilder = new FloorQueryBuilder();
    const areas: Area[] = await floorQueryBuilder.loadItemRelationship('areas', 'floor_id', floor.id);

    for (const area of areas) {
      const payload: any = Object.assign({}, area);
      payload.floor_id = floor.hashed_id;
      payload.building_id = this.activeBuildingHashedId;
      await this.syncArea(payload);
    }
  }

  async syncArea(area: Area) {
    this.activeArea = area;
    const syncResponse = await this.areasService.syncArea(area).toPromise();

    area.hashed_id = syncResponse.area_id;
    const areaQueryBuilder = new AreaQueryBuilder();
    areaQueryBuilder.update({
      id: area.id,
      hashed_id: area.hashed_id,
    });
    await this.syncComponents(area);
    this.activeArea = null;
    await this.areasService.deleteArea(area).toPromise();
  }

  async syncComponents(area: Area) {
    const areaQueryBuilder = new AreaQueryBuilder();
    const components: ComponentModel[] = await areaQueryBuilder.loadItemRelationship('components', 'area_id', area.id);

    for (const component of components) {
      const payload: any = Object.assign({}, component);
      payload.area_id = area.hashed_id;
      await this.syncComponent(payload);
    }
  }

  async syncComponent(component: ComponentModel) {
    this.activeComponent = component;
    const syncResponse = await this.componentsService.syncComponent(component).toPromise();

    component.hashed_id = syncResponse.component_id;
    const componentQueryBuilder = new ComponentQueryBuilder();
    componentQueryBuilder.update({
      id: component.id,
      hashed_id: component.hashed_id,
    });
    await this.syncSurveySamples(component);
    this.activeComponent = null;
    await this.componentsService.deleteComponent(component).toPromise();
  }

  async syncSurveySamples(component: ComponentModel) {
    const componentQueryBuilder = new ComponentQueryBuilder();
    const surveySamples: SurveySample[] = await componentQueryBuilder.loadItemRelationship('survey_samples', 'component_id', component.id);

    for (const surveySample of surveySamples) {
      const payload: any = Object.assign({}, surveySample);
      payload.assignment_id = this.activeAssignmentHashedId;
      payload.survey_id = this.activeSurveyHashedId;
      payload.component_id = component.hashed_id;
      await this.syncSurveySample(payload);
    }
  }

  async syncSurveySample(surveySample: SurveySample) {
    this.activeSurveySample = surveySample;
    const syncResponse = await this.surveySamplesService.syncSurveySample(surveySample).toPromise();

    surveySample.hashed_id = syncResponse.survey_sample_id;
    const surveySampleQueryBuilder = new SurveySampleQueryBuilder();
    surveySampleQueryBuilder.update({
      id: surveySample.id,
      hashed_id: surveySample.hashed_id,
    });
    this.activeSurveySample = null;
    await this.surveySamplesService.deleteSurveySample(surveySample).toPromise();
  }
}
