  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item">
      <a href="javascript:" class="nav-link dropdown-toggle mr-1" (click)="presentPopover($event)">
        <i class="fa fa-user mr-1"></i>
        <span *ngIf="user" class="d-md-down-none">{{ user.profile.name }}</span>
        <span *ngIf="!user" class="d-md-down-none">
          <i class="fa fa-spin fa-spinner"></i>
        </span>
      </a>
    </li>
  </ul>
