<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Users</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li class="breadcrumb-item">
      <a [routerLink]="['/users/index']" routerDirection="backward">Users</a>
    </li>

    <li class="breadcrumb-item active">
      <span>View</span>
    </li>
  </ol>

  <app-loading *ngIf="findingUserById" [text]="'Loading user data. Please wait!'"></app-loading>

  <div *ngIf="!findingUserById" class="row">
    <div class="col-md-3">
      <div class="card animated fadeIn">
        <div class="card-header">
          <strong>User</strong>
          info
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item d-flex justify-content-between">
            <span>Name:</span>
            <span>{{ user?.name }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between">
            <span>Email:</span>
            <span>{{ user?.email }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between">
            <span>Job title:</span>
            <span>{{ user?.job_title }}</span>
          </li>
        </ul>
        <div class="card-footer">
          <div class="btn-block">
            <a
                [routerLink]="['/users/update', user.id]"
                class="btn btn-sm btn-info">
              <i class="fa fa-edit mr-1"></i>
              Edit
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-9">
      <div class="animated fadeIn card card-accent-primary">
        <div class="card-header">
          <strong>Inventory</strong>
          <div class="float-right">
            <button
                (click)="onInventoryAssign()"
                class="btn btn-sm btn-primary">
              Assign
            </button>
          </div>
        </div>

        <div *ngIf="inventories?.length === 0" class="alert alert-info mb-0">
          <i class="fa fa-info mr-1"></i>
          No inventory items found.
        </div>

        <div *ngIf="attachingInventoryUser" class="alert alert-info mb-0">
          <i class="fa fa-spin fa-spinner mr-1"></i>
          Assigning inventory item to user. Please wait!
        </div>

        <div *ngIf="gettingAllUserInventories" class="alert alert-info mb-0">
          <i class="fa fa-spin fa-spinner mr-1"></i>
          Loading inventory item assigned users. Please wait!
        </div>

        <div *ngIf="!attachingInventoryUser && !gettingAllUserInventories" class="table-responsive">
          <table *ngIf="inventories?.length > 0" class="table table-striped mb-0">
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Model</th>
              <th>Serial number</th>
              <th>Inventory code</th>
              <th>Used from</th>
              <th>Used to</th>
              <th class="text-right">Actions</th>
            </tr>

            <tr *ngFor="let inventory of inventories">
              <td>{{ inventory.name }}</td>
              <td>{{ inventory.type ? inventoryTypeEnum[inventory.type].split('_').join(' ') : '' }}</td>
              <td>{{ inventory.model }}</td>
              <td>{{ inventory.serial_number }}</td>
              <td>{{ inventory.inventory_code }}</td>
              <td>{{ inventory.pivot.used_from }}</td>
              <td>{{ inventory.pivot.used_to }}</td>
              <td class="text-right">
                <button
                    *ngIf="!inventory.pivot.used_to"
                    (click)="onInventoryDetach(inventory)"
                    [disabled]="inventory.detaching"
                    class="btn btn-warning btn-sm">
                  <span *ngIf="!inventory.detaching"><i class="fa fa-chain-broken mr-1"></i> Unassign</span>
                  <span *ngIf="inventory.detaching"><i class="fa fa-spin fa-spinner"></i> Unassigning</span>
                </button>
                <button
                    (click)="onInventoryDelete(inventory)"
                    [disabled]="inventory.deleting"
                    class="btn btn-danger btn-sm">
                  <span *ngIf="!inventory.deleting"><i class="fa fa-trash mr-1"></i> Delete</span>
                  <span *ngIf="inventory.deleting"><i class="fa fa-spin fa-spinner"></i> Deleting</span>
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div *ngIf="meta && !gettingAllUserInventories && meta.last_page > 1" class="card-footer">
          <app-pagination
              [hash]="user_id"
              [meta]="meta"
              [links]="links"
              (updated)="updateInventoryList($event)"></app-pagination>
        </div>
      </div>

      <div class="animated fadeIn card card-accent-primary">
        <div class="card-header">
          <strong>Login Attempts</strong>
        </div>

        <div *ngIf="!user.auth_attempts?.data?.length" class="alert alert-warning mb-0">
          This user doesn't have any login attempts yet.
        </div>

        <div class="table-responsive">
          <table *ngIf="user.auth_attempts?.data?.length > 0" class="table table-striped mb-0">
            <tr>
              <th>Success?</th>
              <th>IP</th>
              <th>User Agent</th>
              <th>Date</th>
            </tr>

            <tr *ngFor="let auth_attempt of user.auth_attempts.data">
              <td class="text-center">
                <i *ngIf="auth_attempt.result" class="icon-check text-success"></i>
                <i *ngIf="!auth_attempt.result" class="icon-close text-danger"></i>
              </td>
              <td>{{ auth_attempt.ip_address }}</td>
              <td>{{ auth_attempt.user_agent }}</td>
              <td>{{ auth_attempt.created_at }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</ion-content>
