<div class="form-group">
  <label for="site">Site</label>

  <div class="input-group">
    <input
        (change)="findSiteByName()"
        [(ngModel)]="siteName"
        name="site"
        id="site"
        placeholder="Search for site..."
        type="text"
        class="form-control">

    <div class="input-group-append">
      <button
          [disabled]="findingSiteByName"
          (click)="findSiteByName()"
          class="btn btn-primary">
        <i *ngIf="!findingSiteByName" class="fa fa-search"></i>
        <i *ngIf="findingSiteByName" class="fa fa-spin fa-spinner"></i>
      </button>

      <button
          (click)="onSiteCreate()"
          class="btn btn-info">
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>

  <span *ngIf="hint" class="text-info">* Address: {{ hint }}</span>

  <ul *ngIf="sites.length > 0" class="list-group mt-1">
    <li *ngFor="let site of sites"
        (click)="selectSite(site)"
        class="list-group-item pointer list-group-item-action">{{ site.name }}</li>
  </ul>
</div>
