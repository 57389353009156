import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { InventoriesResponse, InventoryResponse } from '../responses/inventory.response';
import { UsersResponse } from '../../auth/responses/user.response';
import { FormResponse, FormsResponse } from '../../forms/responses/form.response';
import { QueryBuilder } from '../../shared/classes/query-builder.class';
import { Inventory } from '../../shared/entities/inventory.entity';
import { ApiService } from '../../shared/services/api.service';

@Injectable()
export class InventoriesService {
  private resource = 'inventories';
  private queryBuilder: QueryBuilder;

  constructor(private http: HttpClient) {
    if (environment.cordova) {
      this.queryBuilder = new QueryBuilder(new Inventory());
    }
  }

  /**
   * Get all Inventories.
   * @returns {Observable<Object>}
   */
  getAllInventories(): Observable<InventoriesResponse> {
    return this.http.get<InventoriesResponse>(`${ApiService.ApiUrl}/${this.resource}`);
  }

  /**
   * Find inventory by ID.
   * @param inventory_id
   * @returns {Observable<Object>}
   */
  findInventoryById(inventory_id: string): Observable<InventoryResponse> {
    return this.http.get<InventoryResponse>(`${ApiService.ApiUrl}/${this.resource}/${inventory_id}`);
  }

  /**
   * Create a new inventory.
   * @param inventory
   * @returns {Observable<Object>}
   */
  createInventory(inventory): Observable<InventoryResponse> {
    return this.http.post<InventoryResponse>(`${ApiService.ApiUrl}/${this.resource}`, inventory);
  }

  /**
   * Update inventory by id.
   * @param inventory
   * @returns {Observable<Object>}
   */
  updateInventory(inventory): Observable<InventoryResponse> {
    return this.http.patch<InventoryResponse>(`${ApiService.ApiUrl}/${this.resource}/${inventory.id}`, inventory);
  }

  /**
   * Delete inventory by id.
   * @param inventory
   * @returns {Observable<Object>}
   */
  deleteInventory(inventory): Observable<any> {
    return this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${inventory.id}`);
  }

  /**
   * Get all Inventory users.
   * @param inventory_id
   * @returns {Observable<Object>}
   */
  getAllInventoryUsers(inventory_id: string): Observable<UsersResponse> {
    return this.http.get<UsersResponse>(`${ApiService.ApiUrl}/${this.resource}/${inventory_id}/users`);
  }

  /**
   * Attach inventory user.
   * @param inventory_id
   * @param user_id
   * @returns {Observable<any>}
   */
  attachInventoryUser(inventory_id: string, user_id: string): Observable<any> {
    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/${inventory_id}/users/${user_id}/attach`, {});
  }

  /**
   * Detach inventory user.
   * @param inventory_id
   * @param user_id
   * @param used_from
   * @returns {Observable<any>}
   */
  detachInventoryUser(inventory_id: string, user_id: string, used_from: string): Observable<any> {
    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/${inventory_id}/users/${user_id}/detach`, { used_from });
  }

  /**
   * Detach inventory user.
   * @param inventory_id
   * @param user_id
   * @param used_from
   * @returns {Observable<any>}
   */
  deleteInventoryUser(inventory_id: string, user_id: string, used_from: string): Observable<any> {
    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/${inventory_id}/users/${user_id}/delete`, { used_from });
  }

  /**
   * Get all Inventory users.
   * @param inventory_id
   * @returns {Observable<Object>}
   */
  getAllInventoryForms(inventory_id: string): Observable<FormsResponse> {
    return this.http.get<FormsResponse>(`${ApiService.ApiUrl}/${this.resource}/${inventory_id}/forms`);
  }

  /**
   * Get all user inventory items.
   * @param user_id
   * @returns {Observable<Object>}
   */
  getAllUserInventories(user_id: string): Observable<InventoriesResponse> {
    return this.http.get<InventoriesResponse>(`${ApiService.ApiUrl}/users/${user_id}/${this.resource}`);
  }

  /**
   * Get all user inventory items.
   * @returns {Observable<Object>}
   */
  getAllAuthenticatedUserInventories(): Observable<InventoriesResponse> {
    const params = new HttpParams().set('limit', '0');

    return !environment.cordova
      ? this.http.get<InventoriesResponse>(`${ApiService.ApiUrl}/user/${this.resource}`, { params })
      : this.queryBuilder.get();
  }

  /**
   * Sync inventory items with a form.
   * @param form_id
   * @param inventories_ids
   * @returns {Observable<Object>}
   */
  syncFormInventories(form_id: string, inventories_ids: string[]): Observable<FormResponse> {
    return this.http.post<FormResponse>(`${ApiService.ApiUrl}/forms/${form_id}/${this.resource}/sync`, { inventories_ids });
  }
}
