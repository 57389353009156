<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Update User</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li class="breadcrumb-item">
      <a [routerLink]="['/clients/index']" routerDirection="backward">Clients</a>
    </li>

    <li *ngIf="client_id" class="breadcrumb-item">
      <a [routerLink]="['/clients',client_id ,'users']" routerDirection="backward">Users</a>
    </li>

    <li *ngIf="client_id" class="breadcrumb-item">
      <span>Update</span>
    </li>
  </ol>

  <app-loading *ngIf="loadingRequests"></app-loading>

  <div *ngIf="!loadingRequests" class="card animated fadeIn">
    <div class="card-header">
      <strong>Update</strong> User
    </div>
    <form>
      <div [formGroup]="userFormGroup" class="card-body">
        <div class="form-group">
          <label for="name">
            Full Name
          </label>
          <input
              [formControl]="userFormGroup.controls['name']"
              [ngClass]="{'is-invalid': !userFormGroup.controls['name'].valid && userFormGroup.controls['name'].touched}"
              class="form-control"
              id="name"
              name="name"
              placeholder="Enter Fullname"
              type="text">
          <div class="help-block text-danger"
               *ngIf="userFormGroup.controls['name'].touched && userFormGroup.controls['name'].hasError('required')">
            * This field is required.
          </div>
        </div>

        <div class="form-group">
          <label for="email">
            Email
          </label>
          <input
              [formControl]="userFormGroup.controls['email']"
              [ngClass]="{'is-invalid': !userFormGroup.controls['email'].valid && userFormGroup.controls['email'].touched}"
              class="form-control"
              id="email"
              name="email"
              placeholder="Enter Email Address"
              type="email">
          <div class="help-block text-danger"
               *ngIf="userFormGroup.controls['email'].touched && userFormGroup.controls['email'].hasError('required')">
            * This field is required.
          </div>
          <div class="help-block text-danger"
               *ngIf="userFormGroup.controls['email'].touched && userFormGroup.controls['email'].hasError('email')">
            * Please use a valid email address.
          </div>
        </div>

        <div class="form-group">
          <label for="mobile">
            Mobile
          </label>
          <input
              [formControl]="userFormGroup.controls['mobile']"
              class="form-control"
              id="mobile"
              name="mobile"
              type="text">
        </div>

        <div class="form-group">
          <label for="enabled_projects_limitations" class="d-block">
            Enabled projects limitations
          </label>

          <label class="switch switch-text switch-info-outline-alt mb-0">
            <input class="switch-input" type="checkbox" id="enabled_projects_limitations" name="enabled_projects_limitations" formControlName="enabled_projects_limitations">
            <span class="switch-label"></span>
            <span class="switch-handle"></span>
          </label>
        </div>

        <app-projects-search
            *ngIf="userFormGroup.get('enabled_projects_limitations').value"
            (projectSelected)="toggleProject($event)"
            [clientId]="client_id"
            [hint]="'Search projects by title, number or client name'"></app-projects-search>

        <div *ngIf="addedProjects.length > 0" class="form-group">
          <label>Assigned projects</label>

          <div class="d-block">
            <div *ngFor="let project of addedProjects" class="badge badge-primary p-2 rounded font-weight-light font-lg mr-1 mb-1 pointer">
              <i
                  (click)="toggleProject(project)"
                  class="fa fa-times mr-1"></i>
              <a [routerLink]="['/projects/update', project.id]" class="text-white">{{ project.title }}</a>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="role">
            Role
          </label>
          <select
              [formControl]="userFormGroup.controls['role']"
              [ngClass]="{'is-invalid': !userFormGroup.controls['role'].valid && userFormGroup.controls['role'].touched}"
              name="role"
              id="role"
              class="form-control">
            <option *ngFor="let role of roles;" value="{{ role.id }}">{{ role.display_name }}</option>
          </select>
          <div class="help-block text-danger"
               *ngIf="userFormGroup.controls['role'].touched && userFormGroup.controls['role'].hasError('required')">
            * This field is required.
          </div>
        </div>

        <div class="form-group">
          <label for="password">
            Password
          </label>
          <input
              [formControl]="userFormGroup.controls['password']"
              [ngClass]="{'is-invalid': !userFormGroup.controls['password'].valid && userFormGroup.controls['password'].touched}"
              class="form-control"
              id="password"
              name="password"
              placeholder="Enter a password"
              type="password">
          <div class="help-block text-danger"
               *ngIf="userFormGroup.controls['password'].touched && userFormGroup.controls['password'].hasError('minlength')">
            * This field should have minimum 8 characters.
          </div>
        </div>

        <div class="form-group">
          <label for="password_confirmation">
            Password confirmation
          </label>
          <input
              [formControl]="userFormGroup.controls['password_confirmation']"
              [ngClass]="{'is-invalid': userFormGroup.hasError('password-mismatch')}"
              class="form-control"
              id="password_confirmation"
              name="password_confirmation"
              placeholder="Enter the same password again"
              type="password">
          <div class="help-block text-danger"
               *ngIf="userFormGroup.controls['password_confirmation'].touched && userFormGroup.controls['password_confirmation'].hasError('required')">
            * This field is required.
          </div>
          <div class="help-block text-danger"
               *ngIf="userFormGroup.controls['password_confirmation'].touched && userFormGroup.hasError('password-mismatch')">
            * The confirmation password does not match.
          </div>
        </div>
      </div>

      <div class="card-footer">
        <button
            (click)="onSubmit()"
            [disabled]="!userFormGroup.valid"
            class="btn btn-sm btn-primary">
          <span *ngIf="!updatingUser">
            <i class="fa fa-save mr-1"></i>
            Update
          </span>
          <span *ngIf="updatingUser">
            <i class="fa fa-spin fa-spinner mr-1"></i>
            Updating
          </span>
        </button>
      </div>
    </form>
  </div>
</ion-content>
