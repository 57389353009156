import { Form } from '../models/form.model';
import { SampleCalculator } from './sample-calculator.class';
import { Sample } from '../models/sample.model';
import { FormAnswer } from '../models/form-answer.model';
import { FormConfig } from './form-config.class';
import { FormHelpers } from './form-helpers.class';

/**
 * Forms module Form calculator.
 */
export class FormCalculator {
  public values: Object = {};
  public form: Form;

  constructor(form: Form) {
    this.form = form;
    this.setSectionValues();
    [this.values['total_fibers'], this.values['total_volume']] = this.totals();
    [this.values['fields'], this.values['graticule_diameter']] = this.sampleValues();
  }

  /**
   * Initialize Form Values.
   */
  private setSectionValues() {
    const sectionsNumbers = FormConfig.certificates[this.form.form_template_slug]['sections'];

    for (const key in sectionsNumbers) {
      if (sectionsNumbers.hasOwnProperty(key)) {
        const answer: FormAnswer = FormHelpers.findSectionFormAnswerByQuestionNumber(this.form, sectionsNumbers[key]);
        this.values[key] = answer ? answer.value : 0;
      }
    }
  }

  /**
   * Time weighted Calculator.
   * @returns {string}
   */
  public timeWeightedAVG() {
    let sumDelta = 0;

    this.form.samples.data.forEach((sample: Sample) => {
      const sampleCalculator = new SampleCalculator(this.form, sample);
      sumDelta += sampleCalculator.sampleTWPDelta();
    });

    return (0.25 * sumDelta).toFixed(3);
  }

  /**
   * Pooled Calculator.
   * @returns {number|string}
   */
  public poolAVG() {
    const epsilon = this.formTWPepsilon();
    const zeta = this.formTWPzeta();

    return zeta === 0 ? Number(0).toFixed(3) : (epsilon / zeta).toFixed(3);
  }

  /**
   * Sum values Calculator
   * @returns {[number,number]}
   */
  private totals() {
    let totalFibers = 0;
    let totalVolume = 0;

    this.form.samples.data.forEach((sample: Sample) => {
      const sampleCalculator = new SampleCalculator(this.form, sample);
      totalFibers += +sampleCalculator.values['fibers_counted'];
      totalVolume += +sampleCalculator.volume();
    });

    return [totalFibers, totalVolume];
  }

  /**
   * Sample values Calculator/
   * @returns {any}
   */
  private sampleValues() {
    if (!this.form.samples.data[0]) {
      return [0, 0];
    }

    const sample = this.form.samples.data[0];
    const sampleCalculator = new SampleCalculator(this.form, sample);

    return [+sampleCalculator.values['fields'], +sampleCalculator.values['graticule_diameter']];
  }

  /**
   * According to "time weighted personal calculator.xlsx" file.
   * Cell M18.
   * @returns {number}
   */
  private formTWPepsilon() {
    const total_fibers = this.values['total_fibers'];
    const exposed_filter = +this.values['exposed_filter'];

    return 1000 * total_fibers * Math.pow(exposed_filter, 2);
  }

  /**
   * According to "time weighted personal calculator.xlsx" file.
   * Cell N18.
   * @returns {number}
   */
  private formTWPzeta() {
    const total_volume = +this.values['total_volume'];
    const fields = +this.values['fields'];
    const graticule_diameter = +this.values['graticule_diameter'];

    return total_volume * fields * Math.pow(graticule_diameter, 2);
  }
}
