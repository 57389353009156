import { QueryBuilder } from './query-builder.class';
import { Client } from '../entities/client.entity';
import { from, Observable } from 'rxjs';
import { ClientsResponse } from '../../clients/responses/client.response';
import { JsonSerializer } from './serializer.class';

/**
 * Client Entity Query Builder.
 */
export class ClientQueryBuilder extends QueryBuilder {
  constructor() {
    super(new Client());
  }

  /**
   * Get all clients.
   * Includes: Sites
   */
  get(items: string = '*'): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const query = `SELECT ${items} FROM ${
          this._table
        } ${this._buildWhereQuery()} ${this._buildOrderByQuery()} ${this._buildTakeQuery()}`;
        const response = await this._query(query);
        const data = this._filterQueryToArray(response);
        const clientsResponse: ClientsResponse = JsonSerializer.collection(data);

        return resolve(clientsResponse);
      })
    );
  }
}
