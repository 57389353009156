import { Injectable } from '@angular/core';
import { Building } from '../../buildings/models/building.model';
import { Area } from '../../areas/models/area.model';
import { Floor } from '../../floors/models/floor.model';

@Injectable()
export class StateService {
  // tslint:disable-next-line:variable-name
  public _report_number: string;

  // tslint:disable-next-line:variable-name
  public _building: Building;

  // tslint:disable-next-line:variable-name
  public _area: Area;

  // tslint:disable-next-line:variable-name
  public _floor: Floor;

  private static setLocalStorageItem(key: string, item: any) {
    localStorage.setItem(key, JSON.stringify(item));
  }

  private static getLocalStorageItem(key: string) {
    const localStorageItem = localStorage.getItem(key);

    return localStorageItem
      ? JSON.parse(localStorageItem)
      : null;
  }

  constructor() {}

  get building(): Building|null {
    if (this._building) {
      return this._building;
    }

    return StateService.getLocalStorageItem('state_building');
  }

  set building(newBuilding: Building) {
    this._building = newBuilding;
    StateService.setLocalStorageItem('state_building', newBuilding);
  }

  get area(): Area|null {
    if (this._area) {
      return this._area;
    }

    return StateService.getLocalStorageItem('state_area');
  }

  set area(newArea: Area) {
    this._area = newArea;
    StateService.setLocalStorageItem('state_area', newArea);
  }

  get floor(): Floor|null {
    if (this._floor) {
      return this._floor;
    }

    return StateService.getLocalStorageItem('state_floor');
  }

  set floor(newFloor: Floor) {
    this._floor = newFloor;
    StateService.setLocalStorageItem('state_floor', newFloor);
  }

  get reportNumber(): string|null {
    if (this._report_number) {
      return this._report_number;
    }

    return StateService.getLocalStorageItem('state_report_number');
  }

  set reportNumber(newReportNumber: string) {
    this._report_number = newReportNumber;
    StateService.setLocalStorageItem('state_report_number', newReportNumber);
  }
}
