import { Component, Input, OnInit } from '@angular/core';
import { FormHelpers } from '../../classes/form-helpers.class';
import { FormTemplateQuestion } from '../../models/form-template-question.model';

@Component({
  selector: 'app-input-type-base-field',
  templateUrl: './input-type-base-field.component.html',
  styleUrls: ['./input-type-base-field.component.scss']
})
export class InputTypeBaseFieldComponent implements OnInit {
  @Input() question: FormTemplateQuestion;
  public fieldValidation = FormHelpers.fieldValidation;
  public id: string;
  public name: string;

  constructor() {}

  ngOnInit() {}
}
