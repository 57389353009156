import { QueryBuilder } from './query-builder.class';
import { FormTemplateQuestionOption } from '../entities/form_template_question_option.entity';

/**
 * Form Template Question Option Entity Query Builder.
 */
export class FormTemplateQuestionOptionQueryBuilder extends QueryBuilder {
  constructor() {
    super(new FormTemplateQuestionOption());
  }
}
