import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SurveyTypeEnum } from '../../../shared/enums/survey-type.enum';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { SurveysService } from '../../services/surveys.service';
import { AuthService } from '../../../auth/services/auth.service';
import { SurveyResponse } from '../../responses/survey.response';
import { Survey } from '../../models/survey.model';
import { AppHelper } from '../../../shared/classes/app.helper';
import { environment } from 'src/environments/environment';
import { Job } from '../../../jobs/models/job.model';

@Component({
  selector: 'app-surveys-update',
  templateUrl: './surveys-update.component.html',
  styleUrls: ['./surveys-update.component.scss'],
})
export class SurveysUpdateComponent implements OnInit {
  public job: Job;
  public survey: Survey;
  public formGroup: FormGroup;
  public postingSurvey = false;
  public surveyTypeEnum = SurveyTypeEnum;
  public onHide = new Subject();
  public optionValueField: string;
  public environment = environment;

  constructor(
    public bsModalRef: BsModalRef,
    private snotifyService: SnotifyService,
    private formBuilder: FormBuilder,
    private surveysService: SurveysService,
    public authService: AuthService,
  ) {
    this.optionValueField = environment.cordova ? 'hashed_id' : 'id';
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    const userIds = [];

    if (!environment.cordova) {
      this.survey.users.data.forEach(user => userIds.push(user.id));
    }

    this.formGroup = this.formBuilder.group({
      id: [null, [Validators.required]],
      assignment_id: [null, [Validators.required]],
      survey_users: [userIds, !environment.cordova ? [Validators.required] : null],
      survey_type: [null],
    });

    AppHelper.fillFormGroup(this.formGroup, this.survey);
  }

  onSubmit() {
    this.postingSurvey = true;
    const payload = this.formGroup.getRawValue();

    if (environment.cordova) {
      delete payload.survey_users;
      payload.assignment_id = payload.assignment_id.toString();
    }

    this.surveysService.updateSurvey(payload).subscribe(
      (response: SurveyResponse) => {
        this.postingSurvey = false;
        this.bsModalRef.hide();
        this.onHide.next(response.data);

        AppHelper.chime();
      },
      () => {
        this.postingSurvey = false;
        this.snotifyService.error('Unable to update Survey. Please try again.', 'Error!');
      },
    );
  }

}
