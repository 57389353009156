import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthInterceptor } from '../auth/interceptors/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingComponent } from './components/loading/loading.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ReplaceUnderscoreWithSpacePipe } from './pipes/replace-underscore-with-space.pipe';
import { RoleGuard } from './guards/role.guard';
import { Servicem8Service } from './services/servicem8.service';
import { ConvertServiceM8DatesFormatPipe } from './pipes/convert-service-m8-dates-format.pipe';
import { ConvertDateTimeDatesFormatPipe } from './pipes/convert-date-time-dates-format.pipe';
import { FormTemplatesService } from '../forms/services/form-templates.service';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { FormsService } from '../forms/services/forms.service';
import { FormAnswersService } from '../forms/services/form-answers.service';
import { PermissionGuard } from './guards/permission.guard';
import { PrintTextPipe } from './pipes/print-text.pipe';
import { JsonValuesComponent } from './components/json-values/json-values.component';
import { FirstLetterUppercasePipe } from './pipes/first-letter-uppercase.pipe';
import { DateObjectToStringPipe } from './pipes/date-object-to-string.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormSectionsService } from '../forms/services/form-sections.service';
import { CommentsCreateComponent } from './components/comments-create/comments-create.component';
import { CommentsService } from './services/comments.service';
import { DocumentsCreateComponent } from './components/documents-create/documents-create.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { DocumentsService } from './services/documents.service';
import { NetworkService } from './services/network.service';
import { SyncService } from './services/sync.service';
import { BusService } from './services/bus.service';
import { BsDropdownModule, TooltipModule, TypeaheadModule } from 'ngx-bootstrap';
import { SamplesService } from '../forms/services/samples.service';
import { SitesService } from '../sites/services/sites.service';
import { RouterModule } from '@angular/router';
import { JobsIndexComponent } from '../jobs/components/jobs-index/jobs-index.component';
import { PTRConfig, WeUiModule } from 'ngx-weui';
import { Platform } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AppHeaderComponent } from '../../components/app-header/app-header.component';

import { IonicModule } from '@ionic/angular';
import { UserMenuPopoverComponent } from './components/user-menu-popover/user-menu-popover.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { RevisionsService } from '../revisions/services/revisions.service';
import { NgxSelectModule } from 'ngx-select-ex';
import { SurveyJobsService } from '../survey-jobs/services/survey-jobs.service';
import { ProjectsService } from '../projects/services/projects.service';
import { SurveysService } from '../surveys/services/surveys.service';
import { TextMaskModule } from 'angular2-text-mask';
import { BuildingsService } from '../buildings/services/buildings.service';
import { FloorsService } from '../floors/services/floors.service';
import { AreasService } from '../areas/services/areas.service';
import { ComponentTemplatesService } from '../component-templates/services/component-templates.service';
import { MaterialsService } from './services/materials.service';
import { ComponentsService } from '../components/services/components.service';
import { SurveySamplesService } from '../survey-samples/services/survey-samples.service';
import { SignatureFormGroupComponent } from './components/signature-form-group/signature-form-group.component';
import { SurveyReviewsService } from '../survey-reviews/services/survey-reviews.service';
import { StateService } from './services/state.service';
import { ProjectsIndexComponent } from '../projects/components/projects-index/projects-index.component';
import { CascadeService } from './services/cascade.service';
import { SurveysIndexComponent } from '../surveys/components/surveys-index/surveys-index.component';
import { SitesUpdateModalComponent } from './components/sites-update-modal/sites-update-modal.component';
import { SitesCreateModalComponent } from './components/sites-create-modal/sites-create-modal.component';
import { SitesSearchComponent } from './components/sites-search/sites-search.component';
import { RevisionsIndexComponent } from '../revisions/components/revisions-index/revisions-index.component';
import { RevisionsCreateModalComponent } from '../revisions/components/revisions-create-modal/revisions-create-modal.component';
import { RevisionsUpdateModalComponent } from '../revisions/components/revisions-update-modal/revisions-update-modal.component';
import { BeeperDirective } from './directives/beep.directive';
import { SampleNumberComponent } from './components/sample-number/sample-number.component';
import { ScoresService } from './services/scores.service';
import { AreaCodeComponent } from './components/area-code/area-code.component';
import { ProjectsSearchComponent } from './components/projects-search/projects-search.component';
import { BtnLockComponent } from './btn-lock/btn-lock.component';
import { UsersSearchComponent } from './users-search/users-search.component';
import { SurveysSettingsModalComponent } from './components/surveys-settings-modal/surveys-settings-modal.component';
import { DocumentsUpdateComponent } from './components/documents-update/documents-update.component';

export function ptrConfig() {
  return Object.assign(new PTRConfig(), {});
}

@NgModule({
  imports: [
    CommonModule,
    SignaturePadModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUploaderModule,
    BsDropdownModule.forRoot(),
    TypeaheadModule,
    RouterModule,
    WeUiModule,
    IonicModule,
    NgxSelectModule,
    TextMaskModule,
    TooltipModule.forRoot(),
  ],
  declarations: [
    LoadingComponent,
    PaginationComponent,
    ReplaceUnderscoreWithSpacePipe,
    ConvertServiceM8DatesFormatPipe,
    ConvertDateTimeDatesFormatPipe,
    SignaturePadComponent,
    PrintTextPipe,
    JsonValuesComponent,
    FirstLetterUppercasePipe,
    DateObjectToStringPipe,
    CommentsCreateComponent,
    DocumentsCreateComponent,
    DocumentsUpdateComponent,
    JobsIndexComponent,
    AppHeaderComponent,
    UserMenuPopoverComponent,
    LoadingBarComponent,
    SignatureFormGroupComponent,
    ProjectsIndexComponent,
    SurveysIndexComponent,
    SitesUpdateModalComponent,
    SitesCreateModalComponent,
    SitesSearchComponent,
    ProjectsSearchComponent,
    RevisionsIndexComponent,
    RevisionsCreateModalComponent,
    RevisionsUpdateModalComponent,
    BeeperDirective,
    SampleNumberComponent,
    AreaCodeComponent,
    BtnLockComponent,
    UsersSearchComponent,
    SurveysSettingsModalComponent,
  ],
  exports: [
    LoadingComponent,
    PaginationComponent,
    ReplaceUnderscoreWithSpacePipe,
    ConvertServiceM8DatesFormatPipe,
    ConvertDateTimeDatesFormatPipe,
    SignaturePadComponent,
    PrintTextPipe,
    JsonValuesComponent,
    FirstLetterUppercasePipe,
    DateObjectToStringPipe,
    CommentsCreateComponent,
    DocumentsCreateComponent,
    DocumentsUpdateComponent,
    BsDropdownModule,
    NgxUploaderModule,
    TypeaheadModule,
    JobsIndexComponent,
    WeUiModule,
    AppHeaderComponent,
    UserMenuPopoverComponent,
    LoadingBarComponent,
    NgxSelectModule,
    TextMaskModule,
    TooltipModule,
    SignatureFormGroupComponent,
    ProjectsIndexComponent,
    SurveysIndexComponent,
    SitesUpdateModalComponent,
    SitesCreateModalComponent,
    SitesSearchComponent,
    ProjectsSearchComponent,
    RevisionsIndexComponent,
    RevisionsCreateModalComponent,
    RevisionsUpdateModalComponent,
    BeeperDirective,
    SampleNumberComponent,
    AreaCodeComponent,
    BtnLockComponent,
    UsersSearchComponent,
    SurveysSettingsModalComponent,
  ],
  entryComponents: [
    DocumentsCreateComponent,
    DocumentsUpdateComponent,
    UserMenuPopoverComponent,
    SitesUpdateModalComponent,
    SitesCreateModalComponent,
    RevisionsCreateModalComponent,
    RevisionsUpdateModalComponent,
    SurveysSettingsModalComponent,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        CascadeService,
        StateService,
        Servicem8Service,
        FormTemplatesService,
        FormsService,
        SamplesService,
        FormAnswersService,
        FormSectionsService,
        CommentsService,
        DocumentsService,
        SitesService,
        RoleGuard,
        PermissionGuard,
        Platform,
        NetworkService,
        SyncService,
        BusService,
        RevisionsService,
        SurveyJobsService,
        SurveysService,
        ProjectsService,
        BuildingsService,
        FloorsService,
        AreasService,
        ComponentTemplatesService,
        ComponentsService,
        SurveySamplesService,
        MaterialsService,
        SurveyReviewsService,
        Geolocation,
        AppVersion,
        ScoresService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        },
        { provide: PTRConfig, useFactory: ptrConfig }
      ]
    };
  }
}
