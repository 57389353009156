<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Clients</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li class="breadcrumb-item active">
      <span>Clients</span>
    </li>
  </ol>

  <app-loading *ngIf="gettingAllClients"></app-loading>

  <div *ngIf="!gettingAllClients" class="animated fadeIn card card-accent-primary">
    <div class="card-header">
      <strong>Clients</strong>
      <div class="float-right">
        <a [routerLink]="['/clients/create']" class="btn btn-sm btn-primary">
          Add client
        </a>
      </div>
    </div>

    <div *ngIf="clients?.length === 0" class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      No clients found.
    </div>

    <div class="card-body">
      <div class="input-group">
        <input
            [(ngModel)]="searchTerm"
            (change)="getAllClients()"
            type="text"
            name="search"
            id="search"
            class="form-control"
            placeholder="Search clients..."
            title="Search clients.">

        <div *ngIf="searchTerm" class="input-group-append">
          <button
              (click)="clearSearch()"
              class="btn btn-warning">
            <i class="fa fa-times"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table *ngIf="clients?.length > 0" class="table table-striped mb-0">
        <tr>
          <th>Name</th>
          <th>Status</th>
          <th class="text-right">Actions</th>
        </tr>

        <tr *ngFor="let client of clients">
          <td>{{ client.name }}</td>
          <td>{{ statuses[client.status] | replaceUnderscoreWithSpace }}</td>
          <td class="text-right d-md-none">
            <button
                (click)="activeClient = client"
                class="btn btn-secondary">
              <i class="fa fa-bars"></i>
            </button>
          </td>
          <td class="text-right d-none d-md-table-cell">
            <ng-container *ngTemplateOutlet="actions; context:{ $implicit: client }"></ng-container>
          </td>
        </tr>
      </table>
    </div>

    <div *ngIf="meta && !gettingAllClients && meta.last_page > 1" class="card-footer">
      <app-pagination
          [meta]="meta"
          [links]="links"
          (updated)="updateClientsList($event)"></app-pagination>
    </div>
  </div>

  <div *ngIf="activeClient" class="fixed-bottom" style="z-index: 9999;">
    <div class="list-group m-2 fadeIn">
      <ng-container *ngTemplateOutlet="actions; context:{ $implicit: activeClient }"></ng-container>
    </div>
  </div>

  <div *ngIf="activeClient" (click)="activeClient = null" class="modal-backdrop fade show"></div>

  <ng-template #actions let-client>
    <button
        [routerLink]="['/clients', client.id, 'sites']"
        [className]="activeClient ? 'list-group-item list-group-item-action' : 'btn btn-sm btn-primary'">
      <i class="fa fa-map-marker mr-1"></i>
      Sites
    </button>
    <button
        [routerLink]="['/clients', client.id, 'users']"
        [className]="activeClient ? 'list-group-item list-group-item-action' : 'btn btn-sm btn-primary'">
      <i class="fa fa-users mr-1"></i>
      Users
    </button>
    <button
        [routerLink]="['/clients/update', client.id]"
        [className]="activeClient ? 'list-group-item list-group-item-action' : 'btn btn-sm btn-info'">
      <i class="fa fa-edit mr-1"></i>
      Edit
    </button>
    <button
        (click)="onClientDelete(client)"
        [disabled]="client.deleting"
        [className]="activeClient ? 'list-group-item list-group-item-action' : 'btn btn-sm btn-danger'">
      <span *ngIf="!client.deleting"><i class="fa fa-trash mr-1"></i> Delete</span>
      <span *ngIf="client.deleting"><i class="fa fa-spin fa-spinner"></i> Deleting</span>
    </button>
  </ng-template>
</ion-content>
