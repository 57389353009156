<div class="modal-header">
  <h4 class="modal-title pull-left">
    Inventory items index
  </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="card animated fadeIn mb-0">
    <div class="card-header">
      <strong>Inventory</strong> items
    </div>

    <div *ngIf="inventories?.length === 0 && !gettingAllInventories" class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      No inventory items found.
    </div>

    <div *ngIf="gettingAllInventories" class="alert alert-info mb-0">
      <i class="fa fa-spin fa-spinner mr-1"></i>
      Loading inventory items. Please wait!
    </div>

    <div *ngIf="!gettingAllInventories" class="table-responsive">
      <table *ngIf="inventories?.length > 0" class="table table-striped mb-0">
        <tr>
          <th>Name</th>
          <th>Serial Number</th>
          <th class="text-right">Actions</th>
        </tr>

        <tr *ngFor="let inventory of inventories">
          <td>{{ inventory.name }}</td>
          <td>{{ inventory.serial_number }}</td>
          <td class="text-right">
            <button
                [disabled]="!inventory.is_available"
                (click)="onInventoryAttach(inventory)"
                class="btn btn-primary btn-sm">
              Assign
            </button>
          </td>
        </tr>
      </table>
    </div>

    <div *ngIf="meta && !gettingAllInventories && meta.last_page > 1" class="card-footer">
      <app-pagination
          [meta]="meta"
          [links]="links"
          (updated)="updateInventoriesList($event)"></app-pagination>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
    Close
  </button>
</div>
