import * as moment from 'moment';
import { FormTemplateQuestion } from '../models/form-template-question.model';
import { FormTemplateSection } from '../models/form-template-section.model';
import { DataTypeEnum } from '../../shared/enums/data-type.enum';
import { isNullOrUndefined } from 'util';
import { Form } from '../models/form.model';
import { FormSection } from '../models/form-section.model';
import { FormAnswer } from '../models/form-answer.model';
import { Sample } from '../models/sample.model';
import { FormTemplateQuestionOption } from '../models/form-template-question-option.model';

/**
 * Helper functions used in Forms Module.
 */
export class FormHelpers {
  /**
   * Date Regex mask.
   * @type {[RegExp,RegExp,string,RegExp,RegExp,string,RegExp,RegExp,RegExp,RegExp]}
   */
  public static dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /\d/, /\d/, /\d/];

  /**
   * Time Regex mask.
   * @type {[RegExp,RegExp,string,RegExp,RegExp]}
   */
  public static timeMask = [/[0-2]/, /[0-9]/, ':', /[0-6]/, /[0-9]/];

  /**
   * Convert a date from frontend format to API format.
   */
  public static convertAPIDate(date: string) {
    const format = 'DD/MM/YYYY';

    return moment(date, format, true).isValid() ? moment(date, format).format('YYYY-MM-DD') : null;
  }

  /**
   * Scroll to top of window.
   */
  public static scrollToTop() {
    const element = document.getElementById('app-container');

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start'
      });
    }
  }

  /**
   * Scroll to question input element.
   * @param question
   */
  public static scrollToQuestion(question) {
    setTimeout(() => {
      const id = this.createQuestionFieldAttributeValue(question, 'value', 'id');
      const element = document.getElementById(`${id}`);

      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest'
        });
      }
    }, 100);
  }

  /**
   * Set the current time as value of the question.
   * @param question
   */
  public static setCurrentTimeToQuestion(question) {
    question.answer.value = moment().format('HH:mm');
    this.fieldValidation(question);
  }

  /**
   * Set the current date as value of the question.
   * @param question
   */
  public static setCurrentDateToQuestion(question) {
    question.answer.value = moment().format('DD/MM/YYYY');
    this.fieldValidation(question);
  }

  /**
   * Toggles the skip button.
   * @param question
   */
  public static toggleSkipButton(question) {
    question.answer.comment = null;
    question.skipped = !question.skipped;

    if (question.skipped) {
      delete question.answer.value;
    }
  }

  /**
   * Validates that a field has either an answer or a comment.
   * @param question
   */
  public static fieldValidation(question: FormTemplateQuestion) {
    let timeValidation = true;

    if (question.answer.value && [DataTypeEnum.TEXT, DataTypeEnum.STRING].includes(question.input_type)) {
      question.answer.value = question.answer.value.replace(/'/gi, '');
    }

    if (question.answer.value && [DataTypeEnum.TIME].includes(question.input_type)) {
      const timeArray = question.answer.value.split(':');

      if (timeArray.length !== 2) {
        timeValidation = false;
      } else {
        const hrs = +timeArray[0];
        const mins = +timeArray[1];

        timeValidation = hrs >= 0 && hrs <= 24 && mins >= 0 && mins <= 59;
      }
    }

    if (question.answer.comment) {
      question.answer.comment = question.answer.comment.replace(/'/gi, '');
    }

    if (question.answer.value && question.skipped) {
      question.answer.comment = null;
      question.skipped = false;
    }

    question.validation = question.isVisible === false
      || question.js_id === 'optional'
      || question.validation_rules_f === 'optional'
      || !(isNullOrUndefined(question.answer.value) && isNullOrUndefined(question.answer.comment));

    if (!timeValidation) {
      question.validation = false;
    }
  }

  /**
   * Initializes the answer of a question and sets as default value the first option.
   * @param form_template_section
   * @returns {any}
   */
  public static initQuestionsWithOptions(form_template_section: FormTemplateSection) {
    form_template_section.form_template_questions.data = form_template_section.form_template_questions.data.map(
      (question: FormTemplateQuestion) => {
        if (question.answer) {
          return question;
        }

        question.answer = {
          id: null,
          value: null,
          comment: null
        };

        return question;
      }
    );

    return form_template_section;
  }

  /**
   * Passes form answers to for template section questions.
   * @param form_section
   * @returns {[FormTemplateQuestion,FormTemplateQuestion,FormTemplateQuestion,FormTemplateQuestion,FormTemplateQuestion]}
   */
  public static passFormAnswersToQuestions(form_section) {
    const answers = form_section.form_answers.data;

    return form_section.form_template_section.data.form_template_questions.data.map((question: FormTemplateQuestion) => {
      const answer = answers.find(element => element.form_template_question_id === question.id || element.form_template_question_id === question.hashed_id);

      if (answer) {
        question.answer = answer;
      }

      if (answer && !isNullOrUndefined(answer.value) && question.js_class !== 'pump_number' && [DataTypeEnum['NUMBER'], DataTypeEnum['RADIO_BUTTON'], DataTypeEnum['DROP_DOWN']].includes(question.input_type)) {
        question.answer.value = +answer.value;
      }

      if (answer && answer.value && !Array.isArray(answer.value) && [DataTypeEnum['DRAWING'], DataTypeEnum['PHOTO']].includes(question.input_type)) {
        question.answer.value = answer.value.split('|');
      }

      /**
       * DEPRECATED
       * Not needed after switching to sqlite.
       */
      if (answer && answer.value && [DataTypeEnum['SIGNATURE']].includes(question.input_type)) {
        // question.answer.value = null;
      }

      if (answer && answer.comment) {
        question.skipped = true;
      }

      if (!answer) {
        question.answer = {
          id: null,
          value: null,
          comment: null
        };
      }

      return question;
    });
  }

  /**
   * Checks if a question is an image based question.
   * @param question
   * @returns {any}
   */
  public static questionHasImage(question: FormTemplateQuestion) {
    return [DataTypeEnum['DRAWING'], DataTypeEnum['PHOTO'], DataTypeEnum['SIGNATURE']].includes(question.input_type);
  }

  /**
   * Creates a string based on question id, field type (comment/value) and attribute (id, name etc.).
   * @param question
   * @param type
   * @param attribute
   * @returns {string}
   */
  public static createQuestionFieldAttributeValue(question, type, attribute) {
    return `question_${question.id}_${type}_${attribute}`;
  }

  /**
   * Returns form section question based on js_id attribute.
   * @param form
   * @param formTemplateSectionJsRule
   * @param js_id
   * @returns {undefined|FormTemplateQuestion}
   */
  public static findFormFormSectionQuestionByJsId(form: Form, formTemplateSectionJsRule: string, js_id: string) {
    const form_section = form.form_sections.data.find(section => section.form_template_section.data.js_rule === formTemplateSectionJsRule);
    const form_template_question = form_section.form_template_section.data.form_template_questions.data.find(
      question => question.js_id === js_id
    );
    return form_section.form_answers.data.find(answer => answer.form_template_question_id === form_template_question.id || answer.form_template_question_id === form_template_question.hashed_id);
  }

  /**
   * Returns form section question based on js_id attribute.
   * @param form_section
   * @param js_id
   * @returns {undefined|FormTemplateQuestion}
   */
  public static findFormSectionQuestionByJsId(form_section: FormSection, js_id: string) {
    const form_template_question = form_section.form_template_section.data.form_template_questions.data.find(
      question => question.js_id === js_id
    );
    return form_section.form_answers.data.find(answer => answer.form_template_question_id === form_template_question.id || answer.form_template_question_id === form_template_question.hashed_id);
  }

  /**
   * Returns section question based on js_id attribute.
   * @param section
   * @param js_id
   * @returns {undefined|FormTemplateQuestion}
   */
  public static findSectionQuestionByJsId(section: FormTemplateSection, js_id: string) {
    return section.form_template_questions.data.find(question => question.js_id === js_id);
  }

  /**
   * Search the whole form and get a question answer based question js_id field.
   * @param form
   * @param js_id
   * @returns {null}
   */
  public static findFormAnswerByQuestionJsId(form: Form, js_id: string) {
    let answer: FormAnswer = null;

    form.form_sections.data.forEach((form_section: FormSection) => {
      let question = form_section.form_template_section.data.form_template_questions.data.find((form_template_question: FormTemplateQuestion) => {
        return form_template_question.js_id === js_id;
      });

      if (question) {
        answer = form_section.form_answers.data.find((form_answer: FormAnswer) => {
          return form_answer.form_template_question_id === question.id;
        });
      }
    });

    return answer;
  }

  /**
   * Search the whole sample and get a question answer based question number field.
   * @param sample
   * @param number
   * @returns {null}
   */
  public static findSampleFormAnswerByQuestionNumber(sample: Sample, number: string) {
    if (!sample) {
      return null;
    }

    let answer: FormAnswer = null;

    sample.form_sections.data.forEach((form_section: FormSection) => {
      let question = form_section.form_template_section.data.form_template_questions.data.find((form_template_question: FormTemplateQuestion) => {
        return form_template_question.number === number;
      });

      if (question) {
        answer = form_section.form_answers.data.find((form_answer: FormAnswer) => {
          return form_answer.form_template_question_id === question.id || form_answer.form_template_question_id === question.hashed_id;
        });

        if (question.form_template_question_options.data.length > 0) {
          const option: FormTemplateQuestionOption = question.form_template_question_options.data.find(option => option.option_value === +answer.value);

          if (option) {
            answer = Object.assign({}, answer);
            answer.value = option.option_text;
          }
        }
      }
    });

    return answer;
  }

  /**
   * Search the whole form and get a question answer based question number field.
   * @param form
   * @param number
   * @returns {null}
   */
  public static findSectionFormAnswerByQuestionNumber(form: Form, number: string) {
    let answer: FormAnswer = null;

    form.form_sections.data.forEach((form_section: FormSection) => {
      let question = form_section.form_template_section.data.form_template_questions.data.find((form_template_question: FormTemplateQuestion) => {
        return form_template_question.number === number;
      });

      if (question) {
        answer = form_section.form_answers.data.find((form_answer: FormAnswer) => {
          return form_answer.form_template_question_id === question.id || form_answer.form_template_question_id === question.hashed_id;
        });

        if (question.form_template_question_options.data.length > 0) {
          const option: FormTemplateQuestionOption = question.form_template_question_options.data.find(option => option.option_value === +answer.value);

          if (option) {
            answer = Object.assign({}, answer);
            answer.value = option.option_text;
          }
        }
      }
    });

    return answer;
  }

  /**
   * Returns section question based on js_id attribute.
   * @param section
   * @param js_id
   * @returns {undefined|FormTemplateQuestion}
   */
  public static findSectionQuestionIndexByJsId(section: FormTemplateSection, js_id: string) {
    return section.form_template_questions.data.findIndex(question => question.js_id === js_id);
  }

  /**
   * Converts a base64 encoded image to a Blob.
   * @param b64Data
   * @param contentType
   * @param sliceSize
   * @returns {File}
   */
  public static convertBase64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new File(byteArrays, 'file');
  }
}
