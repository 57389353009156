import { QueryBuilder } from './query-builder.class';
import { from, Observable } from 'rxjs';
import { JsonSerializer } from './serializer.class';
import { Building as BuildingModel } from '../../buildings/models/building.model';
import { Building } from '../entities/building.entity';
import { Floor } from '../../floors/models/floor.model';

/**
 * Building Entity Query Builder.
 */
export class BuildingQueryBuilder extends QueryBuilder {
  constructor(migrate = false) {
    super(new Building(), migrate);
  }

  findWithIncludes(id: number, includes: string[] = []): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const data: BuildingModel = await this.find(this._table, id);

        if (includes.includes('floors')) {
          const floors: Floor[] = await this.loadItemRelationship('floors', 'building_id', data.id);

          if (floors) {
            data.floors = {
              data: floors,
            };
          }
        }

        resolve(JsonSerializer.item(data));
      })
    );
  }

  delete(survey: BuildingModel) {
    const query = `DELETE FROM ${this._table} ${this._buildWhereQuery()}`;

    return from(
      new Promise((resolve, reject) => {
        this._query(query, []).then(
          (response: any) => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      })
    );
  }
}
