<form action="">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Create New Job
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div [formGroup]="jobFormGroup" class="modal-body">
    <app-loading *ngIf="gettingAllUsers" [text]="'Loading Users'"></app-loading>

    <div class="form-group">
      <label for="number">
        Number
      </label>
      <div class="input-group">
        <input
                (change)="findJobByNumber()"
                [formControl]="jobFormGroup.controls['number']"
                [ngClass]="{'is-invalid': !jobFormGroup.controls['number'].valid && jobFormGroup.controls['number'].touched}"
                class="form-control"
                id="number"
                name="number"
                placeholder="Enter job number"
                type="text">
        <div class="input-group-append">
          <button
                  (click)="findServiceM8JobByNumber()"
                  [disabled]="!jobFormGroup.controls['number'].valid || findingServiceM8JobByNumber"
                  class="btn btn-primary">
            <i *ngIf="!findingServiceM8JobByNumber" class="fa fa-download mr-2"></i>
            <i *ngIf="findingServiceM8JobByNumber" class="fa fa-spin fa-spinner mr-2"></i>
            ServiceM8
          </button>
        </div>
        <div class="input-group-append">
          <button
                  (click)="findJobByNumber()"
                  [disabled]="findingJobByNumber"
                  class="btn btn-info">
            <i *ngIf="!findingJobByNumber" class="fa fa-search"></i>
            <i *ngIf="findingJobByNumber" class="fa fa-spin fa-spinner"></i>
          </button>
        </div>
      </div>
      <div class="help-block text-danger"
           *ngIf="jobFormGroup.controls['number'].touched && jobFormGroup.controls['number'].hasError('required')">
        * This field is required.
      </div>
    </div>

    <div class="form-group">
      <label>
        Project <i class="fa fa-spinner fa-spin" *ngIf="projectsLoading"></i>
      </label>
      <ngx-select
          class="form-control"
          [items]="projects"
          [formControl]="jobFormGroup.controls['project_id']"
          [optionValueField]="optionValueField"
          optionTextField="number">
        <ng-template ngx-select-option-selected ngx-select-option let-option>
          <span *ngIf="option.data.real_client_id">{{ numToThreeDigits(option.data.real_client_id) }}-</span><span>{{ option.data.number}}</span>
        </ng-template>
      </ngx-select>
    </div>

    <div class="form-group">
      <label>
        Client <i class="fa fa-spinner fa-spin" *ngIf="clientsLoading"></i>
      </label>
      <ngx-select
              class="form-control"
              [items]="clients"
              [formControl]="jobFormGroup.controls['client_id']"
              [optionValueField]="optionValueField"
              optionTextField="name"></ngx-select>
    </div>

    <div *ngIf="authService.user.is('admin') && !gettingAllUsers" class="form-group">
      <label for="user_id">
        Technician/Surveyor
      </label>
      <ngx-select
          *ngIf="!environment.cordova"
          class="form-control"
          [ngClass]="{'is-invalid': !jobFormGroup.controls['user_id'].valid && jobFormGroup.controls['user_id'].touched}"
          [items]="users"
          [formControl]="jobFormGroup.controls['user_id']"
          [optionValueField]="optionValueField"
          [multiple]="true"
          optionTextField="name"></ngx-select>
      <select
          *ngIf="environment.cordova"
          [formControl]="jobFormGroup.controls['user_id']"
          [ngClass]="{'is-invalid': !jobFormGroup.controls['user_id'].valid && jobFormGroup.controls['user_id'].touched}"
          class="form-control"
          id="user_id"
          name="user_id"
          type="text">
        <option *ngFor="let user of users;" [value]="user.id">{{ user.name }}</option>
      </select>
      <div class="help-block text-danger"
           *ngIf="jobFormGroup.controls['user_id'].touched && jobFormGroup.controls['user_id'].hasError('required')">
        * This field is required.
      </div>
    </div>

    <div class="form-group">
      <label for="client_job_number">
        Client Job Number
      </label>
      <input
          [formControl]="jobFormGroup.controls['client_job_number']"
          class="form-control"
          id="client_job_number"
          name="client_job_number"
          placeholder="Enter client job number"
          type="text">
    </div>

    <div *ngIf="jobFormGroup.controls['address'].value" class="form-group">
      <label for="address">
        ServiceM8 Address
      </label>
      <input
          [formControl]="jobFormGroup.controls['address']"
          [ngClass]="{'is-invalid': !jobFormGroup.controls['address'].valid && jobFormGroup.controls['address'].touched}"
          class="form-control"
          id="address"
          name="address"
          placeholder="Enter site address"
          type="text">
      <div class="help-block text-danger"
           *ngIf="jobFormGroup.controls['address'].touched && jobFormGroup.controls['address'].hasError('required')">
        * This field is required.
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" [disabled]="!jobFormGroup.valid || postingJob" (click)="onSubmit()">
      <span *ngIf="!postingJob">
        Create
      </span>
      <span *ngIf="postingJob">
        <i class="fa fa-spin fa-spinner mr-1"></i>
        Creating
      </span>
    </button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
  </div>
</form>
