<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title *ngIf="!form?.certificate_number">Form Fill</ion-title>
    <ion-title *ngIf="form?.certificate_number">Form Fill: {{ form?.certificate_number }}</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li *ngIf="authService.user && authService.user.is('technician')" class="breadcrumb-item">
      <a [routerLink]="['/home']" routerDirection="root">Home</a>
    </li>

    <li *ngIf="authService.user && authService.user.is('admin')" class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="backward">Dashboard</a>
    </li>

    <li *ngIf="form" class="breadcrumb-item">
      <a [routerLink]="['/jobs/view', form.assignment_id]" routerDirection="backward">Job</a>
    </li>

    <li class="breadcrumb-item active">
      <span>Form fill</span>
    </li>
  </ol>
  <app-loading *ngIf="loadingResources"></app-loading>
  <app-loading *ngIf="findingFormTemplateById"></app-loading>
  <app-loading *ngIf="markingFormAsCompleted" [text]="'Updating form. Please wait!'"></app-loading>
  <app-loading *ngIf="creatingForm" [text]="'Creating new form. Please wait!'"></app-loading>
  <app-loading *ngIf="creatingNextFormSection" [text]="'Creating next form section. Please wait!'"></app-loading>

  <div *ngIf="!loadingResources && !inventoryChecked || syncingFormInventories" class="animated fadeIn card card-accent-primary">
    <div class="card-header">
      <strong>Inventory</strong> items
    </div>

    <div *ngIf="syncingFormInventories" class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      Confirming form inventory items. Please wait!
    </div>

    <div *ngIf="inventories?.length === 0" class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      No inventory items found.
    </div>

    <div class="table-responsive">
      <table *ngIf="inventories?.length > 0" class="table table-striped mb-0">
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Model</th>
          <th>Serial number</th>
          <th>Inventory code</th>
        </tr>

        <tr *ngFor="let inventory of inventories">
          <td>{{ inventory.name }}</td>
          <td>{{ inventory.type && inventoryTypeEnum[parseInt(inventory.type)] ? inventoryTypeEnum[parseInt(inventory.type)].split('_').join(' ') : '' }}</td>
          <td>{{ inventory.model }}</td>
          <td>{{ inventory.serial_number }}</td>
          <td>{{ inventory.inventory_code }}</td>
        </tr>
      </table>
    </div>

    <div class="alert alert-warning mb-0">
      <i class="fa fa-info mr-1"></i>
      <span *ngIf="inventories?.length > 0">Do you confirm that you indeed have the correct inventory items?</span>
      <span *ngIf="inventories?.length === 0">Do you confirm that you indeed have no inventory items?</span>
      <div class="btn-group float-right">
        <button
            (click)="onInventoryDeny()"
            class="btn btn-sm btn-warning mx-2">
          <i class="fa fa-times mr-1"></i>
          No
        </button>
        <button
            (click)="onInventoryConfirm()"
            class="btn btn-sm btn-success mx-2">
          <i class="fa fa-check mr-1"></i>
          Yes
        </button>
      </div>
    </div>

    <div *ngIf="inventoryDenied" class="p-3">
      <div class="form-group">
        <label for="inventory_message">
          Message
        </label>
        <textarea
            [(ngModel)]="inventoryMessage"
            class="form-control"
            placeholder="Explain what is not in agreement with the above list..."
            name="inventory_message"
            id="inventory_message"
            cols="30"
            rows="10"></textarea>
        <p class="help-block text-muted">
          The above message will be sent to the system administrator.
        </p>
      </div>

      <button
          [disabled]="!inventoryMessage || inventoryMessage.length === 0 || submittingInventoryMessage"
          (click)="onInventoryMessageSubmit()"
          class="btn btn-sm btn-primary float-right">
        <span *ngIf="!submittingInventoryMessage">Submit</span>
        <span *ngIf="submittingInventoryMessage"><i class="fa fa-spin fa-spinner mr-1"></i> Submitting</span>
      </button>
    </div>
  </div>

  <div *ngIf="!findingFormTemplateById && form && inventoryChecked && !markingFormAsCompleted && !creatingForm && !creatingNextFormSection">
    <div class="row">
      <div class="col-md-9">
        <h4>
          {{ form.form_template_name }}
        </h4>
      </div>

      <div class="col-md-3">
        <div class="btn-group pull-right" dropdown placement="bottom right">
          <button
              id="button-basic"
              dropdownToggle type="button"
              class="btn btn-primary btn-sm dropdown-toggle"
              aria-controls="dropdown-basic">
            Sections <span class="caret"></span>
          </button>
          <ul id="dropdown-basic"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right"
              role="menu"
              aria-labelledby="button-basic">
            <li
                *ngFor="let section of form.form_sections.data; let i = index;"
                role="menuitem"
                [class.disabled]="creatingMultipleFormAnswers"
                class="pointer">
              <a
                  class="dropdown-item"
                  [class.disabled]="creatingMultipleFormAnswers"
                  (click)="onSetActiveSection(i)">
                {{ section.form_template_section.data.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <hr class="mt-1">

    <div *ngIf="form_section.form_template_section.data.js_id !== 'samples_table' && form_section.form_template_section.data.js_id !== 'stages'" class="animated fadeIn card card-accent-primary">
      <div class="card-header">
        {{ form_template_section.title }}
        <span *ngIf="!isNullOrUndefined(sampleNumberIndex) && form_section.form_template_section.data.form_template_questions.data[sampleNumberIndex]"> - Sample Number: {{ form_section.form_template_section.data.form_template_questions.data[sampleNumberIndex].answer.value }}</span>
      </div>

      <div class="card-body">
        <app-sites-search
            *ngIf="form_section.form_template_section.data.position === 1 && form && form.assignment && form.assignment.data"
            (siteSelected)="setSelectedSite($event)"
            [siteName]="form.site?.data?.name"
            [hint]="form.assignment.data.address"
            [clientId]="form.assignment.data.client_id"></app-sites-search>

        <div *ngFor="let question of form_template_section.form_template_questions.data" class="form-group">
          <ng-container *ngIf="isApplicable === true || question.js_id === 'applicable'">
            <div *ngIf="question.input_type === data_types['STRING']">
              <app-input-type-string [question]="question"></app-input-type-string>
            </div>

            <div *ngIf="question.input_type === data_types['TEXT']">
              <app-input-type-text [question]="question"></app-input-type-text>
            </div>

            <div *ngIf="question.input_type === data_types['NUMBER']">
              <app-input-type-number [question]="question" [form]="form" [sample]="activeSample"></app-input-type-number>
            </div>

            <div *ngIf="question.input_type === data_types['DATE']">
              <app-input-type-date [question]="question"></app-input-type-date>
            </div>

            <div *ngIf="question.input_type === data_types['TIME']">
              <app-input-type-time [question]="question"></app-input-type-time>
            </div>

            <div *ngIf="question.input_type === data_types['RADIO_BUTTON']">
              <app-input-type-radio-button [question]="question"></app-input-type-radio-button>
            </div>

            <div *ngIf="question.input_type === data_types['GPS']">
              <app-input-type-gps [question]="question"></app-input-type-gps>
            </div>

            <div *ngIf="question.input_type === data_types['SIGNATURE']">
              <app-input-type-signature [question]="question"></app-input-type-signature>
            </div>

            <div *ngIf="question.input_type === data_types['PHOTO'] || question.input_type === data_types['DRAWING']">
              <app-input-type-photo [question]="question" [form]="form"></app-input-type-photo>
            </div>

            <div *ngIf="question.input_type === data_types['DROP_DOWN']" [hidden]="question.js_id === 'sample_number'">
              <app-input-type-drop-down [question]="question" [inventories]="inventories" [form]="form" [sample]="activeSample"></app-input-type-drop-down>
            </div>

            <div *ngIf="question.input_type === data_types['CHECKBOXES']">
              <app-input-type-checkboxes [question]="question"></app-input-type-checkboxes>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="card-footer">
        <button
            class="btn btn-success float-right ml-2"
            (click)="onNextSection()">
          <span *ngIf="!form_section.saving">Next</span>
          <span *ngIf="form_section.saving"><i class="fa fa-spin fa-spinner mr-1"></i> Saving</span>
        </button>

        <button
            *ngIf="activeSample"
            class="btn btn-secondary float-right ml-2" (click)="jumpToSection({js_rule: form.form_template_slug + '_samples_table_button'})">
          Return to Samples Table
        </button>

        <button
            *ngIf="form.form_template_slug === 'certificate_of_reoccupation' && form_section.form_template_section.data.js_rule !== 'certificate_of_reoccupation_b1'"
            class="btn btn-secondary float-right ml-2" (click)="jumpToSection({js_rule: form.form_template_slug + '_stages_selection_button'})">
          Return to Stages Selection
        </button>

        <button
            class="btn btn-primary float-right ml-2"
            (click)="onSectionSave()">
          <span *ngIf="!form_section.submitting && activeSample">Return to Job</span>
          <span *ngIf="!form_section.submitting && !activeSample">Save</span>
          <span *ngIf="form_section.submitting"><i class="fa fa-spin fa-spinner mr-1"></i> Saving</span>
        </button>
      </div>
    </div>

    <app-forms-samples
        *ngIf="form_section.form_template_section.data.js_id === 'samples_table'"
        [form]="form"
        (jumpToSection)="jumpToSection($event)"></app-forms-samples>

    <app-forms-stages
        *ngIf="form_section.form_template_section.data.js_id === 'stages'"
        [form]="form"
        (jumpToSection)="jumpToSection($event)"></app-forms-stages>
  </div>
</ion-content>
