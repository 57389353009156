import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InputTypeBaseFieldComponent } from '../input-type-base-field/input-type-base-field.component';
import { isNullOrUndefined } from 'util';
import { SnotifyService } from 'ng-snotify';
import { FormHelpers } from '../../classes/form-helpers.class';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { Subscription } from 'rxjs';
import { BusService } from '../../../shared/services/bus.service';
import { FormConfig } from '../../classes/form-config.class';
import { Form } from '../../models/form.model';
import { ImageCompressService } from 'ng2-image-compress';

@Component({
  selector: 'app-input-type-photo',
  templateUrl: './input-type-photo.component.html',
  styleUrls: ['./input-type-photo.component.scss']
})
export class InputTypePhotoComponent extends InputTypeBaseFieldComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  form: Form;
  public deletingFile: number;
  public subscriptions: Subscription = new Subscription();

  constructor(private snotifyService: SnotifyService, private ng2ImgMax: Ng2ImgMaxService, private busService: BusService) {
    super();
  }

  ngOnInit() {
    this.id = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'id');
    this.name = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'name');
  }

  ngAfterViewInit() {
    if (FormConfig.hidden.includes(this.question.number)) {
      this.subscriptions.add(this.busService.listen(this.question.number).subscribe(event => (this.question.isVisible = event.data)));

      setTimeout(() => {
        this.checkVisibility();
      }, 2);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onFileChange($event) {
    ImageCompressService.filesToCompressedImageSource($event.srcElement.files).then(observableImages => {
      observableImages.subscribe(
        image => {
          this.pushBlobToQuestion(image.compressedImage.imageDataUrl);
        },
        error => {
          this.snotifyService.error('Unable to save form files. Please try again. (new system)', 'Error!');
        },
        () => {
          // Done
        }
      );
    });
  }

  pushBlobToQuestion(result) {
    if (!Array.isArray(this.question.answer.value)) {
      this.question.answer.value = [];
    }

    this.question.answer.value.push(result as string);
    this.fieldValidation(this.question);
  }

  deleteFile(index: number) {
    this.deletingFile = index;
    this.question.answer.value.splice(index, 1);
    this.deletingFile = null;
  }

  checkVisibility() {
    if (isNullOrUndefined(FormConfig.hiddenRelation[this.question.number])) {
      return;
    }

    const relationNumber = FormConfig.hiddenRelation[this.question.number];

    const relationValue = FormHelpers.findSectionFormAnswerByQuestionNumber(this.form, relationNumber);

    return (this.question.isVisible = relationValue.value !== 'N/A');
  }
}
