import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserResponse, UsersResponse } from '../../auth/responses/user.response';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from '../../shared/services/api.service';
import { environment } from '../../../../environments/environment';
import { UserQueryBuilder } from '../../shared/classes/user.query-builder.class';
import { SiteQueryBuilder } from '../../shared/classes/site.query-builder.class';
import { SitesResponse } from '../../shared/responses/site.response';

@Injectable()
export class UsersService {
  private resource = 'users';
  private queryBuilder: UserQueryBuilder;

  constructor(private http: HttpClient) { }

  /**
   * Get all users.
   */
  getAllUsers(limit = 15, forceOnline = false, filter: string = null): Observable<UsersResponse> {
    let params = new HttpParams().set('limit', limit.toFixed(0)).set('include', 'roles');
    const online = forceOnline ? forceOnline : !environment.cordova;

    if (!online) {
      this.queryBuilder = new UserQueryBuilder();
    }

    if (filter) {
      params = params.set('filter[system_users]', filter);
    }

    return online
      ? this.http.get<UsersResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params })
      : this.queryBuilder.get();
  }

  /**
   * Get all users.
   */
  getClientUsers(limit = 0, clientId: string): Observable<UsersResponse> {
    const params = new HttpParams()
      .set('limit', limit.toFixed(0))
      .set('filter[client_id]', clientId);

    return this.http.get<UsersResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params });
  }

  /**
   * Get all technician users.
   */
  getAllTechnicianUsers(limit = 15): Observable<UsersResponse> {
    const params = new HttpParams()
      .set('limit', limit.toFixed(0))
      .set('filter[role_group]', 'admin');

    return this.http.get<UsersResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params });
  }

  /**
   * Get all Surveyor users.
   */
  getAllSurveyorUsers(limit = 15): Observable<UsersResponse> {
    const params = new HttpParams()
      .set('limit', limit.toFixed(0))
      .set('filter[role_survey]', 'true');

    return this.http.get<UsersResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params });
  }

  /**
   * Get all Surveyor users.
   */
  getAllTechnicianSurveyorUsers(limit = 15): Observable<UsersResponse> {
    const params = new HttpParams()
      .set('limit', limit.toFixed(0))
      .set('filter[role_tech_survey]', 'true');

    return this.http.get<UsersResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params });
  }

  getUsersByCode(code: string, email: string = null) {
    let params = new HttpParams()
      .set('limit', '0')
      .set('filter[code]', code);

    if (email) {
      params = params.set('filter[except_email]', email);
    }

    return this.http.get<UsersResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params });
  }

  /**
   * Find user by ID.
   */
  findUserById(user_id: string, includes: string[] = null): Observable<UserResponse> {
    let params = new HttpParams();

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    return this.http.get<UserResponse>(`${ApiService.ApiUrl}/${this.resource}/${user_id}`, { params });
  }

  /**
   * Create a new user.
   */
  createUser(credentials): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${ApiService.ApiUrl}/${this.resource}`, credentials);
  }

  /**
   * Update user by id.
   */
  updateUser(user): Observable<UserResponse> {
    return this.http.put<UserResponse>(`${ApiService.ApiUrl}/${this.resource}/${user.id}`, user);
  }

  /**
   * Delete user by id.
   */
  deleteUser(user): Observable<any> {
    return this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${user.id}`);
  }

  /**
   * Activate user by id.
   */
  activateUser(user): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${ApiService.ApiUrl}/${this.resource}/${user.id}/activate`, {});
  }

  /**
   * Deactivate user by id.
   */
  deactivateUser(user): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${ApiService.ApiUrl}/${this.resource}/${user.id}/deactivate`, {});
  }

  findUserByEmail(clientId: string, email: string) {
    const params = new HttpParams()
      .set('filter[client_id]', clientId.toString())
      .set('filter[email]', email)
      .set('limit', '10');

    return this.http.get<UsersResponse>(`${ApiService.ApiUrl}/users`, { params });
  }
}
