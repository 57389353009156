import { BaseModel } from '../classes/query-builder.class';

export class Form extends BaseModel {
  public table = 'forms';
  public schema = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    user_id: 'VARCHAR',
    form_template_id: 'VARCHAR',
    form_template_name: 'VARCHAR',
    form_template_slug: 'VARCHAR',
    assignment_id: 'VARCHAR',
    site_id: 'VARCHAR',
    certificate_number: 'VARCHAR',
    completed_at: 'DATETIME',
    published_at: 'DATETIME',
    synced_at: 'DATETIME'
  };
}
