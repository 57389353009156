import { Component, OnDestroy, OnInit } from '@angular/core';
import { UsersService } from '../../services/users.service';
import { User } from '../../../auth/models/user.model';
import { UsersResponse } from '../../../auth/responses/user.response';
import { SnotifyService } from 'ng-snotify';
import { Subscription } from 'rxjs';
import { BusService } from '../../../shared/services/bus.service';
import { Links, Meta } from '../../../shared/models/pagination.model';
import {AppHelper} from "../../../shared/classes/app.helper";

@Component({
  selector: 'app-users-index',
  templateUrl: './users-index.component.html',
  styleUrls: ['./users-index.component.scss']
})
export class UsersIndexComponent implements OnInit, OnDestroy {
  public users: User[];
  public activeUser: User;
  public gettingAllUsers = false;
  public meta: Meta;
  public links: Links;
  public subscriptions: Subscription = new Subscription();

  constructor(private usersService: UsersService, private snotifyService: SnotifyService, private busService: BusService) {}

  ngOnInit() {
    this.getAllUsers();
    this.addSubscriptions();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  addSubscriptions() {
    this.subscriptions.add(
      this.busService.listen('loading').subscribe(event => {
        this.gettingAllUsers = event.data;
      })
    );

    this.subscriptions.add(
      this.busService.listen('pagination').subscribe(event => {
        this.updateUsersList(event.data);
      })
    );
  }

  getAllUsers() {
    this.gettingAllUsers = true;
    const limit = 15;
    const forceOnline = true;
    const filter = 'true';

    this.usersService.getAllUsers(limit, forceOnline, filter).subscribe(
      (response: UsersResponse) => {
        this.gettingAllUsers = false;
        this.updateUsersList(response);
      },
      () => {
        this.gettingAllUsers = false;
        this.snotifyService.error('Unable to load users. Please try again.', 'Error!');
      }
    );
  }

  onUserDeactivate(user: User) {
    this.snotifyService.confirm(`User "${user.name}" is going to be deactivated.`, 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.deactivateUser(user),
          bold: false
        },
        { text: 'No', action: () => {} }
      ]
    });
  }

  activateUser(user: User) {
    user.activating = true;

    this.usersService.activateUser(user).subscribe(
      () => {
        user.activating = false;
        this.snotifyService.success(`User "${user.name}" has been successfully activated.`, 'Success!');
        this.getAllUsers();
        AppHelper.chime();
      },
      () => {
        user.activating = false;
        this.snotifyService.error(`Unable to activate user "${user.name}". Please try again.`, 'Error!');
      }
    );
  }

  deactivateUser(user: User) {
    user.activating = true;

    this.usersService.deactivateUser(user).subscribe(
      () => {
        user.activating = false;
        this.snotifyService.success(`User "${user.name}" has been successfully deactivated.`, 'Success!');
        this.getAllUsers();
        AppHelper.chime();
      },
      () => {
        user.activating = false;
        this.snotifyService.error(`Unable to deactivate user "${user.name}". Please try again.`, 'Error!');
      }
    );
  }

  onUserDelete(user: User) {
    this.snotifyService.confirm(`User "${user.name}" is going to be erased.`, 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.deleteUser(user),
          bold: false
        },
        { text: 'No', action: () => {} }
      ]
    });
  }

  deleteUser(user: User) {
    user.deleting = true;

    this.usersService.deleteUser(user).subscribe(
      () => {
        user.deleting = false;
        this.snotifyService.success(`User "${user.name}" has been successfully deleted.`, 'Success!');
        this.getAllUsers();
        AppHelper.chime();
      },
      () => {
        user.deleting = false;
        this.snotifyService.error(`Unable to delete user "${user.name}". Please try again.`, 'Error!');
      }
    );
  }

  updateUsersList(response: UsersResponse) {
    this.users = response.data;
    this.meta = response.meta;
    this.links = response.links;
  }
}
