import { Component, OnInit } from '@angular/core';
import { InputTypeBaseFieldComponent } from '../input-type-base-field/input-type-base-field.component';
import { FormTemplateQuestion } from '../../models/form-template-question.model';
import { FormHelpers } from '../../classes/form-helpers.class';

@Component({
  selector: 'app-input-type-signature',
  templateUrl: './input-type-signature.component.html',
  styleUrls: ['./input-type-signature.component.scss']
})
export class InputTypeSignatureComponent extends InputTypeBaseFieldComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    this.id = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'id');
    this.name = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'name');
  }

  drawComplete(question: FormTemplateQuestion) {
    this.fieldValidation(question);
  }
}
