import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { SampleResponse } from '../responses/sample.response';
import { Observable } from 'rxjs';
import { Form } from '../models/form.model';
import { Sample } from '../models/sample.model';
import { SampleQueryBuilder } from '../../shared/classes/sample.query-builder.class';
import { ApiService } from '../../shared/services/api.service';

@Injectable()
export class SamplesService {
  private resource = 'samples';
  private sampleQueryBuilder: SampleQueryBuilder;

  constructor(private http: HttpClient) {
    this.sampleQueryBuilder = new SampleQueryBuilder();
  }

  /**
   * Create new sample.
   * @param form
   * @param includes
   * @returns {Observable<Object>}
   */
  createSample(form: Form, includes: string[] = null): Observable<SampleResponse> {
    let params = new HttpParams();

    const payload = {
      form_id: form.id.toString(),
    };

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    return !environment.cordova
      ? this.http.post<SampleResponse>(`${ApiService.ApiUrl}/${this.resource}`, payload, { params })
      : this.sampleQueryBuilder.create(payload);
  }

  /**
   * Delete sample by id.
   * @param sample
   * @returns {Observable<Object>}
   */
  deleteSample(sample: Sample): Observable<any> {
    return !environment.cordova
      ? this.http.delete<any>(`${ApiService.ApiUrl}/${this.resource}/${sample.id}`)
      : this.sampleQueryBuilder.where('id', '=', sample.id).remove();
  }

  /**
   * Sync sample.
   * @param payload
   * @returns {Observable<Object>}
   */
  syncSample(payload): Observable<any> {
    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/sync`, payload);
  }

}
