<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>zampa</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="col-md-12">
    <div class="mx-4">
      <form action="" (ngSubmit)="onReset()">
        <div
            *ngIf="!reset"
            class="card-body p-4"
            [formGroup]="credentials">
          <h2>Reset password</h2>
          <p class="text-muted">Fill a new password for your account.</p>
          <div class="form-group mb-3">
            <input
                [formControl]="credentials.controls['password']"
                [ngClass]="{'is-invalid': !credentials.controls['password'].valid && credentials.controls['password'].touched}"
                type="password"
                class="form-control"
                name="password"
                placeholder="Password">
            <div class="help-block text-danger"
                 *ngIf="credentials.controls['password'].touched && credentials.controls['password'].hasError('required')">
              * This field is required.
            </div>
            <div class="help-block text-danger"
                 *ngIf="credentials.controls['password'].touched && credentials.controls['password'].hasError('minlength')">
              * This field requires minimum 8 characters.
            </div>
          </div>

          <div class="form-group mb-3">
            <input
                [formControl]="credentials.controls['password_confirmation']"
                [ngClass]="{'is-invalid': !credentials.controls['password_confirmation'].valid && credentials.controls['password_confirmation'].touched}"
                type="password"
                class="form-control"
                name="password_confirmation"
                placeholder="Repeat password">
            <div class="help-block text-danger"
                 *ngIf="credentials.controls['password_confirmation'].touched && credentials.controls['password_confirmation'].hasError('required')">
              * This field is required.
            </div>
            <div class="help-block text-danger"
                 *ngIf="credentials.controls['password'].touched && credentials.controls['password_confirmation'].touched && credentials.hasError('password-mismatch')">
              * The confirmation password does not match.
            </div>
            <div class="help-block text-danger"
                 *ngIf="credentials.controls['password_confirmation'].touched && credentials.controls['password_confirmation'].hasError('minlength')">
              * This field requires minimum 8 characters.
            </div>
          </div>

          <button
              (click)="onReset()"
              [disabled]="!credentials.valid"
              type="submit"
              class="btn btn-block btn-success">
          <span *ngIf="resetting">
            <i class="fa fa-spin fa-spinner mr-1"></i>
            Resetting
          </span>
            <span *ngIf="!resetting">
            Reset
          </span>
          </button>
        </div>
      </form>

      <div *ngIf="reset" class="card-body p-4">
        <h2>Thank you!</h2>
        <p class="text-muted">Your password has been changed.</p>
        <div class="alert alert-info">
          Use your new credentials to login
          <a *ngIf="!isClient" [routerLink]="['/auth/login']" [routerDirection]="'root'">here</a>
          <a *ngIf="isClient" [href]="portalUrl">here</a>.
        </div>
      </div>
    </div>
  </div>
</ion-content>
