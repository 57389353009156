import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../auth/models/user.model';
import { Client } from '../../../clients/models/client.model';
import { BsModalRef } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/services/auth.service';
import { UsersService } from '../../../users/services/users.service';
import { SnotifyService } from 'ng-snotify';
import { ClientsService } from '../../../clients/services/clients.service';
import { Servicem8Service } from '../../../shared/services/servicem8.service';
import { NetworkService } from '../../../shared/services/network.service';
import { UsersResponse } from '../../../auth/responses/user.response';
import { JobResponse } from '../../../jobs/responses/job.response';
import { ClientsResponse } from '../../../clients/responses/client.response';
import { environment } from 'src/environments/environment';
import { SurveyJobStatusEnum } from '../../../shared/enums/survey-job-status.enum';
import { SurveyJob } from '../../models/survey-job.model';
import { SurveyJobsService } from '../../services/survey-jobs.service';
import { Project } from '../../../projects/models/project.model';
import { ProjectsResponse } from '../../../projects/responses/project.response';
import { ProjectsService } from '../../../projects/services/projects.service';
import { Subscription } from 'rxjs';
import { SurveyJobQueryBuilder } from '../../../shared/classes/survey_job.query-builder.class';
import { ClientQueryBuilder } from '../../../shared/classes/client.query-builder.class';
import { SurveyJobResponse } from '../../responses/survey-job.response';

@Component({
  selector: 'app-survey-jobs-create',
  templateUrl: './survey-jobs-create.component.html',
  styleUrls: ['./survey-jobs-create.component.scss'],
})
export class SurveyJobsCreateComponent implements OnInit, OnDestroy {
  public jobFormGroup: FormGroup;
  private job: SurveyJob;
  public postingJob = false;
  public findingServiceM8JobByNumber = false;
  public findingJobByNumber = false;
  public gettingAllUsers = false;
  public users: User[];
  public clients: Client[];
  public project: Project;
  public projects: Project[];
  public clientsLoading: boolean;
  public projectsLoading: boolean;
  public address: string;
  public client: Client;
  public optionValueField: string;
  public environment = environment;
  public subscriptions = new Subscription();

  constructor(
    public bsModalRef: BsModalRef,
    private surveyJobsService: SurveyJobsService,
    private router: Router,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private usersService: UsersService,
    private snotifyService: SnotifyService,
    private clientsService: ClientsService,
    private projectsService: ProjectsService,
    private servicem8Service: Servicem8Service,
    private networkService: NetworkService,
  ) {
    this.clientsLoading = true;
    this.projectsLoading = true;
    this.clients = [];
    this.projects = [];
    this.optionValueField = environment.cordova ? 'hashed_id' : 'id';
  }

  ngOnInit() {
    this.initJobForm();

    this.loadClients();
    this.loadProjects();

    if (this.authService.user.is('admin')) {
      this.getAllUsers();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initJobForm() {
    this.jobFormGroup = this.formBuilder.group({
      number: ['', [Validators.required]],
      user_id: [this.authService.user.profile.id, [Validators.required]],
      client_id: [{value: null, disabled: true}, [Validators.required]],
      project_id: [{value: null, disabled: true}, [Validators.required]],
      status: [SurveyJobStatusEnum.Open],
      address: [null, [Validators.required]],
      commissioner_name: [null],
      scope: [null],
      deviations_exclusions: [null],
    });

    this.subscriptions.add(
      this.jobFormGroup.get('project_id').valueChanges.subscribe(value => {
        const project = this.projects.find(entity => entity.id === value);

        if (project && project.client_id) {
          this.jobFormGroup.get('client_id').setValue(project.client_id);
        }
      })
    );

    if (this.project) {
      this.jobFormGroup.get('project_id').setValue(this.project.id);
      this.jobFormGroup.get('client_id').setValue(this.project.client_id);
    }
  }

  getAllUsers() {
    this.gettingAllUsers = true;

    this.usersService.getAllSurveyorUsers(500).subscribe(
      (response: UsersResponse) => {
        this.gettingAllUsers = false;
        this.users = response.data;
        this.jobFormGroup.get('user_id').setValue(this.authService.user.profile.id);
      },
      () => {
        this.gettingAllUsers = false;
        this.snotifyService.error('Unable to load Users. Please try again!', 'Error!');
      }
    );
  }

  onSubmit() {
    this.postingJob = true;
    const payload = this.jobFormGroup.getRawValue();

    if (environment.cordova) {
      delete payload.user_id;
    }

    this.surveyJobsService.createSurveyJob(payload).subscribe(
      (response: JobResponse) => {
        this.postingJob = false;
        this.snotifyService.success('A new job has been created.', 'Success!');
        this.job = response.data;
        this.bsModalRef.hide();
        return this.router.navigate([`/survey-jobs/view/${this.job.id}`]);
      },
      () => {
        this.postingJob = false;
        this.snotifyService.error('Unable to create job. Please try again.', 'Error!');
      }
    );
  }

  loadClients() {
    const limit = 0;

    this.clientsLoading = true;
    this.clientsService.getAllClients(limit).subscribe(
      (response: ClientsResponse) => {
        this.clients = response.data;
        this.clientsLoading = false;
        this.jobFormGroup.get('client_id').enable();

        if (this.client) {
          this.jobFormGroup.get('client_id').setValue(this.client.id);
        }
      },
      () => {
        this.snotifyService.error('Unable to load Clients!', 'Error!');
        this.clientsLoading = false;
      }
    );
  }

  loadProjects() {
    const limit = 0;

    this.projectsLoading = true;
    this.projectsService.getAllProjects(limit).subscribe(
      (response: ProjectsResponse) => {
        this.projects = response.data;
        this.projectsLoading = false;
        this.jobFormGroup.get('project_id').enable();
      },
      () => {
        this.snotifyService.error('Unable to load Projects!', 'Error!');
        this.projectsLoading = false;
      }
    );
  }

  findServiceM8JobByNumber() {
    this.findingServiceM8JobByNumber = true;
    const payload = this.jobFormGroup.get('number').value;

    this.servicem8Service.findServiceM8JobByNumber(payload).subscribe(
      (response: {client: Client, job_address: string}) => {
        this.findingServiceM8JobByNumber = false;
        this.client = response.client;

        if (response.job_address) {
          this.jobFormGroup.get('address').setValue(response.job_address);
        }

        if (environment.cordova) {
          const clientQueryBuilder = new ClientQueryBuilder();

          clientQueryBuilder.where('hashed_id', '=', this.client.id).first().subscribe(localClient => {
            if (!localClient) {
              clientQueryBuilder.create({
                hashed_id: this.client.id,
                name: this.client.name,
                address: this.client.address,
              }).subscribe(() => this.loadClients());
            } else {
              this.loadClients();
            }
          });
        } else {
          this.loadClients();
        }

        this.loadClients();
      },
      () => {
        this.findingServiceM8JobByNumber = false;
        this.snotifyService.error('Unable to load ServiceM8 job. Please try again.', 'Error!');
      }
    );
  }

  findJobByNumber() {
    if (!this.networkService.connected) {
      return;
    }

    this.findingJobByNumber = true;
    const payload = this.jobFormGroup.get('number').value;

    this.surveyJobsService.findSurveyJobByNumber(payload).subscribe(
      (response: SurveyJobResponse) => {
        this.findingJobByNumber = false;
        this.job = response.data;

        if (!this.job) {
          return;
        }

        if (environment.cordova) {
          const surveyJobQueryBuilder = new SurveyJobQueryBuilder();

          surveyJobQueryBuilder.where('hashed_id', '=', this.job.id).first().subscribe(localSurveyJob => {
            if (localSurveyJob) {
              this.bsModalRef.hide();
              return this.router.navigate([`/survey-jobs/view/${localSurveyJob.data.id}`]);
            }
          });
        } else {
          this.bsModalRef.hide();
          return this.router.navigate([`/survey-jobs/view/${this.job.id}`]);
        }
      },
      () => {
        this.findingJobByNumber = false;
      }
    );
  }

}
