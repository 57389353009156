import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { UploaderComponent } from './components/uploader/uploader.component';
import { UploaderRoutingModule } from './uploader-routing.module';
import { SharedModule } from '../shared/shared.module';
import { JobsService } from '../jobs/services/jobs.service';
import { SurveyingUploaderComponent } from './components/surveying-uploader/surveying-uploader.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    UploaderRoutingModule,
    SharedModule
  ],
  declarations: [
    UploaderComponent,
    SurveyingUploaderComponent,
  ],
  providers: [
    JobsService
  ]
})
export class UploaderModule { }
