import { Component, OnDestroy, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import * as moment from 'moment';
import { JobResponse, JobsResponse } from '../../../jobs/responses/job.response';
import { Job } from '../../../jobs/models/job.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { JobsCreateComponent } from '../../../jobs/components/jobs-create/jobs-create.component';
import { SyncService } from '../../../shared/services/sync.service';
import { AssignmentQueryBuilder } from '../../../shared/classes/assignment.query-builder.class';
import { Subscription } from 'rxjs';
import { BusService } from '../../../shared/services/bus.service';
import { Links, Meta } from '../../../shared/models/pagination.model';
import { JobsService } from '../../../jobs/services/jobs.service';

@Component({
  selector: 'app-home-technician',
  templateUrl: './home-technician.component.html',
  styleUrls: ['./home-technician.component.scss']
})
export class HomeTechnicianComponent implements OnInit, OnDestroy {
  public gettingAllAuthenticatedUserJobs = false;
  public creatingJob = false;
  public jobsNumberSortingDirection = 1;
  public jobsDateSortingDirection = -1;
  private jobsSortedBy: string;
  public jobs: Job[];
  public job: Job;
  public meta: Meta;
  public links: Links;
  private bsModalRef: BsModalRef;
  private subscriptions = new Subscription();
  public filter = 'open';

  constructor(
    private modalService: BsModalService,
    private snotifyService: SnotifyService,
    private syncService: SyncService,
    private busService: BusService,
    private jobsService: JobsService,
  ) {}

  ngOnInit() {
    this.syncService.forceSyncAPI();
    this.getAllAuthenticatedUserJobs();
    this.subscriptions.add(this.busService.listen('refreshJobs').subscribe(() => this.getAllAuthenticatedUserJobs()));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getAllAuthenticatedUserJobs() {
    const assignmentQueryBuilder = new AssignmentQueryBuilder();
    const includes = ['project', 'client'];
    let open = null;

    if (this.filter === 'open') {
      open = 1;
    }

    if (this.filter === 'closed') {
      open = 0;
    }

    const getAllAuthenticatedUserJobs = assignmentQueryBuilder.getWithIncludes(includes, open, 'start_date');
    this.gettingAllAuthenticatedUserJobs = true;

    getAllAuthenticatedUserJobs.subscribe(
      (response: JobsResponse) => {
        this.gettingAllAuthenticatedUserJobs = false;
        this.updateJobsList(response);
      },
      () => {
        this.gettingAllAuthenticatedUserJobs = false;
        this.snotifyService.error('Unable to load jobs. Please try again!', 'Error!');
      }
    );
  }

  updateJobsList(response: JobsResponse) {
    this.jobs = response.data;
    this.jobs.map(job => (job.address = job.site_name ? job.site_name : job.address));
    this.meta = response.meta;
    this.links = response.links;
  }

  sortJobsByNumber() {
    const argument = 'number';
    this.jobsSortedBy = argument;
    this.jobsNumberSortingDirection = -1 * this.jobsNumberSortingDirection;

    this.jobs = this.jobs.sort((a: any, b: any) => {
      if (a[argument] < b[argument]) {
        return -1 * this.jobsNumberSortingDirection;
      } else if (a[argument] > b[argument]) {
        return this.jobsNumberSortingDirection;
      } else {
        return 0;
      }
    });
  }

  sortJobsByDate() {
    const argument = 'start_date';
    this.jobsSortedBy = argument;
    this.jobsDateSortingDirection = -1 * this.jobsDateSortingDirection;

    this.jobs = this.jobs.sort((a: any, b: any) => {
      if (this.jobsDateSortingDirection === 1) {
        return moment.utc(b[argument], 'YYYY-MM-DD HH:mm:ss').diff(moment.utc(a[argument], 'YYYY-MM-DD HH:mm:ss'));
      }

      return moment.utc(a[argument], 'YYYY-MM-DD HH:mm:ss').diff(moment.utc(b[argument], 'YYYY-MM-DD HH:mm:ss'));
    });
  }

  onNewJob() {
    this.bsModalRef = this.modalService.show(JobsCreateComponent);
  }

  // toggleOpenStatus(job: Job) {
  //   job.open = !job.open;
  //
  //   this.updateJob(job);
  // }
  //
  // updateJob(job: Job) {
  //   job.updating = true;
  //
  //   this.jobsService.updateJob(job).subscribe(
  //     (response: JobResponse) => {
  //       job.updating = false;
  //       this.snotifyService.success('Job has been updated.', 'Success!');
  //     },
  //     () => {
  //       job.updating = false;
  //       this.snotifyService.error('Unable to update job. Please try again.', 'Error!');
  //     }
  //   );
  // }

  switchFilter(filter) {
    this.filter = filter;
    this.getAllAuthenticatedUserJobs();
  }
}
