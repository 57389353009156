import { BaseModel } from '../classes/query-builder.class';

export class SurveyJob extends BaseModel {
  public table = 'survey_jobs';
  public schema = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    client_id: 'VARCHAR',
    project_id: 'VARCHAR',
    number: 'VARCHAR',
    address: 'VARCHAR',
    commissioner_name: 'VARCHAR',
    status: 'INTEGER',
    scope: 'LONGTEXT',
    deviations_exclusions: 'LONGTEXT',
  };
}
