import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from '../../shared/services/api.service';
import { Observable } from 'rxjs';
import { SurveyReviewResponse, SurveyReviewsResponse } from '../responses/survey-review.response';
import { SurveyReview } from '../models/survey-review.model';

@Injectable()
export class SurveyReviewsService {
  private resource = 'survey_reviews';

  constructor(private http: HttpClient) {
  }

  /**
   * Get all surveyReviews.
   */
  getAllSurveyReviews(
    limit: number = 15,
    includes: string[] = null,
    sorting: string,
    appends: string[] = null,
  ): Observable<SurveyReviewsResponse> {
    let params = new HttpParams();

    params = params.set('limit', limit.toFixed(0));

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    if (appends) {
      params = params.set('append', appends.join(','));
    }

    if (sorting) {
      params = params.set('sort', sorting);
    }

    return this.http.get<SurveyReviewsResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params });
  }

  /**
   * Find surveyReview by id.
   */
  findSurveyReviewById(surveyReviewId: string, includes: string[] = null): Observable<SurveyReviewResponse> {
    let params = new HttpParams();

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    return this.http.get<SurveyReviewResponse>(`${ApiService.ApiUrl}/${this.resource}/${surveyReviewId}`, { params });
  }

  /**
   * Create new user surveyReview.
   */
  createSurveyReview(surveyReview: SurveyReview): Observable<SurveyReviewResponse> {
    return this.http.post<SurveyReviewResponse>(`${ApiService.ApiUrl}/${this.resource}`, surveyReview);
  }

  /**
   * Update user surveyReview.
   */
  updateSurveyReview(surveyReview: SurveyReview): Observable<SurveyReviewResponse> {
    return this.http.patch<SurveyReviewResponse>(`${ApiService.ApiUrl}/${this.resource}/${surveyReview.id}`, surveyReview);
  }

  /**
   * Delete user surveyReview.
   */
  deleteSurveyReview(surveyReview: SurveyReview): Observable<any> {
    return this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${surveyReview.id}`);
  }
}
