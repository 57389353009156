import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from '../../shared/services/api.service';
import { Observable } from 'rxjs';
import { SurveyJobResponse, SurveyJobsResponse } from '../responses/survey-job.response';
import { SurveyJob } from '../models/survey-job.model';
import { JobResponse } from '../../jobs/responses/job.response';
import { environment } from '../../../../environments/environment';
import { ProjectQueryBuilder } from '../../shared/classes/project.query-builder.class';
import { SurveyJobQueryBuilder } from '../../shared/classes/survey_job.query-builder.class';

@Injectable()
export class SurveyJobsService {
  private resource = 'survey_jobs';
  private queryBuilder: SurveyJobQueryBuilder;

  constructor(private http: HttpClient) {
    if (environment.cordova) {
      this.queryBuilder = new SurveyJobQueryBuilder();
    }
  }

  /**
   * Get all surveyJobs.
   */
  getAllSurveyJobs(
    limit: number = 15,
    includes: string[] = null,
    sorting: string,
    appends: string[] = null,
    search: string = null,
  ): Observable<SurveyJobsResponse> {
    let params = new HttpParams();

    params = params.set('limit', limit.toFixed(0));

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    if (appends) {
      params = params.set('append', appends.join(','));
    }

    if (sorting) {
      params = params.set('sort', sorting);
    }

    if (search) {
      params = params.set('filter[search]', search);
    }

    if (environment.cordova) {
      this.queryBuilder = new SurveyJobQueryBuilder();
    }
    return !environment.cordova
      ? this.http.get<SurveyJobsResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params })
      : this.queryBuilder.getWithProjectsClients(sorting);
  }

  /**
   * Find surveyJob by id.
   */
  findSurveyJobById(surveyJobId: string, includes: string[] = null): Observable<SurveyJobResponse> {
    let params = new HttpParams();

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    return !environment.cordova
      ? this.http.get<SurveyJobResponse>(`${ApiService.ApiUrl}/${this.resource}/${surveyJobId}`, { params })
      : this.queryBuilder.findWithIncludes(+surveyJobId);
  }

  /**
   * Find job by number.
   */
  findSurveyJobByNumber(num: string, includes: string[] = null, excludes: string[] = null): Observable<JobResponse> {
    let params = new HttpParams();

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    if (excludes) {
      params = params.set('exclude', excludes.join(','));
    }

    return this.http.get<JobResponse>(`${ApiService.ApiUrl}/${this.resource}/number/${num}`, { params });
  }

  /**
   * Create new user surveyJob.
   */
  createSurveyJob(surveyJob: SurveyJob): Observable<SurveyJobResponse> {
    return !environment.cordova
      ? this.http.post<SurveyJobResponse>(`${ApiService.ApiUrl}/${this.resource}`, surveyJob)
      : this.queryBuilder.create(surveyJob);
  }

  /**
   * Update user surveyJob.
   */
  updateSurveyJob(surveyJob: SurveyJob): Observable<SurveyJobResponse> {
    return this.http.patch<SurveyJobResponse>(`${ApiService.ApiUrl}/${this.resource}/${surveyJob.id}`, surveyJob);
  }

  /**
   * Delete user surveyJob.
   */
  deleteSurveyJob(surveyJob: SurveyJob): Observable<any> {
    return this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${surveyJob.id}`);
  }

  /**
   * Sync survey.
   */
  syncSurveyJob(payload): Observable<any> {
    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/sync`, payload);
  }
}
