import { QueryBuilder } from './query-builder.class';
import { from, Observable } from 'rxjs';
import { JsonSerializer } from './serializer.class';
import { Floor as FloorModel } from '../../floors/models/floor.model';
import { Floor } from '../entities/floor.entity';
import { Building as BuildingModel } from '../../buildings/models/building.model';
import { Area } from '../../areas/models/area.model';
import { ComponentModel } from '../../components/models/component.model';
import { AppHelper } from './app.helper';

/**
 * Floor Entity Query Builder.
 */
export class FloorQueryBuilder extends QueryBuilder {
  constructor(migrate = false) {
    super(new Floor(), migrate);
  }

  findWithIncludes(id: number, includes: string[] = []): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const data: FloorModel = await this.find(this._table, id);

        if (data && includes.includes('areas')) {
          let areas: Area[] = await this.loadItemRelationship('areas', 'floor_id', data.id);
          const areasIncludes = AppHelper.getSubIncludes(includes, 'areas.');

          if (areas && areasIncludes.includes('components')) {
            const components: ComponentModel[] = await this.loadCollectionRelationships('components', 'area_id', 'id', areas);
            areas = areas.map(entry => {
              const childComponents: ComponentModel[] = components.filter(component => +component.area_id === +entry.id);

              if (childComponents) {
                entry.components = {
                  data: childComponents
                };
              }

              return entry;
            });
          }

          if (areas) {
            data.areas = {
              data: areas,
            };
          }
        }

        if (includes.includes('building')) {
          const building: BuildingModel = await this.find('buildings', data.building_id);
          const buildingIncludes = AppHelper.getSubIncludes(includes, 'building.');

          if (building && buildingIncludes.includes('areas')) {
            let areas: Area[] = await this.loadItemRelationship('areas', 'building_id', building.id);
            const areasIncludes = AppHelper.getSubIncludes(buildingIncludes, 'areas.');

            if (areas && areasIncludes.includes('components')) {
              const components: ComponentModel[] = await this.loadCollectionRelationships('components', 'id', 'area_id', areas);

              areas = areas.map(entry => {
                const childComponents: ComponentModel[] = components.filter(component => component.area_id === entry.id);

                if (childComponents) {
                  entry.components = {
                    data: childComponents
                  };
                }

                return entry;
              });
            }

            if (areas) {
              building.areas = {
                data: areas,
              };
            }
          }

          if (building) {
            data.building = {
              data: building,
            };
          }
        }

        resolve(JsonSerializer.item(data));
      })
    );
  }

  delete(survey: FloorModel) {
    const query = `DELETE FROM ${this._table} ${this._buildWhereQuery()}`;

    return from(
      new Promise((resolve, reject) => {
        this._query(query, []).then(
          (response: any) => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      })
    );
  }
}
