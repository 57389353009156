import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InventoryIndexComponent } from './components/inventory-index/inventory-index.component';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { InventoryCreateComponent } from './components/inventory-create/inventory-create.component';
import { InventoryUpdateComponent } from './components/inventory-update/inventory-update.component';
import { InventoryViewComponent } from './components/inventory-view/inventory-view.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'index'
  },
  {
    path: 'index',
    component: InventoryIndexComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ['manage-inventory']
    }
  },
  {
    path: 'create',
    component: InventoryCreateComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ['manage-inventory']
    }
  },
  {
    path: 'update/:inventory_id',
    component: InventoryUpdateComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ['manage-inventory']
    }
  },
  {
    path: 'view/:inventory_id',
    component: InventoryViewComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ['manage-inventory']
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventoryRoutingModule { }
