<label *ngIf="question.js_id !== 'sample_number'">
  {{ question.text }}
</label>

<div class="input-group">
  <ion-input
      (change)="fieldValidation(question)"
      [ngClass]="{'is-invalid': question.validation === false && !question.skipped}"
      [(ngModel)]="question.answer.value"
      [id]="id"
      [name]="name"
      class="form-control"
      type="text">
  </ion-input>
  <span class="input-group-append">
    <button (click)="getGeolocation()" class="btn btn-info" tabindex="-1">
      <i class="fa fa-map-marker"></i>
    </button>
    <app-input-value-skip-button [question]="question"></app-input-value-skip-button>
  </span>
  <div *ngIf="question.validation === false && !question.skipped" class="invalid-feedback">
    This field is required. Please either fill a value or press <i>Skip</i> button and enter a comment.
  </div>
</div>

<app-input-comment [question]="question"></app-input-comment>
