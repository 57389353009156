<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>zampa</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="col-md-12">
    <div *ngIf="mode === 'testing'" class="alert alert-info">
      Testing mode enabled. To turn this off tap just below the login box.
    </div>
    <div class="mb-0">
      <div class="p-4">
        <div class="card-body">
          <form action="" (ngSubmit)="onLogin()">
            <h1>Login</h1>
            <p class="text-muted">Sign In to your account</p>
            <div [formGroup]="credentials">
              <div class="form-group mb-3">
                <input
                    [formControl]="credentials.controls['email']"
                    [ngClass]="{'is-invalid': !credentials.controls['email'].valid && credentials.controls['email'].touched}"
                    type="text"
                    class="form-control"
                    placeholder="Email address">
                <div class="help-block text-danger"
                     *ngIf="credentials.controls['email'].touched && credentials.controls['email'].hasError('required')">
                  * This field is required.
                </div>
                <div class="help-block text-danger"
                     *ngIf="credentials.controls['email'].touched && credentials.controls['email'].hasError('email')">
                  * Please use a valid email address.
                </div>
              </div>

              <div class="form-group mb-3">
                <input
                    [formControl]="credentials.controls['password']"
                    [ngClass]="{'is-invalid': !credentials.controls['password'].valid && credentials.controls['password'].touched}"
                    type="password"
                    class="form-control"
                    placeholder="Password">
                <div class="help-block text-danger"
                     *ngIf="credentials.controls['password'].touched && credentials.controls['password'].hasError('required')">
                  * This field is required.
                </div>
              </div>
            </div>

            <div class="form-group" *ngIf="!isMobile">
              <div class="checkbox">
                <label for="remember">
                  <input
                      [(ngModel)]="rememberCredentials"
                      id="remember"
                      name="remember"
                      type="checkbox"> Remember Me
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <button
                    [disabled]="!credentials.valid || loggingIn"
                    type="submit"
                    class="btn btn-primary px-4">
                  <span *ngIf="!loggingIn">Login</span>
                  <span *ngIf="loggingIn">
                    <i class="fa fa-spinner fa-spin mr-1"></i>
                    Logging In
                  </span>
                </button>
              </div>
              <div class="col-8 text-right">
                <a [routerLink]="['/auth/password/forgot']" class="align-middle">Forgot Password?</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div (click)="toggleApi()" style="cursor: default;" class="btn btn-block btn-transparent">
      &nbsp;
    </div>
  </div>
</ion-content>
