import { Component, OnDestroy, OnInit } from '@angular/core';
import { Inventory } from '../../../inventory/models/inventory.model';
import { Subject } from 'rxjs';
import { InventoriesService } from '../../../inventory/services/inventories.service';
import { BsModalRef } from 'ngx-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { InventoriesResponse } from '../../../inventory/responses/inventory.response';
import { Subscription } from 'rxjs';
import { BusService } from '../../../shared/services/bus.service';
import { Links, Meta } from '../../../shared/models/pagination.model';

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss']
})
export class InventoryListComponent implements OnInit, OnDestroy {
  public inventories: Inventory[];
  public meta: Meta;
  public links: Links;
  public gettingAllInventories = false;
  public onInventorySelect: Subject<Inventory>;
  public subscriptions: Subscription = new Subscription();

  constructor(
    public bsModalRef: BsModalRef,
    private inventoriesService: InventoriesService,
    private snotifyService: SnotifyService,
    private busService: BusService
  ) {}

  ngOnInit() {
    this.onInventorySelect = new Subject();

    this.getAllInventories();
    this.addSubscriptions();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  addSubscriptions() {
    this.subscriptions.add(
      this.busService.listen('loading').subscribe(event => {
        this.gettingAllInventories = event.data;
      })
    );

    this.subscriptions.add(
      this.busService.listen('pagination').subscribe(event => {
        this.updateInventoriesList(event.data);
      })
    );
  }

  getAllInventories() {
    this.gettingAllInventories = true;

    this.inventoriesService.getAllInventories().subscribe(
      (response: InventoriesResponse) => {
        this.gettingAllInventories = false;
        this.updateInventoriesList(response);
      },
      error => {
        this.gettingAllInventories = false;
        this.snotifyService.error('Unable to load inventory items. Please try again.', 'Error!');
      }
    );
  }

  updateInventoriesList(response: InventoriesResponse) {
    this.inventories = response.data;
    this.meta = response.meta;
    this.links = response.links;
  }

  onInventoryAttach(inventory: Inventory) {
    this.onInventorySelect.next(inventory);
    this.bsModalRef.hide();
  }
}
