import { QueryBuilder } from './query-builder.class';
import { InventoryMessage } from '../entities/inventory_message.entity';

/**
 * Inventory Message Entity Query Builder.
 */
export class InventoryMessageQueryBuilder extends QueryBuilder {
  constructor() {
    super(new InventoryMessage());
  }
}
