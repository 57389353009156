<div class="float-right mb-2">
  <button
      *ngIf="(form && form.published_at && form.completed_at) || (survey && survey.published_at)"
      (click)="addRevision()"
      class="btn btn-sm btn-info">
    <i class="fa fa-plus mr-1"></i> Add Revision
  </button>
</div>

<div class="clearfix"></div>

<div *ngIf="revisions?.length === 0" class="alert alert-info mb-0">
  <i class="fa fa-info mr-1"></i>
  No Revisions found.
</div>

<div *ngIf="revisions?.length > 0" class="table-responsive">
  <table class="table table-striped mb-0">
    <thead>
      <tr>
        <th>Number</th>
        <th>Date</th>
        <th>Publisher</th>
        <th>Reason</th>
        <th class="text-right">Actions</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let revision of revisions; let last = last;">
        <td>{{ revision.number }}</td>
        <td>{{ revision.date }}</td>
        <td>{{ revision.publisher }}</td>
        <td>{{ revision.reason }}</td>
        <td class="text-right d-md-none">
          <button
              (click)="activeRevision = revision"
              class="btn btn-secondary">
            <i class="fa fa-bars"></i>
          </button>
        </td>
        <td class="text-right d-none d-md-table-cell">
          <ng-container *ngTemplateOutlet="actions; context:{ revision: revision, last: last }"></ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="activeRevision" (click)="activeRevision = null" class="modal-backdrop fade show"></div>

<ng-template #actions let-revision="revision" let-last="last">
  <button
      (click)="onRevisionDelete(revision)"
      [disabled]="revision.deleting || !authService.user.is('admin') || !last"
      [className]="activeRevision ? 'list-group-item list-group-item-action' : 'btn btn-sm btn-danger'">
    <ng-container *ngIf="revision.deleting">
      <i class="fa fa-spin fa-spinner mr-1"></i>
      Deleting
    </ng-container>
    <ng-container *ngIf="!revision.deleting">
      <i class="fa fa-trash mr-1"></i>
      Delete
    </ng-container>
  </button>
  <a
      [href]="revision.url"
      class="btn btn-sm btn-success'"
      target="_blank"
      [className]="activeRevision ? 'list-group-item list-group-item-action' : 'btn btn-sm btn-success'">
    <i class="fa fa-certificate mr-1"></i>
    <span *ngIf="survey">Report</span>
    <span *ngIf="form">Certificate</span>
  </a>
</ng-template>
